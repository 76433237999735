import { Box, styled } from '@mui/material';
import { booleanish } from 'types/booleanish.types';

const DATA_IMPORT_ITEM_HEIGHT = 214;

export const DataItem = styled('div')<{ selected: booleanish; hasWarning?: booleanish }>(
  ({ theme, selected, hasWarning }) => ({
    height: DATA_IMPORT_ITEM_HEIGHT,
    position: 'relative',
    padding: theme.spacing(2),
    background: hasWarning === 'true' ? theme.custom.themeColors.accent[10] : '#fff',
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
    width: 172,

    border:
      selected === 'true'
        ? `2px solid ${theme.palette.secondary.main}`
        : `2px solid ${theme.palette.grey[200]}`,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.6,
    },

    '&:hover .select-button': {
      opacity: 1,
    },
  })
);

export const DetailsCard = styled('div')(({ theme }) => ({
  height: DATA_IMPORT_ITEM_HEIGHT,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(3),
  background: '#fff',
  borderRadius: theme.spacing(1),
  overflow: 'hidden',
  border: `2px solid ${theme.palette.grey[200]}`,
  width: '20%',
  minWidth: 395,
}));

export const DatasetTitle = styled('p')<{ unnamed?: boolean }>(({ theme, unnamed = false }) => ({
  fontSize: '12px',
  lineHeight: '14px',
  fontWeight: 700,
  color: unnamed ? theme.custom.themeColors.grayScale[60] : theme.custom.themeColors.black,
  margin: 0,
  padding: 0,
}));

export const DatasetDate = styled('p')(({ theme }) => ({
  fontSize: '11px',
  lineHeight: '16px',
  fontWeight: 400,
  color: theme.custom.themeColors.grayScale[80],
  margin: 0,
  padding: 0,
}));

export const Badge = styled(Box)<{ severity: 'warning' | 'error' | 'info' }>(
  ({ theme, severity = 'warning' }) => ({
    background:
      severity === 'error'
        ? theme.custom.themeColors.error[60]
        : severity === 'warning'
        ? theme.custom.themeColors.accent[60]
        : theme.custom.themeColors.primary[40],
    padding: '4px 8px',
    borderRadius: 16,
    fontWeight: 700,
    fontSize: '10px',
    whiteSpace: 'nowrap',
    width: 'fit-content',
  })
);
