import { Box, IconButton, styled, Tooltip } from '@mui/material';
import { Location } from 'components/Partners/PartnersTable/PartnersTableBody/PartnersTableBody.styles';
import { FlexBox } from 'components/Structure';
import { CardContainer, ThemeTypography } from 'designSystem';
import Image from 'designSystem/DataDisplay/Image/Image';
import Icon from 'designSystem/Primitives/Icon/Icon';
import React, { FC, MouseEvent } from 'react';
import { IActivity } from 'types/activity.types';

interface IActivityItemProps {
  activity: IActivity;
  onClick?: (activity: IActivity) => void;
  onEdit?: (activity: IActivity) => void;
  onDelete?: (activity: IActivity) => void;
}

const Tag = styled(FlexBox)(({ theme }) => ({
  background: theme.custom.colors.lightBorderColor,
  height: 21,
  padding: theme.spacing(1),
}));

const StyledCardContainer = styled(CardContainer)(({ theme }) => ({
  maxWidth: '100%',
  '&:not(:last-child)': {
    marginBottom: theme.spacing(1),
  },
}));

const ActivityItem: FC<IActivityItemProps> = ({ activity, onClick, onEdit, onDelete }) => {
  const { title, site, mediaList, component } = activity;

  // Check if an image exists on activity
  const firstMediaWithImage = mediaList.find(media => media.imageId || media.imageVariantId);

  const handleEdit = (event: MouseEvent) => {
    event.stopPropagation();
    onEdit?.(activity);
  };
  const handleDelete = (event: MouseEvent) => {
    event.stopPropagation();
    onDelete?.(activity);
  };

  return (
    <StyledCardContainer padding={1.5} blueHover={!!onClick} onClick={() => onClick?.(activity)}>
      <FlexBox maxWidth="100%" justifyContent="space-between">
        {/*  48px for the action button container & 24px padding */}
        <FlexBox width="calc(100% - 48px - 24px)">
          {firstMediaWithImage?.url ? (
            <Image alt={`Logo of ${title}`} image={firstMediaWithImage} height={44} width={44} />
          ) : (
            <Box display="flex" bgcolor="white" borderRadius="6px" padding="4px">
              <Icon name="activity-placeholder" color="blue-ice" size="xx-large" />
            </Box>
          )}
          {/* 8px py padding & 44px image & margin 16px */}
          <Box width="calc(100% - 68px)" ml={1.5} display="flex" flexDirection="column">
            <FlexBox maxWidth="100%">
              <ThemeTypography variant="ITEM_TITLE" autoOverflow>
                {title}
              </ThemeTypography>
              {component && (
                <>
                  <Icon name="arrow-right" size="large" mx={1} />
                  <Tag>
                    <ThemeTypography variant="LABEL_INPUT" autoOverflow>
                      {component?.title}
                    </ThemeTypography>
                  </Tag>
                </>
              )}
            </FlexBox>
            <Location autoOverflow variant="BODY_MEDIUM">
              {site.locationName}
            </Location>
          </Box>
        </FlexBox>

        <FlexBox gap={0.5} mr={1}>
          {onEdit && (
            <Tooltip title="Edit activity">
              <IconButton onClick={handleEdit}>
                <Icon name="edit" size="medium" />
              </IconButton>
            </Tooltip>
          )}
          {onDelete && (
            <Tooltip title="Delete activity">
              <IconButton onClick={handleDelete}>
                <Icon name="delete" size="medium" />
              </IconButton>
            </Tooltip>
          )}
        </FlexBox>
      </FlexBox>
    </StyledCardContainer>
  );
};

export default ActivityItem;
