import React, { FC, ReactElement } from 'react';
import { DatasetError } from 'types/dataset.types';
import { Box, styled } from '@mui/material';
import { ThemeTypography } from 'designSystem';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface IWarningTooltipProps {
  warnings: DatasetError[];
  severity?: 'warning' | 'error';
  children: ReactElement;
}

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    maxWidth: 480,
    padding: theme.spacing(3),
    boxShadow: theme.custom.shadows[1],
  },
}));

const Title = styled(ThemeTypography)({
  textTransform: 'uppercase',
  fontWeight: '300 !important',
});

const WarningTextContainer = styled(Box)<{ severity: 'error' | 'warning' }>(
  ({ theme, severity }) => ({
    borderRadius: 4,
    padding: '2px 4px',
    background:
      severity === 'error'
        ? theme.custom.themeColors.error[20]
        : theme.custom.themeColors.accent[20],

    '&:not(:last-child)': {
      marginBottom: 10,
    },
  })
);

const NumberCyrcle = styled(Box)<{ severity: 'error' | 'warning' }>(({ theme, severity }) => ({
  width: 16,
  height: 16,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background:
    severity === 'error' ? theme.custom.themeColors.error[60] : theme.custom.themeColors.accent[60],
}));

const WarningTooltip: FC<IWarningTooltipProps> = ({ warnings, severity, children }) => {
  return (
    <StyledTooltip
      title={
        <Box>
          <Box display="flex" alignItems="center" gap={1} mb={1}>
            <NumberCyrcle severity={severity || 'warning'}>
              <ThemeTypography variant="BODY_SMALL">{warnings.length}</ThemeTypography>
            </NumberCyrcle>
            <Title variant="ITEM_TITLE">Plot {severity}s</Title>
          </Box>

          {warnings.map((warning, index) => (
            <WarningTextContainer key={index} severity={severity || 'warning'}>
              <ThemeTypography variant="BODY_SMALL" color="GRAY_100">
                {warning.errorMessage}
              </ThemeTypography>
            </WarningTextContainer>
          ))}
        </Box>
      }
    >
      {children}
    </StyledTooltip>
  );
};

export default WarningTooltip;
