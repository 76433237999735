import { Box, Grid, styled } from '@mui/material';
import MappingItem from 'components/DueDiligenceProcess/components/MappingItem';
import { ErrorState, FlexBox } from 'components/Structure';
import { IGeoMappingFormValue } from 'constants/schemas/geoUpload.schema';
import { InfoTooltip, ThemeButton, ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { Field, useField } from 'formik';
import React, { FC } from 'react';
import { AvailableSizes } from 'types/enums';

interface IDatasetMappingFormProps {
  datasetIndex: number;
}

const MappingContainer = styled(Box)(() => ({
  background: '#fff',
  borderRadius: 4,
  border: `1px solid #EEEEEE`,
}));

const TableHeaderNames = styled(ThemeTypography)(() => ({
  fontWeight: `300 !important`,
  textTransform: 'uppercase',
}));

const DatasetMappingForm: FC<IDatasetMappingFormProps> = ({ datasetIndex }) => {
  const [{ value: datasetValues }, { error }, { setValue }] = useField<
    IGeoMappingFormValue | undefined
  >(`[${datasetIndex}]`);

  const notRemovedColumns = datasetValues?.mappedColumns?.filter(({ removed }) => !removed);

  const handleClickExcludeAll = () => {
    setValue({
      ...datasetValues,
      datasetId: datasetValues?.datasetId || '',
      mappedColumns:
        datasetValues?.mappedColumns?.map(column => ({ ...column, removed: true })) || [],
    });
  };

  return (
    <MappingContainer mt={2} px={2} py={2.5}>
      {error && (
        <Box mb={3}>
          <ThemeTypography variant="BODY_LARGE" color="RED">
            <>{error}</>
          </ThemeTypography>
        </Box>
      )}

      <Box mb={1}>
        <Grid container>
          <Grid item xs={3}>
            <FlexBox>
              <TableHeaderNames variant="BODY_LARGE">Uploaded Columns</TableHeaderNames>
              <InfoTooltip size={AvailableSizes.SMALL} text="The data columns that you uploaded" />
            </FlexBox>
          </Grid>
          <Grid item xs={3}>
            <FlexBox>
              <TableHeaderNames variant="BODY_LARGE">File Sample Data</TableHeaderNames>
              <InfoTooltip
                size={AvailableSizes.SMALL}
                text="Samples of what is contained in each column of your uploaded dataset"
              />
            </FlexBox>
          </Grid>
          <Grid item xs={3}>
            <FlexBox>
              <TableHeaderNames variant="BODY_LARGE">Template Columns</TableHeaderNames>
              <InfoTooltip
                size={AvailableSizes.SMALL}
                text="The equivalent column in the Seedtrace system with required formats"
              />
            </FlexBox>
          </Grid>
          <Grid item xs={3}>
            {!!notRemovedColumns?.length && (
              <FlexBox justifyContent="flex-end">
                <ThemeButton
                  color="BLUE_ICE"
                  size="medium"
                  padding="5px 10px !important"
                  onClick={handleClickExcludeAll}
                  startIcon={<Icon name="exclude" size="medium" color="blue-100" />}
                >
                  Exclude all
                </ThemeButton>
              </FlexBox>
            )}
          </Grid>
        </Grid>
      </Box>

      {datasetValues?.mappedColumns?.length ? (
        datasetValues.mappedColumns.map((_, index) => (
          <Field
            key={index}
            component={MappingItem}
            name={`${datasetIndex}.mappedColumns[${index}]`}
          />
        ))
      ) : (
        <Box position="relative" minHeight={400}>
          <ErrorState error="Something went wrong processing your sheet. The columns could not be mapped correctly." />
        </Box>
      )}
    </MappingContainer>
  );
};

export default DatasetMappingForm;
