import { DialogContent, styled, useTheme } from '@mui/material';
import { StyledLink } from 'components/ChainMapping/Forms/styles';
import { useConfig, useDialog } from 'components/hooks';
import { InlineHelperText } from 'components/Product/InlineHelperText/InlineHelperText';
import { FlexBox, SeparatorLine } from 'components/Structure';
import { DialogDefault, ThemeButton, ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IDefaultDialogProps } from 'types/dialog.types';
import { IBasePartnerCompany } from 'types/partner.types';

interface ICreateDatasetDecisionDialogProps extends IDefaultDialogProps {
  datasetOwnedBy?: IBasePartnerCompany;
}

const HelperTextWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const CreateDatasetDecisionDialog: FC<ICreateDatasetDecisionDialogProps> = ({
  open,
  datasetOwnedBy,
  onClose,
}) => {
  const { geoDataTemplateURL } = useConfig();
  const { openDialog } = useDialog();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleUploadGeoDataClick = () => {
    openDialog({
      type: 'DATASET_IMPORT',
      props: { ownedBy: datasetOwnedBy },
    });
    onClose?.();
  };

  const handleManualEnterClick = () => {
    navigate(`${location.pathname}/datasets`);
    onClose?.();
  };

  return (
    <DialogDefault
      open={open}
      title="New dataset"
      onClose={onClose}
      maxWidth="xs"
      data-cy="decision-dialog"
    >
      <DialogContent>
        <ThemeButton
          color="YELLOW"
          size="large"
          fullWidth
          data-cy="select-partner-button"
          startIcon={<Icon name="upload" />}
          onClick={handleUploadGeoDataClick}
        >
          Upload geo-data file
        </ThemeButton>
        <FlexBox mt={2} gap={0.5}>
          <ThemeTypography color="GRAY_60" variant="BODY_SMALL">
            Upload origin geo-data by .csv .xlsx .kml or .geojson.
            <StyledLink to={geoDataTemplateURL}>
              {' '}
              Download optimal file template examples.
            </StyledLink>
          </ThemeTypography>
        </FlexBox>
        <SeparatorLine label="or" width="80%" backgroundColor={theme.custom.colors.offWhite} />

        <ThemeButton
          color="BLUE_ICE"
          size="large"
          fullWidth
          data-cy="add-new-activity-button"
          onClick={handleManualEnterClick}
        >
          Manually enter geo-data
        </ThemeButton>

        <HelperTextWrapper>
          <InlineHelperText
            variant="INFO"
            helperText="Add geo-data by copy & pasting into the table or manually entering numbers"
          />
        </HelperTextWrapper>
      </DialogContent>
    </DialogDefault>
  );
};

export default CreateDatasetDecisionDialog;
