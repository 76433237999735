import { GeoCBatch, TRiskLevel } from './compliance.types';
import { IRawMaterial } from './component.types';
import { IBasePartnerCompany } from './partner.types';
import { ICultivatedAreas } from './cultivatedArea.types';
import { GraphQlConnection } from './types';

export enum EDatasetExportType {
  ORIGINAL = 'ORIGINAL',
  VALIDATED = 'VALIDATED',
}

export enum EDatasetExportFormat {
  CSV = 'CSV',
  EXCEL = 'EXCEL',
  GEOJSON = 'GEOJSON',
}

export enum EDatasetStatus {
  CREATED = 'CREATED',
  PARSED = 'PARSED',
  PARSING_FAILED = 'PARSING_FAILED',
  MAPPED = 'MAPPED',
  MAPPING_FAILED = 'MAPPING_FAILED',
  VALIDATED = 'VALIDATED',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
  VERIFICATION_FAILED = 'VERIFICATION_FAILED',
  NORMALISED = 'NORMALISED',
  NORMALISATION_FAILED = 'NORMALISATION_FAILED',
  COMPLETED = 'COMPLETED',
}

export enum EDatasetColumn {
  FARM_ID = 'FARM_ID',
  ORIGIN_CLUSTER_ID = 'ORIGIN_CLUSTER_ID',
  FARM_NAME = 'FARM_NAME',
  FARM_OWNER_FIRST_NAME = 'FARM_OWNER_FIRST_NAME',
  FARM_OWNER_LAST_NAME = 'FARM_OWNER_LAST_NAME',
  FARM_OWNER_FULL_NAME = 'FARM_OWNER_FULL_NAME',
  NUMBER_OF_FARMERS = 'NUMBER_OF_FARMERS',
  COORDINATES_LAT_LNG = 'COORDINATES_LAT_LNG',
  COORDINATES_LNG_LAT = 'COORDINATES_LNG_LAT',
  LATITUDE = 'LATITUDE',
  LONGITUDE = 'LONGITUDE',
  FARM_SIZE_KM2 = 'FARM_SIZE_KM2',
  FARM_SIZE_HA = 'FARM_SIZE_HA',
  ADDITIONAL_CROPS = 'ADDITIONAL_CROPS',
  LAND_OWNERSHIP = 'LAND_OWNERSHIP',
  SITE_DESCRIPTION = 'SITE_DESCRIPTION',
}

export type CoordinateDatasetColumnType =
  | `${EDatasetColumn.COORDINATES_LAT_LNG}`
  | `${EDatasetColumn.COORDINATES_LNG_LAT}`;

export type FarmSizeDatasetColumnType =
  | `${EDatasetColumn.FARM_SIZE_KM2}`
  | `${EDatasetColumn.FARM_SIZE_HA}`;

export enum EDatasetErrorEntity {
  SITE = 'SITE',
  DOCUMENT = 'DOCUMENT',
  DATASET = 'DATASET',
}

export enum EDatasetErrorCategory {
  EUDR_REQUIREMENT = 'EUDR_REQUIREMENT',
}

export type TDatasetColumnType = `${EDatasetColumn}`;

export type TDatasetErrorEntity = `${EDatasetErrorEntity}`;

export type TDatasetStatus = `${EDatasetStatus}`;

export const DATASET_STATUS: Record<EDatasetStatus, string> = {
  [EDatasetStatus.CREATED]: 'Created',
  [EDatasetStatus.PARSED]: 'Parsed',
  [EDatasetStatus.PARSING_FAILED]: 'Parsing failed',
  [EDatasetStatus.MAPPED]: 'Mapped',
  [EDatasetStatus.MAPPING_FAILED]: 'Mapping failed',
  [EDatasetStatus.VALIDATED]: 'Validated',
  [EDatasetStatus.VALIDATION_FAILED]: 'Validation failed',
  [EDatasetStatus.VERIFICATION_FAILED]: 'Verification failed',
  [EDatasetStatus.NORMALISED]: 'Normalised',
  [EDatasetStatus.NORMALISATION_FAILED]: 'Normalisation failed',
  [EDatasetStatus.COMPLETED]: 'Completed',
};

export interface DatasetError {
  entityId: string;
  entityType: TDatasetErrorEntity;
  errorMessage: string;
  errorCategory?: EDatasetErrorCategory;
}

export interface IDataset {
  id: string;
  title: string;
  status: TDatasetStatus;
  createdTimestamp: Date;
  modifiedTimestamp: Date;
  originCountry: string;
  documents: { id: string; title: string }[];
  sites?: { count: number };
  /**
   * PartnerCompany base values
   */
  ownedBy: IBasePartnerCompany;
  rawMaterial: IRawMaterial;
  riskStatus: TRiskLevel;
  geocBatches: GeoCBatch[];
  cultivatedAreas?: ICultivatedAreas[];
  tableRepresentation?: DatasetTableRepresentation[];
  issues: DatasetError[];
}

export enum DatasetView {
  LIST = 'LIST',
  MAP = 'MAP',
}

export interface IDatasetsResponse {
  datasets: GraphQlConnection<IDataset>;
}

export interface ColumnsData {
  columnName: string;
  columnDataExample: string[]; // Array of strings representing first 5 items as examples
  mappedColumnName?: TDatasetColumnType; // The backend tries to automatically map columns based on the column name
}

export interface IDatasetMapping {
  datasetId: string;
  columnsData: ColumnsData[];
}

export interface DatasetError {
  entityId: string;
  entityType: TDatasetErrorEntity;
  errorMessage: string;
}

export type EUDRDatasetCell = {
  value?: string | number | Date;
  error?: DatasetError[];
  isValid?: boolean;
};

export type EUDRDatasetRow = {
  farmId: EUDRDatasetCell;
  farmName?: EUDRDatasetCell;
  coordinates: EUDRDatasetCell;
  farmSizeKm2?: EUDRDatasetCell;
  originClusterId?: EUDRDatasetCell;
  numberOfFarmers?: EUDRDatasetCell;
  additionalCrops?: EUDRDatasetCell;
  landOwnership?: EUDRDatasetCell;
  farmOwnerFullName?: EUDRDatasetCell;
  farmOwnerFirstName?: EUDRDatasetCell;
  farmOwnerLastName?: EUDRDatasetCell;
  siteDescription?: EUDRDatasetCell;
};

export type DatasetTableRepresentation = {
  columnsNames: TDatasetColumnType[];
  rows: EUDRDatasetRow[];
};

export interface DatasetProcessingError {
  datasetId: string;
  datasetErrors: DatasetError[];
  hasCellErrors: boolean;
}

export interface IProcessDatasetsPayload {
  datasets: IDataset[];
  datasetProcessingErrors?: DatasetProcessingError[];
  datasetMappings?: IDatasetMapping[];
}
export interface FileConfiguration {
  csvSeparator?: TCsvSeparator;
  decimalSeparator?: TDecimalSeparator;
}
export enum ECsvSeparator {
  'COMMA' = 'COMMA',
  'SEMICOLON' = 'SEMICOLON',
  'TAB' = 'TAB',
  'AUTO_DETECT' = 'AUTO_DETECT',
}

export enum EDecimalSeparator {
  'DOT' = 'DOT',
  'COMMA' = 'COMMA',
}

export type TCsvSeparator = `${ECsvSeparator}`;

export type TDecimalSeparator = `${EDecimalSeparator}`;

export type IPreProcessDatasetPayload = IProcessDatasetsPayload;
