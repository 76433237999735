import { Box, styled } from '@mui/material';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import React, { FC } from 'react';
import { ERiskLevel, TRiskLevel } from 'types/compliance.types';

const Container = styled(Box)<{ riskLevel: TRiskLevel }>(({ theme, riskLevel }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 4,
  padding: theme.spacing(0.2, 1),
  backgroundColor:
    riskLevel === ERiskLevel.HIGH
      ? theme.custom.themeColors.error[20]
      : riskLevel === ERiskLevel.MEDIUM
      ? theme.custom.themeColors.accent[20]
      : theme.custom.themeColors.success[20],
}));

const RiskBadgeText = styled(ThemeTypography)<{ riskLevel: TRiskLevel }>(
  ({ theme, riskLevel }) => ({
    color: `${
      riskLevel === ERiskLevel.HIGH
        ? theme.custom.themeColors.error[100]
        : riskLevel === ERiskLevel.MEDIUM
        ? theme.custom.themeColors.accent[100]
        : theme.custom.themeColors.success[100]
    } !important`,
  })
);

interface IRiskBadgeProps {
  riskLevel: TRiskLevel;
  text?: string;
}

const RiskBadge: FC<IRiskBadgeProps> = ({ riskLevel, text }) => {
  return (
    <Container riskLevel={riskLevel}>
      <RiskBadgeText variant="BODY_SMALL_BOLD" riskLevel={riskLevel}>
        {text ? text : riskLevel}
      </RiskBadgeText>
    </Container>
  );
};

export default RiskBadge;
