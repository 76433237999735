import React, { FC } from 'react';
import { styled, Box } from '@mui/material';
import { GeoCBatch } from 'types/compliance.types';
import { DropDown } from 'components/Forms';
import { AvailableSizes } from 'types/enums';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import Divider from 'designSystem/Layout/Divider/Divider';
import { FlexBox } from 'components/Structure';

interface IBatchSelectionProps {
  batches: GeoCBatch[];
  selectedBatch: GeoCBatch;
  onSelectBatch: (batch: GeoCBatch) => void;
}

const DropdownContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  minWidth: 280,
  marginRight: theme.spacing(1.5),

  '& .MuiSelect-select': {
    background: '#fff !important',
    borderRadius: 4,
    border: `1px solid #EEEEEE`,
    paddingTop: '4px !important',
    paddingBottom: '4px !important',
    maxWidth: 120,

    '& .MuiTypography-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.custom.themeColors.grayScale[20],
  borderColor: theme.custom.themeColors.grayScale[20],
  borderBottomWidth: 'initial',
}));

const BatchSelection: FC<IBatchSelectionProps> = ({ batches, selectedBatch, onSelectBatch }) => {
  return (
    <FlexBox>
      <DropdownContainer>
        <ThemeTypography variant="LABEL_INPUT">Show origin batch:</ThemeTypography>
        <Box mb={0.5} />
        <DropDown
          size={AvailableSizes.SMALL}
          options={batches}
          onChange={(item: GeoCBatch) => {
            onSelectBatch(item);
          }}
          renderItems={(item: GeoCBatch) => (
            <ThemeTypography variant="BODY_SMALL">{item.id}</ThemeTypography>
          )}
          currentValue={selectedBatch}
        />
      </DropdownContainer>
      <Box width="100%">
        <StyledDivider orientation="horizontal" />
      </Box>
    </FlexBox>
  );
};

export default BatchSelection;
