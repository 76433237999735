import { Box, ButtonGroup, FormControl, FormHelperText, TextField, styled } from '@mui/material';
import { areCoordinatesValid } from 'components/DataImport/utils/dataImport.validators';
import { useQueryReturn } from 'components/hooks';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import { FieldProps, useField } from 'formik';
import { GET_PLACES } from 'graphql/queries';
import React, { FC, useEffect, useState } from 'react';
import { CoordinateDatasetColumnType, EDatasetColumn } from 'types/dataset.types';

interface ICoordinatesInputFieldProps extends FieldProps {
  locationNameKey?: string;
}

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const StyledTextField = styled(TextField)(() => ({
  minWidth: 200,
  '& .Mui-error': {
    marginLeft: 0,
    marginBottom: 2,
  },
}));

const CoordinatesInputField: FC<ICoordinatesInputFieldProps> = ({
  field,
  form,
  locationNameKey,
}) => {
  const { name, value } = field;
  const [, { error }, { setError, setValue }] = useField(name);
  const { setFieldValue } = form;
  const [input, setInput] = useState<string>('');
  const [coordinateFormat, setCoordinateFormat] = useState<CoordinateDatasetColumnType>(
    EDatasetColumn.COORDINATES_LAT_LNG
  );

  const [, , { setValue: setMapBoxId }] = useField<string | null>('mapboxId');

  useEffect(() => {
    if (value && (value.lat === undefined || value.lng === undefined)) {
      setInput('');
    } else {
      if (coordinateFormat === EDatasetColumn.COORDINATES_LAT_LNG) {
        setInput(`${value?.lat},${value?.lng}`);
      } else {
        setInput(`${value?.lng},${value?.lat}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    handleSetCoordinates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinateFormat]);

  const getLocation = useQueryReturn(GET_PLACES, {
    fetchPolicy: 'no-cache',
  });
  const request = (query: unknown) =>
    getLocation({
      variables: {
        query,
      },
    });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };

  const handleSetCoordinates = async () => {
    if (!input) {
      return;
    }

    if (!areCoordinatesValid(input, coordinateFormat)) {
      setError('Invalid coordinates');
      return;
    } else {
      setError(undefined);
    }

    const [fistCoordinate, secondCoordinate] = input?.split(',').map(Number);
    const lat =
      coordinateFormat === EDatasetColumn.COORDINATES_LNG_LAT ? secondCoordinate : fistCoordinate;
    const lng =
      coordinateFormat === EDatasetColumn.COORDINATES_LNG_LAT ? fistCoordinate : secondCoordinate;
    const hasLocationNameKey = locationNameKey !== undefined;
    const hasLatAndLng = !isNaN(lat) && !isNaN(lng);

    if (hasLocationNameKey && hasLatAndLng) {
      const { data } = await request(`${lng},${lat}`);

      if (data.getPlaces.length !== 0) {
        const location = data.getPlaces[0];
        setFieldValue(locationNameKey, location.title);
        setMapBoxId(data.getPlaces[0].mapboxId);
      }
    }
    if (hasLatAndLng) {
      setValue({ lat, lng });
    }
  };

  return (
    <FormControl fullWidth size="small" error={!!error}>
      <Box display="flex" alignItems="center" overflow="hidden">
        <StyledTextField
          variant="outlined"
          size="small"
          name={name}
          error={!!error}
          value={input}
          placeholder={
            coordinateFormat === EDatasetColumn.COORDINATES_LNG_LAT
              ? 'Enter coordinates (lng, lat)'
              : 'Enter coordinates (lat,lng)'
          }
          onChange={handleChange}
          onBlur={handleSetCoordinates}
        />
        <StyledButtonGroup>
          <ThemeButton
            size="medium"
            className={coordinateFormat === EDatasetColumn.COORDINATES_LNG_LAT ? 'selected' : ''}
            onClick={() => setCoordinateFormat(EDatasetColumn.COORDINATES_LAT_LNG)}
          >
            Lat, Lng
          </ThemeButton>
          <ThemeButton
            size="medium"
            className={coordinateFormat === EDatasetColumn.COORDINATES_LNG_LAT ? 'selected' : ''}
            onClick={() => setCoordinateFormat(EDatasetColumn.COORDINATES_LAT_LNG)}
          >
            Lng, Lat
          </ThemeButton>
        </StyledButtonGroup>
      </Box>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

export default CoordinatesInputField;
