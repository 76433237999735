import { Box } from '@mui/material';
import QnAGuideTemplate from 'components/DueDiligenceProcess/components/QnAGuideTemplate';
import {
  TGeoDataCollectionMethod,
  useGeoDataCollection,
} from 'components/DueDiligenceProcess/Context/SubSections/GeoDataCollectionContext';
import DueDiligenceProcessSubSectionNavigation from 'components/DueDiligenceProcess/DueDiligenceProcessSubSectionNavigation';
import { InlineHelperText } from 'components/Product/InlineHelperText/InlineHelperText';
import React, { FC, useEffect } from 'react';
import { EDueDiligenceProcessSubSectionKey } from 'types/dueDilligenceProcess.types';

const GeoDataCollectionMethods: FC = () => {
  const { selectedDatasetIds, geoDataCollectionMethods, setGeoDataCollectionMethods, onNextClick } =
    useGeoDataCollection();

  /**
   * If returning with a preselection of datasets, we need to set the default method to selection
   */
  useEffect(() => {
    if (!geoDataCollectionMethods.length && selectedDatasetIds.length) {
      setGeoDataCollectionMethods([EDueDiligenceProcessSubSectionKey.SELECTION]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DueDiligenceProcessSubSectionNavigation
      disabledPreviousNavigation
      allowNextStepNavigation={!!geoDataCollectionMethods.length}
      onNextStepClick={onNextClick}
    >
      <QnAGuideTemplate<TGeoDataCollectionMethod>
        multipleAnswers
        selectedAnswers={geoDataCollectionMethods}
        question=""
        answers={[
          {
            id: EDueDiligenceProcessSubSectionKey.UPLOAD,
            label: 'I want to upload geo-data.',
          },
          {
            id: EDueDiligenceProcessSubSectionKey.SELECTION,
            label: selectedDatasetIds.length ? (
              <>
                I want to use previously added geo-data.
                <b>
                  {' ('}
                  {selectedDatasetIds.length} included dataset
                  {selectedDatasetIds.length > 1 ? 's)' : ')'}
                </b>
              </>
            ) : (
              'I want to use previously added geo-data.'
            ),
            disabled: !!selectedDatasetIds.length,
          },
          {
            id: EDueDiligenceProcessSubSectionKey.REQUEST,
            label: 'I want to request geo-data from my suppliers.',
            disabled: true,
          },
          {
            id: EDueDiligenceProcessSubSectionKey.DRAWING,
            label: 'I want to create geo-data plots by drawing points or polygons on the map.',
            disabled: true,
          },
        ]}
        onAnswerChange={setGeoDataCollectionMethods}
      />
      <Box mt={3} />
      <InlineHelperText
        variant="INFO"
        helperText="Seedtrace are working on adding more support for you and your suppliers to collect geo-data. The compliance guide will soon support you in creating adapted requests to your suppliers, giving them clear instructions of how to upload and normalise data or how to draw their polygon geo-data on a map."
      />
    </DueDiligenceProcessSubSectionNavigation>
  );
};

export default GeoDataCollectionMethods;
