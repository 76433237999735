import { Grid } from '@mui/material';
import { PartnerAutocomplete } from 'components/Partners/Form';
import { FieldWrapper } from 'designSystem';
import { useField } from 'formik';
import React, { FC } from 'react';
import { ShadowedFieldBlock } from './styles';
import { IChainActivity } from 'types/chain.types';

const CompanySelectBlock: FC<{ disabled?: boolean }> = ({ disabled }) => {
  const [field, , helper] = useField<IChainActivity['activity']['ownedBy'] | null>(
    'activity.ownedBy'
  );

  const handlePartnerSelect = (partner: IChainActivity['activity']['ownedBy'] | null) => {
    helper.setValue(partner);
  };

  return (
    <ShadowedFieldBlock container>
      <Grid item xs={12}>
        <FieldWrapper
          label="Owner"
          tooltip={{
            helperText:
              'Add the company that owns or facilitates this activity in the supply chain. The owner is a company that is linked to this activity and that has ownership over the data. The owner has access to confidentiality settings of their data.',
            variant: 'INFO',
          }}
        >
          <PartnerAutocomplete
            filterByRegisteredPartners={false}
            placeholder="Type or select company"
            defaultValue={field.value}
            disabled={disabled}
            onPartnerSelect={handlePartnerSelect}
            fontSize={14}
          />
        </FieldWrapper>
      </Grid>
    </ShadowedFieldBlock>
  );
};

export default CompanySelectBlock;
