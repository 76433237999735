import UploadContext from 'contexts/UploadContext';
import { useContext } from 'react';
import { IDocument } from 'types/document.types';

type AddionalProperties = {
  formikName: string;
};

type FileMap = {
  [id: string]: {
    recordId: string;
    data: File;
    error?: string;
    success: boolean;
    uploadStarted: boolean;
    uploadTask: unknown;
    itemType: string;
    contextObject: AddionalProperties;
  };
};

// Not 100% sure about these types, but don't have time to type the full context atm
interface ReturnProps {
  cleanFiles: () => void;
  addFiles: (fileIDs: string[]) => void;
  removeFile: (id: string, isRecordId?: boolean) => void;
  fileMap: FileMap;
  fileIDs: string[];
  createdRecords: IDocument[];
  handleCleanCreatedRecords: () => void;
  undoFileUploads: () => void;
}

const useUploadState = () => {
  const values: ReturnProps = useContext(UploadContext);
  return values;
};

export default useUploadState;
