import { IOriginTableSettings } from 'types/dataImport.types';
import { EDatasetColumn } from 'types/dataset.types';
import * as Yup from 'yup';

export type IEudrStatementCreationFormValues = {
  title: string;
  year: number;
};

export type IEudrStatementSubmitSchemaFormValues = {
  eoriNumber: string;
  importToCountry: string;
  internalDdsNumber?: string;
  confirmed: boolean;
};

export const eudrStatementCreationSchema = Yup.object().shape({
  title: Yup.string().required('This field is required'),
  year: Yup.number().required('This field is required'),
}) as Yup.ObjectSchema<IEudrStatementCreationFormValues>;

export const eudrStatementSubmitSchema = Yup.object().shape({
  eoriNumber: Yup.string().required('This field is required'),
  internalDdsNumber: Yup.string().nullable(),
  importToCountry: Yup.string().required('This field is required'),
  confirmed: Yup.bool().default(false).oneOf([true]).required('Needs to be confirmed'),
}) as Yup.ObjectSchema<IEudrStatementSubmitSchemaFormValues>;

export const createTableSettingsColumnSchema = (defaultEnabled = false) =>
  Yup.object().shape({
    enabled: Yup.boolean().default(defaultEnabled),
    format: Yup.string().nullable().default(''),
  });

export const originTableSettingsSchema = Yup.object().shape({
  overwriteExisting: Yup.boolean().default(true),
  clusterSites: Yup.boolean().default(true),
  columns: Yup.object()
    .shape({
      farmId: Yup.object().shape({
        enabled: Yup.boolean().default(true),
      }),
      coordinates: Yup.object().shape({
        enabled: Yup.boolean().default(true),
        format: Yup.string()
          .default(EDatasetColumn.COORDINATES_LAT_LNG)
          .oneOf([EDatasetColumn.COORDINATES_LAT_LNG, EDatasetColumn.COORDINATES_LNG_LAT]),
      }),
      originClusterId: Yup.object().shape({
        enabled: Yup.boolean().default(false),
      }),
      farmSize: Yup.object().shape({
        enabled: Yup.boolean().default(false),
        format: Yup.string()
          .default(EDatasetColumn.FARM_SIZE_KM2)
          .oneOf([EDatasetColumn.FARM_SIZE_HA, EDatasetColumn.FARM_SIZE_KM2]),
      }),
      farmName: Yup.object().shape({
        enabled: Yup.boolean().default(false),
      }),
      farmOwnerFullName: Yup.object().shape({
        enabled: Yup.boolean().default(false),
      }),
      farmOwnerFirstName: Yup.object().shape({
        enabled: Yup.boolean().default(false),
      }),
      farmOwnerLastName: Yup.object().shape({
        enabled: Yup.boolean().default(false),
      }),
      landOwnership: Yup.object().shape({
        enabled: Yup.boolean().default(false),
      }),
      numberOfFarmers: Yup.object().shape({
        enabled: Yup.boolean().default(false),
      }),
      additionalCrops: Yup.object().shape({
        enabled: Yup.boolean().default(false),
      }),
      siteDescription: Yup.object().shape({
        enabled: Yup.boolean().default(false),
      }),
    })
    .required(),
}) as Yup.ObjectSchema<IOriginTableSettings>;
