import { Box, FormControl, IconButton, InputLabel, MenuItem, styled } from '@mui/material';
import { FlexBox } from 'components/Structure';
import { DatasetBatch } from 'constants/schemas/riskAnalysis.schema';
import { CardContainer, InfoTooltip, ThemeTypography } from 'designSystem';
import DatePickerField from 'designSystem/Formik/DatePickerField/DatePickerField';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { Field, FieldProps, useField } from 'formik';
import { Select, SelectProps, TextField } from 'formik-mui';
import React, { FC, useEffect } from 'react';
import { IBaseHsCode } from 'types/component.types';
import { v4 as uuid } from 'uuid';

interface IBatchCardFieldProps {
  enableDelete?: boolean;
  hsCodes?: IBaseHsCode[];
  onBatchDelete: () => void;
}

const ItemContainer = styled(Box)(({ theme }) => ({
  padding: 11,
  borderRadius: 8,
  background: theme.custom.themeColors.grayScale[10],
  display: 'flex',
  alignItems: 'center',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  minWidth: 120,

  '& input': {
    fontSize: '11px !important',
  },

  '& .MuiFormHelperText-root': {
    margin: 0,
    fontSize: 9,
  },

  '& .MuiInputLabel-root': {
    top: 2,
    lineHeight: '1rem',
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiSelect-select': {
    paddingRight: '32px !important',
    minWidth: 150,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
    '& input': {
      fontSize: '11px !important',
    },
    '& .MuiInputLabel-root': {
      top: 2,
      lineHeight: '1rem',
    },
  },
}));

const StyledPlaceholder = styled(InputLabel)<{ styledSize: 'small' | 'medium' }>(
  ({ theme, styledSize }) => ({
    zIndex: 1,
    fontSize: 12,
    fontWeight: 500,
    color: `${theme.palette.grey[500]} !important`,
    transform: `translate(14px, ${styledSize === 'small' ? 6 : 8}px) scale(1)`,
  })
);

const CustomSelect: FC<SelectProps> = ({ placeholder, ...props }) => {
  return (
    <StyledFormControl fullWidth>
      {/* Placeholder */}
      {!props.field?.value && (
        <StyledPlaceholder variant="outlined" styledSize="small" shrink={false}>
          {placeholder}
        </StyledPlaceholder>
      )}
      <Select {...props} inputLabel={{ shrink: true }} />
    </StyledFormControl>
  );
};

const DatePickerContainer = styled(Box)(({ theme }) => ({
  padding: 11,
  borderRadius: 8,
  background: theme.custom.themeColors.accent[20],
  display: 'flex',
  alignItems: 'center',

  '& .MuiFormControl-root': {
    width: 120,
  },

  '& input': {
    fontSize: '11px !important',
  },

  '& .MuiFormHelperText-root': {
    margin: 0,
    fontSize: 9,
  },

  '& .MuiInputLabel-root': {
    top: 3,
  },
}));

const BatchCardField: FC<IBatchCardFieldProps & FieldProps<DatasetBatch>> = ({
  enableDelete,
  field: { name },
  hsCodes = [],
  onBatchDelete,
}) => {
  const [autoAssignIdsField] = useField<boolean>('autoAssignIds');
  const [, , { setValue, setTouched }] = useField<string>(`${name}.externalBatchId`);

  useEffect(() => {
    if (autoAssignIdsField.value) {
      setValue(uuid());
      setTouched(true, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoAssignIdsField.value]);

  return (
    <CardContainer padding={1.5} width="100%">
      <FlexBox justifyContent="space-between">
        <FlexBox gap={2}>
          <Icon name="batch" color="gray-100" size="xxx-large" />

          <DatePickerContainer>
            <Field
              name={`${name}.checkDate`}
              size="small"
              component={DatePickerField}
              label="Check date*"
              shrink
              placeholder="Select a date"
            />
            <InfoTooltip text="The check date is required to run the deforestation analysis. The analysis will search for deforestation risks at this origin from the EUDR cut-off date (2020-12-31) until the check date you choose (e.g. today’s date). The check date should be the accurate harvest date of the origin batch. You can however later update the check date and re-run the analysis without extra cost for the same plots." />
          </DatePickerContainer>

          <ItemContainer>
            <FlexBox gap={2}>
              <Field
                name={`${name}.externalBatchId`}
                disabled={autoAssignIdsField.value}
                size="small"
                component={StyledTextField}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Origin batch ID"
                placeholder="Enter a number"
              />
              <Field
                name={`${name}.weight`}
                size="small"
                component={StyledTextField}
                type="number"
                label="Quantity (kg)"
                InputLabelProps={{ shrink: true }}
                placeholder="Enter weight"
              />
              <Field
                name={`${name}.hsCodeId`}
                size="small"
                fullWidth
                component={CustomSelect}
                renderValue={(selectedId: string) => {
                  const item = hsCodes.find(item => item.id === selectedId);
                  if (!item) {
                    return 'Select code';
                  }
                  return (
                    <ThemeTypography variant="BODY_MEDIUM" autoOverflow>
                      {item.code} {item.description}
                    </ThemeTypography>
                  );
                }}
                label="Commodity trade type (HS code)"
                placeholder="Select code"
              >
                {hsCodes.map(({ id, code, description }) => (
                  <MenuItem key={id} value={id}>
                    {code} {description}
                  </MenuItem>
                ))}
              </Field>
              <Field
                name={`${name}.productDescription`}
                size="small"
                component={StyledTextField}
                type="text"
                label="Product description"
                InputLabelProps={{ shrink: true }}
                placeholder="Enter description"
              />
            </FlexBox>
            <InfoTooltip text="Origin batch id, quantity, commodity trade type (HS code) and product description are not required to run a deforestation analysis. If you don’t know these details yet, you can add them for this origin batch later on. It will be required before you submit a due diligence statement to the EUIS." />
          </ItemContainer>
        </FlexBox>
        {enableDelete && (
          <FlexBox>
            <IconButton onClick={onBatchDelete}>
              <Icon name="delete" />
            </IconButton>
          </FlexBox>
        )}
      </FlexBox>
    </CardContainer>
  );
};

export default BatchCardField;
