import { styled } from '@mui/material';
import { ReactComponent as PinIcon } from 'assets/img/icons/pin.svg';
import React from 'react';
import { IActivity } from 'types/activity.types';
import ActivityItem from '../ActivitiesTable/ActivityItem';

interface Props {
  activity: IActivity;
  isSelected?: boolean;
  hasShadow?: boolean;
  onSelect?: () => void;
}

export const LocationBlockWrapper = styled('div')<{
  isSelected: boolean;
}>(({ theme, isSelected }) => ({
  position: 'relative',
  overflow: 'visible',
  width: '100%',
  height: '100%',
  // Showing and hiding the border moves the content of the box, so we use a pseudo element instead
  '&::before': {
    content: '""',
    position: 'absolute',
    top: -2,
    left: -2,
    height: 'calc(100% + 4px)',
    width: 'calc(100% + 4px)',
    border: isSelected ? `2px solid ${theme.palette.secondary.main}` : 'none',
    borderRadius: 4,
  },
}));

export const Title = styled('div')(() => ({
  fontSize: 16,
  fontWeight: 900,
}));

export const SubTitle = styled('div')(({ theme }) => ({
  fontSize: 12,
  fontWeight: 700,
  color: theme.custom.colors.textLight,
}));

export const BlockWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const Pin = styled(PinIcon)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
  width: 30,
  height: 30,
  color: theme.custom.themeColors.primary[5],
}));

const ActivityRow: React.FC<Props> = ({ activity, onSelect, isSelected = false, hasShadow }) => {
  return (
    <LocationBlockWrapper isSelected={isSelected}>
      <ActivityItem onClick={onSelect} activity={activity} />
    </LocationBlockWrapper>
  );
};

export default ActivityRow;
