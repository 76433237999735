import { Box } from '@mui/material';
import { AlertDialog, ThemeTypography } from 'designSystem';
import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { BlockerFunction, Location, To, useBlocker, useNavigate } from 'react-router-dom';

interface IAlertDialogConfig {
  title: string;
  content: ReactNode;
  cancelText: string;
  submitText: string;
}

export interface IBlockNavigationOptions {
  /**
   * If true, the navigation will be blocked always even if only the parameters change.
   * Default: False
   */
  blockSearchChanges: boolean;
  /**
   * Configuration for the alert dialog
   */
  alertDialogConfig: IAlertDialogConfig;
}

interface IBlockNavigationContextValues {
  unblock: (newState?: To) => void;
  block: (
    options?: Partial<
      Omit<IBlockNavigationOptions, 'alertDialogConfig'> & {
        alertDialogConfig?: Partial<IAlertDialogConfig>;
      }
    >
  ) => void;
  navigationBlocked: boolean;
}

const DEFAULT_OPTIONS: IBlockNavigationOptions = {
  blockSearchChanges: false,
  alertDialogConfig: {
    title: 'Unsaved changes',
    content:
      'If you exit without saving, your data will be lost. Do you want to continue to keep editing?',
    cancelText: 'Discard & exit',
    submitText: 'Continue editing',
  },
};

/**
 * Open issue on react-router-dom (https://github.com/remix-run/react-router/issues/11430)
 * The route blocker does not get correctly removed on hot reload (only in dev mode)
 */
const BlockNavigationContext = createContext<IBlockNavigationContextValues>({
  unblock: () => undefined,
  block: () => undefined,
  navigationBlocked: false,
});

const BlockNavigationContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [options, setOptions] = useState<IBlockNavigationOptions>(DEFAULT_OPTIONS);
  const [shouldBlock, setShouldBlock] = useState<boolean>(false);
  const [blockedTarget, setBlockedTarget] = useState<Location | undefined>();

  const navigate = useNavigate();

  const checkBlocking: BlockerFunction = useCallback(
    ({ currentLocation, nextLocation }) => {
      if (
        shouldBlock &&
        (currentLocation.pathname !== nextLocation.pathname ||
          (options.blockSearchChanges && currentLocation.search !== nextLocation.search))
      ) {
        setBlockedTarget(nextLocation);
        return true;
      }

      return false;
    },
    [shouldBlock, options.blockSearchChanges, setBlockedTarget]
  );

  const blocker = useBlocker(checkBlocking);

  const alert = useCallback(
    (event: BeforeUnloadEvent) => {
      if (!shouldBlock) return;
      // Cancel the event as stated by the standard.
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    },
    [shouldBlock]
  );

  const forceRouteChange = useCallback(
    (newState?: To) => {
      setShouldBlock(false);
      if (blocker.state === 'blocked') {
        // Sometimes the blocker ends up in an invalid state
        try {
          blocker.proceed?.();
          blocker.reset?.();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
      if (newState || blockedTarget) {
        // Navigate after the current event loop
        setTimeout(
          () =>
            navigate(
              newState || { pathname: blockedTarget?.pathname, search: blockedTarget?.search }
            ),
          1
        );
      }
      setBlockedTarget(undefined);
    },
    [blocker, blockedTarget, navigate]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', alert);
    return () => {
      window.removeEventListener('beforeunload', alert);
    };
  }, [alert, blocker]);

  const startBlocking = useCallback(
    (
      options: Partial<
        Omit<IBlockNavigationOptions, 'alertDialogConfig'> & {
          alertDialogConfig?: Partial<IAlertDialogConfig>;
        }
      > = DEFAULT_OPTIONS
    ) => {
      blocker.reset?.();
      setOptions(() => ({
        ...DEFAULT_OPTIONS,
        ...options,
        alertDialogConfig: { ...DEFAULT_OPTIONS.alertDialogConfig, ...options.alertDialogConfig },
      }));
      setShouldBlock(true);
    },
    [blocker, setOptions, setShouldBlock]
  );

  const state = useMemo(
    () => ({
      unblock: forceRouteChange,
      block: startBlocking,
      navigationBlocked: !!blockedTarget,
    }),
    [forceRouteChange, startBlocking, blockedTarget]
  );

  return (
    <BlockNavigationContext.Provider value={state}>
      {children}
      <AlertDialog
        open={!!blockedTarget}
        title={options.alertDialogConfig.title}
        text={
          <Box>
            {typeof options.alertDialogConfig.content === 'string' ? (
              <ThemeTypography variant="BODY_MEDIUM">
                {options.alertDialogConfig.content}
              </ThemeTypography>
            ) : (
              options.alertDialogConfig.content
            )}
          </Box>
        }
        cancelText={options.alertDialogConfig.cancelText}
        submitText={options.alertDialogConfig.submitText}
        onSubmit={() => setBlockedTarget(undefined)} // Resetting the block target
        onClose={() => setBlockedTarget(undefined)} // Resetting the block target
        onCancel={() => forceRouteChange()} // Force route change and discarding changes}
        preventCloseAfterSubmit
        displayCloseButton
      />
    </BlockNavigationContext.Provider>
  );
};

const useBlockNavigation = () => useContext(BlockNavigationContext);

export { BlockNavigationContextProvider, useBlockNavigation };

export default BlockNavigationContext;
