import { gql } from '@apollo/client';
import { CULTIVATED_AREAS_FRAGMENT } from 'graphql/fragments/cultivatedArea.fragment';

export const GET_CULTIVATED_AREAS = gql`
  query GetCultivatedAreas(
    $filters: CultivatedAreasFilters
    $first: Int
    $after: String
    $before: String
    $last: Int
  ) {
    cultivatedAreasConnection(
      first: $first
      after: $after
      before: $before
      last: $last
      filters: $filters
    ) {
      edges {
        node {
          ...cultivatedAreaValues
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${CULTIVATED_AREAS_FRAGMENT}
`;
