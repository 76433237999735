import merge from 'lodash/merge';
import queryPolicies from './query.policies';
import feedbackPolicies from './feedback.policies';
import mediaPolicies from './media.policies';
import companyPolicies from './company.policies';
import productPolicies from './product.policies';
import versionPolicies from './version.policies';
import impactClaimPolicies from './impactClaim.policies';
import documentsPolicies from './document.policies';
import chainStepActivityPolicies from './chainStepActivitie.policies';
import sitePolicies from './site.policies';
import datasetPolicies from './dataset.policies';

export default merge(
  queryPolicies,
  mediaPolicies,
  feedbackPolicies,
  companyPolicies,
  productPolicies,
  versionPolicies,
  impactClaimPolicies,
  documentsPolicies,
  chainStepActivityPolicies,
  sitePolicies,
  datasetPolicies
);
