import { CompanyLog, IBaseCompany, User } from 'types/company.types';
import { GraphQlConnection } from 'types/types';
import { IActivity } from './activity.types';
import { IMinBaseChain } from './chain.types';
import { IDocument } from './document.types';
import { ImpactClaim } from './impactClaim.types';
import { ISite } from './site.types';
import { ICultivatedAreas } from './cultivatedArea.types';
import { IComponentItem } from './component.types';

export enum RequestType {
  CHAIN = 'CHAIN',
  DOCUMENT = 'DOCUMENT',
  SELF_ASSESSMENT = 'SELF_ASSESSMENT',
}

export enum PartnerRequestLogAction {
  DENIED = 'DENIED',
  PROVIDED = 'PROVIDED',
  DELETED = 'DELETED',
  REQUESTED = 'REQUESTED',
  EDITED = 'EDITED',
  COMPLETED = 'COMPLETED',
}

export enum PartnerRequestStatus {
  DELETED = 'DELETED',
  DENIED = 'DENIED',
  DENIED_COMPLETED = 'DENIED_COMPLETED',
  PROVIDED = 'PROVIDED',
  PROVIDED_COMPLETED = 'PROVIDED_COMPLETED',
  REQUESTED = 'REQUESTED',
  EDITED = 'EDITED',
}

export enum PartnerRequestDirection {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
}

export type RequestTypeType = `${RequestType}`;

export type PartnerRequestLogActionType = `${PartnerRequestLogAction}`;

export type PartnerRequestStatusType = `${PartnerRequestStatus}`;

export type PartnerRequestDirectionType = `${PartnerRequestDirection}`;

export type IBasePartnerCompany = IBaseCompany;

export interface IPartnerCompanySummary extends IBasePartnerCompany {
  sitesCount: number;
  activitiesCount: number;
}

/**
 * Be extremely careful when using this type, as it is quite a big query to get this data.
 */
export interface IPartnerCompany extends IBasePartnerCompany {
  logs: CompanyLog[];
  latestLog: CompanyLog | null;
  /** This is only a preview of the items, we only fetch the three latest of each */
  impactClaims: GraphQlConnection<ImpactClaim>;
  sites: GraphQlConnection<ISite>;
  activities: GraphQlConnection<IActivity>;
  requests: GraphQlConnection<PartnerRequest>;
  sharedDocuments: GraphQlConnection<IDocument>;
  components: GraphQlConnection<IComponentItem>;
  cultivatedAreas: GraphQlConnection<ICultivatedAreas>;
}

export interface PartnerRequestForm {
  requestTitle: string;
  requestMessage: string;
  requestType: RequestTypeType;
  requestedToCompanyId: string;
  componentId?: string;
}

export interface PartnerRequestLog {
  id: string;
  comment: string;
  userEmail: string;
  user: User;
  company: IBasePartnerCompany;
  timestamp: Date;
  action: PartnerRequestLogActionType;
}

export interface PartnerRequest {
  id: string;
  requestedByUserId: string;
  documentId?: string;
  requestTitle: string;
  requestMessage: string;
  requestReplyMessage?: string;
  direction: PartnerRequestDirectionType;
  requestStatus: PartnerRequestStatusType;
  requestedTimestamp: Date;
  requestedToCompany: IBasePartnerCompany;
  requestedFromCompany: IBasePartnerCompany;
  user: User;
  document?: IDocument;
  latestLog: PartnerRequestLog;
  logs: PartnerRequestLog[];
  previouslyUploadedDocuments?: IDocument[];
  requestType: RequestType;
  componentId?: string;
  chainId?: string;
  chain?: IMinBaseChain;
  questionnaires?: { overallRisk: 'LOW' | 'MEDIUM' | 'HIGH' };
}

export interface UpdatePartnerRequest {
  partnerRequest: PartnerRequest;
}
