import { useQuery } from '@apollo/client';
import { Box, DialogActions, DialogContent, styled } from '@mui/material';
import useDatasetMutation from 'components/ComponentsLibrary/hooks/useDatasetMutation';
import { DropDown, Loader } from 'components/Forms';
import { useMessages } from 'components/hooks';
import {
  datasetFarmClusteringSchema,
  IDatasetFarmClusteringFormValues,
  IDatasetImportFormValues,
} from 'constants/schemas/geoUpload.schema';
import {
  CardContainer,
  DialogDefault,
  InfoTooltip,
  ThemeButton,
  ThemeTypography,
} from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { Field, Form, Formik } from 'formik';
import { Switch } from 'formik-mui';
import { IDatasetMappedColumn } from 'graphql/mutations/types/dataset-mutation.types';
import { GET_DATASET_CLUSTERS } from 'graphql/queries/dataset.queries';
import React, { FC } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';
import { IDefaultDialogProps } from 'types/dialog.types';
import { AvailableSizes } from 'types/enums';
import { IBaseFarmSite, IClusterSite } from 'types/site.types';

type IClusterSiteOption = { title: string; value: string | null };

interface IConfirmDatasetImportDialogProps extends IDefaultDialogProps {
  datasets: {
    datasetId: string;
    mappedColumns?: IDatasetMappedColumn[];
  }[];
  values: IDatasetImportFormValues & Partial<IDatasetFarmClusteringFormValues>;
  onProcessingStart: () => void;
  onProcessingComplete: (processedDatasetIds: string[]) => void;
}

const FormItem = styled('div')<{ whiteBackground?: booleanish }>(({ theme, whiteBackground }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  borderRadius: theme.spacing(0.5),
  backgroundColor: whiteBackground === 'true' ? '#fff' : theme.custom.themeColors.grayScale[5],
  padding: theme.spacing(0, 2),
}));

const DropdownContainer = styled(Box)(({ theme }) => ({
  borderRadius: 6,
  padding: theme.spacing(2),
  backgroundColor: theme.custom.themeColors.grayScale[5],

  '& .MuiSelect-select': {
    background: '#fff !important',
    borderRadius: 4,
    border: `1px solid #EEEEEE`,
    padding: '6px 8px !important',
  },
}));

const StyledTooltip = styled(InfoTooltip)(() => ({
  margin: 0,
}));

const ConfirmDatasetImportDialog: FC<IConfirmDatasetImportDialogProps> = ({
  open,
  datasets,
  values: existingValues,
  onProcessingComplete,
  onProcessingStart,
  onClose,
}) => {
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { startDatasetProcessing } = useDatasetMutation();

  /**
   * Runs a query to get the clusters of the dataset only if there is one dataset
   */
  const { data: datasetClusters, loading: datasetClustersLoading } = useQuery<{
    datasetClusters: IClusterSite[];
  }>(GET_DATASET_CLUSTERS, {
    variables: { datasetId: datasets[0]?.datasetId },
    skip: !datasets.length || datasets.length > 1,
  });

  const initialValues = {
    ...datasetFarmClusteringSchema.default(),
    ...existingValues,
  };

  const clusters = datasetClusters?.datasetClusters || [];
  const hasClusters = !!datasetClusters?.datasetClusters?.length;

  const dropdownOptions: IClusterSiteOption[] = clusters.length
    ? [
        ...clusters.map(cluster => ({ title: cluster.title, value: cluster.id })),
        { title: 'New cluster', value: null },
      ]
    : [];

  const handleSubmit = async (
    values: IDatasetImportFormValues & IDatasetFarmClusteringFormValues
  ) => {
    onProcessingStart();
    try {
      // Start the processing of the dataset again to validate the new data
      const processingResponse = await startDatasetProcessing(
        datasets.map(({ datasetId: id, mappedColumns }) => ({
          datasetId: id,
          csvSeperator: ';',
          mappedColumns,
          overwriteExisting: values.overwriteExisting,
          clusterSites: values.clusterSites,
          ...(values.siteClusterId && { siteClusterId: values.siteClusterId }),
        }))
      );

      if (processingResponse.data?.processDatasets.datasets.length) {
        const amountOfSitesCreated = processingResponse.data?.processDatasets.datasets.reduce(
          (prev, dataset) => prev + (dataset.sites?.count || 0),
          0
        );
        setSuccessMessage(
          `${amountOfSitesCreated} farm site(s) have been created or updated and are now also available in your Partners and Sites library.`
        );
      }
      onProcessingComplete(
        processingResponse.data?.processDatasets.datasets.map(({ id }) => id) || []
      );
    } catch (error) {
      onProcessingComplete([]);
      // eslint-disable-next-line no-console
      console.error('Something went wrong updating / validating the dataset');
      setErrorMessage(
        'Something went wrong validating the dataset. If the issue persists please contact support.'
      );
    } finally {
      onClose?.();
    }
  };

  return (
    <DialogDefault open={open} title="Confirm farm clustering" onClose={onClose}>
      <Formik
        initialValues={initialValues}
        validationSchema={datasetFarmClusteringSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <DialogContent>
              {datasetClustersLoading ? (
                <Loader />
              ) : (
                <Box display="flex" flexDirection="column">
                  {hasClusters ? (
                    <Box>
                      <ThemeTypography variant="BODY_MEDIUM_BOLD">
                        Some farms of this import are already part of the following origin clusters:
                      </ThemeTypography>
                      {clusters.map(siteCluster => (
                        <CardContainer padding={1.5} mt={1} key={siteCluster.id} minWidth={500}>
                          <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box display="flex" alignItems="center">
                              <Box display="flex" bgcolor="white" borderRadius="6px" padding="4px">
                                <Icon
                                  name="site-cluster-placeholder"
                                  size="xx-large"
                                  color="blue-ice"
                                />
                              </Box>
                              <Box ml={1.5}>
                                <Box display="flex" alignItems="center" gap={0.5}>
                                  <ThemeTypography variant="ITEM_TITLE">
                                    {siteCluster.title}
                                  </ThemeTypography>
                                  <Box ml={0.5} />
                                </Box>
                                <Box display="flex" alignItems="center">
                                  <Icon name="tag-filled" size="small" color="gray-60" />
                                  <Box ml={0.5} />
                                  <ThemeTypography variant="LABEL_INPUT">
                                    Origin Cluster
                                  </ThemeTypography>
                                  <Box ml={1} />
                                  <Icon name="farm" size="small" color="gray-60" />
                                  <Box ml={0.5} />
                                  <ThemeTypography autoOverflow variant="LABEL_INPUT">
                                    {siteCluster.sites.count} farms
                                  </ThemeTypography>
                                  {siteCluster.sites.count > 0 && (
                                    <>
                                      <Box ml={1} />
                                      <Icon name="raw-material" size="small" color="gray-60" />
                                      <Box ml={0.5} />
                                      <ThemeTypography autoOverflow variant="LABEL_INPUT">
                                        {
                                          (siteCluster.sites?.edges[0]?.node as IBaseFarmSite)
                                            ?.rawMaterial?.title
                                        }
                                      </ThemeTypography>
                                    </>
                                  )}

                                  <Box ml={1} />
                                  <Icon name="location" size="small" color="gray-60" />
                                  <Box ml={0.5} />
                                  <ThemeTypography autoOverflow variant="LABEL_INPUT">
                                    {siteCluster.locationName}
                                  </ThemeTypography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </CardContainer>
                      ))}
                      <DropdownContainer mt={3}>
                        <FormItem whiteBackground={Booleanish(true)}>
                          <ThemeTypography variant="BODY_MEDIUM">
                            Add all imported farms to the same origin cluster:
                          </ThemeTypography>
                          <Field name="clusterSites" fullWidth component={Switch} type="checkbox" />
                          <StyledTooltip
                            variant="INFO"
                            text="Enable this to cluster all farms of each imported dataset to an origin cluster unique per country and partner. Disable this to not cluster farms and store them individually per partner. An origin cluster is a group of sites at the origin that output the same component (e.g. a cluster of cocoa farms)."
                          />
                        </FormItem>
                        {values.clusterSites && (
                          <>
                            <Box mt={1.5} mb={0.5}>
                              <ThemeTypography variant="BODY_MEDIUM_BOLD">
                                Select cluster to add imported farms into
                              </ThemeTypography>
                            </Box>
                            <DropDown
                              size={AvailableSizes.SMALL}
                              options={dropdownOptions || []}
                              onChange={(item: IClusterSiteOption) => {
                                setFieldValue('siteClusterId', item.value);
                              }}
                              fullWidth
                              renderItems={(item: IClusterSiteOption) => (
                                <ThemeTypography variant="BODY_SMALL">{item.title}</ThemeTypography>
                              )}
                              placeholder="Select cluster"
                              currentValue={dropdownOptions.find(
                                cluster => cluster.value === values.siteClusterId
                              )}
                            />
                          </>
                        )}
                      </DropdownContainer>
                    </Box>
                  ) : (
                    <>
                      <ThemeTypography variant="BODY_MEDIUM_BOLD">
                        {datasets.length > 1
                          ? 'Do you want farms of this import to be added to an new origin cluster?'
                          : 'Do you want farms of each imported dataset to be added to a new origin cluster?'}
                      </ThemeTypography>
                      <Box mb={0.5} />
                      <FormItem>
                        <ThemeTypography variant="BODY_MEDIUM">
                          {datasets.length > 1
                            ? 'Add all imported farms to the same origin cluster:'
                            : 'Add all imported farms in one dataset to the same origin cluster:'}
                        </ThemeTypography>
                        <Field name="clusterSites" fullWidth component={Switch} type="checkbox" />
                        <StyledTooltip
                          variant="INFO"
                          text="Enable this to cluster all farms of each imported dataset to an origin cluster unique per country and partner. Disable this to not cluster farms and store them individually per partner. An origin cluster is a group of sites at the origin that output the same component (e.g. a cluster of cocoa farms)."
                        />
                      </FormItem>
                    </>
                  )}
                </Box>
              )}
            </DialogContent>

            <DialogActions>
              <Box display="flex" justifyContent="space-between" width="100%">
                <ThemeButton color="BLUE_ICE" onClick={onClose}>
                  Cancel
                </ThemeButton>
                <ThemeButton type="submit" disabled={datasetClustersLoading} loading={isSubmitting}>
                  Confirm
                </ThemeButton>
              </Box>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </DialogDefault>
  );
};

export default ConfirmDatasetImportDialog;
