import { gql } from '@apollo/client';
import { RAW_MATERIAL_FRAGMENT } from './component.fragments';
import { DATASET_FRAGMENT, GEOC_BATCH } from './dataset.fragments';
import { CULTIVATED_AREAS_FRAGMENT } from './cultivatedArea.fragment';

export const GEO_DATA_PROCESS_FRAGMENT = gql`
  fragment dueDiligenceProcessValues on GeoDataProcess {
    id
    createdTimestamp
    modifiedTimestamp
    status
    datasets {
      ...datasetValues
    }
  }
  ${DATASET_FRAGMENT}
`;

export const GEOC_PARCEL = gql`
  fragment geocParcelValues on GeocParcel {
    id
    createdTimestamp
    geocParcelId
    checkDate
    rawMaterialId
    cultivatedAreasId
    resultTimestamp
    deforestationRisk
    baselineDate
    landcoverPngBaselineDate
    forestCoverage
    plantationCoverage
    shrubsCoverage
    noTreesCoverage
    rgbPngBaselineDate
    rgbPngProductionEnddate
    forestChangePng
    deforestationIndex
    deforestationAreaHa
    degradationRisk
    degradationAreaHa
    degradationIndex
    countryRisk
    geocBatches {
      ...geocBatchValues
    }
    rawMaterial {
      ...rawMaterialValues
    }
    cultivatedAreas {
      ...cultivatedAreaValues
    }
  }
  ${GEOC_BATCH}
  ${RAW_MATERIAL_FRAGMENT}
  ${CULTIVATED_AREAS_FRAGMENT}
`;
