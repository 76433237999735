/* eslint-disable @typescript-eslint/no-explicit-any */
import { ValidationResult } from 'types/dataImport.types';

import { Theme } from '@mui/material';
import { ColDef } from 'ag-grid-community';

export interface IExcelTableContext {
  theme: Theme;
  mode: 'view' | 'edit' | 'add-edit' | 'validation';
  isAppleDevice: boolean;
  columnDefs: ColDefWithValidator<any>[];
}

export type SystemColumns = {
  validation: boolean;
  delete: boolean;
};

export const SYSTEM_COLUMN_KEYS = ['validation', 'delete'];

export type ValidatorFunction<ContextValues = any> = (
  newValue: string,
  columnValues: string[],
  context?: IExcelTableContext & ContextValues
) => ValidationResult;

export type ColDefWithValidator<
  TableColumnDef = unknown,
  ContextValues = unknown
> = ColDef<TableColumnDef> & {
  validator?: ValidatorFunction<ContextValues>;
  isRequired?: boolean;
};

export type CellValue = string | number | Date | null;

export type ColumnDefinition<T extends CellValue> = {
  id?: string;
  value?: T;
  isValid?: boolean;
  validationMessage?: string;
  errorMessage?: string;
  touched?: boolean;
};
