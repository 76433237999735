import { useMutation } from '@apollo/client';
import { useMessages } from 'components/hooks';
import {
  CREATE_DUE_DILIGENCE_PROCESS,
  DELETE_DUE_DILIGENCE_PROCESSES,
  UPDATE_DUE_DILIGENCE_PROCESS,
} from 'graphql/mutations/dueDiligenceProcess.mutations';
import {
  ICreateGeoDataProcessInput,
  IUpdateGeoDataProcessInput,
} from 'graphql/mutations/types/dueDiligenceProcess-mutation.types';
import { IDueDiligenceProcess } from 'types/compliance.types';

const useDueDiligenceProcessMutations = () => {
  const { setSuccessMessage, setErrorMessage } = useMessages();
  const [_createDueDiligenceProcess] = useMutation<
    { createGeoDataProcess: { geoDataProcess: IDueDiligenceProcess } },
    { input: IUpdateGeoDataProcessInput }
  >(CREATE_DUE_DILIGENCE_PROCESS);

  const createDueDiligenceProcess = (input: ICreateGeoDataProcessInput) =>
    _createDueDiligenceProcess({ variables: { input } });

  const [_updateDueDiligenceProcess] = useMutation<
    { updateGeoDataProcess: { geoDataProcess: IDueDiligenceProcess } },
    { id: string; input: IUpdateGeoDataProcessInput }
  >(UPDATE_DUE_DILIGENCE_PROCESS);

  const updateDueDiligenceProcess = (id: string, input: IUpdateGeoDataProcessInput) =>
    _updateDueDiligenceProcess({ variables: { id, input } });

  const [_deleteDueDiligenceProcesses] = useMutation<
    { deleteGeoDataProcesses: { ids: string[] } },
    { ids: string[] }
  >(DELETE_DUE_DILIGENCE_PROCESSES, {
    onCompleted: () => {
      setSuccessMessage('Success! Your due diligence process has been deleted.');
    },
    onError: e => {
      setErrorMessage('Something went wrong deleting your due diligence process');
    },
    update: (cache, { data }) => {
      const deletedIds = data?.deleteGeoDataProcesses.ids;

      if (!deletedIds) return;

      const deletedRefs = deletedIds?.map(id =>
        cache.identify({ __typename: 'GeoDataProcess', id })
      );

      cache.modify({
        fields: {
          geoDataProcesses(existingProcesses = { edges: [] }) {
            const edges = existingProcesses.edges || [];
            return {
              ...existingProcesses,
              edges: edges.filter(
                ({ node }: { node: { __ref: string; __typeName: string } }) =>
                  !deletedRefs.includes(node.__ref)
              ),
            };
          },
        },
      });
    },
  });

  const deleteDueDiligenceProcess = (id: string) =>
    _deleteDueDiligenceProcesses({ variables: { ids: [id] } });

  return {
    createDueDiligenceProcess,
    updateDueDiligenceProcess,
    deleteDueDiligenceProcess,
  };
};

export default useDueDiligenceProcessMutations;
