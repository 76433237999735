import { IBaseHsCode } from 'types/component.types';
import * as Yup from 'yup';

export interface DatasetBatch {
  externalBatchId: string;
  weight: number;
  checkDate: string;
  hsCodeId: string;
}

export interface DatasetBatchRelation {
  datasetId: string;
  title: string;
  originCountry: string;
  ownedBy: { name: string };
  rawMaterial: { title: string; hsCodes?: IBaseHsCode[] };
  batches: DatasetBatch[];
}

export interface AddBatchDetailsFormValues {
  datasetBatchRelation: DatasetBatchRelation[];
  autoAssignIds: boolean;
}

export const batchDetailSchema = Yup.object().shape({
  externalBatchId: Yup.string(),
  weight: Yup.number(),
  checkDate: Yup.string().required('Please enter a check date'),
  hsCodeId: Yup.string(),
  productDescription: Yup.string(),
}) as Yup.ObjectSchema<DatasetBatch>;

export const addBatchDetailsSchema = Yup.object().shape({
  autoAssignIds: Yup.boolean().default(false),
  datasetBatchRelation: Yup.array().of(
    Yup.object().shape({
      datasetId: Yup.string(),
      title: Yup.string(),
      originCountry: Yup.string(),
      ownedBy: Yup.object().shape({ name: Yup.string() }),
      rawMaterial: Yup.object().shape({ title: Yup.string() }),
      batches: Yup.array().of(batchDetailSchema).min(1).required('Please add at least one batch'),
    })
  ),
}) as Yup.ObjectSchema<AddBatchDetailsFormValues>;
