import { Box, IconButton, styled, Tooltip } from '@mui/material';
import { FlexBox } from 'components/Structure';
import { CardContainer, ThemeTypography } from 'designSystem';
import Image from 'designSystem/DataDisplay/Image/Image';
import Icon from 'designSystem/Primitives/Icon/Icon';
import React, { FC, MouseEvent } from 'react';
import { IComponentItem } from 'types/component.types';

interface IComponentItemProps {
  componentItem: IComponentItem;
  onClick?: (site: IComponentItem) => void;
  onEdit?: (site: IComponentItem) => void;
  onDelete?: (site: IComponentItem) => void;
}

const TitleContainer = styled(FlexBox)(({ theme }) => ({
  wordBreak: 'break-all',
}));

const StyledCardContainer = styled(CardContainer)(({ theme }) => ({
  maxWidth: '100%',
  '&:not(:last-child)': {
    marginBottom: theme.spacing(1),
  },
}));

const ComponentItem: FC<IComponentItemProps> = ({ componentItem, onEdit, onClick, onDelete }) => {
  const handleEdit = (event: MouseEvent) => {
    event.stopPropagation();
    onEdit?.(componentItem);
  };
  const handleDelete = (event: MouseEvent) => {
    event.stopPropagation();
    onDelete?.(componentItem);
  };

  return (
    <StyledCardContainer
      padding={1.5}
      blueHover={!!onClick}
      onClick={() => onClick?.(componentItem)}
    >
      <FlexBox maxWidth="100%" justifyContent="space-between">
        {/*  48px for the action button container & 24px padding */}
        <FlexBox width="calc(100% - 48px - 24px)">
          {!!componentItem.image ? (
            <Image width={44} height={44} image={componentItem.image} />
          ) : (
            <Box display="flex" bgcolor="white" borderRadius="6px" padding="4px">
              <Icon name="component-placeholder" size="xx-large" color="blue-ice" />
            </Box>
          )}
          {/* 12px margin left & 44px image */}
          <Box width="calc(100% - 56px)" ml={1.5}>
            <TitleContainer gap={1} maxWidth="100%">
              <ThemeTypography variant="ITEM_TITLE" autoOverflow>
                {componentItem.title}
              </ThemeTypography>
              {componentItem.articleNumber && (
                <Box ml={0.8}>
                  <ThemeTypography
                    variant="BODY_MEDIUM"
                    autoOverflow
                    maxWidth={150}
                    color="GRAY_80"
                  >
                    | {componentItem.articleNumber}
                  </ThemeTypography>
                </Box>
              )}
            </TitleContainer>
            <FlexBox>
              <Icon name="raw-material" mr={0.5} color="gray-80" />
              <ThemeTypography
                variant="BODY_MEDIUM_BOLD"
                color="GRAY_80"
                maxWidth={200}
                autoOverflow
              >
                {componentItem.rawMaterials?.reduce(
                  (prev, rawMaterial) =>
                    prev ? `${prev}, ${rawMaterial.title}` : rawMaterial.title,
                  ''
                )}
              </ThemeTypography>
            </FlexBox>
          </Box>
        </FlexBox>

        <Box display="flex" alignItems="center" gap={0.5} mr={1}>
          {onEdit && (
            <Tooltip title="Edit site">
              <IconButton onClick={handleEdit}>
                <Icon name="edit" size="medium" />
              </IconButton>
            </Tooltip>
          )}
          {onDelete && (
            <Tooltip title="Delete site">
              <IconButton onClick={handleDelete}>
                <Icon name="delete" size="medium" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </FlexBox>
    </StyledCardContainer>
  );
};

export default ComponentItem;
