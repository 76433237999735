import { useQuery } from '@apollo/client';
import useConfig from 'components/hooks/useConfig';
import { useMemo } from 'react';
import { GraphQlConnection } from 'types/types';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { GET_GEO_DATA_PROCESSES } from 'graphql/queries/dueDiligence.queries';
import { IDueDiligenceProcess } from 'types/compliance.types';
import { DUE_DILIGENCE_PROCESSES_PER_PAGE } from 'constants/pagination';

const useDueDiligenceProcesses = () => {
  const { appQueryParams } = useConfig();

  const [query] = useQueryParams({
    [appQueryParams.order]: withDefault(StringParam, 'DESC'),
  });

  const orderByMethod = query[appQueryParams.order] as 'ASC' | 'DESC' | undefined;
  const orderByField = query[appQueryParams.orderBy];

  const { data, loading, error, refetch, fetchMore } = useQuery<{
    geoDataProcesses: GraphQlConnection<IDueDiligenceProcess>;
  }>(GET_GEO_DATA_PROCESSES, {
    variables: {
      orderBy: {
        orderByField,
        orderByMethod,
      },
      first: DUE_DILIGENCE_PROCESSES_PER_PAGE,
    },
  });

  const hasNextPage: boolean = !!data?.geoDataProcesses?.pageInfo?.hasNextPage;
  const endCursor = data?.geoDataProcesses?.pageInfo?.endCursor;

  const dueDiligenceProcesses: IDueDiligenceProcess[] = useMemo(() => {
    if (!data) return [];
    return data?.geoDataProcesses.edges.map(edge => edge.node);
  }, [data]);

  const handlePageEndReached = () => {
    if (endCursor) {
      fetchMore({
        variables: {
          after: endCursor,
        },
      });
    }
  };

  return {
    dueDiligenceProcesses,
    hasNextPage,
    loading,
    error,
    handlePageEndReached,
    refetch,
  };
};

export default useDueDiligenceProcesses;
