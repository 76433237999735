import { Box, Checkbox, DialogActions, DialogContent, Divider, Grid, styled } from '@mui/material';
import { FlexBox, PageSubTitle } from 'components/Structure';
import { originTableSettingsSchema } from 'constants/schemas/compliance.schema';
import { DialogDefault, InfoTooltip, ThemeButton, ThemeTypography } from 'designSystem';
import { Field, Form, Formik, FormikProps } from 'formik';
import { Switch } from 'formik-mui';
import React, { ChangeEvent, FC, useRef } from 'react';
import { IOriginTableSettings, TColumnKeys } from 'types/dataImport.types';
import { CoordinateDatasetColumnType, EDatasetColumn } from 'types/dataset.types';
import { IDefaultDialogProps } from 'types/dialog.types';
import { CoordinateDatasetColumnName, DatasetColumnSampleData } from 'utils/dataset.utils';
import SettingColumItem from '../SettingColumItem';

interface IDatasetTableSettingsDialogsProps extends IDefaultDialogProps {
  /**
   * Hides the top two checkboxes for the import settings
   */
  hideImportSettings?: boolean;
  settings: IOriginTableSettings;
  onTableSettingsChange: (settings: IOriginTableSettings) => void;
}

const BackgroundContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: theme.spacing(0.5),
  backgroundColor: theme.custom.themeColors.grayScale[5],
  padding: theme.spacing(0, 2),
}));

const StyledTooltip = styled(InfoTooltip)(() => ({
  margin: 0,
}));

const Header = styled(ThemeTypography)(() => ({
  fontFamily: 'Lato',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '16px',
  textTransform: 'uppercase',
}));

const DatasetTableSettingsDialogs: FC<IDatasetTableSettingsDialogsProps> = ({
  open,
  settings,
  hideImportSettings,
  onClose,
  onTableSettingsChange,
}) => {
  const formRef = useRef<FormikProps<IOriginTableSettings>>(null);

  const initialValues = {
    ...originTableSettingsSchema.default(),
    ...settings,
  };

  const coordinateFormatOptions = (
    Object.entries(CoordinateDatasetColumnName) as [CoordinateDatasetColumnType, string][]
  ).map(([key, label]) => ({
    key,
    label,
    sampleData: DatasetColumnSampleData[key],
  }));

  const farmSizeFormatOptions = [
    {
      key: EDatasetColumn.FARM_SIZE_KM2,
      label: 'Square km',
      sampleData: DatasetColumnSampleData[EDatasetColumn.FARM_SIZE_KM2],
    },
    {
      key: EDatasetColumn.FARM_SIZE_HA,
      label: 'Hectares',
      sampleData: DatasetColumnSampleData[EDatasetColumn.FARM_SIZE_HA],
    },
  ];

  const handleClose = () => onClose?.();

  const handleSubmitSettings = (values: IOriginTableSettings) => {
    onTableSettingsChange(values);
    onClose?.();
  };

  const handleToggleSelectAll = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const values = formRef.current?.values;
    if (!formRef.current || !values?.columns) {
      console.error('Columns are not defined');
      return;
    }
    (Object.keys(values.columns) as TColumnKeys[]).forEach(key => {
      // Skip farmId and coordinates which are always required
      if (key === 'farmId' || key === 'coordinates') {
        return;
      }
      // @ts-ignore
      values.columns[key] = { ...values.columns[key], enabled: checked };
    });
    formRef.current.setValues(values);
  };

  return (
    <DialogDefault
      open={!!open}
      title="Import settings"
      maxWidth="xl"
      fullWidth
      onClose={handleClose}
    >
      <>
        <Formik<IOriginTableSettings>
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={originTableSettingsSchema}
          onSubmit={handleSubmitSettings}
        >
          {({ isSubmitting, isValid, values }) => (
            <Form>
              <DialogContent>
                <Box display="flex" flexDirection="column" gap={2} maxHeight="75vh">
                  {!hideImportSettings && (
                    <>
                      <BackgroundContainer width="fit-content">
                        <ThemeTypography variant="BODY_MEDIUM">
                          Import and update farm data for existing farm ids:
                        </ThemeTypography>
                        <Field
                          name="overwriteExisting"
                          fullWidth
                          component={Switch}
                          type="checkbox"
                        />
                        <StyledTooltip
                          variant="INFO"
                          text="Enable this to update the existing farm data with the new imported farm data for the Farm ids that already exist in the platform. The old farm data will be kept as history in the platform and any changes in the imported data will become the new current status for this farm."
                        />
                      </BackgroundContainer>
                      <BackgroundContainer width="fit-content">
                        <ThemeTypography variant="BODY_MEDIUM">
                          Add all imported farms to the same origin cluster:
                        </ThemeTypography>
                        <Field name="clusterSites" fullWidth component={Switch} type="checkbox" />
                        <StyledTooltip
                          variant="INFO"
                          text="Enable this to cluster all farms of each imported dataset to an origin cluster unique per country and partner. Disable this to not cluster farms and store them individually per partner. An origin cluster is a group of sites at the origin that output the same component (e.g. a cluster of cocoa farms)."
                        />
                      </BackgroundContainer>
                    </>
                  )}

                  <BackgroundContainer width="100%">
                    <Box width="100%" py={2}>
                      <PageSubTitle title="Required data" />
                      <Divider />

                      <FlexBox flexDirection="column" gap={1} mt={2}>
                        <Grid container spacing={1}>
                          <Grid item xs={4}>
                            <Header variant="BODY_MEDIUM">Data column</Header>
                          </Grid>

                          <Grid item xs={4}>
                            <Header variant="BODY_MEDIUM">Data format / unit</Header>
                          </Grid>

                          <Grid item xs={4}>
                            <Header variant="BODY_MEDIUM">Sample data</Header>
                          </Grid>
                        </Grid>

                        <Field
                          required
                          component={SettingColumItem}
                          name="columns.farmId"
                          label="Farm ID"
                          sampleData="3c0bb6d4-4077-4d97-9c2a-cfbc3e6fa1fa"
                        />
                        <Field
                          required
                          component={SettingColumItem}
                          name="columns.coordinates"
                          label="Coordinates"
                          infoTooltip="The different kind of coordinates formats that are currently supported are: Signed DD, DD, DDM, DDS, DMS."
                          formatOptions={coordinateFormatOptions}
                        />
                      </FlexBox>
                    </Box>
                  </BackgroundContainer>

                  <BackgroundContainer width="100%">
                    <Box width="100%" py={2}>
                      <PageSubTitle title="Extended data" />
                      <Divider />

                      <FlexBox flexDirection="column" gap={1} mt={2}>
                        <Grid container spacing={1}>
                          <Grid item xs={4}>
                            <FlexBox>
                              <Checkbox
                                checked={Object.values(values.columns).every(
                                  column => column.enabled
                                )}
                                onChange={handleToggleSelectAll}
                              />
                              <Header variant="BODY_MEDIUM">Data column</Header>
                            </FlexBox>
                          </Grid>

                          <Grid item xs={4}>
                            <FlexBox height="100%">
                              <Header variant="BODY_MEDIUM">Data format / unit</Header>
                            </FlexBox>
                          </Grid>

                          <Grid item xs={4}>
                            <FlexBox height="100%">
                              <Header variant="BODY_MEDIUM">Sample data</Header>
                            </FlexBox>
                          </Grid>
                        </Grid>

                        <Field
                          component={SettingColumItem}
                          name="columns.originClusterId"
                          label="Origin cluster ID"
                          sampleData="12345678"
                        />
                        <Field
                          component={SettingColumItem}
                          name="columns.farmSize"
                          label="Farm size"
                          formatOptions={farmSizeFormatOptions}
                        />
                        <Field
                          component={SettingColumItem}
                          name="columns.farmName"
                          label="Farm name"
                          sampleData="Green Valley Farm"
                        />
                        <Field
                          component={SettingColumItem}
                          name="columns.farmOwnerFullName"
                          label="Farm owner full name"
                          sampleData="John Doe"
                        />
                        <Field
                          component={SettingColumItem}
                          name="columns.farmOwnerFirstName"
                          label="Farm owner first name"
                          sampleData="John"
                        />
                        <Field
                          component={SettingColumItem}
                          name="columns.farmOwnerLastName"
                          label="Farm owner last name"
                          sampleData="Doe"
                        />
                        <Field
                          component={SettingColumItem}
                          name="columns.numberOfFarmers"
                          label="Number of farmers"
                          sampleData="15"
                        />
                        <Field
                          component={SettingColumItem}
                          name="columns.additionalCrops"
                          label="Additional crops"
                          sampleData="Cocoa, Coffee"
                        />
                        <Field
                          component={SettingColumItem}
                          name="columns.landOwnership"
                          label="Land ownership"
                          sampleData="Private ownership"
                        />
                        <Field
                          component={SettingColumItem}
                          name="columns.siteDescription"
                          label="Site description"
                          sampleData="A small cocoa farm located in the western region"
                        />
                      </FlexBox>
                    </Box>
                  </BackgroundContainer>
                </Box>
              </DialogContent>

              <DialogActions sx={{ justifyContent: 'space-between' }}>
                <ThemeButton color="BLUE_ICE" onClick={handleClose}>
                  Cancel
                </ThemeButton>
                <ThemeButton
                  color="YELLOW"
                  type="submit"
                  loading={isSubmitting}
                  disabled={!isValid}
                >
                  Save settings
                </ThemeButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </>
    </DialogDefault>
  );
};

export default DatasetTableSettingsDialogs;
