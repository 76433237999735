import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ITooltipParams } from 'ag-grid-community';
import React, { FC, forwardRef, useImperativeHandle } from 'react';

const StyledTooltip = styled(Box)(({ theme }) => ({
  borderRadius: '5px',
  padding: '10px',
  maxWidth: '180px',
  backgroundColor: theme.custom.themeColors.white,
  boxShadow: theme.custom.shadows[4],
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

/**
 * Shows the validation message first and then the error message if the validation message is not present.
 * This component needs to have a ref to be used in the ag-grid tooltip.
 */
// eslint-disable-next-line react/display-name
const TooltipComponent: FC<ITooltipParams> = forwardRef(({ value }, ref) => {
  const showWarningMessage = !value.validationMessage && !!value.errorMessage && value.touched;
  const message = value.validationMessage || value.errorMessage;

  useImperativeHandle(ref, () => ({}));

  return !message ? null : (
    <StyledTooltip ref={ref}>
      <span>{message}</span>
      {showWarningMessage && <span>Click ‘Save & verify’ to validate changes.</span>}
    </StyledTooltip>
  );
});

export default TooltipComponent;
