import { Box, Grid, IconButton, Tooltip, styled } from '@mui/material';
import { DropDown } from 'components/Forms';
import { IColumnMappingValues } from 'constants/schemas/geoUpload.schema';
import { ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { FieldProps, useField } from 'formik';
import React, { FC } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';
import { TDatasetColumnType } from 'types/dataset.types';
import { DatasetColumnName } from 'utils/dataset.utils';

const Container = styled(Grid)<{ removed: booleanish }>(({ theme, removed }) => ({
  marginBottom: theme.spacing(1),
  opacity: removed === 'true' ? 0.4 : 1,
}));

const SourceContainer = styled(Grid)(({ theme }) => ({
  background: theme.custom.themeColors.grayScale[5],
  borderTopLeftRadius: 6,
  borderBottomLeftRadius: 6,
  padding: theme.spacing(2),
}));

const TargetContainer = styled(Grid)(({ theme }) => ({
  borderTop: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
  borderBottom: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
  borderRight: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
  borderTopRightRadius: 6,
  borderBottomRightRadius: 6,
  padding: theme.spacing(2),
}));

const SourceColumnContainer = styled(Box)(({ theme }) => ({
  borderRadius: 4,
  border: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
  borderBottom: 'none',
  background: theme.custom.themeColors.grayScale[10],
  padding: theme.spacing(1),
  marginRight: theme.spacing(6),
}));

const ExampleContainer = styled(Box)(({ theme }) => ({
  background: theme.custom.themeColors.white,
  padding: theme.spacing(1),

  borderTop: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
  borderRight: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
  borderLeft: `1px solid ${theme.custom.themeColors.grayScale[20]}`,

  ':nth-child(even)': {
    background: theme.custom.themeColors.grayScale[0],
  },

  ':first-of-type': {
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
  },

  ':last-child': {
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottom: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
  },
}));

const MappingItem: FC<FieldProps<IColumnMappingValues>> = ({ field: { name, value } }) => {
  const [, , { setValue }] = useField<IColumnMappingValues>(name);

  const handleChange = (optionKey: TDatasetColumnType) => {
    setValue({ ...value, targetColumnName: optionKey });
  };

  const handleRemove = () => {
    setValue({ ...value, removed: true });
  };

  const handleAdd = () => {
    setValue({ ...value, removed: false });
  };

  return (
    <Container container removed={Booleanish(value.removed)}>
      <SourceContainer container xs={6}>
        <Grid item xs={6}>
          <SourceColumnContainer>
            <ThemeTypography variant="BODY_MEDIUM_BOLD" autoOverflow>
              {value.sourceColumnName}
            </ThemeTypography>
          </SourceColumnContainer>
        </Grid>
        <Grid item xs={6}>
          <Box>
            {value.columnDataExample?.slice(0, 3).map((exampleContent, index) => (
              <ExampleContainer key={`${exampleContent + index}`}>
                {exampleContent}
              </ExampleContainer>
            ))}
          </Box>
        </Grid>
      </SourceContainer>
      <TargetContainer container xs={6}>
        <Grid item xs={7}>
          <DropDown<TDatasetColumnType, { key: TDatasetColumnType; label: string }>
            variant="outlined"
            options={Object.entries(DatasetColumnName).map(([key, label]) => ({
              key: key as TDatasetColumnType,
              label,
            }))}
            placeholder="Select data type"
            renderItems={item => <>{item.label}</>}
            currentValue={value.targetColumnName}
            keyField="key"
            onChange={optionKey => handleChange(optionKey)}
            disabled={value.removed}
            fullWidth
          />
        </Grid>
        <Grid item xs={5}>
          <Box display="flex" justifyContent="flex-end" width="100%">
            {value.removed ? (
              <Tooltip title="Include column">
                <IconButton onClick={handleAdd}>
                  <Icon name="include" size="medium" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Exclude column">
                <IconButton onClick={handleRemove}>
                  <Icon name="exclude" size="medium" color="gray-40" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Grid>
      </TargetContainer>
    </Container>
  );
};

export default MappingItem;
