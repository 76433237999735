import { TablePagination } from '@mui/material';
import { PaginationContainer, SortableTableHead, StyledTable } from 'components/TableBase';
import { appQueryParams } from 'constants/appQueryParams';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { IBasePartnerCompany, IPartnerCompanySummary } from 'types/partner.types';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { getComparator, stableSort } from 'utils';
import PartnersTableBody from './PartnersTableBody/PartnersTableBody';
import { PartnerHeaderColumnEnum, getPartnerHeaderColumns } from './partnerHeaderColumns';

interface Props {
  /**
   * The current page of the table. Enable this to control the page from outside the component.
   */
  page?: number;
  partners: IPartnerCompanySummary[];
  selectedPartner?: IBasePartnerCompany;
  hideHeader?: boolean;
  isTransparent?: boolean;
  rowsPerPage?: number;
  showActions?: boolean;
  columns: PartnerHeaderColumnEnum[];
  disablePagination?: boolean;
  onSelect: (partner: IBasePartnerCompany, lang?: string, page?: number) => void;
  onEdit?: (partner: IBasePartnerCompany) => void;
  onDelete?: (partner: IBasePartnerCompany) => void;
  onPageChange?: (page: number) => void;
}

const PartnersTable: FC<Props> = ({
  page: currentPage = 0,
  partners,
  selectedPartner,
  hideHeader = false,
  isTransparent = false,
  rowsPerPage = 5,
  disablePagination,
  columns,
  onSelect,
  onEdit,
  onDelete,
  onPageChange,
}) => {
  const [_currentPage, setCurrentPage] = useState(currentPage);

  const [query, setQuery] = useQueryParams({
    [appQueryParams.order]: withDefault(StringParam, 'DESC'),
    [appQueryParams.orderBy]: withDefault(StringParam, PartnerHeaderColumnEnum.MODIFIED_TIMESTAMP),
  });
  const order = query[appQueryParams.order] as 'ASC' | 'DESC' | undefined;
  const orderBy = query[appQueryParams.orderBy];

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'ASC';
    setCurrentPage(0);
    onPageChange?.(0);
    setQuery({
      [appQueryParams.order]: isAsc ? 'DESC' : 'ASC',
    });
    setQuery({
      [appQueryParams.orderBy]: property,
    });
  };

  const sortedPartners: IPartnerCompanySummary[] = useMemo(() => {
    // This means the pagination is disabled and sorting should work on the backend
    if (disablePagination) {
      return partners;
    }
    return stableSort(partners, getComparator(order, orderBy));
  }, [order, orderBy, partners, disablePagination]);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setCurrentPage(page);
    onPageChange?.(page);
  };

  const handleSelect = (partner: IBasePartnerCompany) => {
    onSelect(partner, undefined, _currentPage);
  };

  useEffect(() => {
    if (currentPage !== undefined) {
      setCurrentPage(currentPage);
    }
  }, [currentPage]);

  const headerColumns = getPartnerHeaderColumns(columns);

  return (
    <>
      <StyledTable
        aria-labelledby="tableTitle"
        aria-label="enhanced table"
        data-cy="partners-table"
      >
        {!hideHeader && (
          <SortableTableHead
            order={order === 'ASC' ? 'asc' : 'desc'}
            orderBy={orderBy}
            onSort={handleRequestSort}
            headerColumns={headerColumns}
          />
        )}
        <PartnersTableBody
          selectedPartner={selectedPartner}
          disablePagination={disablePagination}
          isTransparent={isTransparent}
          rowsPerPage={rowsPerPage}
          currentPage={_currentPage}
          partners={sortedPartners}
          onSelect={handleSelect}
          onEdit={onEdit}
          onDelete={onDelete}
          headerColumns={headerColumns}
        />
      </StyledTable>

      {!disablePagination && partners.length > rowsPerPage && (
        <PaginationContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            count={partners.length}
            rowsPerPage={rowsPerPage}
            page={_currentPage}
            onPageChange={handlePageChange}
            labelDisplayedRows={({ page }) =>
              `Page ${page + 1} of ${Math.ceil(partners.length / rowsPerPage)}`
            }
          />
        </PaginationContainer>
      )}
    </>
  );
};

export default PartnersTable;
