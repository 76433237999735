import { gql } from '@apollo/client';
import { GEO_DATA_PROCESS_FRAGMENT } from 'graphql/fragments/dueDiligence.fragments';

export const CREATE_DUE_DILIGENCE_PROCESS = gql`
  mutation CreateGeoDataProcess($input: CreateGeoDataProcessInput!) {
    createGeoDataProcess(input: $input) {
      geoDataProcess {
        ...dueDiligenceProcessValues
      }
    }
  }
  ${GEO_DATA_PROCESS_FRAGMENT}
`;

export const UPDATE_DUE_DILIGENCE_PROCESS = gql`
  mutation UpdateDueDiligenceProcess($id: UUID!, $input: UpdateGeoDataProcessInput!) {
    updateGeoDataProcess(id: $id, input: $input) {
      geoDataProcess {
        ...dueDiligenceProcessValues
      }
    }
  }
  ${GEO_DATA_PROCESS_FRAGMENT}
`;

export const DELETE_DUE_DILIGENCE_PROCESSES = gql`
  mutation DeleteDueDiligenceProcesses($ids: [UUID]!) {
    deleteGeoDataProcesses(ids: $ids) {
      ids
    }
  }
`;

export const SUBMIT_EUDR_RISK_ANALYSIS = gql`
  mutation SubmitEUDRRiskAnalysis($input: SubmitEUDRRiskAnalysisInput!) {
    submitEUDRRiskAnalysis(input: $input) {
      id
      message
      status
    }
  }
`;
