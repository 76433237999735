import React from 'react';
import { Loader } from 'components/Forms';
import { useMessages } from 'components/hooks';
import Container from 'components/Settings/Container';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USER_DETAILS } from 'graphql/queries';
import { UPDATE_NOTIFICATION_SETTINGS } from 'graphql/mutations';
import { ErrorState } from 'components/Structure';
import SettingsHeader from 'components/Settings/SettingsHeader';
import NotificationsForm from 'components/Settings/NotificationsForm';

const Keys = () => {
  const { setSuccessMessage, setErrorMessage } = useMessages();

  const { data: { user: { notifications } } = {}, loading, error } = useQuery(GET_USER_DETAILS);

  const [updateNotifications] = useMutation(UPDATE_NOTIFICATION_SETTINGS, {
    onCompleted: () => {
      setSuccessMessage('Success! Notification settings changed.');
    },
    onError: () => {
      setErrorMessage('Something went wrong updating your notification preferences.');
    },
  });

  const handleSubmit = async (values: { weeklyRoundup: boolean; trialExpiration?: boolean }) => {
    await updateNotifications({
      variables: {
        input: values,
      },
    });
  };

  if (error) return <ErrorState />;
  if (!notifications && loading) return <Loader />;

  return (
    <Container>
      <SettingsHeader
        title="Notifications"
        buttonText={undefined}
        onClick={undefined}
        subtitle={undefined}
      />
      <NotificationsForm onSubmit={handleSubmit} notifications={notifications} />
    </Container>
  );
};

export default Keys;
