import { Box, Grid } from '@mui/material';
import ComplianceEudrImage from 'assets/img/compliance/eudr.png';
import { EUDR_GUIDE_PROGRESS } from 'components/DueDiligenceProcess/utils/eudrCompliance.utils';
import { Loader } from 'components/Forms';
import {
  ErrorState,
  FlexBox,
  PageContainer,
  PageTitle,
  SlideIndicator,
} from 'components/Structure';
import OverviewHeader from 'components/Structure/OverviewHeader/OverviewHeader';
import { useConfig, useDialog, useFeatureFlags, usePageTitle } from 'components/hooks';
import { FeatureFlag } from 'components/hooks/useFeatureFlags';
import { CardIllustration, EmptyStateCard, ThemeButton, ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import useEudrComplianceStatements from 'hooks/useEudrCompliance';
import React, { FC } from 'react';
import { EDueDiligenceProcessStatus } from 'types/compliance.types';
import { AvailableSizes, BackgroundTypes, IllustrationTypes } from 'types/enums';

// const StatementContainer = styled(Box)(({ theme }) => ({
//   border: `${theme.custom.colors.lightBorderColor} 1px solid`,
//   padding: theme.spacing(3),
//   backgroundColor: theme.palette.common.white,
//   borderRadius: 6,
// }));

// const Tag = styled(FlexBox)(({ theme }) => ({
//   background: theme.custom.colors.lightBorderColor,
//   height: theme.spacing(2.625),
//   padding: theme.spacing(1),
//   borderRadius: 4,
//   marginRight: theme.spacing(1),
//   overflow: 'hidden',
// }));

// const DueDiligenceStatementItem = styled(FlexBox)(({ theme }) => ({
//   cursor: 'pointer',
//   height: 20,
//   padding: theme.spacing(2, 1),
//   borderRadius: 6,
//   maxWidth: '100%',
//   justifyContent: 'space-between',
//   '&:hover': {
//     backgroundColor: theme.custom.colors.backgroundMedium,
//     '& .MuiIconButton-root': {
//       display: 'block',
//     },
//   },
//   '& .MuiIconButton-root': {
//     display: 'none',
//   },
// }));

// const BorderLinearProgress = styled(LinearProgress)<{ percentage: number }>(
//   ({ percentage, theme }) => ({
//     borderRadius: 2,
//     height: 12,
//     width: 100,
//     backgroundColor: theme.palette.grey[300],
//     marginRight: theme.spacing(1),

//     '& .MuiLinearProgress-bar': {
//       backgroundColor: percentage === 100 ? theme.palette.success.main : theme.palette.primary.main,
//     },
//   })
// );

// const AffectivenessIndicator = styled(FlexBox)(({ theme }) => ({
//   background: theme.custom.themeColors.accent[20],
//   borderRadius: 8,
//   padding: theme.spacing(0, 2),
// }));

const EudrComplianceOverview: FC = () => {
  const { openDialog } = useDialog();
  usePageTitle('Compliance overview');
  const {
    latestDueDiligenceProcess,
    loading,
    error,
    handleCreateNewProcess,
    navigateToLatestProcessGuide,
  } = useEudrComplianceStatements();
  const config = useConfig();
  const { isFeatureEnabled } = useFeatureFlags();
  const enableStartDueDiligence = isFeatureEnabled(FeatureFlag.ENABLE_START_DUE_DILIGENCE);

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <ErrorState />;
  }

  const handleSubmitStatement = () => {
    if (latestDueDiligenceProcess?.id) {
      openDialog({
        type: 'SUBMIT_EUDR_STATEMENT',
        props: {
          statementId: latestDueDiligenceProcess.id,
          eudrDatasets: latestDueDiligenceProcess.datasets,
        },
      });
    }
  };

  const handleViewStatementDetails = () => {
    if (latestDueDiligenceProcess?.id) {
      openDialog({
        type: 'EUDR_STATEMENT_DETAILS',
        props: { eudrDatasets: latestDueDiligenceProcess.datasets, eoriNumber: 'xxx' },
      });
    }
  };

  return (
    <PageContainer>
      <PageTitle
        title="Compliance overview"
        titleExtension="EU Deforestation Regulation (EUDR)"
        goBackLabel="Regulation overview"
        goBackUrl="/compliance"
      />
      <Box mb={3} />

      <Grid container spacing={2}>
        {/* Left container for main card and empty card  */}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} direction="column">
              <Box maxHeight={180}>
                <OverviewHeader imageUrl={ComplianceEudrImage}>
                  <Box display="flex" justifyContent="space-between" flex={1} height="100%">
                    <Box>
                      {/* Title  */}
                      <FlexBox mb={1}>
                        <ThemeTypography variant="TITLE_MEDIUM" data-cy="component-title">
                          EU Deforestation Regulation (EUDR)
                        </ThemeTypography>
                      </FlexBox>

                      {/* {statements.length > 0 &&
                        latestDueDiligenceProcess &&
                        selectedStatementId && (
                          <Box display="flex" mb={2}>
                            <Box mr={1} width={200}>
                              <Select
                                size="small"
                                value={selectedStatementId}
                                fullWidth
                                onChange={event => navigateToStatementOverview(event.target.value)}
                              >
                                {statements.map(({ id, title, year }) => (
                                  <MenuItem key={id} value={id}>{`${title} - ${year}`}</MenuItem>
                                ))}
                              </Select>
                            </Box>
                            <Box display="flex" alignItems="center" mr={1}>
                              <BorderLinearProgress
                                percentage={
                                  EUDR_GUIDE_PROGRESS[latestDueDiligenceProcess.status].percentage
                                }
                                variant="determinate"
                                value={
                                  EUDR_GUIDE_PROGRESS[latestDueDiligenceProcess.status].percentage
                                }
                              />
                              <ThemeTypography variant="ITEM_SUBTITLE" color="GRAY_80">
                                {EUDR_GUIDE_PROGRESS[latestDueDiligenceProcess.status].step}/6
                                COMPLETED
                              </ThemeTypography>
                            </Box>
                            <AffectivenessIndicator>
                              <ThemeTypography variant="BODY_MEDIUM_BOLD">
                                Fully affected
                              </ThemeTypography>
                            </AffectivenessIndicator>
                          </Box>
                        )} */}

                      {/* Action buttons  */}

                      <FlexBox gap={2}>
                        {enableStartDueDiligence && (
                          <ThemeButton
                            startIcon={<Icon name="guide" />}
                            size="large"
                            onClick={handleCreateNewProcess}
                          >
                            Create new process
                          </ThemeButton>
                        )}
                        {latestDueDiligenceProcess && (
                          <>
                            {latestDueDiligenceProcess?.status ===
                              EDueDiligenceProcessStatus.COMPLETED && (
                              <ThemeButton
                                startIcon={<Icon name="upload" />}
                                size="large"
                                onClick={handleSubmitStatement}
                              >
                                Submit statement
                              </ThemeButton>
                            )}

                            {latestDueDiligenceProcess?.status ===
                              EDueDiligenceProcessStatus.SUBMITTED_EU && (
                              <ThemeButton
                                size="large"
                                color="BLUE_ICE"
                                onClick={handleViewStatementDetails}
                              >
                                View statement details
                              </ThemeButton>
                            )}

                            {/* <ThemeButton
                              startIcon={<Icon name="guide" />}
                              size="large"
                              onClick={navigateToLatestProcessGuide}
                            >
                              Continue latest process
                            </ThemeButton> */}
                          </>
                        )}

                        <ThemeButton
                          startIcon={<Icon name="link" />}
                          size="large"
                          color="BLUE_ICE"
                          onClick={() => window.open(config?.regulationUrl, '_blank')}
                        >
                          View Regulation
                        </ThemeButton>

                        {latestDueDiligenceProcess?.status ===
                          EDueDiligenceProcessStatus.SUBMITTED_EU && (
                          <ThemeButton
                            disabled
                            startIcon={<Icon name="download" />}
                            size="large"
                            color="BLUE_ICE"
                            onClick={handleSubmitStatement}
                          >
                            Download report
                          </ThemeButton>
                        )}
                      </FlexBox>
                    </Box>
                  </Box>
                </OverviewHeader>
              </Box>
            </Grid>

            <Grid item xs={12}>
              {latestDueDiligenceProcess ? (
                latestDueDiligenceProcess?.status !== EDueDiligenceProcessStatus.SUBMITTED_EU ? (
                  <EmptyStateCard
                    title="Continue your due diligence process to become compliant with this regulation"
                    onCreateItemClick={navigateToLatestProcessGuide}
                    buttonIcon={<Icon name="guide" />}
                    renderForeground={
                      <Box display="flex">
                        <SlideIndicator
                          slideCount={6}
                          activeIndex={
                            latestDueDiligenceProcess
                              ? EUDR_GUIDE_PROGRESS[latestDueDiligenceProcess.status].step - 1
                              : 0
                          }
                          stepColor="GRAY"
                          fillPrevious
                        />
                      </Box>
                    }
                    renderIllustration={
                      <CardIllustration
                        image={IllustrationTypes.COMPLIANCE}
                        itemType="LOCATIONS"
                        width={130}
                        right={50}
                        top={50}
                      />
                    }
                    background={BackgroundTypes.MAP_LAYERS_RIGHT}
                    buttonText="Continue latest process"
                    showSpark={false}
                    size={AvailableSizes.LARGE}
                    data-testid="statements-getting-started-card"
                    data-testid-button="statements-getting-started-card-button"
                  />
                ) : (
                  <></>
                )
              ) : (
                <EmptyStateCard
                  title="Start with your first due diligence process to become compliant with this regulation"
                  onCreateItemClick={handleCreateNewProcess}
                  buttonIcon={<Icon name="due-diligence-statement-add" />}
                  buttonText="Start new process"
                  size={AvailableSizes.LARGE}
                  showSpark={false}
                  renderForeground={
                    <Box display="flex">
                      <SlideIndicator
                        onClick={() => undefined}
                        slideCount={6}
                        activeIndex={0}
                        stepColor="GRAY"
                        fillPrevious
                      />
                    </Box>
                  }
                  renderIllustration={
                    <CardIllustration
                      image={IllustrationTypes.COMPLIANCE}
                      itemType="LOCATIONS"
                      width={130}
                      right={50}
                      top={50}
                    />
                  }
                  background={BackgroundTypes.MAP_LAYERS_RIGHT}
                  data-testid="statements-getting-started-card"
                  data-testid-button="statements-getting-started-card-button"
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* {latestDueDiligenceProcess && (
          <Grid item xs={3}>
            <StatementContainer>
              <FlexBox mb={1}>
                <ThemeTypography variant="ITEM_TITLE">Due diligence</ThemeTypography>
                <Box mr={1} />
                <ThemeButton
                  disabled={!enableStartDueDiligence}
                  size="small"
                  color="BLUE_ICE"
                  startIcon={<Icon name="due-diligence-statement-add" />}
                  onClick={handleCreateNewProcess}
                >
                  Start new process
                </ThemeButton>
              </FlexBox>
              {statements.map(({ id, title, status, year }) => {
                return (
                  <DueDiligenceStatementItem
                    key={id}
                    data-testid="due-diligence-item"
                    onClick={() => navigateToLatestProcessGuide(id)}
                  >
                    <FlexBox>
                      <Icon size="large" name="due-diligence-statement" mr={1} />
                      <Tag>
                        <ThemeTypography
                          autoOverflow
                          variant="BODY_SMALL_BOLD"
                          color="GRAY_60"
                          maxWidth={isSmallScreen ? 60 : 100}
                        >
                          {title} - {year}
                        </ThemeTypography>
                      </Tag>
                      {!isSmallerScreen && (
                        <ThemeTypography
                          autoOverflow
                          variant={isSmallScreen ? 'BODY_SMALLER' : 'ITEM_SUBTITLE'}
                          textTransform="uppercase"
                          color={EUDR_GUIDE_PROGRESS[status].color}
                        >
                          {EUDR_GUIDE_PROGRESS[status].title}
                        </ThemeTypography>
                      )}
                    </FlexBox>
                    <Tooltip title="Delete statement">
                      <IconButton
                        size="small"
                        data-testid="delete-due-diligence-item"
                        onClick={event => {
                          event.stopPropagation();
                          handleDeleteDueDiligenceProcess(id);
                        }}
                      >
                        <Icon size="small" name="delete" />
                      </IconButton>
                    </Tooltip>
                  </DueDiligenceStatementItem>
                );
              })}
            </StatementContainer>
          </Grid>
        )} */}
      </Grid>
    </PageContainer>
  );
};

export default EudrComplianceOverview;
