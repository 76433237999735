import { useMutation } from '@apollo/client';
import { CustomCellEditorProps } from 'ag-grid-react';
import { useMessages } from 'components/hooks';
import AutocompleteSelection from 'designSystem/Inputs/AutocompleSelection/AutocompleteSelection';
import { CREATE_RAW_MATERIAL } from 'graphql/mutations/component.mutations';
import React, { FC, useState } from 'react';
import { IRawMaterial } from 'types/component.types';

const CropCellEditor: FC<CustomCellEditorProps<unknown, string>> = ({
  value,
  context,
  onValueChange,
  stopEditing,
}) => {
  const { setSuccessMessage, setErrorMessage } = useMessages();

  const [createRawMaterial] = useMutation<{ createRawMaterial: IRawMaterial }>(
    CREATE_RAW_MATERIAL,
    {
      onCompleted: ({ createRawMaterial: { id } }) => {
        setSuccessMessage(
          'You have added a new raw material! It will take some time util your raw material will be approved by us and can be used.'
        );
      },
      onError: e => {
        setErrorMessage('Something went wrong creating your raw material');
      },
    }
  );

  const selectMultiple = context?.selectMultipleRawMaterials;
  const rawMaterials = (context?.rawMaterials || []) as IRawMaterial[];

  const initialSelection = selectMultiple
    ? (value
        ?.split(',')
        .reduce(
          (prev, title) => [
            ...prev,
            rawMaterials.find(rawMaterial => rawMaterial.title === title)?.id,
          ],
          [] as (string | undefined)[]
        )
        .filter(Boolean) as string[])
    : rawMaterials.find(rawMaterial => rawMaterial.title === value)?.id;

  const [selection, setSelection] = useState<string | string[] | undefined>(initialSelection);

  const getCropTitle = (selection: string | string[]) =>
    Array.isArray(selection)
      ? selection.reduce(
          (prev, id) =>
            `${prev.length ? `${prev}, ` : ''}${
              rawMaterials.find(rawMaterial => rawMaterial.id === id)?.title
            }`,
          ''
        )
      : rawMaterials.find(rawMaterial => rawMaterial.id === selection)?.title;

  const handleAddNewItemClick = (title: string) => {
    createRawMaterial({ variables: { title } });
  };

  const handleRawMaterialSelect = (newSelection: string[] | string | undefined) => {
    setSelection(() => newSelection);
    onValueChange(newSelection ? getCropTitle(newSelection) : '');
    if (!selectMultiple) {
      stopEditing();
    }
  };

  return (
    <AutocompleteSelection
      label="Select raw material"
      closeAfterSelection={!selectMultiple}
      defaultOpen
      enableAddNewItem
      size="small"
      multiple={selectMultiple}
      itemName="raw material"
      items={rawMaterials}
      errorMessage={'error'}
      hasError={false}
      selected={selectMultiple ? selection || [] : selection || ''}
      maxWidth={150}
      onAddNewItemClick={handleAddNewItemClick}
      onSelectionChange={handleRawMaterialSelect}
    />
  );
};

export default CropCellEditor;
