import { useQuery } from '@apollo/client';
import { useDialog } from 'components/hooks';
import { GET_LATEST_GEO_DATA_PROCESS } from 'graphql/queries/dueDiligence.queries';
import { useNavigate } from 'react-router-dom';
import { IDueDiligenceProcess } from 'types/compliance.types';
import useDueDiligenceProcessMutations from './useDueDiligenceProcessMutations';

const useEudrComplianceStatements = () => {
  const navigate = useNavigate();
  const { openDialog } = useDialog();
  const { deleteDueDiligenceProcess } = useDueDiligenceProcessMutations();
  const { data, loading, error } = useQuery<{
    latestGeoDataProcess: IDueDiligenceProcess;
  }>(GET_LATEST_GEO_DATA_PROCESS);

  const navigateToOverview = () => navigate(`/compliance/eudr`);
  const navigateToLatestProcessGuide = () =>
    navigate(`/compliance/eudr/${data?.latestGeoDataProcess.id}/guide`);
  const handleCreateNewProcess = () => navigate('/compliance/eudr/guide'); // Empty guide

  const handleDeleteDueDiligenceProcess = (id: string) => {
    openDialog({
      type: 'ALERT',
      props: {
        title: 'Delete due diligence process',
        itemTitle: data?.latestGeoDataProcess ? data.latestGeoDataProcess.title : '',
        text: 'Are you sure you want to delete this due diligence process? This action cannot be undone.',
        cancelText: 'Cancel',
        submitText: 'Delete',
        onCancel: () => undefined,
        onSubmit: () => {
          deleteDueDiligenceProcess(id);
        },
      },
    });
  };

  return {
    latestDueDiligenceProcess: data?.latestGeoDataProcess,
    loading,
    error,
    handleDeleteDueDiligenceProcess,
    navigateToOverview,
    navigateToLatestProcessGuide,
    handleCreateNewProcess,
  };
};

export default useEudrComplianceStatements;
