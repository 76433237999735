import { useQuery } from '@apollo/client';
import { Box, ButtonGroup, styled, AccordionDetails, AccordionSummary } from '@mui/material';
import { Loader } from 'components/Forms';
import { useDialog } from 'components/hooks';
import useSiteMutations from 'components/Sites/hooks/useSiteMutations';
import { ErrorState, FlexBox, PageSubTitle } from 'components/Structure';
import InfiniteScrollWrapper from 'components/Structure/InfiniteScrollWrapper';
import { appQueryParams } from 'constants/appQueryParams';
import { CULTIVATION_AREAS_PER_PAGE, SITES_TABLE_ROWS_PER_PAGE } from 'constants/pagination';
import { InfoTooltip, ThemeButton, ThemeTypography } from 'designSystem';
import { GET_DATASET_SITES } from 'graphql/queries/dataset.queries';
import React, { FC, useMemo } from 'react';
import { DatasetView, EDatasetErrorCategory, IDataset } from 'types/dataset.types';
import { IFarmSite, ISite, SiteType } from 'types/site.types';
import { GraphQlConnection } from 'types/types';
import { createEnumParam, useQueryParam, withDefault } from 'use-query-params';
import { removeGraphConnections } from 'utils/graphql.utils';
import PlotWarningBanner from '../PlotWarningBanner';
import SiteItem from '../SiteItem';
import { useLocation, useNavigate } from 'react-router-dom';
import PaginatedMap from 'components/CultivatedFarmArea/PaginatedMap';
import useMeasure from 'react-use/lib/useMeasure';
import { Accordion } from 'designSystem/DataDisplay/Accordion/Accordion';

interface IDatasetVerificationProps {
  dataset: IDataset;
  // if true all warnings become errors
  isEUDR?: boolean;
  initialViewOption?: DatasetView;
  isEditDisabled?: boolean;
  pageSubTitle?: string;
}

const Container = styled(Box)({
  width: '100%',
});

const HintContainer = styled('div')(({ theme }) => ({
  background: theme.custom.themeColors.primary[5],
  borderRadius: theme.spacing(1),
  height: 'fit-content',
  border: `1px solid ${theme.custom.themeColors.primary[40]}`,
}));

const StyledAccordion = styled(Accordion)(({ theme, transparent }) => ({
  '&.MuiAccordion-root': {
    margin: 0,
    border: 'none !important',
  },

  '& .MuiAccordionDetails-root': {
    paddingTop: 0,
    background: 'transparent',
  },
}));

const List = styled('ul')(({ theme }) => ({
  margin: 0,
  padding: '0 0 0 16px',
}));

const DatasetVerification: FC<IDatasetVerificationProps> = ({
  dataset,
  isEUDR,
  initialViewOption = DatasetView.MAP,
  isEditDisabled,
  pageSubTitle,
}) => {
  const [containerRef, { height: containerHeight }] = useMeasure<HTMLDivElement>();

  const [viewOption, setViewOption] = useQueryParam(
    appQueryParams.viewOption,
    withDefault(
      createEnumParam<DatasetView>([DatasetView.LIST, DatasetView.MAP]),
      initialViewOption
    ),
    { removeDefaultsFromUrl: true }
  );
  const navigate = useNavigate();
  const location = useLocation();

  const { data, loading, error, fetchMore } = useQuery<{
    dataset: { sites: GraphQlConnection<ISite> };
  }>(GET_DATASET_SITES, {
    variables: {
      datasetId: dataset.id,
      first:
        viewOption === DatasetView.MAP ? CULTIVATION_AREAS_PER_PAGE : SITES_TABLE_ROWS_PER_PAGE,
    },
    notifyOnNetworkStatusChange: true,
  });
  const { openDialog } = useDialog();
  const { deleteSite } = useSiteMutations();

  const farms: IFarmSite[] = useMemo(
    () =>
      data?.dataset.sites
        ? (removeGraphConnections(data.dataset.sites).filter(
            site => site.siteType === SiteType.FARM
          ) as IFarmSite[])
        : [],
    [data]
  );

  const hasNextPage: boolean = !!data?.dataset.sites?.pageInfo?.hasNextPage;
  const endCursor: string | undefined = data?.dataset.sites?.pageInfo?.endCursor;
  const totalSites = data?.dataset.sites?.count || 0;

  const handlePageEndReached = async () => {
    if (endCursor) {
      await fetchMore({
        variables: {
          after: endCursor,
        },
      });
    }
  };

  const warningTypes = useMemo(() => {
    const issueCounts: { errorMessage: string; count: number }[] = [];
    dataset.issues?.forEach(error => {
      if (error.errorCategory !== EDatasetErrorCategory.EUDR_REQUIREMENT) {
        const existingIssues = issueCounts.find(e => e.errorMessage === error.errorMessage);
        if (existingIssues) {
          existingIssues.count += 1;
        } else {
          issueCounts.push({ errorMessage: error.errorMessage, count: 1 });
        }
      }
    });
    return issueCounts;
  }, [dataset.issues]);

  const warningTypesEUDR = useMemo(() => {
    const issueCounts: { errorMessage: string; count: number }[] = [];
    dataset.issues?.forEach(error => {
      if (error.errorCategory === EDatasetErrorCategory.EUDR_REQUIREMENT) {
        const existingIssues = issueCounts.find(e => e.errorMessage === error.errorMessage);
        if (existingIssues) {
          existingIssues.count += 1;
        } else {
          issueCounts.push({ errorMessage: error.errorMessage, count: 1 });
        }
      }
    });
    return issueCounts;
  }, [dataset.issues]);

  const handleEditSite = (site: ISite) => {
    if (!site) {
      console.warn('No site to edit');
      return;
    }

    openDialog({
      type: 'ADD_EDIT_SITE',
      props: { site, partner: dataset.ownedBy },
    });
  };

  const handleEditCultivatedArea = (site?: ISite) => {
    if (!site) {
      console.warn('No site to edit cultivation area');
      return;
    }

    openDialog({
      type: 'ADD_EDIT_CULTIVATED_AREA',
      props: {
        site,
      },
    });
  };

  const handleDeleteSite = (site: ISite) => {
    if (!site) {
      console.warn('No site to delete');
      return;
    }

    openDialog({
      type: 'ALERT',
      props: {
        title: 'Delete site',
        text: 'Are you sure you want to delete this site? All data will be lost and you will not be able to recover this item.',
        submitText: 'Delete',
        itemTitle: site.title,
        displayCloseButton: true,
        onSubmit: () => {
          deleteSite({
            variables: { id: site.id },
            onCompleted: () => {
              // if the last site of a dataset is deleted the dataset also gets deleted and we need to navigate out of the dataset
              if (!isEUDR && farms.length === 1) {
                const datasetIdPattern = new RegExp(`/datasets/${dataset.id}`);
                if (datasetIdPattern.test(location.pathname)) {
                  const newPath = location.pathname.replace(datasetIdPattern, '');
                  navigate(newPath);
                }
              }
            },
            update: (cache, { data }) => {
              if (!data) return;

              const existingSites = cache.readQuery<{
                dataset: { sites: GraphQlConnection<ISite> };
              }>({
                query: GET_DATASET_SITES,
                variables: {
                  datasetId: dataset.id,
                },
              });

              const newSites = existingSites?.dataset.sites.edges.filter(
                ({ node }) => node.id !== data?.deleteSite.id
              );

              cache.writeQuery({
                query: GET_DATASET_SITES,
                data: {
                  dataset: {
                    ...existingSites?.dataset,
                    sites: {
                      ...existingSites?.dataset.sites,
                      edges: newSites,
                    },
                  },
                },
                variables: {
                  datasetId: dataset.id,
                },
              });
            },
          });
        },
        onCancel: () => undefined,
      },
    });
  };

  if (loading && !farms.length) {
    return <Loader />;
  }
  if (error) {
    return <ErrorState />;
  }

  return (
    <Box height="100%">
      <Box ref={containerRef}>
        {dataset.issues.length > 0 && (
          <Box display="flex" flexDirection="column" gap={1} mb={2}>
            {warningTypesEUDR.length > 0 && (
              <PlotWarningBanner
                plotWarnings={warningTypesEUDR}
                warningCount={warningTypesEUDR.reduce((acc, curr) => acc + curr.count, 0)}
                severity={isEUDR ? 'error' : 'warning'}
                warningCategory={EDatasetErrorCategory.EUDR_REQUIREMENT}
              />
            )}
            {warningTypes.length > 0 && (
              <PlotWarningBanner
                plotWarnings={warningTypes}
                warningCount={warningTypes.reduce((acc, curr) => acc + curr.count, 0)}
                severity={isEUDR ? 'error' : 'warning'}
              />
            )}
            <HintContainer>
              <StyledAccordion transparent={true}>
                <AccordionSummary>
                  <ThemeTypography color="ALMOST_BLACK" variant="BODY_MEDIUM_BOLD">
                    You can still save this import with warnings. To address the issues, consider
                    the following recommendations:
                  </ThemeTypography>
                </AccordionSummary>
                <AccordionDetails>
                  <ThemeTypography variant="BODY_SMALL">
                    <List>
                      <li>
                        If you need to export a summary of the geo-data warnings, download the file
                        with highlighted issues below and forward the file to relevant partners.
                      </li>
                      <li>
                        If you are you able to manually adjust plot boundaries on the map, you can
                        edit the cultivation area per plot in this view directly (e.g. edit points
                        and draw polygons).
                      </li>
                      <li>
                        If you have another dataset of the same farm plots but with different
                        polygon geometries, you can save this dataset and later add the new dataset.
                        Changes in the plots will then be updated for the same farm ids.
                      </li>
                      <li>
                        If you prefer to fix the problems in the original file, you can always
                        re-upload again.
                      </li>
                    </List>
                  </ThemeTypography>
                </AccordionDetails>
              </StyledAccordion>
            </HintContainer>
          </Box>
        )}

        <FlexBox mb={viewOption === DatasetView.MAP ? 1 : 2} gap={2}>
          {pageSubTitle && <PageSubTitle title={pageSubTitle} />}
          <FlexBox>
            <ButtonGroup>
              <ThemeButton
                className={viewOption === DatasetView.MAP ? 'selected' : ''}
                onClick={() => setViewOption(DatasetView.MAP)}
              >
                Map view
              </ThemeButton>
              <ThemeButton
                className={viewOption === DatasetView.LIST ? 'selected' : ''}
                onClick={() => setViewOption(DatasetView.LIST)}
              >
                List view
              </ThemeButton>
            </ButtonGroup>
            <InfoTooltip text="Select to view the farm plots in a list or on the map. Both views allows editing the farm data (e.g. farm size) or the farm plot cultivation area." />
          </FlexBox>
        </FlexBox>
      </Box>

      <Container height={`calc(100% - ${containerHeight}px)`}>
        {farms.length > 0 && viewOption === DatasetView.LIST && (
          <InfiniteScrollWrapper hasMore={hasNextPage} next={handlePageEndReached}>
            {farms.map(site => {
              const warningsOfSite = dataset.issues.filter(issue => issue.entityId === site.id);
              return (
                <SiteItem
                  key={site.id}
                  site={site}
                  warnings={warningsOfSite}
                  severity={isEUDR ? 'error' : 'warning'}
                  onEdit={!isEditDisabled ? handleEditSite : undefined}
                  onEditCultivatedArea={!isEditDisabled ? handleEditCultivatedArea : undefined}
                  onDelete={!isEditDisabled ? handleDeleteSite : undefined}
                />
              );
            })}
          </InfiniteScrollWrapper>
        )}

        {farms.length > 0 && viewOption === DatasetView.MAP && (
          <PaginatedMap
            farms={farms}
            hasNextPage={hasNextPage}
            loading={loading}
            onFetchMore={handlePageEndReached}
            warnings={dataset.issues}
            hideTitle
            itemCount={totalSites}
            warningSeverity={isEUDR ? 'error' : 'warning'}
            tooltipText={`Due to browser capacity, maximum ${CULTIVATION_AREAS_PER_PAGE} farm plots can be shown at the same time. Farm plots with warnings are prioritised first and shown on the first page. Click to load the next page of farms.`}
            onEditCultivatedArea={
              !isEditDisabled
                ? id => handleEditCultivatedArea(farms.find(farm => farm.id === id))
                : undefined
            }
          />
        )}
      </Container>
    </Box>
  );
};

export default DatasetVerification;
