import React, { FC } from 'react';
import { DUE_DILIGENCE_PROCESSES } from 'components/DueDiligenceProcess/constants/headerColumns';
import { IDueDiligenceProcess } from 'types/compliance.types';
import { SortableTableHead, StyledTable, StyledTableBody } from 'components/TableBase';
import { InfiniteScrollWrapper } from 'components/Structure';
import DueDiligenceHistoryTableRow from './DueDiligenceHistoryTableRow';

interface IDueDiligenceHistoryTableProps {
  dueDiligenceProcesses: IDueDiligenceProcess[];
  hasNextPage: boolean;
  onEndReached: () => void;
  onNavigateToProcessGuide: (dueDiligenceProcessId: string) => void;
  onDeleteProcess: (dueDiligenceProcessId: string) => void;
}

const DueDiligenceHistoryTable: FC<IDueDiligenceHistoryTableProps> = ({
  dueDiligenceProcesses,
  hasNextPage,
  onEndReached,
  onNavigateToProcessGuide,
  onDeleteProcess,
}) => {
  return (
    <>
      <InfiniteScrollWrapper hasMore={hasNextPage} next={onEndReached}>
        <StyledTable aria-labelledby="tableTitle" aria-label="enhanced table">
          <SortableTableHead headerColumns={DUE_DILIGENCE_PROCESSES} />
          <StyledTableBody>
            {dueDiligenceProcesses.map(dueDiligenceProcess => (
              <DueDiligenceHistoryTableRow
                key={dueDiligenceProcess.id}
                dueDiligenceProcess={dueDiligenceProcess}
                headerColumns={DUE_DILIGENCE_PROCESSES}
                onNavigateToProcessGuide={onNavigateToProcessGuide}
                onDeleteProcess={onDeleteProcess}
              />
            ))}
          </StyledTableBody>
        </StyledTable>
      </InfiniteScrollWrapper>
      {/* TODO: Use this when Table component is ready */}
      {/* <Table
        columns={DUE_DILIGENCE_PROCESSES}
        hasMoreRows={hasNextPage}
        hideSearchbar
        onEndReached={onEndReached}
      >
        {({ TableRow }) => (
          <>
            {dueDiligenceProcesses.map(dueDiligenceProcess => {
              const batchesOfProcess = dueDiligenceProcess.datasets
                .flatMap(dataset => dataset.geocBatches || [])
                .filter(geocBatch => geocBatch.geoDataProcessId === dueDiligenceProcess.id);

              const totalQuantity = batchesOfProcess.reduce((acc, batch) => acc + batch.weight, 0);

              const checkDate = batchesOfProcess.reduce<GeoCBatch | null>((acc, batch) => {
                if (!acc) {
                  return batch;
                }
                return acc.checkDate > batch.checkDate ? acc : batch;
              }, null)?.checkDate;

              const riskOfProcess = batchesOfProcess.reduce<ERiskLevel | undefined>(
                (acc, geocBatch) => {
                  if (geocBatch.riskStatus === ERiskLevel.HIGH) {
                    return ERiskLevel.HIGH;
                  }
                  if (geocBatch.riskStatus === ERiskLevel.MEDIUM && acc !== ERiskLevel.HIGH) {
                    return ERiskLevel.MEDIUM;
                  }
                  if (
                    geocBatch.riskStatus === ERiskLevel.LOW &&
                    acc !== ERiskLevel.HIGH &&
                    acc !== ERiskLevel.MEDIUM
                  ) {
                    return ERiskLevel.LOW;
                  }
                  return acc;
                },
                undefined
              );

              return (
                <TableRow
                  key={dueDiligenceProcess.id}
                  onClick={() => onNavigateToProcessGuide(dueDiligenceProcess.id)}
                >
                  {({ Title, Quantity, CheckDate, Actions }) => (
                    <>
                      <Title>
                        <DueDiligenceProcessTitle
                          dueDiligenceProcess={dueDiligenceProcess}
                          processRiskLevel={riskOfProcess}
                        />
                      </Title>
                      <Quantity>
                        {totalQuantity > 0 && (
                          <ThemeTypography variant="BODY_SMALL">{totalQuantity}</ThemeTypography>
                        )}
                      </Quantity>
                      <CheckDate>
                        {checkDate && (
                          <ThemeTypography variant="BODY_SMALL">
                            <Moment format="MMM DD, YYYY" date={checkDate} />
                          </ThemeTypography>
                        )}
                      </CheckDate>
                      <Actions>
                        <IconButton
                          onClick={() => onNavigateToProcessGuide(dueDiligenceProcess.id)}
                        >
                          <Icon name="guide" />
                        </IconButton>
                        <IconButton onClick={() => onDeleteProcess(dueDiligenceProcess.id)}>
                          <Icon name="delete" />
                        </IconButton>
                      </Actions>
                    </>
                  )}
                </TableRow>
              );
            })}
          </>
        )}
      </Table> */}
    </>
  );
};

export default DueDiligenceHistoryTable;
