import { Box, CircularProgress, styled } from '@mui/material';
import { FlexBox } from 'components/Structure';
import { Skeleton, ThemeTypography } from 'designSystem';
import { ButtonColor } from 'designSystem/Buttons/ThemeButton/ThemeButton';
import Stepper from 'designSystem/Navigation/Stepper/Stepper';
import { IconNameType } from 'designSystem/Primitives/Icon/Icon';
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { useDueDiligenceProcess } from './Context/DueDiligenceProcessContext';

interface IDueDiligenceProcessSubSectionNavigationProps extends PropsWithChildren {
  disableNavigation?: boolean;
  disabledPreviousNavigation?: boolean;
  allowNextStepNavigation?: boolean;
  nextStepLoading?: boolean;
  titleAdornment?: ReactNode;
  titleLoading?: boolean;

  /**
   * Warning: Use this with caution, it will override the title and description of the current sub section
   *
   */
  title?: string;
  description?: string;

  /**
   * Custom text which will display a normal button instead of the next step button
   */
  customNextStepButton?: { text: string; startIcon?: IconNameType; color?: ButtonColor };
  /**
   * Optional: If not passed and enabled it will perform the default navigateToNextSubSection
   */
  onNextStepClick?: () => void;
  /**
   * Optional: If not passed and enabled it will perform the default navigateToPreviousSubSection
   */
  onPreviousStepClick?: () => void;
}

const Container = styled(Box)(({ theme }) => ({
  borderRadius: 6,
  border: `1px solid ${theme.custom.colors.lightBorderColor}`,
  background: theme.custom.themeColors.white,
  padding: theme.spacing(2),
  position: 'relative',
}));

const Pill = styled('div')<{ color: 'dark' | 'light' }>(({ theme, color }) => ({
  color: color === 'light' ? theme.palette.secondary.main : theme.custom.themeColors.white,
  background:
    color === 'light'
      ? theme.custom.themeColors.grayScale[20]
      : theme.custom.themeColors.primary[100],
  borderRadius: '50%',
  minWidth: theme.spacing(3.5),
  height: theme.spacing(3.5),
  fontSize: 12,
  lineHeight: 11,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 700,
  marginRight: theme.spacing(2),
}));

const TitleLoaderContainer = styled(Box)(({ theme }) => ({
  '& .MuiCircularProgress-svg': {
    color: theme.custom.themeColors.grayScale[30],
  },
}));

const DueDiligenceProcessSubSectionNavigation: FC<
  IDueDiligenceProcessSubSectionNavigationProps
> = ({
  children,
  titleAdornment,
  allowNextStepNavigation,
  nextStepLoading,
  titleLoading,
  customNextStepButton,
  disableNavigation,
  title,
  description,
  disabledPreviousNavigation,
  onNextStepClick,
  onPreviousStepClick,
}) => {
  const {
    sections,
    selectedSectionIndex,
    selectedSubSectionIndex,
    selectedSubSection,
    navigateToNextSection,
    navigateToPreviousSection,
  } = useDueDiligenceProcess();

  const loading = !selectedSubSection || selectedSubSectionIndex === undefined;

  /**
   * Passing the selected section and sub section index to the parent component since they can not be undefined here
   */
  const handleNextStepClick = () => {
    if (onNextStepClick) {
      onNextStepClick();
    } else {
      navigateToNextSection();
    }
  };

  const handlePreviousStepClick = () => {
    if (onPreviousStepClick) {
      onPreviousStepClick();
    } else {
      navigateToPreviousSection();
    }
  };

  return (
    <Container>
      <Stepper
        disablePreviousStep={disabledPreviousNavigation || disableNavigation}
        disableNextStep={disableNavigation || !allowNextStepNavigation}
        selectedIndex={selectedSectionIndex}
        customNextStepButton={customNextStepButton}
        steps={sections}
        loading={nextStepLoading}
        onNextStepClick={handleNextStepClick}
        onPreviousStepClick={handlePreviousStepClick}
      />

      <Box p={2} mt={2}>
        <FlexBox mb={2} mr={1}>
          <Pill color={selectedSubSectionIndex === undefined ? 'dark' : 'light'}>
            {selectedSectionIndex + 1}
            {selectedSubSectionIndex !== undefined ? `.${selectedSubSectionIndex + 1}` : ''}
          </Pill>
          {!loading || title ? (
            <ThemeTypography variant="TITLE_SMALL">
              {title || selectedSubSection?.title}
            </ThemeTypography>
          ) : (
            <Skeleton width={150} />
          )}

          {!loading ? (
            selectedSubSection?.itemTitle && (
              <Box ml={1}>
                <ThemeTypography variant="TITLE_SMALL" color="GRAY_60">
                  {selectedSubSection.itemTitle}
                </ThemeTypography>
              </Box>
            )
          ) : (
            <Box ml={1}>
              <Skeleton width={100} />
            </Box>
          )}

          {titleAdornment && <Box ml={1}>{titleAdornment}</Box>}

          {titleLoading && (
            <TitleLoaderContainer ml={1} display="flex" alignItems="center">
              <CircularProgress size={16} thickness={8} color="info" />
            </TitleLoaderContainer>
          )}
        </FlexBox>
        {!loading || description ? (
          <ThemeTypography variant="BODY_MEDIUM">
            {description || selectedSubSection?.description}
          </ThemeTypography>
        ) : (
          <Skeleton width="100%" height={50} />
        )}
        <Box mb={2} />
        {children}
      </Box>
    </Container>
  );
};

export default DueDiligenceProcessSubSectionNavigation;
