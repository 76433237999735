import { gql } from '@apollo/client';
import { RAW_MATERIAL_FRAGMENT } from './component.fragments';
import { IMAGE_VARIANT_FRAGMENT } from './media.fragments';
import { BASE_PARTNER_COMPANY_FRAGMENT } from './partner.fragments';

export const BASE_CULTIVATED_AREAS_FRAGMENT = gql`
  fragment baseCultivatedAreaValues on CultivatedAreas {
    id
    farmId
    coordinates
    areaType
    centerPoint {
      lat
      lng
    }
  }
`;

/**
 * Contains the base fragment of the farm site which excludes the cultivatedAreas
 */
export const CULTIVATED_AREAS_FRAGMENT = gql`
  fragment cultivatedAreaValues on CultivatedAreas {
    ...baseCultivatedAreaValues
    farm {
      id
      ownedBy {
        ...basePartnerCompanyValues
      }
      createdBy {
        ...basePartnerCompanyValues
      }
      image {
        ...imageVariant
      }
      siteType
      title
      locationCoordinates {
        lat
        lng
      }
      modifiedTimestamp
      locationName
      imageId
      externalId
      description
      coordinateFeatures {
        featureType
        locationName
        center {
          lat
          lng
        }
      }
      inUse
      countryCode
      size
      rawMaterial {
        ...rawMaterialValues
      }
    }
  }
  ${BASE_CULTIVATED_AREAS_FRAGMENT}
  ${RAW_MATERIAL_FRAGMENT}
  ${IMAGE_VARIANT_FRAGMENT}
  ${BASE_PARTNER_COMPANY_FRAGMENT}
`;
