import ErrorBoundary from 'components/ErrorBoundary';
import NotFoundPage from 'components/NotFoundPage/NotFoundPage';
import { RequestReplyDialogProvider } from 'components/PartnerIntegration/Contexts/RequestReplyDialogContext';
import { Login, PasswordForgot, Register, RegisterInvited, TokenizedLogin } from 'containers';
import CustomProviders from 'contexts/CustomProviders';
import { TrackNavigationEventsProvider } from 'contexts/TrackNavigationEvents';
import { VersionProvider } from 'contexts/VersionContext';
import React, { FC } from 'react';
import { Route, RouterProvider, Routes, createBrowserRouter } from 'react-router-dom';
import AuthenticatedRoute from 'routes/AuthenticatedRoutes';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import * as Sentry from '@sentry/react';
import { BlockNavigationContextProvider } from 'contexts/BlockNavigationContext';

/** These providers needs to live inside and access the browser route context  */
const INNER_ROUTER_PROVIDERS = [
  { provider: QueryParamProvider, props: { adapter: ReactRouter6Adapter } },
  TrackNavigationEventsProvider,
  VersionProvider,
  RequestReplyDialogProvider,
  BlockNavigationContextProvider,
];

/**
 * The main router of the application it requires currently object structure and is only needed to use route blocking and other features
 * It returns a data router, later this should also be used as react element as soon as the react-router team releases the new version
 */
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    id: 'root',
    path: '/*',
    errorElement: <ErrorBoundary />,
    element: (
      <CustomProviders providers={INNER_ROUTER_PROVIDERS}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/register-partner" element={<RegisterInvited />} />
          <Route path="/invitation" element={<RegisterInvited />} />
          <Route path="/password-forgot" element={<PasswordForgot />} />
          <Route path="/verify-user" element={<TokenizedLogin />} />

          <Route path="/*" element={<AuthenticatedRoute />} />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </CustomProviders>
    ),
  },
]);

const Router: FC = () => <RouterProvider router={router} />;

export default Router;
