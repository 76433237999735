import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { InfoCircle } from '@styled-icons/bootstrap';
import { ChevronLeft } from '@styled-icons/bootstrap/ChevronLeft';
import { ChevronRight } from '@styled-icons/bootstrap/ChevronRight';
import ProductIllustration from 'assets/img/product-illustration.svg';
import ChainMapping from 'components/ChainMapping/ChainMapping';
import { StyledLink } from 'components/ChainMapping/Forms/styles';
import { Loader } from 'components/Forms';
import { useContentBlock } from 'components/Product/MultiStep/hooks';
import { ErrorState, FlexBox } from 'components/Structure';
import useProductSupplyChainMapping from 'components/SupplyChainsLibrary/hooks/useProductSupplyChainMapping';
import { useDialog, useHorizontalScrollIndicator } from 'components/hooks';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import { ThemeButton, ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import React, { FC } from 'react';
import { ContentBlock } from '../constants/menu';
import FormContainer from './FormContainer';
import Header from './Header';
import { Container } from './styles';
import { CustomerPlan } from 'types/company.types';

interface IProductJourneyProps {
  menu: ContentBlock;
}

const StyledContainer = styled(Container)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  maxHeight: '100%',
  width: '100%',

  '& form': {
    flexGrow: 1,
  },
}));

const MeasurementContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  width: '100%',
  height: '100%',
  outline: 'none',
  minHeight: 800,
  paddingTop: 20,
});

const ScrollIndicator = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  top: '50%',
  transform: 'translateY(-50%)',
  width: 28,
  height: 28,
  padding: 0,
  background: theme.custom.colors.backgroundLight,
  color: theme.palette.secondary.main,
  boxShadow: theme.custom.shadows[1],

  '&:hover': {
    background: theme.custom.colors.blueFrost,
    color: theme.palette.secondary.main,
  },
}));

const ScrollButtonLeft = styled(ScrollIndicator)(() => ({
  left: 2,
}));

const ScrollButtonRight = styled(ScrollIndicator)(() => ({
  right: 2,
}));

const ProductJourney: FC<IProductJourneyProps> = ({ menu }) => {
  const { ref, width, height, scrollLeft, scrollRight, scrollToLeft, scrollToRight } =
    useHorizontalScrollIndicator();
  const [{ enabled, id: contentBlockId }, , { loading, error }] = useContentBlock(menu.id);
  const { supplyChain, handleChainMappingAction } = useProductSupplyChainMapping();
  const { plan } = useCompanyPlanContext();
  const { openDialog } = useDialog();

  const handleOpenVisibilitySettings = () => {
    if (!supplyChain) return;

    openDialog({
      type: 'VISIBILITY_SETTINGS',
      props: {
        visibilitySettings: supplyChain.visibilitySettings,
        supplyChainId: supplyChain.id,
      },
    });
  };

  return (
    <StyledContainer>
      <FormContainer
        initialValues={{
          enabled,
          supplyChain,
        }}
        containerStyles={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        {() => (
          <Box display="flex" flexDirection="column" height="100%">
            <Box display="flex" flexDirection="row" alignItems="center" gap={3}>
              <Header
                title={menu.title}
                showEnabled={plan === CustomerPlan.PAYING}
                contentBlockId={contentBlockId}
              />
              <ThemeButton
                color="BLUE_ICE"
                startIcon={<Icon name="show" />}
                onClick={handleOpenVisibilitySettings}
              >
                Visibility settings
              </ThemeButton>
              <FlexBox>
                <InfoCircle size={14} />
                <Box mr={1} />
                <ThemeTypography variant="BODY_LARGE">
                  To edit supply chain details of this activity, go to the{' '}
                </ThemeTypography>
                <Box mr={0.5} />
                <StyledLink
                  to={`/supply-chains/${supplyChain?.productId}/${supplyChain?.id}/editor`}
                >
                  Supply chain mapping tool.
                </StyledLink>
              </FlexBox>
            </Box>
            {scrollLeft && (
              <ScrollButtonLeft onClick={scrollToLeft}>
                <ChevronLeft size={12} />
              </ScrollButtonLeft>
            )}
            {scrollRight && (
              <ScrollButtonRight onClick={scrollToRight}>
                <ChevronRight size={12} />
              </ScrollButtonRight>
            )}
            <MeasurementContainer ref={ref}>
              {loading ? (
                <Loader />
              ) : error || !supplyChain ? (
                <ErrorState />
              ) : supplyChain.mappingInitialised ? (
                <ChainMapping
                  height={height}
                  width={width}
                  viewMode="edit-activities"
                  chainMapping={supplyChain}
                  onActionClick={handleChainMappingAction}
                />
              ) : (
                <FlexBox
                  flexDirection="column"
                  justifyContent="center"
                  width={width}
                  height={height}
                >
                  <img src={ProductIllustration} alt="Product Illustration" />
                  <FlexBox>
                    <ThemeTypography variant="BODY_LARGE">
                      There is no supply chain data for this product yet. Manage your supply chain
                      in the
                    </ThemeTypography>
                    <Box mr={0.5} />
                    <StyledLink
                      to={`/supply-chains/${supplyChain.productId}/${supplyChain.id}/editor`}
                    >
                      Supply chain mapping view.
                    </StyledLink>
                  </FlexBox>
                </FlexBox>
              )}
            </MeasurementContainer>
          </Box>
        )}
      </FormContainer>
    </StyledContainer>
  );
};

export default ProductJourney;
