import { Box, Grid, styled } from '@mui/material';
import { FlexBox } from 'components/Structure';
import { ThemeTypography } from 'designSystem';
import { Percentage } from 'designSystem/Navigation/Stepper/Stepper';
import React, { FC, PropsWithChildren } from 'react';
import {
  TDueDiligenceProcessSection,
  TDueDiligenceProcessSectionKey,
  TDueDiligenceProcessSubSectionKey,
} from 'types/dueDilligenceProcess.types';
import NavigationItem from './components/NavigationItem';
import NavigationSubItem from './components/NavigationSubItem';

interface IDueDiligenceProcessHeaderProps extends PropsWithChildren {
  title: string;
  imageUrl: string;

  selectedSectionKey: TDueDiligenceProcessSectionKey;
  selectedSubSectionKey?: TDueDiligenceProcessSubSectionKey;

  /**
   * Navigation items
   */
  sections: (TDueDiligenceProcessSection & {
    disabled: boolean;
    completion: Percentage;
    count: number;
  })[];

  onMenuSectionClick: (sectionKey: TDueDiligenceProcessSectionKey) => void;
}

const Image = styled('img')(({ theme }) => ({
  height: theme.spacing(7),
  width: theme.spacing(7),
}));

const StyledContainer = styled(FlexBox)(({ theme }) => ({
  padding: theme.spacing(1.5),
  overflow: 'visible',
  position: 'relative',
  height: '100%',
  borderRadius: 8,
  background: '#fff',
  border: '2px solid',
  borderColor: theme.palette.grey[200],
}));

/**
 * This component relies on the ComplianceGuideContext to provide the sections and the selected section.
 * Please wrap this component with the ComplianceGuideContextProvider.
 */
const IDueDiligenceProcessHeader: FC<IDueDiligenceProcessHeaderProps> = ({
  children,
  imageUrl,
  selectedSectionKey,
  selectedSubSectionKey,
  title,
  sections,
  onMenuSectionClick,
}) => {
  return (
    <Grid container>
      <Grid item xs={12} mb={2}>
        <StyledContainer>
          <Image src={imageUrl} alt={title} />
          <Box ml={2} display="flex">
            <Box display="flex" flexDirection="column">
              <ThemeTypography variant="TITLE_SMALL">{title}</ThemeTypography>
              {/* <Box display="flex">
                <Box mr={1} width={200}>
                  <Select
                    fullWidth
                    size="small"
                    value={selectedId}
                    onChange={handleSelectionChange}
                  >
                    {selectValues.map(({ id, title }) => (
                      <MenuItem key={id} value={id}>
                        {title}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>

                {complianceAdornment}
              </Box>*/}
            </Box>
          </Box>
        </StyledContainer>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={2.5}>
          {sections.map((section, sectionIndex) => {
            const isSelected = selectedSectionKey === section.key;
            const isDisabled = section.disabled;
            const isCompleted = section.completion === 100;
            const completedUntilSubSectionIndex = section.subSections.findIndex(
              ({ key }) => key === selectedSubSectionKey
            );

            const handleClick = () => {
              if (!isDisabled) {
                onMenuSectionClick(section.key);
              }
            };

            return (
              <NavigationItem
                key={section.key}
                title={section.title}
                state={isSelected ? 'selected' : isDisabled ? 'disabled' : 'default'}
                validationState={isCompleted ? 'valid' : 'default'}
                index={sectionIndex}
                onClick={handleClick}
              >
                {section.subSections.map((subSection, subSectionIndex) => {
                  const isSubsectionDisabled = false;
                  const isSubSectionCompleted =
                    isCompleted ||
                    (completedUntilSubSectionIndex !== -1 &&
                      subSectionIndex < completedUntilSubSectionIndex);

                  return (
                    <NavigationSubItem
                      key={subSection.key}
                      title={subSection.title}
                      state={
                        isSelected ? 'selected' : isSubsectionDisabled ? 'disabled' : 'default'
                      }
                      validationState={isSubSectionCompleted ? 'valid' : 'default'}
                      sectionIndex={sectionIndex}
                      index={subSectionIndex}
                    />
                  );
                })}
              </NavigationItem>
            );
          })}
        </Grid>
        <Grid item xs={9.5}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IDueDiligenceProcessHeader;
