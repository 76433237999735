import { gql } from '@apollo/client';
import { DATASET_MAPPING_FRAGMENT } from 'graphql/fragments/dataset.fragments';
import {
  DATASET_FRAGMENT,
  DATASET_TABLE_REPRESENTATION_FRAGMENT,
} from 'graphql/fragments/dataset.fragments';

export const CREATE_DATASET = gql`
  mutation CreateDataset($input: CreateDatasetInput!) {
    createDataset(input: $input) {
      dataset {
        ...datasetValues
      }
    }
  }
  ${DATASET_FRAGMENT}
`;

export const UPDATE_DATASET = gql`
  mutation UpdateDataset($id: UUID!, $input: UpdateDatasetInput!) {
    updateDataset(id: $id, input: $input) {
      dataset {
        ...datasetValues
      }
    }
  }
  ${DATASET_FRAGMENT}
`;

export const DELETE_DATASET = gql`
  mutation DeleteDataset($id: UUID!) {
    deleteDataset(id: $id) {
      id
    }
  }
`;

export const PROCESS_DATASETS = gql`
  mutation ProcessDatasets($inputs: [ProcessDatasetInput]!) {
    processDatasets(inputs: $inputs) {
      datasetMappings {
        ...datasetMappingValues
      }
      datasets {
        ...datasetValues
        tableRepresentation {
          ...datasetTableRepresentationValues
        }
        sites {
          count
        }
      }
      datasetProcessingErrors {
        datasetId
        datasetErrors {
          entityId
          entityType
          errorMessage
          errorCategory
        }
        hasCellErrors
      }
    }
  }
  ${DATASET_FRAGMENT}
  ${DATASET_TABLE_REPRESENTATION_FRAGMENT}
  ${DATASET_MAPPING_FRAGMENT}
`;

export const EXPORT_DATASET = gql`
  mutation ExportDataset($id: UUID!, $input: ExportDatasetInput!) {
    exportDataset(id: $id, input: $input) {
      fileUrl
    }
  }
`;

export const PRE_PROCESS_DATASETS = gql`
  mutation PreProcessDatasets($inputs: [PreProcessDatasetInput]!) {
    preProcessDatasets(inputs: $inputs) {
      datasetMappings {
        ...datasetMappingValues
      }
      datasets {
        ...datasetValues
        tableRepresentation {
          ...datasetTableRepresentationValues
        }
        sites {
          count
        }
      }
      datasetProcessingErrors {
        datasetId
        datasetErrors {
          entityId
          entityType
          errorMessage
          errorCategory
        }
        hasCellErrors
      }
    }
  }
  ${DATASET_FRAGMENT}
  ${DATASET_TABLE_REPRESENTATION_FRAGMENT}
  ${DATASET_MAPPING_FRAGMENT}
`;
