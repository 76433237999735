import { IconButton, TableCell } from '@mui/material';
import {
  StyledCell,
  TableRow,
} from 'components/Partners/PartnersTable/PartnersTableBody/PartnersTableBody.styles';
import { ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import React, { FC, MouseEvent, useMemo } from 'react';
import { HeaderColumn } from 'types/types';
import { EDueDiligenceHistoryTableColumnKeys } from 'components/DueDiligenceProcess/constants/headerColumns';
import { ERiskLevel, IDueDiligenceProcess } from 'types/compliance.types';
import DueDiligenceProcessTitle from '../DueDiligenceProcessTitle';
import Moment from 'react-moment';

interface IDueDiligenceHistoryTableRowProps {
  dueDiligenceProcess: IDueDiligenceProcess;
  headerColumns: HeaderColumn[];
  onNavigateToProcessGuide: (dueDiligenceProcessId: string) => void;
  onDeleteProcess: (dueDiligenceProcessId: string) => void;
}

const DueDiligenceHistoryTableRow: FC<IDueDiligenceHistoryTableRowProps> = ({
  dueDiligenceProcess,
  headerColumns,
  onNavigateToProcessGuide,
  onDeleteProcess,
}) => {
  const batchesOfProcess = useMemo(
    () =>
      dueDiligenceProcess.datasets
        .flatMap(dataset => dataset.geocBatches || [])
        .filter(geocBatch => geocBatch.geoDataProcessId === dueDiligenceProcess.id),
    [dueDiligenceProcess]
  );

  const totalQuantity = batchesOfProcess.reduce((acc, batch) => acc + batch.weight, 0);

  const checkDateRange = batchesOfProcess.reduce<{ min?: string; max?: string }>(
    (acc, batch) => {
      if (!acc.min || acc.min > batch.checkDate) {
        acc.min = batch.checkDate;
      }
      if (!acc.max || acc.max < batch.checkDate) {
        acc.max = batch.checkDate;
      }
      return acc;
    },
    { min: undefined, max: undefined }
  );

  const riskOfProcess = batchesOfProcess.reduce<ERiskLevel | undefined>((acc, geocBatch) => {
    if (geocBatch.riskStatus === ERiskLevel.HIGH) {
      return ERiskLevel.HIGH;
    }
    if (geocBatch.riskStatus === ERiskLevel.MEDIUM && acc !== ERiskLevel.HIGH) {
      return ERiskLevel.MEDIUM;
    }
    if (
      geocBatch.riskStatus === ERiskLevel.LOW &&
      acc !== ERiskLevel.HIGH &&
      acc !== ERiskLevel.MEDIUM
    ) {
      return ERiskLevel.LOW;
    }
    return acc;
  }, undefined);

  const handleDeleteProcess = (event: MouseEvent) => {
    event.stopPropagation();
    onDeleteProcess(dueDiligenceProcess.id);
  };

  return (
    <TableRow
      data-cy="due-diligence-history-table-row"
      hover
      onClick={(event: MouseEvent) => {
        event.stopPropagation();
        onNavigateToProcessGuide(dueDiligenceProcess.id);
      }}
    >
      {headerColumns.map(({ id: columnId, align }) => {
        switch (columnId) {
          case EDueDiligenceHistoryTableColumnKeys.TITLE: {
            return (
              <StyledCell scope="row" align={align}>
                <DueDiligenceProcessTitle
                  dueDiligenceProcess={dueDiligenceProcess}
                  processRiskLevel={riskOfProcess}
                />
              </StyledCell>
            );
          }

          case EDueDiligenceHistoryTableColumnKeys.QUANTITY: {
            return (
              <StyledCell scope="row" align={align}>
                {totalQuantity > 0 && (
                  <ThemeTypography variant="BODY_SMALL">{totalQuantity}</ThemeTypography>
                )}
              </StyledCell>
            );
          }
          case EDueDiligenceHistoryTableColumnKeys.CHECK_DATE: {
            return (
              <StyledCell scope="row" align={align}>
                {checkDateRange.min && checkDateRange.max && (
                  <ThemeTypography variant="BODY_SMALL">
                    {checkDateRange.min === checkDateRange.max ? (
                      <Moment format="MMM DD, YYYY" date={checkDateRange.min} />
                    ) : (
                      <>
                        <Moment format="MMM DD, YYYY" date={checkDateRange.min} /> - &nbsp;
                        <Moment format="MMM DD, YYYY" date={checkDateRange.max} />
                      </>
                    )}
                  </ThemeTypography>
                )}
              </StyledCell>
            );
          }
          case EDueDiligenceHistoryTableColumnKeys.ACTIONS: {
            return (
              <TableCell width={100} scope="row" align={align}>
                <IconButton onClick={() => onNavigateToProcessGuide(dueDiligenceProcess.id)}>
                  <Icon name="guide" />
                </IconButton>
                <IconButton onClick={handleDeleteProcess}>
                  <Icon name="delete" />
                </IconButton>
              </TableCell>
            );
          }
          default:
            return <></>;
        }
      })}
    </TableRow>
  );
};

export default DueDiligenceHistoryTableRow;
