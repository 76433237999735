import { ColDef } from 'ag-grid-community';
import { Coordinates, GraphQlConnection } from './types';
import {
  ColumnDefinition,
  ColDefWithValidator,
  SystemColumns,
} from 'components/DataImport/DataImportTable/excelTable.types';
import { IBasePartnerCompany } from './partner.types';
import { CoordinateDatasetColumnType, FarmSizeDatasetColumnType } from './dataset.types';

export enum DataImportCategories {
  Farms = 'farms',
}

export type Farm = {
  id: string;
  farmExternalId: string;
  siteTitle: string;
  rawMaterialIds: string[];
  locationCoordinates?: Coordinates;
  farmSize?: number;
  ownedById?: string;
  farmDescription?: string;
};

export type FirstMileImport = {
  id: string;
  title?: string;
  createdTimestamp: Date;
  createdBy: IBasePartnerCompany;
  modifiedTimestamp: Date;
  language: string;
};

export interface IFarmResponse extends FirstMileImport {
  rows: GraphQlConnection<Farm>;
}

export interface IFirstMileImportsResponse {
  firstMileImports: GraphQlConnection<FirstMileImport>;
}

export interface IFirstMileImportResponse {
  firstMileImport: IFarmResponse;
}

export type FarmColumns = {
  id: string;
  farmId: ColumnDefinition<string>;
  name: ColumnDefinition<string>;
  crop: ColumnDefinition<string>;
  location: ColumnDefinition<string>;
  farmSize: ColumnDefinition<number>;
  weight?: ColumnDefinition<number>;
  description?: ColumnDefinition<string>;
};

export type DataImportColumn = ColDefWithValidator | ColDef<SystemColumns>;

export type CoordinateVersion = 'latlng' | 'lnglat';

export type FarmSizeUnit = 'ha' | 'km2';

export type WeightUnit = 'kg' | 't';

export interface ITableSettings {
  autoAssignIds: boolean;
  coordinatesVersion: CoordinateVersion;
  farmSizeUnit: FarmSizeUnit;
  weightUnit: WeightUnit;
}

export type OriginTableSettingsColumn<T = unknown> = {
  enabled: boolean;
  format?: T;
};
export interface IOriginTableSettings {
  overwriteExisting: boolean;
  clusterSites: boolean;
  columns: {
    farmId: OriginTableSettingsColumn<string>;
    coordinates: OriginTableSettingsColumn<CoordinateDatasetColumnType>;
    originClusterId: OriginTableSettingsColumn<string>;
    farmSize: OriginTableSettingsColumn<FarmSizeDatasetColumnType>;
    farmName: OriginTableSettingsColumn<string>;
    farmOwnerFullName: OriginTableSettingsColumn<string>;
    farmOwnerFirstName: OriginTableSettingsColumn<string>;
    farmOwnerLastName: OriginTableSettingsColumn<string>;
    landOwnership: OriginTableSettingsColumn<string>;
    numberOfFarmers: OriginTableSettingsColumn<number>;
    additionalCrops: OriginTableSettingsColumn<string>;
    siteDescription: OriginTableSettingsColumn<string>;
  };
}

export type TColumnKeys = keyof IOriginTableSettings['columns'];

export type ValidationResult = {
  isValid?: boolean;
  validationMessage?: string;
};

export enum TableCellStatus {
  VALID = 'VALID',
  ERROR = 'ERROR',
  WARN = 'WARN',
  EMPTY = 'EMPTY',
}

export type TableCellStatusType = `${TableCellStatus}`;
