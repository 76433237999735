import { Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { InfoCircleFill } from '@styled-icons/bootstrap';
import { ICellRendererParams } from 'ag-grid-community';
import { isRowEmpty } from 'components/DataImport/utils/dataImport.utils';
import { FlexBox } from 'components/Structure';
import Divider from 'designSystem/Layout/Divider/Divider';
import Icon from 'designSystem/Primitives/Icon/Icon';
import React, { FC, ReactNode, useRef, useState } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';
import copyTextToClipboard, { pasteTextFromClipboard } from 'utils/clipboard.utils';
import { IExcelTableContext } from '../excelTable.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface IValidatedCellRendererParams extends ICellRendererParams<any, any, IExcelTableContext> {
  children?: ReactNode;
}

const ValidatorContainer = styled('div')<{ focus: booleanish }>(({ theme, focus }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: focus === 'true' ? theme.custom.themeColors.primary[5] : 'inherit',
}));

const CellContent = styled('div')(() => ({
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
}));

const InfoIcon = styled(InfoCircleFill)(() => ({
  marginLeft: '5px',
  minWidth: '10px',
}));

const StyledMenu = styled(Menu)(() => ({
  '& .MuiMenu-list': {
    minWidth: 180,
  },
}));

const Tag = styled('div')(({ theme }) => ({
  padding: 4,
  borderRadius: 4,
  height: 16,
  minWidth: 16,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.custom.themeColors.grayScale[20],
  color: theme.custom.themeColors.grayScale[80],
}));

const MenuItemError = styled(MenuItem)(({ theme }) => ({
  color: theme.custom.themeColors.error[100],
}));

const ValidatedCellRenderer: FC<IValidatedCellRendererParams> = ({
  value,
  colDef,
  data,
  context,
  children,
  setValue,
}) => {
  const [contextMenuOpen, setContextMenuOpen] = useState<boolean>(false);
  const field = colDef?.field;

  const menuRef = useRef<HTMLDivElement>(null);

  const isValid =
    isRowEmpty(data) || !field || data[field]?.isValid || data[field]?.isValid === undefined;
  const hasErrorMessage = data && field && data[field]?.errorMessage;

  const handleCutClick = () => {
    if (!value) return;
    copyTextToClipboard(value);
    setValue?.(undefined);
    setContextMenuOpen(false);
  };

  const handleCopyClick = () => {
    if (!value) return;
    copyTextToClipboard(value);
    setContextMenuOpen(false);
  };

  const handlePasteClick = async () => {
    if (context.mode !== 'edit' && context.mode !== 'add-edit') return;
    const clipboardValue = await pasteTextFromClipboard();
    setValue?.(clipboardValue);
    setContextMenuOpen(false);
  };

  const handleClearCellClick = async () => {
    setValue?.(undefined);
    setContextMenuOpen(false);
  };

  return (
    <>
      <ValidatorContainer
        ref={menuRef}
        focus={Booleanish(contextMenuOpen)}
        onContextMenu={e => {
          e.preventDefault();
          setContextMenuOpen(true);
        }}
      >
        <CellContent>{children || <span>{value}</span>}</CellContent>
        {(!isValid || hasErrorMessage) && <InfoIcon size={10} />}
      </ValidatorContainer>

      <StyledMenu
        anchorEl={menuRef.current}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        open={contextMenuOpen}
        onContextMenu={e => {
          e.preventDefault();
          setContextMenuOpen(false);
        }}
        onClose={() => setContextMenuOpen(false)}
      >
        <MenuItem
          disabled={!value || (context.mode !== 'edit' && context.mode !== 'add-edit')}
          onClick={handleCutClick}
        >
          <FlexBox width="100%" justifyContent="space-between">
            <FlexBox>
              <Icon mr={1} name="cut" />
              Cut
            </FlexBox>
            <FlexBox gap={0.2}>
              <Tag>{context.isAppleDevice ? <>&#8984;</> : 'CTRL'}</Tag>+<Tag>X</Tag>
            </FlexBox>
          </FlexBox>
        </MenuItem>
        <MenuItem disabled={!value} onClick={handleCopyClick}>
          <FlexBox width="100%" justifyContent="space-between">
            <FlexBox>
              <Icon mr={1} name="copy" />
              Copy
            </FlexBox>
            <FlexBox gap={0.2}>
              <Tag>{context.isAppleDevice ? <>&#8984;</> : 'CTRL'}</Tag>+<Tag>C</Tag>
            </FlexBox>
          </FlexBox>
        </MenuItem>
        <MenuItem
          disabled={context.mode !== 'edit' && context.mode !== 'add-edit'}
          onClick={handlePasteClick}
        >
          <FlexBox width="100%" justifyContent="space-between">
            <FlexBox>
              <Icon mr={1} name="paste" />
              Paste
            </FlexBox>
            <FlexBox gap={0.2}>
              <Tag>{context.isAppleDevice ? <>&#8984;</> : 'CTRL'}</Tag>+<Tag>V</Tag>
            </FlexBox>
          </FlexBox>
        </MenuItem>
        <Divider variant="middle" />
        <MenuItemError
          disabled={context.mode !== 'edit' && context.mode !== 'add-edit'}
          onClick={handleClearCellClick}
        >
          <Icon mr={1} color="red" name="delete" />
          Clear cell
        </MenuItemError>
      </StyledMenu>
    </>
  );
};

export default ValidatedCellRenderer;
