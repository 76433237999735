import { Box, Stepper as MuiStepper, Step, StepLabel, styled } from '@mui/material';
import FlexBox from 'components/Structure/FlexBox';
import ActionLink from 'designSystem/Buttons/ActionLink/ActionLink';
import ThemeButton, { ButtonColor } from 'designSystem/Buttons/ThemeButton/ThemeButton';
import Icon, { IconNameType } from 'designSystem/Primitives/Icon/Icon';
import React, { FC } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';

type Range<T extends number> = number extends T ? number : _Range<T, []>;
type _Range<T extends number, R extends unknown[]> = R['length'] extends T
  ? R[number]
  : _Range<T, [R['length'], ...R]>;

export type Percentage = Range<101>;

interface IStepperProps {
  selectedIndex: number;
  /**
   * Array of steps to be displayed in the stepper
   * Each step should have an id and a completion percentage
   * Count of how many sub steps are in the step
   */
  steps: { completion: Percentage; disabled?: boolean; count?: number }[];
  disablePreviousStep?: boolean;
  disableNextStep?: boolean;
  loading?: boolean;
  /**
   * Custom text which will display a normal button instead of the next step button
   */
  customNextStepButton?: { text: string; startIcon?: IconNameType; color?: ButtonColor };
  onNextStepClick?: () => void;
  onPreviousStepClick?: () => void;
}

const Container = styled(FlexBox)(({ theme }) => ({
  width: '100%',
  justifyContent: 'space-between',
  userSelect: 'none',
}));

const Index = styled('div')<{ disabled: booleanish }>(({ theme, disabled }) => ({
  color: '#fff',
  background:
    disabled === 'true' ? theme.custom.themeColors.grayScale[60] : theme.palette.secondary.main,
  borderRadius: '50%',
  width: theme.spacing(3),
  minWidth: theme.spacing(3),
  height: theme.spacing(3),
  fontSize: 12,
  lineHeight: 11,
  boxShadow: theme.custom.shadows[1],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 700,
  marginRight: theme.spacing(1),
}));

const ConnectorContainer = styled('div')(({ theme }) => ({
  background: theme.custom.colors.backgroundMedium,
  width: `calc(100% - ${theme.spacing(3)})`,
  position: 'relative',
  height: theme.spacing(1),
  marginLeft: theme.spacing(-3),
}));

const Connector = styled('div')<{ completion: number }>(({ theme, completion }) => ({
  position: 'absolute',
  width: `${completion}%`,
  height: theme.spacing(1),
  background: theme.palette.primary.main,

  // Apply border radius only if the completion is 100%
  ...(completion !== 100
    ? {}
    : {
        borderTopRightRadius: theme.spacing(1),
        borderBottomRightRadius: theme.spacing(1),
      }),

  // The end of the connector should also have a 55 degree angle if the completion is not 100%
  '&:after': {
    content: completion === 100 || completion === 0 ? 'none' : '""',
    background: 'white',
    position: 'absolute',
    width: theme.spacing(1),
    height: theme.spacing(1),
    // Missing angle to 90 degrees of the 55 degree angle
    transform: 'skew(-35deg)',
    right: -4,
  },
}));

/**
 * This has an option count to show the location of the user in the step
 */
const SelectedConnector = styled('div')<{ completion: number; count?: number }>(
  ({ theme, completion, count }) => ({
    position: 'absolute',
    background: `repeating-linear-gradient(
    -55deg,
    ${theme.palette.primary.main},
    ${theme.palette.primary.light} 4px,
    ${theme.palette.primary.light} 4px,
    ${theme.palette.primary.light} 8px
  );`,
    left: `${completion}%`,
    width: !!count ? `calc(100% / ${count})` : 16,
    height: theme.spacing(1),

    // Fix beginning of the next line gap
    ':after': {
      content: '""',
      position: 'absolute',
      width: 3,
      height: '100%',
      background: theme.palette.primary.light,
      top: 0,
      right: -2,
      transform: 'skew(-35deg)',
    },
  })
);

const Stepper: FC<IStepperProps> = ({
  steps,
  selectedIndex,
  disablePreviousStep,
  disableNextStep,
  loading,
  customNextStepButton,
  onPreviousStepClick,
  onNextStepClick,
}) => {
  const isPreviousStepDisabled = disablePreviousStep;
  const isNextStepDisabled = disableNextStep;

  return (
    <Container>
      <ActionLink type="cancel" rtl disabled={isPreviousStepDisabled} onClick={onPreviousStepClick}>
        Previous
      </ActionLink>

      <Box width="60%">
        <MuiStepper activeStep={selectedIndex ?? -1}>
          {steps.map(({ completion, disabled, count }, index) => {
            return (
              <>
                <Step key={`stepper-label-${index}`}>
                  <StepLabel
                    StepIconComponent={() => (
                      <Index disabled={Booleanish(disabled)}>{index + 1}</Index>
                    )}
                  />
                </Step>
                <ConnectorContainer key={`stepper-connector-${index}`}>
                  <Connector completion={completion} />
                  {/* This is indicating the the location where the user is currently working on which is not completed  */}
                  {index === selectedIndex && completion !== 100 && (
                    <SelectedConnector completion={completion} count={count} />
                  )}
                </ConnectorContainer>
              </>
            );
          })}
        </MuiStepper>
      </Box>

      {customNextStepButton ? (
        <ThemeButton
          type="submit"
          color={customNextStepButton.color}
          disabled={isNextStepDisabled}
          loading={loading}
          startIcon={
            customNextStepButton.startIcon ? (
              <Icon name={customNextStepButton.startIcon} />
            ) : undefined
          }
          onClick={onNextStepClick}
        >
          {customNextStepButton.text}
        </ThemeButton>
      ) : (
        <ActionLink
          type="submit"
          disabled={isNextStepDisabled}
          loading={loading}
          onClick={onNextStepClick}
        >
          Next
        </ActionLink>
      )}
    </Container>
  );
};

export default Stepper;
