import { Box, Grid, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Edit } from '@styled-icons/boxicons-solid/Edit';
import { MegaphoneIcon, SupplyChainEditIcon } from 'assets/img/icons';
import defaultSupplyChainImage from 'assets/img/supply-chain-placeholder-image.svg';
import { Loader } from 'components/Forms';
import PartnerList from 'components/Partners/PartnerList';
import { ErrorState, PageContainer, PageTitle } from 'components/Structure';
import FlexBox from 'components/Structure/FlexBox';
import OverviewHeader from 'components/Structure/OverviewHeader/OverviewHeader';
import useSupplyChain from 'components/SupplyChainsLibrary/hooks/useSupplyChain';
import {
  CardIllustration,
  EmptyStateCard,
  TaskProgress,
  ThemeButton,
  ThemeTypography,
} from 'designSystem';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { AvailableSizes, BackgroundTypes, IllustrationTypes, ItemTypes } from 'types/enums';

const StyledEditIcon = styled(Edit)(({ theme }) => ({
  color: theme.custom.themeColors.grayScale[40],
}));

const SupplyChainOverview: FC = () => {
  const { product, loading, error, handleEditProduct } = useSupplyChain();
  const navigate = useNavigate();

  if (loading || !product) {
    return <Loader />;
  }
  if (error || !product) {
    return <ErrorState />;
  }

  const handleProductClick = () => {
    navigate(`/products/${product.id}`);
  };

  const handleMappingClick = () => {
    navigate(`/supply-chains/${product.id}/${product.supplyChainId}/editor`);
  };

  return (
    <PageContainer>
      <PageTitle
        title="Supply chain overview"
        titleExtension={product.title}
        goBackLabel="All supply chains"
        goBackUrl="/supply-chains"
      />
      <Box mt={4}>
        <OverviewHeader image={product.image} imageUrl={defaultSupplyChainImage}>
          <Box display="flex" justifyContent="space-between" flex={1} height="100%">
            <Box
              display="flex"
              flexDirection="column"
              flex={1}
              justifyContent="center"
              alignItems="flex-start"
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <ThemeTypography variant="TITLE_MEDIUM" data-cy="supply-chain-title">
                  {product.title}
                </ThemeTypography>
                {product.articleNumber && (
                  <FlexBox ml={2}>
                    <ThemeTypography variant="TITLE_EXTRA_SMALL" color="GRAY_40">
                      |
                    </ThemeTypography>
                    <Box mr={1} />
                    <ThemeTypography
                      variant="TITLE_EXTRA_SMALL"
                      autoOverflow
                      maxWidth={200}
                      color="GRAY_40"
                    >
                      {product.articleNumber}
                    </ThemeTypography>
                  </FlexBox>
                )}
                <IconButton onClick={handleEditProduct}>
                  <StyledEditIcon size={20} />
                </IconButton>
              </Box>
              <Box mt={1}>
                {product.latestSupplyChain?.chainTasksDetails && (
                  <TaskProgress
                    totalTasks={product.latestSupplyChain.chainTasksDetails.total}
                    completedTasks={product.latestSupplyChain.chainTasksDetails.completed}
                  />
                )}
              </Box>
              <Box display="flex" mt={2.5}>
                {!!product.connectedPartnerCompanies?.length && (
                  <ThemeButton
                    startIcon={<SupplyChainEditIcon />}
                    size="large"
                    color="YELLOW"
                    loading={false}
                    onClick={handleMappingClick}
                    data-cy="edit-supply-chain-btn"
                  >
                    Map supply chain
                  </ThemeButton>
                )}
                <Box ml={1}>
                  <ThemeButton
                    startIcon={<MegaphoneIcon />}
                    data-cy="product-page-btn"
                    size="large"
                    color="BLUE_ICE"
                    loading={false}
                    onClick={handleProductClick}
                  >
                    Product page
                  </ThemeButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </OverviewHeader>
      </Box>
      <Box mt={4}>
        {!!product.connectedPartnerCompanies?.length ? (
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <PartnerList partners={product.connectedPartnerCompanies} />
            </Grid>
          </Grid>
        ) : (
          <EmptyStateCard
            title="Start mapping the supply chain of this product, to be in control of all steps and find the gaps of information"
            variant={ItemTypes.CHAIN_MAPPING}
            buttonIcon={<SupplyChainEditIcon />}
            buttonText="Map supply chain"
            background={BackgroundTypes.WORLD}
            size={AvailableSizes.MEDIUM}
            renderIllustration={
              <CardIllustration
                image={IllustrationTypes.LOCATIONS}
                width={300}
                right={25}
                top={0}
              />
            }
            onCreateItemClick={handleMappingClick}
          />
        )}
      </Box>
    </PageContainer>
  );
};

export default SupplyChainOverview;
