import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Files, Plus } from '@styled-icons/bootstrap';
import { Edit } from '@styled-icons/boxicons-solid';
import { Loader, SearchToolbar } from 'components/Forms';
import { ProductSummarySkeleton } from 'components/Product/ProductTable';
import {
  ActionContainer,
  EmptySearchResults,
  ErrorState,
  PageContainer,
  PageTitle,
  ScrollableTableContainer,
} from 'components/Structure';
import { CardIllustration, EmptyStateCard, ThemeButton } from 'designSystem';
import React, { FC, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { AvailableSizes, BackgroundTypes, IllustrationTypes, ItemTypes } from 'types/enums';
import useSupplyChains from 'components/SupplyChainsLibrary/hooks/useSupplyChains';
import SupplyChainTable from 'components/SupplyChainsLibrary/SupplyChainTable/SupplyChainTable';
import { ITableRowAction } from 'components/SupplyChainsLibrary/SupplyChainTable/TableRow';
import { useProductActions } from 'components/hooks';
import { IBaseProduct } from 'types/product.type';

const Container = styled('div')(() => ({
  position: 'relative',
  minHeight: 50,
  marginTop: 16,

  '& .MuiGrid-item': {
    display: 'flex',
  },
}));

const SupplyChains: FC = () => {
  const {
    productsWithSupplyChains,
    filteredProductsWithSupplyChains,
    searchTerm,
    setSearchTerm,
    loading,
    error,
  } = useSupplyChains();
  const { handleCreateProduct, handleDuplicateProduct, navigateToProductEditor } =
    useProductActions();
  const navigate = useNavigate();

  const tableActions: ITableRowAction[] = [
    {
      renderIcon: () => <Files size="17" />,
      tooltip: 'Duplicate product',
      onClick: handleDuplicateProduct,
    },
    {
      renderIcon: () => <Edit size="17" />,
      tooltip: 'Edit product',
      onClick: ({ id }) => navigateToProductEditor(id),
    },
  ];

  const onClickSupplyChain = (product: IBaseProduct) => {
    navigate(`/supply-chains/${product.id}/${product.supplyChainId}`);
  };

  if (error) {
    return <ErrorState />;
  }
  if (loading) {
    return <Loader />;
  }

  return (
    <PageContainer>
      <PageTitle title="Supply chains" />
      <Container>
        {!productsWithSupplyChains.length ? (
          <Box mt={4}>
            <EmptyStateCard
              title="Add your products to start mapping their supply chains"
              onCreateItemClick={handleCreateProduct}
              variant={ItemTypes.PRODUCT}
              buttonText="Add product"
              background={BackgroundTypes.WORLD}
              size={AvailableSizes.LARGE}
              renderIllustration={
                <CardIllustration
                  image={IllustrationTypes.LOCATIONS}
                  width={300}
                  right={25}
                  top={0}
                />
              }
              data-testid="supply-chain-getting-started-card"
            />
          </Box>
        ) : (
          <Fragment>
            <ActionContainer>
              <SearchToolbar
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm || ''}
                className="search-input"
                data-cy="supply-chain-search-input"
                placeholder="Search"
                actions={
                  <Box ml={1}>
                    <ThemeButton
                      size="medium"
                      color="YELLOW"
                      onClick={handleCreateProduct}
                      startIcon={<Plus size={20} />}
                      data-cy="create-product-btn"
                    >
                      Add new product
                    </ThemeButton>
                  </Box>
                }
              />
            </ActionContainer>
            <ScrollableTableContainer pb={1}>
              <SupplyChainTable
                products={filteredProductsWithSupplyChains}
                data-cy="supply-chain-table"
                actions={tableActions}
                onRowClick={onClickSupplyChain}
              />
            </ScrollableTableContainer>
          </Fragment>
        )}
      </Container>
      {/* No Result container */}
      {productsWithSupplyChains.length && !filteredProductsWithSupplyChains?.length ? (
        <EmptySearchResults
          message="No search results found"
          action={{
            label: 'Add new product',
            onClick: handleCreateProduct,
          }}
          skeletonComponent={<ProductSummarySkeleton title={searchTerm} />}
        />
      ) : undefined}
    </PageContainer>
  );
};

export default SupplyChains;
