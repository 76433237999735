import { Box, styled } from '@mui/material';
import React, { FC } from 'react';

interface IEmptyDatasetProps {
  active?: boolean;
  width?: number;
  height?: number;
}

const Container = styled(Box)<{ active?: boolean }>(({ theme, active }) => ({
  position: 'relative',
  borderRadius: 10,
  background: theme.custom.themeColors.grayScale[10],
  padding: '11px 15px',

  ...(active && {
    ':before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      borderRadius: 10,
      top: 0,
      left: 0,
      border: `3px solid ${theme.custom.themeColors.grayScale[40]}`,
    },
  }),
}));

const DatasetSkeleton: FC<IEmptyDatasetProps> = ({ active, width = 219, height = 60 }) => {
  return (
    <Container
      active={active}
      width={width}
      height={height}
      display="flex"
      flexDirection="column"
      gap={1}
    >
      <Box bgcolor="white" borderRadius="2px" width={width * 0.63} height={height * 0.25} />
      <Box bgcolor="white" borderRadius="2px" width={width * 0.41} height={height * 0.25} />
    </Container>
  );
};

export default DatasetSkeleton;
