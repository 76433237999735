import { ErrorState } from 'components/Structure';
import React, { FC, createContext, createElement, useContext } from 'react';
import { IDueDiligenceProcess } from 'types/compliance.types';
import {
  IDueDiligenceProcessSubSectionContextDefaultValues,
  TDueDiligenceProcessSubSection,
} from 'types/dueDilligenceProcess.types';
import { IDueDiligenceProcessContextValues } from '../DueDiligenceProcessContext';
import RiskMitigationEmptyState from 'components/DueDiligenceProcess/components/SubSections/RiskMitigationEmptyState';

interface IRiskMitigationContextValues extends IDueDiligenceProcessSubSectionContextDefaultValues {
  dueDiligenceProcess: IDueDiligenceProcess;
}

const RiskMitigationContext = createContext<IRiskMitigationContextValues>({
  selectedSubSectionIndex: 0,
  selectedSubSection: {} as TDueDiligenceProcessSubSection,
  dueDiligenceProcess: {} as IDueDiligenceProcess,
  onNextClick: () => undefined,
  onPreviousClick: () => undefined,
});

const RiskMitigationContextProvider: FC<IDueDiligenceProcessContextValues> = ({
  selectedSubSection,
  selectedSubSectionIndex,
  dueDiligenceProcess,
  navigateToPreviousSection,
}) => {
  if (!selectedSubSection || selectedSubSectionIndex === undefined) {
    return <RiskMitigationEmptyState navigateToPreviousSection={navigateToPreviousSection} />;
  }
  if (!dueDiligenceProcess) {
    return <ErrorState />;
  }

  const handleClickNext = () => {
    // TODO: Implement
  };

  const handleClickPrevious = () => {
    // TODO: Implement
  };

  const state = {
    selectedSubSection,
    selectedSubSectionIndex,
    dueDiligenceProcess,
    onNextClick: handleClickNext,
    onPreviousClick: handleClickPrevious,
  };

  return (
    <RiskMitigationContext.Provider value={state}>
      {createElement(selectedSubSection.content)}
    </RiskMitigationContext.Provider>
  );
};

const useRiskMitigationContext = () => useContext(RiskMitigationContext);

export { RiskMitigationContextProvider, useRiskMitigationContext };

export default RiskMitigationContext;
