import { FlexBox } from 'components/Structure';
import React, { FC } from 'react';
import { ERiskLevel } from 'types/compliance.types';
import DueDiligenceProcessSubSectionNavigation from 'components/DueDiligenceProcess/DueDiligenceProcessSubSectionNavigation';
import DatasetSkeleton from 'designSystem/DataDisplay/DatasetSkeleton/DatasetSkeleton';
import { Box, styled, Grid, Skeleton } from '@mui/material';
import { ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import RiskBadge from 'designSystem/DataDisplay/RiskBadge/RiskBadge';

interface IRiskMitigationContextValues {
  navigateToPreviousSection: () => void;
}

const Container = styled(Box)(({ theme }) => ({
  background: 'white',
  padding: theme.spacing(3),
  border: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
  borderRadius: 6,
  width: '100%',
  marginTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.custom.themeColors.grayScale[10],
  borderRadius: 4,
}));

const UploadContainer = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.custom.themeColors.grayScale[30]}`,
  borderRadius: 6,
  width: '100%',
  height: 132,
}));

const RiskContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
  borderRadius: 8,
  width: '100%',
  height: 68,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  paddingLeft: theme.spacing(2),
}));

const RiskMitigationEmptyState: FC<IRiskMitigationContextValues> = ({
  navigateToPreviousSection,
}) => {
  return (
    <>
      <DueDiligenceProcessSubSectionNavigation
        title="Risk mitigation"
        description="Once you have completed a deforestation risk analysis, you will be able to overview and get recommendations of how to mitigate any risks with mitigation procedures here. If mitigations are implemented, you can describe and upload supporting documentation to prove your mitigations. This uploaded information will be included into your due diligence data for DDS submissions and exported reports. "
        allowNextStepNavigation={false}
        onPreviousStepClick={navigateToPreviousSection}
      >
        <FlexBox gap={2}>
          <DatasetSkeleton active />
          <DatasetSkeleton />
          <DatasetSkeleton />
        </FlexBox>
      </DueDiligenceProcessSubSectionNavigation>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <RiskContainer mb={1}>
              <Icon name="site-placeholder" size="xx-large" color="blue-ice" />
              <RiskBadge riskLevel={ERiskLevel.HIGH} text="High risk" />
            </RiskContainer>
            <RiskContainer>
              <Icon name="site-placeholder" size="xx-large" color="blue-ice" />
              <RiskBadge riskLevel={ERiskLevel.MEDIUM} text="Medium risk" />
            </RiskContainer>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Consider the following recommendations
              </ThemeTypography>
              <Box display="flex" flexDirection="column" mt={1.5} gap={0.25}>
                <StyledSkeleton width={'calc(100% * 0.7)'} height={16} animation={false} />
                <StyledSkeleton width={'calc(100% * 0.65)'} height={16} animation={false} />
                <StyledSkeleton width={'calc(100% * 0.75)'} height={16} animation={false} />
                <StyledSkeleton width={'calc(100% * 0.8)'} height={16} animation={false} />
              </Box>
            </Box>
            <Box mt={1.5}>
              <ThemeTypography variant="BODY_LARGE_BOLD">Document upload</ThemeTypography>
              <UploadContainer mt={1} display="flex" justifyContent="center" alignItems="center">
                <Icon name="import" size="x-large" color="gray-30" />
              </UploadContainer>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default RiskMitigationEmptyState;
