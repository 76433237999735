import ComplianceEudrImage from 'assets/img/compliance/eudr.png';
import { EComplianceType } from 'types/compliance.types';
import {
  EDueDiligenceProcessSectionKey,
  EDueDiligenceProcessSubSectionKey,
  IDueDiligenceProcess as IComplianceProcess,
} from 'types/dueDilligenceProcess.types';
import SummaryOverview from './components/SubSections/SummaryOverview';
import GeoDataCollectionConfirmation from './components/SubSections/GeoDataCollectionConfirmation';
import GeoDataCollectionMethods from './components/SubSections/GeoDataCollectionMethods';
import GeoDataCollectionSelection from './components/SubSections/GeoDataCollectionSelection';
import UploadGeoData from './components/SubSections/GeoDataCollectionUpload';
import GeoDataCollectionUploadMapping from './components/SubSections/GeoDataCollectionUploadMapping';
import GeoDataCollectionUploadValidation from './components/SubSections/GeoDataCollectionUploadValidation';
import GeoDataCollectionVerification from './components/SubSections/GeoDataCollectionVerification';
import RiskMitigationPolygons from './components/SubSections/RiskMitigationPolygons';
import { GeoDataCollectionContextProvider } from './Context/SubSections/GeoDataCollectionContext';
import { RiskMitigationContextProvider } from './Context/SubSections/RiskMitigationContext';
import { SummaryContextProvider } from './Context/SubSections/SummaryContext';
import RiskAnalysisBatches from './components/SubSections/RiskAnalysisBatches';
import RiskAnalysisResults from './components/SubSections/RiskAnalysisResults';
import { RiskAnalysisContextProvider } from './Context/SubSections/RiskAnalysisContext';

export const DUE_DILIGENCE_PROCESS_SECTIONS: IComplianceProcess = {
  key: EComplianceType.EUDR,
  title: 'EU Deforestation Regulation (EUDR)',
  image: ComplianceEudrImage,
  sections: [
    {
      key: EDueDiligenceProcessSectionKey.GEO_DATA_COLLECTION,
      title: 'Collect geo-data',
      contextProvider: GeoDataCollectionContextProvider,
      disabled: false,
      subSections: [
        {
          key: EDueDiligenceProcessSubSectionKey.METHODS,
          title: 'Geo-data collection methods',
          description:
            'Select how to collect geo-data for the farm plots of the supply chain origins that are used to grow the affected commodities.',
          content: GeoDataCollectionMethods,
        },
        {
          key: EDueDiligenceProcessSubSectionKey.UPLOAD,
          title: 'Upload geo-data',
          description:
            'You can upload your origin geo-data in sheet format (.csv or xlsx), or in polygon-adapted file format (.kml or .geojson). One dataset refers to the geo-data of one origin. Giving a name to your dataset will help you remember to what origin it refers to. You need to confirm and specify which country of origin and commodity that each origin geo-dataset refers to. Select also the partner that is the owner of each dataset (this can for example be the supplier that provided the data, a supplier linked to that origin, or your own company).',
          content: UploadGeoData,
        },
        {
          key: EDueDiligenceProcessSubSectionKey.UPLOAD_MAPPING,
          title: 'Map uploaded data columns',
          description:
            'The columns of your uploaded dataset are listed here. Please link the data of your uploaded file with the correct template data type in the Seedtrace system by selecting a template column for each uploaded column. If there is no matching template column selection for an uploaded column, this data point is currently not available for import and needs to be excluded. Click the minus/plus icon to exclude or include a data point. The dataset can only be saved once all columns are mapped or excluded.',
          content: GeoDataCollectionUploadMapping,
        },
        {
          key: EDueDiligenceProcessSubSectionKey.UPLOAD_VALIDATION,
          title: 'Validate uploaded geo-data',
          description: 'Please make sure to correct any visible errors before continuing.',
          content: GeoDataCollectionUploadValidation,
        },
        {
          key: EDueDiligenceProcessSubSectionKey.SELECTION,
          title: 'Select geo-data to include',
          description:
            'Make sure to select all origin geo-datasets from partners that are involved in the supply chains of commodities affected by the regulation (Cocoa, Coffee, Palm, Soy, Rubber, Timber, Cattle). You can also select the latest available origin cluster cultivation area data from the platform to include all plots from potentially multiple geo-data uploads. To edit a dataset, go back to the geo-data collection step.',
          content: GeoDataCollectionSelection,
        },
        {
          key: EDueDiligenceProcessSubSectionKey.VERIFICATION,
          title: 'Geo-data verification',
          description:
            'We detected some issues in your data. In order to perform a credible deforestation risk analysis, please address the issues before continuing. Errors must be resolved to continue. Geo-datasets with warnings can be analysed, but it is recommended to resolve all warnings before.',
          content: GeoDataCollectionVerification,
        },
        {
          key: EDueDiligenceProcessSubSectionKey.CONFIRMATION,
          title: 'Geo-data overview',
          description: 'Browse your included geo-data for this due diligence.',
          content: GeoDataCollectionConfirmation,
        },
      ],
    },
    {
      key: EDueDiligenceProcessSectionKey.RISK_ANALYSIS,
      title: 'Deforestation risk analysis',
      contextProvider: RiskAnalysisContextProvider,
      disabled: false,
      subSections: [
        {
          key: EDueDiligenceProcessSubSectionKey.BATCHES,
          title: 'Add origin batch data',
          description:
            'For each origin, please add the details of the origin batches you want to analyse. An origin batch refers to the material that comes out from an activity at the origin (e.g. harvest).',
          content: RiskAnalysisBatches,
        },
        {
          key: EDueDiligenceProcessSubSectionKey.RESULTS,
          title: 'Deforestation risk analysis',
          description: undefined,
          content: RiskAnalysisResults,
        },
      ],
    },
    {
      key: EDueDiligenceProcessSectionKey.RISK_MITIGATION,
      title: 'Risk mitigation',
      contextProvider: RiskMitigationContextProvider,
      disabled: false,
      subSections: [
        {
          key: EDueDiligenceProcessSubSectionKey.POLYGONS,
          title: 'Risk mitigation',
          itemTitle: 'Deforestion risk measures',
          description: undefined,
          content: RiskMitigationPolygons,
        },
      ],
    },
    {
      key: EDueDiligenceProcessSectionKey.SUMMARY,
      title: 'Due diligence summary',
      contextProvider: SummaryContextProvider,
      disabled: true,
      subSections: [
        {
          key: EDueDiligenceProcessSubSectionKey.OVERVIEW,
          title: 'Due diligence summary',
          content: SummaryOverview,
        },
      ],
    },
  ],
};
