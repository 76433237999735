import { AccordionDetails, AccordionSummary, Box, styled } from '@mui/material';
import ForestChangeLegendImage from 'assets/img/forest_change_legend.png';
import LandcoverLegendImage from 'assets/img/landcover_legend.png';
import { ThemeButton, ThemeTypography } from 'designSystem';
import { Accordion } from 'designSystem/DataDisplay/Accordion/Accordion';
import RiskBadge from 'designSystem/DataDisplay/RiskBadge/RiskBadge';
import Icon from 'designSystem/Primitives/Icon/Icon';
import moment from 'moment';
import React, { FC } from 'react';
import { ChangeDetection, GeoCParcel, ERiskLevel } from 'types/compliance.types';
import DeforestationImage from './DeforestationImage';
import capitalize from 'lodash/capitalize';

interface IRiskAccordionProps {
  parcel: GeoCParcel;
  hasBorder?: boolean;
  onOpenFarmClick?: () => void;
}

const RiskBadgeContainer = styled(Box)(({ theme }) => ({
  '& p': {
    fontSize: '10px !important',
  },
}));

const RiskAccordion: FC<IRiskAccordionProps> = ({ parcel, onOpenFarmClick }) => {
  return (
    <Accordion elevated slotProps={{ transition: { unmountOnExit: true, timeout: 600 } }}>
      <AccordionSummary>
        <Box display="flex" alignItems="center">
          <Box display="flex" mr={1}>
            <Icon name="location" size="x-large" color="blue-ice" />
          </Box>
          <Box>
            <Box display="flex" alignItems="center">
              {parcel.cultivatedAreas.farm.title && (
                <Box mr={0.75}>
                  <ThemeTypography variant="ITEM_TITLE">
                    {parcel.cultivatedAreas.farm.title}
                  </ThemeTypography>
                </Box>
              )}
              <RiskBadge
                riskLevel={ERiskLevel[parcel.deforestationRisk]}
                text={capitalize(ERiskLevel[parcel.deforestationRisk]) + ' risk'}
              />
            </Box>
            {parcel.cultivatedAreas.farm.externalId && (
              <Box display="flex" alignItems="center" gap={1}>
                <ThemeTypography variant="BODY_LARGE" color="GRAY_60">
                  FARM ID: {parcel.cultivatedAreas.farm.externalId}
                </ThemeTypography>
                {/* Todo: add plot if when we implement external plot id */}
                {/* <ThemeTypography variant="BODY_LARGE" color="GRAY_60">
                  PLOT ID: {parcel.id}
                </ThemeTypography> */}
              </Box>
            )}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" gap={1} flexWrap="wrap">
          <DeforestationImage
            headline="Land cover"
            subHeadline={moment(parcel.baselineDate).format('YYYY-MM-DD')}
            imageSrc={parcel.landcoverPngBaselineDate}
            legendSrc={LandcoverLegendImage}
            openFullScreen
          />
          <DeforestationImage
            headline="Start date"
            subHeadline={moment(parcel.baselineDate).format('YYYY-MM-DD')}
            imageSrc={parcel.rgbPngBaselineDate}
            openFullScreen
          />
          <DeforestationImage
            headline="Harvest date"
            subHeadline={moment(parcel.checkDate).format('YYYY-MM-DD')}
            imageSrc={parcel.rgbPngProductionEnddate}
            openFullScreen
          />
          <DeforestationImage
            headline="Period change"
            subHeadline="Start - Harvest"
            imageSrc={parcel.forestChangePng}
            legendSrc={ForestChangeLegendImage}
            displayBorder
            openFullScreen
          />
          <Box flexDirection="column">
            <ThemeTypography variant="LABEL_INPUT">Deforestation risk result</ThemeTypography>
            <Box mb={0.5} />
            <RiskBadgeContainer>
              <RiskBadge
                riskLevel={ERiskLevel[parcel.deforestationRisk]}
                text={ChangeDetection[parcel.deforestationRisk]}
              />
            </RiskBadgeContainer>
          </Box>
          <Box flexDirection="column">
            <ThemeTypography variant="LABEL_INPUT">Links</ThemeTypography>
            <Box mb={0.5} />
            {onOpenFarmClick && (
              <ThemeButton
                color="BLUE_ICE"
                size="small"
                startIcon={<Icon name="link" size="small" />}
                onClick={onOpenFarmClick}
              >
                View farm
              </ThemeButton>
            )}
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default RiskAccordion;
