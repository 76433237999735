import { FC } from 'react';
import { EComplianceType } from './compliance.types';
import { IDueDiligenceProcessContextValues } from 'components/DueDiligenceProcess/Context/DueDiligenceProcessContext';

export interface IDueDiligenceProcessSubSectionContextDefaultValues {
  selectedSubSectionIndex: number;
  selectedSubSection: TDueDiligenceProcessSubSection;
  onNextClick: () => void;
  onPreviousClick: () => void;
}

export enum EDueDiligenceProcessSectionKey {
  GEO_DATA_COLLECTION = 'GEO_DATA_COLLECTION',
  RISK_ANALYSIS = 'RISK_ANALYSIS',
  RISK_MITIGATION = 'RISK_MITIGATION',
  SUMMARY = 'SUMMARY',
}

export enum EDueDiligenceProcessSubSectionKey {
  METHODS = 'METHODS',
  UPLOAD = 'UPLOAD',
  UPLOAD_MAPPING = 'UPLOAD_MAPPING',
  UPLOAD_VALIDATION = 'UPLOAD_VALIDATION',
  SELECTION = 'SELECTION',
  REQUEST = 'REQUEST',
  DRAWING = 'DRAWING',
  VERIFICATION = 'VERIFICATION',
  CONFIRMATION = 'CONFIRMATION',
  BATCHES = 'BATCHES',
  RESULTS = 'RESULTS',
  POLYGONS = 'POLYGONS',
  OVERVIEW = 'OVERVIEW',
}

export type TComplianceType = `${EComplianceType}`;

export type TDueDiligenceProcessSectionKey = `${EDueDiligenceProcessSectionKey}`;

export type TGeoDataCollectionSubSectionKey =
  | EDueDiligenceProcessSubSectionKey.METHODS
  | EDueDiligenceProcessSubSectionKey.UPLOAD
  | EDueDiligenceProcessSubSectionKey.UPLOAD_MAPPING
  | EDueDiligenceProcessSubSectionKey.UPLOAD_VALIDATION
  | EDueDiligenceProcessSubSectionKey.SELECTION
  | EDueDiligenceProcessSubSectionKey.REQUEST
  | EDueDiligenceProcessSubSectionKey.DRAWING
  | EDueDiligenceProcessSubSectionKey.VERIFICATION
  | EDueDiligenceProcessSubSectionKey.CONFIRMATION;

export type TRiskAnalysisSubSectionKey =
  | EDueDiligenceProcessSubSectionKey.BATCHES
  | EDueDiligenceProcessSubSectionKey.RESULTS;

export type TRiskMitigationSubSectionKey = EDueDiligenceProcessSubSectionKey.POLYGONS;

export type TSummarySubSectionKey = EDueDiligenceProcessSubSectionKey.OVERVIEW;

export type TDueDiligenceProcessSubSectionKey =
  | TGeoDataCollectionSubSectionKey
  | TRiskAnalysisSubSectionKey
  | TRiskMitigationSubSectionKey
  | TSummarySubSectionKey;

export interface IDueDiligenceProcessSubSection<
  SubSectionKey extends TDueDiligenceProcessSubSectionKey
> {
  key: SubSectionKey;
  title: string;
  itemTitle?: string;
  description?: string;
  content: FC;
}

export type TDueDiligenceProcessSubSection =
  IDueDiligenceProcessSubSection<TDueDiligenceProcessSubSectionKey>;

export type TDueDiligenceProcessSection = {
  title: string;
  contextProvider: FC<IDueDiligenceProcessContextValues>;
  disabled: boolean;
} & (
  | {
      key: EDueDiligenceProcessSectionKey.GEO_DATA_COLLECTION;
      subSections: IDueDiligenceProcessSubSection<TGeoDataCollectionSubSectionKey>[];
    }
  | {
      key: EDueDiligenceProcessSectionKey.RISK_ANALYSIS;
      subSections: IDueDiligenceProcessSubSection<TRiskAnalysisSubSectionKey>[];
    }
  | {
      key: EDueDiligenceProcessSectionKey.RISK_MITIGATION;
      subSections: IDueDiligenceProcessSubSection<TRiskMitigationSubSectionKey>[];
    }
  | {
      key: EDueDiligenceProcessSectionKey.SUMMARY;
      subSections: IDueDiligenceProcessSubSection<TSummarySubSectionKey>[];
    }
);
export interface IDueDiligenceProcessDetails {
  key: TComplianceType;
  image: string;
  title: string;
}

export type IDueDiligenceProcess = IDueDiligenceProcessDetails & {
  sections: TDueDiligenceProcessSection[];
};
