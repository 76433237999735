import { useQuery } from '@apollo/client';
import { GET_DATASET_SITES } from 'graphql/queries/dataset.queries';
import React, { FC, useMemo } from 'react';
import { IDataset } from 'types/dataset.types';
import { DialogDefault } from 'designSystem';
import { IDefaultDialogProps } from 'types/dialog.types';
import DatasetItem from '../DatasetItem';
import { Box, DialogContent, styled } from '@mui/material';
import { Loader } from 'components/Forms';
import { ErrorState } from 'components/Structure';
import { InlineHelperText } from 'components/Product/InlineHelperText/InlineHelperText';
import { GraphQlConnection } from 'types/types';
import { IFarmSite, ISite, SiteType } from 'types/site.types';
import { removeGraphConnections } from 'utils/graphql.utils';
import PaginatedMap from 'components/CultivatedFarmArea/PaginatedMap';
import { CULTIVATION_AREAS_PER_PAGE } from 'constants/pagination';

interface IDatasetPlotOverviewDialogProps extends IDefaultDialogProps {
  dataset: IDataset;
}

const StyledDialog = styled(DialogDefault)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    paddingBottom: '0 !important',
  },
}));

const DatasetPlotOverviewDialog: FC<IDatasetPlotOverviewDialogProps> = ({
  dataset,
  open,
  onClose,
}) => {
  const { data, loading, error, fetchMore } = useQuery<{
    dataset: { sites: GraphQlConnection<ISite> };
  }>(GET_DATASET_SITES, {
    variables: {
      datasetId: dataset.id,
      first: CULTIVATION_AREAS_PER_PAGE,
    },
    notifyOnNetworkStatusChange: true,
  });

  const farms: IFarmSite[] = useMemo(
    () =>
      data?.dataset.sites
        ? (removeGraphConnections(data.dataset.sites).filter(
            site => site.siteType === SiteType.FARM
          ) as IFarmSite[])
        : [],
    [data]
  );

  const hasNextPage: boolean = !!data?.dataset.sites?.pageInfo?.hasNextPage;
  const endCursor: string | undefined = data?.dataset.sites?.pageInfo?.endCursor;
  const totalSites = data?.dataset.sites?.count || 0;

  const handlePageEndReached = async () => {
    if (endCursor) {
      await fetchMore({
        variables: {
          after: endCursor,
        },
      });
    }
  };

  return (
    <StyledDialog open={!!open} title="Plot overview" maxWidth="md" fullWidth onClose={onClose}>
      <DialogContent>
        <Box mt={2.5}>
          <DatasetItem dataset={dataset} />
          {error ? (
            <Box p={10}>
              <ErrorState center={false} />
            </Box>
          ) : (loading && !farms.length) || !data?.dataset ? (
            <Box position="relative" py={15}>
              <Loader />
            </Box>
          ) : (
            <>
              <Box mt={3} />
              <PaginatedMap
                farms={farms}
                hasNextPage={hasNextPage}
                loading={loading}
                onFetchMore={handlePageEndReached}
                warnings={dataset.issues}
                hideTitle
                itemCount={totalSites}
                warningSeverity="error"
                tooltipText={`Due to browser capacity, maximum ${CULTIVATION_AREAS_PER_PAGE} farm plots can be shown at the same time. Farm plots with warnings are prioritised first and shown on the first page. Click to load the next page of farms.`}
              />
              <Box mt={2} mb={1}>
                <InlineHelperText
                  variant="INFO"
                  helperText="To edit geo-data, go back to the geo-data collection step or manage your geo-data per partner in the platform."
                />
              </Box>
            </>
          )}
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};

export default DatasetPlotOverviewDialog;
