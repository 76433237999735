import { Box, FormControlLabel, Radio, styled } from '@mui/material';
import { ThemeTypography } from 'designSystem';
import React, { ReactNode, SyntheticEvent } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';

type IQnAGuideTemplateProps<TId extends string = string> = {
  question: string;
  answers: { id: TId; label: string | ReactNode; disabled?: boolean }[];
} & (
  | {
      selectedAnswer?: TId;
      multipleAnswers?: false;
      onAnswerChange: (id: TId) => void;
    }
  | {
      selectedAnswers?: TId[];
      multipleAnswers: true;
      onAnswerChange: (ids: TId[]) => void;
    }
);

const Answer = styled('div')<{ selected: booleanish; disabled: booleanish }>(
  ({ theme, selected, disabled }) => ({
    userSelect: 'none',
    cursor: 'pointer',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    border: `1px solid ${
      selected === 'true' && disabled !== 'true'
        ? theme.custom.themeColors.accent[60]
        : theme.custom.colors.lightBorderColor
    }`,
    marginBottom: theme.spacing(1),
    backgroundColor:
      selected === 'true' && disabled !== 'true' ? theme.custom.themeColors.accent[20] : 'white',
    pointerEvents: disabled === 'true' ? 'none' : 'auto',
  })
);

const QnAGuideTemplate = <TId extends string = string>({
  question,
  answers,
  multipleAnswers,
  onAnswerChange,
  ...props
}: IQnAGuideTemplateProps<TId>) => {
  const { selectedAnswer, selectedAnswers = [] } = {
    selectedAnswer: undefined,
    selectedAnswers: undefined,
    ...props,
  };

  return (
    <Box>
      <ThemeTypography variant="BODY_LARGE_BOLD">{question}</ThemeTypography>
      <Box mt={2}>
        {answers.map(({ id, label, disabled }) => {
          const isSelected = multipleAnswers ? selectedAnswers.includes(id) : id === selectedAnswer;

          const handleChange = (event: SyntheticEvent) => {
            event.preventDefault();
            onAnswerChange(
              (multipleAnswers
                ? isSelected
                  ? selectedAnswers.filter(existingId => existingId !== id)
                  : [...selectedAnswers, id]
                : // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  id) as any
            );
          };

          return (
            <Answer
              key={id}
              disabled={Booleanish(disabled)}
              selected={Booleanish(isSelected)}
              onClick={handleChange}
            >
              <FormControlLabel
                checked={isSelected}
                label={label}
                disabled={disabled}
                control={<Radio />}
                onChange={handleChange}
              />
            </Answer>
          );
        })}
      </Box>
    </Box>
  );
};

export default QnAGuideTemplate;
