import { Loader } from 'components/Forms';
import { appQueryParams } from 'constants/appQueryParams';
import { useBlockNavigation } from 'contexts/BlockNavigationContext';
import React, {
  Dispatch,
  FC,
  createContext,
  createElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import { EDatasetStatus, IProcessDatasetsPayload } from 'types/dataset.types';
import {
  EDueDiligenceProcessSectionKey,
  EDueDiligenceProcessSubSectionKey,
  IDueDiligenceProcessSubSectionContextDefaultValues,
  TDueDiligenceProcessSubSection,
} from 'types/dueDilligenceProcess.types';
import { createEnumParam, useQueryParam } from 'use-query-params';
import { IDueDiligenceProcessContextValues } from '../DueDiligenceProcessContext';
import { IDueDiligenceProcess } from 'types/compliance.types';

export type TGeoDataCollectionMethod =
  | EDueDiligenceProcessSubSectionKey.UPLOAD
  | EDueDiligenceProcessSubSectionKey.SELECTION
  | EDueDiligenceProcessSubSectionKey.REQUEST
  | EDueDiligenceProcessSubSectionKey.DRAWING;

interface IGeoDataCollectionContextValues
  extends IDueDiligenceProcessSubSectionContextDefaultValues {
  dueDiligenceProcess?: IDueDiligenceProcess;
  geoDataCollectionMethods: TGeoDataCollectionMethod[];
  selectedDatasetIds: string[];
  processedDatasets?: IProcessDatasetsPayload;
  setProcessedDatasets: Dispatch<React.SetStateAction<IProcessDatasetsPayload | undefined>>;
  setGeoDataCollectionMethods: (subSections: TGeoDataCollectionMethod[]) => void;
  setSelectedDatasetIds: (ids: string[]) => void;
  onNextClick: (newProcessId?: string) => void;
}

const GeoDataCollectionContext = createContext<IGeoDataCollectionContextValues>({
  dueDiligenceProcess: undefined,
  geoDataCollectionMethods: [],
  selectedDatasetIds: [],
  selectedSubSectionIndex: 0,
  processedDatasets: undefined,
  selectedSubSection: {} as TDueDiligenceProcessSubSection,
  setProcessedDatasets: () => undefined,
  setSelectedDatasetIds: () => undefined,
  setGeoDataCollectionMethods: () => undefined,
  onNextClick: () => undefined,
  onPreviousClick: () => undefined,
});

const GeoDataCollectionContextProvider: FC<IDueDiligenceProcessContextValues> = ({
  dueDiligenceProcess,
  selectedSection,
  selectedSubSection,
  selectedSubSectionIndex,
  updateSectionCompletion,
  setSelectedSubSection,
  navigateToNextSection,
}) => {
  const [subSectionParam, setSubSectionParam] = useQueryParam<
    EDueDiligenceProcessSubSectionKey | undefined | null
  >(appQueryParams.step, createEnumParam(Object.values(EDueDiligenceProcessSubSectionKey)));
  const { block, unblock } = useBlockNavigation();

  const [geoDataCollectionMethods, setGeoDataCollectionMethods] = useState<
    TGeoDataCollectionMethod[]
  >([]);
  const [selectedDatasetIds, setSelectedDatasetIds] = useState<string[]>([]);

  /**
   * This state saves the datasets that are currently being uploaded. It also stores mappings if from the previous sections
   * This state is only returned by the backend from the processing so we need to store it across the upload sections
   * TODO: Move this to the backend and return it always if the dataset is in state MAPPING_FAILED
   */
  const [processedDatasets, setProcessedDatasets] = useState<IProcessDatasetsPayload>();

  /**
   * Initially if a due diligence process is already in progress, we need to pre select the already included datasets and already add the collection method SELECT
   */
  useEffect(() => {
    if (dueDiligenceProcess) {
      setSelectedDatasetIds(dueDiligenceProcess.datasets.map(({ id }) => id));
      setGeoDataCollectionMethods([EDueDiligenceProcessSubSectionKey.SELECTION]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Set initial selected sub section based on the due diligence process
   */
  useEffect(() => {
    if (dueDiligenceProcess?.datasets.length) {
      if (dueDiligenceProcess.datasets.every(({ status }) => status === EDatasetStatus.COMPLETED)) {
        setSelectedSubSection(EDueDiligenceProcessSubSectionKey.CONFIRMATION);
      } else {
        setSelectedSubSection(EDueDiligenceProcessSubSectionKey.VERIFICATION);
      }
    } else {
      setSelectedSubSection(EDueDiligenceProcessSubSectionKey.METHODS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !dueDiligenceProcess ||
      (selectedSubSection?.key !== EDueDiligenceProcessSubSectionKey.VERIFICATION &&
        selectedSubSection?.key !== EDueDiligenceProcessSubSectionKey.CONFIRMATION)
    ) {
      block();
    } else {
      unblock();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubSection]);

  /**
   * Workaround to set the selected sub section based on the query param and not skip the validation step if no error is present
   */
  useEffect(() => {
    if (subSectionParam) {
      setSelectedSubSection(subSectionParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subSectionParam]);

  if (!selectedSubSection || selectedSubSectionIndex === undefined) {
    return <Loader />;
  }

  const handleClickNext = (newProcessId?: string) => {
    switch (selectedSubSection.key) {
      case EDueDiligenceProcessSubSectionKey.METHODS:
        if (geoDataCollectionMethods.includes(EDueDiligenceProcessSubSectionKey.UPLOAD)) {
          setProcessedDatasets(undefined); // Reset the processed datasets
          setSelectedSubSection(EDueDiligenceProcessSubSectionKey.UPLOAD);
        } else if (geoDataCollectionMethods.includes(EDueDiligenceProcessSubSectionKey.SELECTION)) {
          setSelectedSubSection(EDueDiligenceProcessSubSectionKey.SELECTION);
        } else {
          setSelectedSubSection(EDueDiligenceProcessSubSectionKey.METHODS);
        }
        break;
      case EDueDiligenceProcessSubSectionKey.UPLOAD:
        setSelectedSubSection(EDueDiligenceProcessSubSectionKey.UPLOAD_MAPPING);
        break;
      case EDueDiligenceProcessSubSectionKey.UPLOAD_MAPPING:
        setSelectedSubSection(EDueDiligenceProcessSubSectionKey.UPLOAD_VALIDATION);
        break;
      case EDueDiligenceProcessSubSectionKey.UPLOAD_VALIDATION:
        if (geoDataCollectionMethods.includes(EDueDiligenceProcessSubSectionKey.SELECTION)) {
          if (newProcessId) {
            unblock(
              `/compliance/eudr/${newProcessId}/guide?${appQueryParams.step}=${EDueDiligenceProcessSubSectionKey.SELECTION}`
            );
          } else {
            setSelectedSubSection(EDueDiligenceProcessSubSectionKey.SELECTION);
          }
        } else {
          if (newProcessId) {
            unblock(
              `/compliance/eudr/${newProcessId}/guide?${appQueryParams.step}=${EDueDiligenceProcessSubSectionKey.VERIFICATION}`
            );
          } else {
            setSelectedSubSection(EDueDiligenceProcessSubSectionKey.VERIFICATION);
          }
        }
        break;
      case EDueDiligenceProcessSubSectionKey.SELECTION:
        if (newProcessId) {
          unblock(
            `/compliance/eudr/${newProcessId}/guide?${appQueryParams.step}=${EDueDiligenceProcessSubSectionKey.VERIFICATION}`
          );
        } else {
          setSelectedSubSection(EDueDiligenceProcessSubSectionKey.VERIFICATION);
        }
        setSubSectionParam(undefined);
        break;
      case EDueDiligenceProcessSubSectionKey.VERIFICATION:
        setSubSectionParam(undefined);
        setSelectedSubSection(EDueDiligenceProcessSubSectionKey.CONFIRMATION);
        break;
      case EDueDiligenceProcessSubSectionKey.CONFIRMATION:
        if (selectedSection?.completion !== 100) {
          updateSectionCompletion(EDueDiligenceProcessSectionKey.GEO_DATA_COLLECTION, 100);
        }
        navigateToNextSection();
        break;
      default:
        console.error('Unknown sub section key', selectedSubSection.key);
        break;
    }
  };

  const handleClickPrevious = () => {
    switch (selectedSubSection.key) {
      case EDueDiligenceProcessSubSectionKey.METHODS:
        // Do nothing
        break;
      case EDueDiligenceProcessSubSectionKey.UPLOAD:
        setSelectedSubSection(EDueDiligenceProcessSubSectionKey.METHODS);
        break;
      case EDueDiligenceProcessSubSectionKey.UPLOAD_MAPPING:
        setSelectedSubSection(EDueDiligenceProcessSubSectionKey.UPLOAD);
        break;
      case EDueDiligenceProcessSubSectionKey.UPLOAD_VALIDATION:
        // TODO: Currently we can't go back from validation to mapping because the mapping columns can not be queried on the dataset
        // setSelectedSubSection(EDueDiligenceProcessSubSectionKey.UPLOAD_MAPPING);
        setSelectedSubSection(EDueDiligenceProcessSubSectionKey.UPLOAD);
        break;
      case EDueDiligenceProcessSubSectionKey.SELECTION:
        if (geoDataCollectionMethods.includes(EDueDiligenceProcessSubSectionKey.UPLOAD)) {
          setProcessedDatasets(undefined); // Reset the processed datasets
          setSelectedSubSection(EDueDiligenceProcessSubSectionKey.UPLOAD);
        } else {
          setSelectedSubSection(EDueDiligenceProcessSubSectionKey.METHODS);
        }
        setSubSectionParam(undefined);
        break;
      case EDueDiligenceProcessSubSectionKey.VERIFICATION:
        if (geoDataCollectionMethods.includes(EDueDiligenceProcessSubSectionKey.SELECTION)) {
          setSelectedSubSection(EDueDiligenceProcessSubSectionKey.SELECTION);
        } else if (geoDataCollectionMethods.includes(EDueDiligenceProcessSubSectionKey.UPLOAD)) {
          setSelectedSubSection(EDueDiligenceProcessSubSectionKey.UPLOAD);
        } else {
          setSelectedSubSection(EDueDiligenceProcessSubSectionKey.METHODS);
        }
        break;
      case EDueDiligenceProcessSubSectionKey.CONFIRMATION:
        setSelectedSubSection(EDueDiligenceProcessSubSectionKey.VERIFICATION);
        break;
      default:
        console.error('Unknown sub section key', selectedSubSection.key);
        break;
    }
  };

  const state = {
    dueDiligenceProcess,
    selectedSubSectionIndex,
    selectedSubSection,
    geoDataCollectionMethods,
    selectedDatasetIds,
    processedDatasets,
    setGeoDataCollectionMethods,
    setSelectedDatasetIds,
    setProcessedDatasets,
    onNextClick: handleClickNext,
    onPreviousClick: handleClickPrevious,
  };

  return (
    <GeoDataCollectionContext.Provider value={state}>
      {createElement(selectedSubSection.content)}
    </GeoDataCollectionContext.Provider>
  );
};

const useGeoDataCollection = () => useContext(GeoDataCollectionContext);

export { GeoDataCollectionContextProvider, useGeoDataCollection };

export default GeoDataCollectionContext;
