import { gql } from '@apollo/client';
import { GEOC_PARCEL } from 'graphql/fragments/dueDiligence.fragments';
import { GEO_DATA_PROCESS_FRAGMENT } from 'graphql/fragments/dueDiligence.fragments';

export const GET_LATEST_GEO_DATA_PROCESS = gql`
  query GetLatestGeoDataProcess {
    latestGeoDataProcess {
      ...dueDiligenceProcessValues
    }
  }
  ${GEO_DATA_PROCESS_FRAGMENT}
`;

export const GET_GEO_DATA_PROCESS = gql`
  query GetGeoDataProcess($id: UUID!) {
    geoDataProcess(id: $id) {
      ...dueDiligenceProcessValues
    }
  }
  ${GEO_DATA_PROCESS_FRAGMENT}
`;

export const GET_GEOC_PARCELS = gql`
  query GetParcels($filters: GeocParcelsFilters) {
    parcels(filters: $filters) {
      edges {
        node {
          ...geocParcelValues
        }
      }
      highRisks
      mediumRisks
      lowRisks
      count
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${GEOC_PARCEL}
`;

export const GET_GEO_DATA_PROCESSES = gql`
  query GetGeoDataProcesses(
    $first: Int
    $after: String
    $filters: GeoDataProcessesFilters
    $orderBy: GeoDataProcessesOrderBy
  ) {
    geoDataProcesses(first: $first, after: $after, filters: $filters, orderBy: $orderBy) {
      edges {
        node {
          ...dueDiligenceProcessValues
        }
      }
      count
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${GEO_DATA_PROCESS_FRAGMENT}
`;
