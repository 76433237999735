import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
import FlexBox from './FlexBox';
import { ThemeTypography } from 'designSystem';

const StyledSubTitle = styled('p')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  zIndex: 1,
  fontWeight: 300,
  fontSize: '16px',
  padding: '3px 0',
  textTransform: 'uppercase',
  letterSpacing: '0.1em',
  margin: 0,
  fontFamily: theme.custom.fontFamilyBase,
}));

interface Props {
  title: string;
  visibleItems?: number;
  totalItems?: number;
  children?: ReactNode;
  spaceBetween?: boolean;
}

const PageSubTitle: React.FC<Props & BoxProps> = ({
  title,
  children,
  visibleItems,
  totalItems,
  spaceBetween = true,
  ...rest
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={spaceBetween ? 'space-between' : ''}
      {...rest}
    >
      <FlexBox gap={1}>
        <StyledSubTitle>{title}</StyledSubTitle>
        {visibleItems && totalItems && totalItems > visibleItems ? (
          <ThemeTypography variant="BODY_MEDIUM" color="GRAY_100">
            (1-{visibleItems} of {totalItems})
          </ThemeTypography>
        ) : undefined}
      </FlexBox>

      {children}
    </Box>
  );
};

export default PageSubTitle;
