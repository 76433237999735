import { gql } from '@apollo/client';
import { COMPONENT_FRAGMENT, DOCUMENT_FRAGMENT, IMPACT_CLAIM_FRAGMENT } from 'graphql/fragments';
import { ACTIVITY_FRAGMENT } from 'graphql/fragments/activity.fragments';
import { COMPANY_LOG_FRAGMENT } from 'graphql/fragments/company.fragments';
import {
  BASE_PARTNER_COMPANY_FRAGMENT,
  BASE_PARTNER_REQUEST_FRAGMENT,
  PARTNER_COMPANY_SUMMARY_FRAGMENT,
  PARTNER_REQUEST_FRAGMENT,
} from 'graphql/fragments/partner.fragments';
import {
  BASE_FARM_SITE_FRAGMENT,
  COLLECTION_SITE_FRAGMENT,
  DISTRIBUTION_SITE_FRAGMENT,
  FACTORY_SITE_FRAGMENT,
  FOREST_SITE_FRAGMENT,
  GENERIC_SITE_FRAGMENT,
  GROCERY_STORE_SITE_FRAGMENT,
  HANDLING_SITE_FRAGMENT,
  MANUFACTURING_SITE_FRAGMENT,
  MINE_SITE_FRAGMENT,
  ORIGIN_SITE_FRAGMENT,
  PLANT_SITE_FRAGMENT,
  PROCESSING_SITE_FRAGMENT,
  SITE_CLUSTER_FRAGMENT,
  WAREHOUSE_SITE_FRAGMENT,
} from 'graphql/fragments/site.fragments';
import { CULTIVATED_AREAS_FRAGMENT } from 'graphql/fragments/cultivatedArea.fragment';
import { IDocument } from 'types/document.types';
import { PartnerRequest } from 'types/partner.types';
import { GraphQlConnection } from 'types/types';

export const GET_ALL_PARTNER_COMPANIES = gql`
  query GetPartnerCompanies(
    $first: Int
    $after: String
    $before: String
    $filters: PartnersFilters
    $orderBy: PartnersOrderBy
  ) {
    partners(first: $first, after: $after, before: $before, filters: $filters, orderBy: $orderBy) {
      edges {
        node {
          ...partnerCompanySummaryValues
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${PARTNER_COMPANY_SUMMARY_FRAGMENT}
`;

export const GET_BASE_PARTNER_COMPANY = gql`
  query GetPartnerCompany($id: UUID!) {
    partner(id: $id) {
      ...basePartnerCompanyValues
    }
  }
  ${BASE_PARTNER_COMPANY_FRAGMENT}
`;

/**
 * @Do not use this query directly, use GET_BASE_PARTNER_COMPANY instead. This is a uge query that is used only in the overview query
 */
export const GET_PARTNER_WITH_COMPANY_IMPACT_CLAIMS = gql`
  query GetPartnerCompany($id: UUID!) {
    partner(id: $id) {
      ...basePartnerCompanyValues
      impactClaims {
        edges {
          node {
            ...impactClaimValues
          }
        }
      }
    }
  }
  ${BASE_PARTNER_COMPANY_FRAGMENT}
  ${IMPACT_CLAIM_FRAGMENT}
`;

export const GET_PARTNER_OVERVIEW = gql`
  query GetPartnerOverview($id: UUID!, $numberOfItems: Int = 3) {
    partner(id: $id) {
      ...basePartnerCompanyValues
      activities(first: $numberOfItems) {
        count
        edges {
          node {
            ...activityValues
          }
        }
      }
      sites(first: $numberOfItems) {
        count
        edges {
          node {
            ... on GenericSite {
              ...genericSiteValues
            }
            ... on Farm {
              ...baseFarmSiteValues
            }
            ... on Mine {
              ...mineValues
            }
            ... on Forest {
              ...forestValues
            }
            ... on CollectionSite {
              ...collectionSiteValues
            }
            ... on OriginSite {
              ...originSiteValues
            }
            ... on Plant {
              ...plantValues
            }
            ... on ProcessingSite {
              ...processingSiteValues
            }
            ... on Factory {
              ...factoryValues
            }
            ... on ManufacturingSite {
              ...manufacturingSiteValues
            }
            ... on Warehouse {
              ...warehouseValues
            }
            ... on HandlingSite {
              ...handlingSiteValues
            }
            ... on GroceryStore {
              ...groceryStoreValues
            }
            ... on DistributionSite {
              ...distributionSiteValues
            }
            ... on SiteCluster {
              ...siteClusterValues
            }
            __typename
          }
        }
      }
      impactClaims(first: $numberOfItems) {
        count
        edges {
          node {
            ...impactClaimValues
          }
        }
      }

      components(first: $numberOfItems) {
        count
        edges {
          node {
            ...componentValues
          }
        }
      }
      sharedDocuments(first: $numberOfItems) {
        count
        edges {
          node {
            ...documentsValues
          }
        }
      }
      requests(first: $numberOfItems) {
        count
        edges {
          node {
            ...partnerRequestValues
          }
        }
      }
      cultivatedAreas {
        count
      }
      logs {
        ...companyLogValues
      }
      latestLog {
        ...companyLogValues
      }
    }
  }
  ${BASE_PARTNER_COMPANY_FRAGMENT}
  ${IMPACT_CLAIM_FRAGMENT}
  ${COMPANY_LOG_FRAGMENT}
  ${ACTIVITY_FRAGMENT}
  ${GENERIC_SITE_FRAGMENT}
  ${BASE_FARM_SITE_FRAGMENT}
  ${MINE_SITE_FRAGMENT}
  ${FOREST_SITE_FRAGMENT}
  ${COLLECTION_SITE_FRAGMENT}
  ${ORIGIN_SITE_FRAGMENT}
  ${PLANT_SITE_FRAGMENT}
  ${PROCESSING_SITE_FRAGMENT}
  ${FACTORY_SITE_FRAGMENT}
  ${MANUFACTURING_SITE_FRAGMENT}
  ${WAREHOUSE_SITE_FRAGMENT}
  ${HANDLING_SITE_FRAGMENT}
  ${GROCERY_STORE_SITE_FRAGMENT}
  ${DISTRIBUTION_SITE_FRAGMENT}
  ${SITE_CLUSTER_FRAGMENT}
  ${PARTNER_REQUEST_FRAGMENT}
  ${DOCUMENT_FRAGMENT}
  ${COMPONENT_FRAGMENT}
  ${CULTIVATED_AREAS_FRAGMENT}
`;

export interface GetPartnerUserHomeDataValues {
  documents: GraphQlConnection<IDocument>;
  partnerRequests: GraphQlConnection<PartnerRequest>;
}

export const GET_PARTNER_USER_HOME_DATA = gql`
  query GetPartnerUserHomeData($first: Int!, $after: String, $filters: PartnerRequestsFilters) {
    documents(first: $first, after: $after) {
      edges {
        node {
          ...documentsValues
        }
      }
    }
    partnerRequests(filters: $filters) {
      edges {
        node {
          ...partnerRequestValues
        }
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
  ${PARTNER_REQUEST_FRAGMENT}
`;

export interface GetPartnerRequestData {
  partnerRequest: PartnerRequest;
}

export const GET_BASE_PARTNER_REQUEST = gql`
  query GetPartnerRequest($id: UUID!) {
    partnerRequest(id: $id) {
      ...basePartnerRequestValues
    }
  }
  ${BASE_PARTNER_REQUEST_FRAGMENT}
`;

export const GET_PARTNER_REQUEST = gql`
  query GetPartnerRequest($id: UUID!) {
    partnerRequest(id: $id) {
      ...partnerRequestValues
    }
  }
  ${PARTNER_REQUEST_FRAGMENT}
`;

export const GET_ALL_PARTNER_REQUESTS = gql`
  query GetPartnerRequest($filters: PartnerRequestsFilters) {
    partnerRequests(filters: $filters) {
      count
      edges {
        node {
          ...partnerRequestValues
        }
      }
    }
  }
  ${PARTNER_REQUEST_FRAGMENT}
`;
