import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Loader } from 'components/Forms';
import { useQuery, useMutation } from '@apollo/client';
import { GET_COMPANY_DETAILS } from 'graphql/queries';
import { ErrorState } from 'components/Structure';
import { EDIT_COMPANY } from 'graphql/mutations';
import { useMessages } from 'components/hooks';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import CompanyFormCombined from 'components/Settings/CompanyFormCombined';

const StyledActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2, 0, 0),
}));

const CompanyDataDialog = ({ open, onClose, onSubmit }) => {
  let { data: { company } = {}, error, loading } = useQuery(GET_COMPANY_DETAILS);

  const { setSuccessMessage, setErrorMessage } = useMessages();
  const [editCompany] = useMutation(EDIT_COMPANY, {
    onCompleted: () => {
      setSuccessMessage('Success! Company details were updated.');
      onSubmit();
    },
    onError: () => {
      setErrorMessage('Something went wrong updating your companies details.');
    },
  });

  const handleSubmit = input => {
    return editCompany({
      variables: {
        input,
      },
    });
  };

  if (error) {
    return <ErrorState error="Something went wrong fetching your company data." />;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      data-cy="dialog-update-company-details"
    >
      {loading && <Loader overlay />}
      <DialogTitle id="form-dialog-title">Update company data</DialogTitle>
      <DialogContent>
        <CompanyFormCombined onSubmit={handleSubmit} data={company}>
          {({ isSubmitting }) => (
            <StyledActions>
              <ThemeButton color="WHITE" size="large" onClick={onClose}>
                Cancel
              </ThemeButton>
              <ThemeButton
                loading={isSubmitting}
                type="submit"
                color="YELLOW"
                size="large"
                autoFocus
                data-cy="submit-company-form"
              >
                Submit
              </ThemeButton>
            </StyledActions>
          )}
        </CompanyFormCombined>
      </DialogContent>
    </Dialog>
  );
};

CompanyDataDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CompanyDataDialog;
