import React, { FC, useMemo } from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import RiskPieLabel from './RiskPieLabel';
import { Box } from '@mui/material';

const Container = styled(Box)(({ theme }) => ({
  width: 'fit-content',
  '& .MuiPieArc-root': {
    strokeWidth: 0,
  },
}));

interface IRiskPieProps {
  inProgress: number;
  lowRisk: number;
  mediumRisk: number;
  highRisk: number;
  height?: number;
}

const RiskPie: FC<IRiskPieProps> = ({
  inProgress,
  lowRisk,
  mediumRisk,
  highRisk,
  height = 230,
}) => {
  const { custom } = useTheme();
  const data = [
    { label: 'Low Risk', value: lowRisk, color: custom.themeColors.success[100] },
    { label: 'In Progress', value: inProgress, color: custom.themeColors.primary[40] },
    { label: 'Medium Risk', value: mediumRisk, color: custom.themeColors.accent[100] },
    { label: 'High Risk', value: highRisk, color: custom.themeColors.error[80] },
  ];

  const percentageLowRisk = useMemo(
    () => ((lowRisk / (lowRisk + inProgress + mediumRisk + highRisk)) * 100).toFixed(0),
    [lowRisk, inProgress, mediumRisk, highRisk]
  );

  return (
    <Container>
      <PieChart
        series={[
          {
            data,
            innerRadius: height / 3.5,
          },
        ]}
        margin={{ right: 0, left: 0, top: 0, bottom: 0 }}
        width={height}
        height={height}
        slotProps={{
          legend: { hidden: true },
        }}
        tooltip={{ trigger: 'none' }}
      >
        <RiskPieLabel label={`${percentageLowRisk}%`} subLabel="Low risk" />
      </PieChart>
    </Container>
  );
};

export default RiskPie;
