import { useQuery } from '@apollo/client';
import { Box, Checkbox, IconButton, InputAdornment, styled } from '@mui/material';
import { Search } from '@styled-icons/bootstrap';
import { Close } from '@styled-icons/ionicons-sharp';
import { useDueDiligenceProcess } from 'components/DueDiligenceProcess/Context/DueDiligenceProcessContext';
import { useGeoDataCollection } from 'components/DueDiligenceProcess/Context/SubSections/GeoDataCollectionContext';
import DueDiligenceProcessSubSectionNavigation from 'components/DueDiligenceProcess/DueDiligenceProcessSubSectionNavigation';
import { Loader } from 'components/Forms';
import { SearchInput } from 'components/Forms/SearchToolbar';
import { useDialog, useMessages } from 'components/hooks';
import { ErrorState, InfiniteScrollWrapper } from 'components/Structure';
import { ThemeTypography } from 'designSystem';
import { GET_DATASETS } from 'graphql/queries/dataset.queries';
import useDueDiligenceProcessMutations from 'hooks/useDueDiligenceProcessMutations';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import React, { FC, useMemo, useState } from 'react';
import { EDatasetStatus, IDataset } from 'types/dataset.types';
import { GraphQlConnection } from 'types/types';
import { removeGraphConnections } from 'utils/graphql.utils';
import DatasetItem from '../DatasetItem';

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: 0,
  margin: 0,
}));

const SelectedCount = styled(Box)(({ theme }) => ({
  background: theme.custom.themeColors.grayScale[20],
  borderRadius: 4,
  padding: '2px 8px',
}));

const HintContainer = styled('div')(({ theme }) => ({
  background: theme.custom.themeColors.primary[5],
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  height: 'fit-content',
}));

const GeoDataCollectionSelection: FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const { createDueDiligenceProcess, updateDueDiligenceProcess } =
    useDueDiligenceProcessMutations();
  const { setErrorMessage } = useMessages();
  const { dueDiligenceProcess } = useDueDiligenceProcess();
  const { selectedDatasetIds, setSelectedDatasetIds, onNextClick, onPreviousClick } =
    useGeoDataCollection();
  const { openDialog } = useDialog();

  const {
    data,
    loading: loadingDatasets,
    error,
    fetchMore,
  } = useQuery<{ datasets: GraphQlConnection<IDataset> }>(GET_DATASETS, {
    variables: {
      filters: {
        searchTerm,
        eudrRelevant: true,
        status: [
          EDatasetStatus.COMPLETED,
          EDatasetStatus.VALIDATED,
          EDatasetStatus.VERIFICATION_FAILED,
        ],
      },
      first: 15,
    },
  });

  const hasNextPage: boolean = !!data?.datasets?.pageInfo?.hasNextPage;
  const endCursor: string | undefined = data?.datasets?.pageInfo?.endCursor;

  const datasets = useMemo(
    () => (data?.datasets ? removeGraphConnections(data?.datasets) : []),
    [data]
  );

  const handlePageEndReached = () => {
    if (endCursor) {
      fetchMore({
        variables: {
          after: endCursor,
        },
      });
    }
  };

  const handleSelect = (id: string) => {
    const isSelected = selectedDatasetIds.includes(id);
    if (!isSelected) {
      setSelectedDatasetIds([...selectedDatasetIds, id]);
    } else {
      setSelectedDatasetIds(selectedDatasetIds.filter(datasetId => datasetId !== id));
    }
  };

  const handleToggleAll = () => {
    if (selectedDatasetIds.length === datasets.length) {
      setSelectedDatasetIds([]);
    } else {
      setSelectedDatasetIds(datasets.map(({ id }) => id));
    }
  };

  const handleNextStepClick = async () => {
    try {
      setLoading(true);

      // Create new due diligence process if it does not exist
      if (!dueDiligenceProcess) {
        const createdDueDiligenceProcess = await createDueDiligenceProcess({
          complianceId: '00000000-0000-0000-0000-000000000000',
          datasetIds: selectedDatasetIds,
        });
        const dueDiligenceProcessId =
          createdDueDiligenceProcess.data?.createGeoDataProcess.geoDataProcess.id;
        if (dueDiligenceProcessId) {
          onNextClick(dueDiligenceProcessId);
        } else {
          throw new Error('Failed to create due diligence process');
        }
        // Update existing due diligence process with selected datasets if they changed
      } else if (
        !isEqual(
          sortBy(dueDiligenceProcess.datasets.map(({ id }) => id)),
          sortBy(selectedDatasetIds)
        )
      ) {
        await updateDueDiligenceProcess(dueDiligenceProcess.id, {
          datasetIds: selectedDatasetIds,
        });
        onNextClick();
        // Do nothing just continue to the next step
      } else {
        onNextClick();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setErrorMessage('Failed to create due diligence process');
    } finally {
      setLoading(false);
    }
  };

  if (error) {
    return <ErrorState />;
  }

  return (
    <DueDiligenceProcessSubSectionNavigation
      allowNextStepNavigation={!!selectedDatasetIds.length}
      nextStepLoading={loading}
      onPreviousStepClick={onPreviousClick}
      onNextStepClick={handleNextStepClick}
    >
      <HintContainer>
        <ThemeTypography variant="BODY_MEDIUM" color="BLUE_80">
          Only datasets that have EUDR relevant raw materials are shown here. Those are materials
          for the following commodities: Cattle, cocoa, coffee, oil palm, rubber, soya and wood.
        </ThemeTypography>
      </HintContainer>

      <Box pt={2}>
        <Box display="flex" alignItems="center" gap={1}>
          <Checkbox
            checked={selectedDatasetIds.length === data?.datasets.edges.length}
            onClick={handleToggleAll}
          />
          <SearchInput
            setDebouncedState={setSearchTerm}
            className="search-input"
            data-cy="dataset-search-input"
            delay={500}
            placeholder="Search"
            initialValue={searchTerm}
            inputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {searchTerm === '' ? (
                    <Search size={12} />
                  ) : (
                    <CloseButton onClick={() => setSearchTerm('')}>
                      <Close size={16} />
                    </CloseButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          {selectedDatasetIds.length > 0 && (
            <SelectedCount>
              <ThemeTypography variant="LABEL_INPUT">
                {selectedDatasetIds.length} selected
              </ThemeTypography>
            </SelectedCount>
          )}
        </Box>
        {loadingDatasets ? (
          <Box position="relative" height="100%" p={5}>
            <Loader />
          </Box>
        ) : (
          <InfiniteScrollWrapper hasMore={hasNextPage} next={handlePageEndReached}>
            <Box display="flex" width="100%" flexDirection="column" gap={1} pt={2}>
              {datasets.map(dataset => {
                const isSelected = selectedDatasetIds.includes(dataset.id);

                return (
                  <Box display="flex" alignItems="center" key={dataset.id}>
                    <Box mr={1}>
                      <Checkbox checked={isSelected} onClick={() => handleSelect(dataset.id)} />
                    </Box>
                    <DatasetItem
                      dataset={dataset}
                      onClick={handleSelect}
                      onClickViewPlots={() =>
                        openDialog({ type: 'DATASET_PLOT_OVERVIEW', props: { dataset: dataset } })
                      }
                    />
                  </Box>
                );
              })}
            </Box>
          </InfiniteScrollWrapper>
        )}
      </Box>
    </DueDiligenceProcessSubSectionNavigation>
  );
};

export default GeoDataCollectionSelection;
