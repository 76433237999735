import React, { FC } from 'react';
import { Booleanish } from 'types/booleanish.types';
import EmptyGridStateIcon from 'assets/img/empty-grid-state.svg';
import { DataItem, DatasetDate, DatasetTitle } from 'components/DataImport/DataImport.styles';
import { FlexBox } from 'components/Structure';
import { IDataset, EDatasetStatus } from 'types/dataset.types';
import Moment from 'react-moment';
import { Box, styled } from '@mui/material';

interface IDatasetOverviewCardProps {
  selected: boolean;
  dataset: IDataset;
  onClick: () => void;
}

const ImageContainer = styled(FlexBox)(() => ({
  background: '#fff',
  borderRadius: 8,
  padding: '16px',
  marginBottom: 8,
}));

const WarningBadge = styled('div')(({ theme }) => ({
  background: theme.custom.themeColors.accent[60],
  padding: '4px 8px',
  borderRadius: 16,
  fontWeight: 700,
  fontSize: '10px',
  whiteSpace: 'nowrap',
}));

const DatasetOverviewCard: FC<IDatasetOverviewCardProps> = ({ selected, dataset, onClick }) => {
  return (
    <DataItem
      selected={Booleanish(selected)}
      hasWarning={Booleanish(dataset.status === EDatasetStatus.VERIFICATION_FAILED)}
      onClick={onClick}
    >
      <ImageContainer justifyContent="center" mb={2}>
        <img width={100} src={EmptyGridStateIcon} alt="empty" />
      </ImageContainer>
      {dataset.title ? (
        <DatasetTitle>{dataset.title}</DatasetTitle>
      ) : (
        <DatasetTitle unnamed>Unnamed</DatasetTitle>
      )}
      <Box display="flex" gap={1} alignItems="center">
        <DatasetDate>
          <Moment format="L">{dataset.createdTimestamp}</Moment>
        </DatasetDate>
        {dataset.status === EDatasetStatus.VERIFICATION_FAILED && (
          <WarningBadge>
            {dataset.issues.length} warning
            {dataset.issues.length && dataset.issues.length > 1 && 's'}
          </WarningBadge>
        )}
      </Box>
    </DataItem>
  );
};

export default DatasetOverviewCard;
