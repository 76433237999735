import { Box, styled } from '@mui/material';
import { ThemeTypography } from 'designSystem';
import RiskPie from 'designSystem/DataDisplay/RiskPie/RiskPie';
import React, { FC, useMemo } from 'react';
import theme from 'styles/theme';
import { ERiskLevel } from 'types/compliance.types';

interface IBatchResultsProps {
  highRisks: number;
  mediumRisks: number;
  lowRisks: number;
}

const RISK_COLOR: Record<ERiskLevel, string> = {
  [ERiskLevel.LOW]: theme.custom.themeColors.success[100],
  [ERiskLevel.MEDIUM]: theme.custom.themeColors.accent[100],
  [ERiskLevel.HIGH]: theme.custom.themeColors.error[80],
};

const Container = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
  background: theme.custom.themeColors.white,
  borderRadius: 6,
}));

const RiskBadge = styled(Box)<{ riskLevel: ERiskLevel }>(({ theme, riskLevel }) => ({
  position: 'relative',
  backgroundColor: theme.custom.themeColors.primary[5],
  borderRadius: 20,
  padding: theme.spacing(0.5, 2, 0.5, 3.5),
  display: 'flex',
  alignItems: 'center',
  '&::before': {
    content: '""',
    display: 'block',
    width: '13px',
    height: '13px',
    borderRadius: '50%',
    backgroundColor: RISK_COLOR[riskLevel],
    position: 'absolute',
    top: '50%',
    left: theme.spacing(2),
    transform: 'translate(-50%, -50%)',
  },
}));

const PlotAmount = styled('span')(({ theme }) => ({
  color: theme.custom.themeColors.grayScale[60],
}));

const BatchResults: FC<IBatchResultsProps> = ({ highRisks, mediumRisks, lowRisks }) => {
  const percentageRisks = useMemo(() => {
    const total = lowRisks + mediumRisks + highRisks;
    return {
      lowRisks: ((lowRisks / total) * 100).toFixed(0),
      mediumRisks: ((mediumRisks / total) * 100).toFixed(0),
      highRisks: ((highRisks / total) * 100).toFixed(0),
    };
  }, [highRisks, mediumRisks, lowRisks]);

  return (
    <Container
      display="flex"
      alignItems="center"
      flexDirection={{ xs: 'column', lg: 'row' }}
      gap={2}
      padding={{ xs: 2, lg: 4, xl: 5 }}
    >
      <RiskPie inProgress={0} lowRisk={lowRisks} mediumRisk={mediumRisks} highRisk={highRisks} />
      <Box display="flex" flexDirection="column" mb={2} gap={1}>
        <RiskBadge riskLevel={ERiskLevel.LOW}>
          <ThemeTypography variant="BODY_SMALLER_BOLD">
            {percentageRisks.lowRisks}% Low risk <PlotAmount>({lowRisks} plots)</PlotAmount>
          </ThemeTypography>
        </RiskBadge>
        <RiskBadge riskLevel={ERiskLevel.MEDIUM}>
          <ThemeTypography variant="BUTTON_SMALL">
            {percentageRisks.mediumRisks}% Medium risk{' '}
            <PlotAmount>({mediumRisks} plots)</PlotAmount>
          </ThemeTypography>
        </RiskBadge>
        <RiskBadge riskLevel={ERiskLevel.HIGH}>
          <ThemeTypography variant="BUTTON_SMALL">
            {percentageRisks.highRisks} High risk <PlotAmount>({highRisks} plots)</PlotAmount>
          </ThemeTypography>
        </RiskBadge>
      </Box>
    </Container>
  );
};

export default BatchResults;
