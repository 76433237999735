import { Box, DialogActions, Grid, styled } from '@mui/material';
import { MediaField } from 'components/Forms';
import TextFieldLimited from 'components/Forms/TextFieldLimited';
import { mergeDefaults } from 'components/Forms/utils';
import componentCreationSchema from 'constants/schemas/components';
import { FieldWrapper, ThemeButton } from 'designSystem';
import { FastField as Field, Form, Formik, FormikProps } from 'formik';
import { TextField } from 'formik-mui';
import isEqual from 'lodash/isEqual';
import React, { FC, RefObject } from 'react';
import { IComponentItem } from 'types/component.types';
import { Cropping } from 'types/media.types';
import PartnerAutocomplete from '../../ChainMapping/Forms/PartnerAutocomplete';
import RawMaterialSelection from './RawMaterialSelection';
import { IBasePartnerCompany } from 'types/partner.types';

const StyledCard = styled(Grid)(({ theme }) => ({
  background: theme.custom.themeColors.white,
  boxShadow: theme.custom.shadows[4],
  borderRadius: 6,
  minHeight: 150,
  width: '100%',
  padding: theme.spacing(2),
}));

const StyledTextFieldLimited = styled(TextFieldLimited)(() => ({
  '&.MuiTextField-root': {
    '& .MuiInputBase-input': {
      fontSize: 12,
      height: 16,
      padding: '10px 12px 8px',
    },
  },
}));

const StyledTextField = styled(TextField)(() => ({
  '&.MuiTextField-root': {
    '& .MuiFormLabel-root': {
      fontSize: 11,
      fontWeight: 300,
      '&[data-shrink="false"]': {
        transform: 'translate(14px, 10px) scale(1)',
      },
    },
    '& .MuiInputBase-input': {
      height: 16,
      padding: '9px 12px',
    },
  },
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(4, 0, 0, 0),
  justifyContent: 'space-between',
}));

export type FormValues = {
  title: string;
  image: {
    imageId: string;
    crop?: Cropping;
  };
  ownedBy?: { id: string };
  rawMaterialIds: string[];
  articleNumber?: string;
};

interface ICreateEditComponentFormProps {
  formRef: RefObject<FormikProps<FormValues>>;
  component?: Omit<IComponentItem, 'inUse' | 'modifiedTimestamp'>;
  /*
   *Pass this if the company select should already be prefilled
   *  Warning: This overrides the partner on the componentItem and disabled the field (so the partner can not be changed)
   */
  company?: IBasePartnerCompany;
  hidePartner?: boolean;
  language?: string;
  onSubmit: (values: FormValues) => void;
  onCancel?: () => void;
}

const CreateEditComponentForm: FC<ICreateEditComponentFormProps> = ({
  formRef,
  component,
  company,
  hidePartner,
  language,
  onSubmit,
  onCancel,
}) => {
  const handleSubmit = (values: FormValues) => {
    onSubmit(values);
  };

  const initialValues = mergeDefaults(componentCreationSchema.default(), {
    title: component?.title,
    image: component?.image,
    ownedBy: company || component?.ownedBy,
    rawMaterialIds: component?.rawMaterials?.map(({ id }) => id),
    articleNumber: component?.articleNumber,
  });

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={componentCreationSchema}
      enableReinitialize
      initialStatus={{
        lang: language,
      }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, isValid, values }) => (
        <Form data-cy="create-product-form">
          <StyledCard container>
            <Grid item xs={3}>
              <FieldWrapper>
                <Field
                  component={MediaField}
                  name="image"
                  data-cy="field-component-image"
                  legacyImage={false}
                  styles={{
                    width: 116,
                    height: 116,
                    maxWidth: '100%',
                  }}
                />
              </FieldWrapper>
            </Grid>
            <Grid container xs={9} alignContent="center">
              <Grid item xs={12}>
                <Box display="flex" mb={2.5}>
                  <FieldWrapper width="calc(100% - 126px)" mr={2}>
                    <Field
                      name="title"
                      fullWidth
                      placeholder="E.g. Coffee beans, Cocoa butter"
                      component={StyledTextFieldLimited}
                      variant="outlined"
                      charLimit={50}
                      label="Title"
                      inputProps={{
                        'data-testid': 'field-component-title',
                      }}
                    />
                  </FieldWrapper>
                  <FieldWrapper width={110}>
                    <Field
                      component={StyledTextField}
                      placeholder="1234567"
                      fullWidth
                      name="articleNumber"
                      variant="outlined"
                      label="Article number"
                      data-cy="article-number-input"
                    />
                  </FieldWrapper>
                </Box>
              </Grid>
              <Grid container spacing={2}>
                {!hidePartner && (
                  <Grid item xs={6}>
                    <PartnerAutocomplete
                      name="ownedBy"
                      disabled={!!company}
                      helperText="Select the actor that produces this component. This could be a partner or your own company. Assigning an invited partner as owner means that they also have access to editing this component."
                    />
                  </Grid>
                )}
                <Grid item xs={hidePartner ? 12 : 6}>
                  <RawMaterialSelection
                    name="rawMaterialIds"
                    size="small"
                    helperText="Select the raw material (Unprocessed origin material) content of this component (E.g. ‘Tomato’ for ‘Dried tomato flakes’). This will help trace and filter your ingredients per raw material."
                  />
                </Grid>
              </Grid>
            </Grid>
          </StyledCard>
          <StyledDialogActions>
            <ThemeButton
              color="BLUE_ICE"
              size="large"
              className="button-wide"
              data-cy="cancel-component-button"
              onClick={onCancel}
            >
              Cancel
            </ThemeButton>
            <ThemeButton
              loading={isSubmitting}
              disabled={!isValid || (!!component && isEqual(initialValues, values))}
              color="YELLOW"
              size="large"
              className="button-wide"
              type="submit"
              data-cy="create-component-button"
            >
              Save
            </ThemeButton>
          </StyledDialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default CreateEditComponentForm;
