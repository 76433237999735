import React, { FC } from 'react';
import { Box, styled, AccordionDetails, AccordionSummary } from '@mui/material';
import { ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { Badge } from 'components/DataImport/DataImport.styles';
import { FlexBox } from 'components/Structure';
import { EDatasetErrorCategory } from 'types/dataset.types';
import { Accordion } from 'designSystem/DataDisplay/Accordion/Accordion';

interface IWarningBannerProps {
  plotWarnings: { errorMessage: string; count: number }[];
  warningCount: number;
  severity: 'warning' | 'error';
  warningCategory?: EDatasetErrorCategory;
}

const Container = styled(FlexBox)<{ severity: 'warning' | 'error' }>(({ theme, severity }) => ({
  gap: theme.spacing(2),
  background:
    severity === 'error' ? theme.custom.themeColors.error[20] : theme.custom.themeColors.accent[20],
  borderRadius: theme.spacing(1),
  height: 'fit-content',
}));

const NumberCyrcle = styled(Box)<{ severity: 'warning' | 'error' }>(({ theme, severity }) => ({
  width: 20,
  height: 20,
  borderRadius: '50%',
  display: 'flex',
  flexShrink: 0,
  alignItems: 'center',
  justifyContent: 'center',
  background:
    severity === 'error' ? theme.custom.themeColors.error[60] : theme.custom.themeColors.accent[60],
}));

const StyledAccordion = styled(Accordion)(({ theme, transparent }) => ({
  '&.MuiAccordion-root': {
    margin: 0,
    border: 'none !important',
  },

  '& .MuiAccordionDetails-root': {
    paddingTop: 0,
    background: 'transparent',
  },
}));

const PlotWarningBanner: FC<IWarningBannerProps> = ({
  plotWarnings,
  warningCount,
  severity,
  warningCategory,
}) => {
  return (
    <Container severity={severity}>
      <StyledAccordion transparent={true}>
        <AccordionSummary>
          <Box display="flex" alignItems="center" gap={1}>
            <Icon
              name="warning"
              color={severity === 'error' ? 'red-80' : 'yellow-80'}
              size="x-large"
            />
            <Badge severity={severity}>
              <ThemeTypography variant="BODY_SMALL_BOLD" color="ALMOST_BLACK">
                {warningCount} plot {severity}
                {warningCount > 1 && 's'}
              </ThemeTypography>
            </Badge>
            {warningCategory && warningCategory === EDatasetErrorCategory.EUDR_REQUIREMENT && (
              <Badge severity="info">
                <ThemeTypography variant="BODY_SMALL_BOLD" color="WHITE">
                  EUDR requirement
                </ThemeTypography>
              </Badge>
            )}
            <ThemeTypography color="ALMOST_BLACK" variant="BODY_MEDIUM_BOLD">
              {warningCategory && warningCategory === EDatasetErrorCategory.EUDR_REQUIREMENT
                ? 'Incomplete geo-data for EUDR deforestation analysis'
                : 'Faulty plot geometries detected (This prevents a credible deforestation analysis)'}
            </ThemeTypography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column">
            {plotWarnings
              // Sort by warning count
              .sort((a, b) => b.count - a.count)
              .map((warning, index) => (
                <Box display="flex" alignItems="center" mt={1} gap={1} key={`${warning}-${index}`}>
                  <NumberCyrcle severity={severity}>
                    <ThemeTypography variant="BODY_SMALL_BOLD" color="ALMOST_BLACK">
                      {warning.count}
                    </ThemeTypography>
                  </NumberCyrcle>
                  <ThemeTypography color="ALMOST_BLACK" variant="BODY_SMALL">
                    {warning.errorMessage}
                  </ThemeTypography>
                </Box>
              ))}
            {warningCategory && warningCategory === EDatasetErrorCategory.EUDR_REQUIREMENT && (
              <Box mt={1}>
                <ThemeTypography color="ALMOST_BLACK" variant="BODY_SMALL">
                  If you plan to use this geo-data for EUDR compliance, you should consider
                  addressing this immediately. The EUDR Regulation requires that each plot larger
                  than 4 hectares is marked as a polygon. A polygon refers to a shape on the map
                  that has multiple coordinates marking the boundaries around the cultivation area
                  of a farm plot.
                </ThemeTypography>
              </Box>
            )}
          </Box>
        </AccordionDetails>
      </StyledAccordion>
    </Container>
  );
};

export default PlotWarningBanner;
