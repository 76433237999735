import {
  IChain,
  IChainActivity,
  IChainStep,
  IChainTask,
  IChainTaskDetails,
  ISubChain,
} from 'types/chain.types';
import { CompanyStatus } from 'types/company.types';
import { AvailableLanguages } from 'types/enums';
import { ImageVariant } from 'types/media.types';
import { IBasePartnerCompany } from 'types/partner.types';
import { ISite, SiteType } from 'types/site.types';
import { IBaseCultivatedAreas } from 'types/cultivatedArea.types';

export type IComponentChainRes = Omit<
  IChain,
  | 'subChains'
  | 'chainStepActivities'
  | 'image'
  | 'productId'
  | 'productVersionId'
  | 'partnerRequest'
  | 'ownedBy'
> & {
  subChains: IComponentChainSubChainRes[];
  chainStepActivities: IComponentChainActivityRes[];
  image?: ImageVariant | null;
  productId?: string | null;
  ownedBy: IChain['ownedBy'] | null;
  partnerRequest: IChain['partnerRequest'] | null;
  productVersionId?: string | null;
  __typename: 'Chain';
};

type IComponentChainTaskRes = Omit<IChainTaskDetails, 'tasks'> & {
  tasks: (IChainTask & { __typename: 'ChainTask' })[];
  __typename: 'ChainTaskDetails';
};

type IComponentChainStepRes = IChainStep & { __typename: 'ChainStep' };

type IComponentChainActivityRes = Omit<IChainActivity, 'activity'> & {
  activity: Omit<IChainActivity['activity'], 'ownedBy' | 'cultivatedAreas'> & {
    ownedBy?:
      | (Omit<IBasePartnerCompany, 'logo'> & {
          __typename: 'Company';
          logo?: (ImageVariant & { __typename: 'ImageType' }) | null;
        })
      | null;
    site: ISite & { __typename: 'Site' };
    component: IChainActivity['activity']['component'] & {
      __typename: 'Component';
    };
    cultivatedAreas: null | IBaseCultivatedAreas;
    __typename: 'Activity';
  };
  __typename: 'ChainStepActivity';
};

type IComponentChainSubChainRes = Omit<ISubChain, 'outputComponents'> & {
  outputComponents: { partner: { title: string } }[];
  __typename: 'SubChain';
};

const EXAMPLE_COMPONENT = {
  partner: {
    title: 'GhaniCoco',
  },
  __typename: 'Component',
};

export const EXAMPLE_COMPONENT_CHAIN_TASKS: IComponentChainTaskRes = {
  tasks: [
    {
      id: '1',
      title: 'Create component chain',
      done: true,
      __typename: 'ChainTask',
    },
    {
      id: '2',
      title: 'Apply component chains (import when applicable)',
      done: false,
      __typename: 'ChainTask',
    },
    {
      id: '3',
      title: 'Add component chain steps',
      done: true,
      __typename: 'ChainTask',
    },
    {
      id: '4',
      title: 'Add activities for each step',
      done: false,
      __typename: 'ChainTask',
    },
    {
      id: '5',
      title: 'Add activity detail for each activity card',
      done: true,
      __typename: 'ChainTask',
    },
    {
      id: '6',
      title: 'Specify incoming connections for each activity',
      done: true,
      __typename: 'ChainTask',
    },
    {
      id: '7',
      title: 'Specify company for each activity',
      done: false,
      __typename: 'ChainTask',
    },
    {
      id: '8',
      title: 'Specify the output components for each activity',
      done: false,
      __typename: 'ChainTask',
    },
  ],
  completed: 5,
  total: 8,
  __typename: 'ChainTaskDetails',
};

export const EXAMPLE_COMPONENT_CHAIN_STEPS: IComponentChainStepRes[] = [
  {
    id: '0243bd92-1b97-4420-87e6-934a940c45cc',
    title: 'Origin',
    isDeletable: true,
    __typename: 'ChainStep',
  },
  {
    id: 'b37c1128-2a15-403b-a506-c697fc23499b',
    title: 'Processing',
    isDeletable: true,
    __typename: 'ChainStep',
  },
  {
    id: 'b37c1128-2a15-403b-a506-asdfc697fcd3499b',
    title: 'Processing',
    isDeletable: true,
    __typename: 'ChainStep',
  },
  {
    id: 'b37c1128-2a15-403b-a506-asdfc6927fc23499b',
    title: 'Manufacturing',
    isDeletable: true,
    __typename: 'ChainStep',
  },
  {
    id: 'b37c1128-2a15-403b-a506-asdfc6937fc23fa499b',
    title: 'Manufacturing',
    isDeletable: true,
    __typename: 'ChainStep',
  },
  {
    id: 'b37c1128-2a15-403b-a506-asdfc6937fasc23499b',
    title: 'Distribution',
    isDeletable: true,
    __typename: 'ChainStep',
  },
  {
    id: 'b37c1128dsf-2a15-403b-a506-asdfc69743fc23499b',
    title: 'Distribution',
    isDeletable: true,
    __typename: 'ChainStep',
  },
  {
    id: 'b37c1128-2a1asda5-403b-a506-asdfc697f24c23499b',
    title: 'Distribution',
    isDeletable: true,
    __typename: 'ChainStep',
  },
];

export const EXAMPLE_COMPONENT_CHAIN_ACTIVITIES_STEP_2: IComponentChainActivityRes[] = [
  {
    id: '62d7b09c-c221-493f-b8db-0c3f298dd6ca',
    incomingLinks: [],
    activity: {
      companyId: null,
      id: '96b709ee-66fb-438c-8618-ecf82926db8c',
      modifiedTimestamp: new Date('2023-04-19T07:05:04.866205+00:00'),
      langs: [AvailableLanguages.ENGLISH],
      title: 'Harvest',
      description: '',

      mediaList: [],
      partner: {
        id: '6b3sda4a7-99ab-472a-ab23-67b8a54970c4',
        name: 'Company name',
        logo: null,
        logs: [],
        latestLog: null,
        modifiedTimestamp: '2023-04-19T07:05:04.866205+00:00',
        status: CompanyStatus.REGISTERED,
        impactClaims: { edges: [] },
        activities: { edges: [] },
        farm: null,
        sites: { count: 0, edges: [] },
        __typename: 'Company',
      },
      component: {
        id: '6b37b4a7-99ab-472a-ab23-67b8a54970c2',
        title: 'Cocoa fruit',
        rawMaterials: [{ id: '6b37b4a7-99ab-472a-ab23-67b8a54970c2', title: 'Almonds Raw' }],

        __typename: 'Component',
      },
      cultivatedAreas: null,
      site: {
        id: 'b85b8855-23d8-4998-aa26-ba1f46a9bdfd',
        title: 'Munich, Germany',
        siteType: SiteType.GENERIC_SITE,
        locationCoordinates: {
          lat: 52.5170365,
          lng: 13.3888599,
        },
        locationName: 'Kintampo, Ghana',
        description: '',
        // @ts-ignore
        ownedBy: null,
        image: null,
        externalId: '123',
        inUse: false,
        __typename: 'Site',
      },
      __typename: 'Activity',
    },
    chainStepId: '0243bd92-1b97-4420-87e6-934a940c45cc',
    pointPosition: {
      x: 0,
      y: 0,
    },
    __typename: 'ChainStepActivity',
  },
];

export const EXAMPLE_COMPONENT_CHAIN_ACTIVITIES_STEP_3: IComponentChainActivityRes[] = [
  {
    id: '62d7b09c-c221-493f-b8db-0c3f298dd6ca',
    incomingLinks: [],
    activity: {
      companyId: null,
      id: '96b709ee-66fb-438c-8618-ecf82926db8c',
      modifiedTimestamp: new Date('2023-04-19T07:05:04.866205+00:00'),
      langs: [AvailableLanguages.ENGLISH],
      title: 'Harvest',
      description: '',
      mediaList: [],
      partner: null,
      component: {
        id: '6b37b4a7-99ab-472a-ab23-67b8a54970c2',
        title: 'Cocoa beans Raw',

        rawMaterials: [{ id: '6b37b4a7-99ab-472a-ab23-67b8a54970c2', title: 'Almonds Raw' }],
        __typename: 'Component',
      },
      cultivatedAreas: null,
      site: {
        id: 'b85b8855-23d8-4998-aa26-ba1f46a9bdfd',
        title: 'Munich, Germany',
        siteType: SiteType.GENERIC_SITE,
        locationCoordinates: {
          lat: 52.5170365,
          lng: 13.3888599,
        },
        locationName: 'Kintampo, Ghana',
        description: '',
        // @ts-ignore
        ownedBy: null,
        image: null,
        externalId: '123',
        inUse: false,
        __typename: 'Site',
      },
      __typename: 'Activity',
    },
    chainStepId: '0243bd92-1b97-4420-87e6-934a940c45cc',
    pointPosition: {
      x: 0,
      y: 0,
    },
    __typename: 'ChainStepActivity',
  },
  {
    id: '0a88caa7-0e65-4071-b16c-a6d630cb3f56',
    incomingLinks: [],
    activity: {
      companyId: null,
      id: 'e5787c72-f2fb-4ec8-8a97-03af9e44e9ef',
      modifiedTimestamp: new Date('2023-04-19T07:05:04.866205+00:00'),
      langs: [AvailableLanguages.ENGLISH],
      title: 'Harvest',
      description: '',

      mediaList: [],
      partner: null,
      component: {
        id: '6fc550c4-31bb-44c2-81ba-96e842cb9e61',
        title: 'Cocoa beans',

        rawMaterials: [{ id: '6b37b4a7-99ab-472a-ab23-67b8a54970c2', title: 'Almonds Raw' }],

        __typename: 'Component',
      },
      cultivatedAreas: null,
      site: {
        id: 'b85b8855-23d8-4998-aa26-ba1f46a9bdfd',
        title: 'Munich, Germany',
        siteType: SiteType.GENERIC_SITE,
        locationCoordinates: {
          lat: 52.5170365,
          lng: 13.3888599,
        },
        locationName: 'Soubré, Ivory coast',
        description: '',
        // @ts-ignore
        ownedBy: null,
        image: null,
        externalId: '123',
        inUse: false,
        __typename: 'Site',
      },
      __typename: 'Activity',
    },
    chainStepId: '0243bd92-1b97-4420-87e6-934a940c45cc',
    pointPosition: {
      x: 0,
      y: 1,
    },
    __typename: 'ChainStepActivity',
  },
  {
    id: '69745d43-3d91-4eb8-98ca-b9e864d76110',
    incomingLinks: [{ chainStepActivitySourceId: '62d7b09c-c221-493f-b8db-0c3f298dd6ca' }],
    activity: {
      companyId: null,
      id: '0b8a5420-2f9c-438c-bab3-5e3232c1dabf',
      modifiedTimestamp: new Date('2023-04-19T07:05:04.866205+00:00'),
      langs: [AvailableLanguages.ENGLISH],
      title: 'Fermentation & Drying',
      description: '',

      mediaList: [],
      partner: null,
      component: {
        id: '88ea4f3f-870f-4ab2-af01-746162e0c09a',
        title: 'Cocoa beans Dried',

        rawMaterials: [{ id: '6b37b4a7-99ab-472a-ab23-67b8a54970c2', title: 'Almonds Raw' }],

        __typename: 'Component',
      },
      cultivatedAreas: null,
      site: {
        id: 'b85b8855-23d8-4998-aa26-ba1f46a9bdfd',
        title: 'Munich, Germany',
        siteType: SiteType.GENERIC_SITE,
        locationCoordinates: {
          lat: 52.5170365,
          lng: 13.3888599,
        },
        locationName: 'Accra, Ghana',
        description: '',
        // @ts-ignore
        ownedBy: null,
        image: null,
        externalId: '123',
        inUse: false,
        __typename: 'Site',
      },
      __typename: 'Activity',
    },
    chainStepId: 'b37c1128-2a15-403b-a506-c697fc23499b',
    pointPosition: {
      x: 1,
      y: 0,
    },
    __typename: 'ChainStepActivity',
  },
  {
    id: '72663b67-4bcc-4559-9442-52e2fa80bb0a',
    incomingLinks: [{ chainStepActivitySourceId: '0a88caa7-0e65-4071-b16c-a6d630cb3f56' }],
    activity: {
      id: '91a8ddb3-5bff-4511-8bc1-9f99d655a97d',
      modifiedTimestamp: new Date('2023-04-19T07:05:04.866205+00:00'),
      langs: [AvailableLanguages.ENGLISH],
      title: 'Fermentation & Drying',
      description: '',
      mediaList: [],
      companyId: null,
      partner: null,
      component: {
        id: 'a585eddb-bcf6-4569-846c-faa9374e4067',
        title: 'Cocoa beans Dried',

        rawMaterials: [{ id: '6b37b4a7-99ab-472a-ab23-67b8a54970c2', title: 'Almonds Raw' }],

        __typename: 'Component',
      },
      cultivatedAreas: null,
      site: {
        id: 'b85b8855-23d8-4998-aa26-ba1f46a9bdfd',
        title: 'Munich, Germany',
        siteType: SiteType.GENERIC_SITE,
        locationCoordinates: {
          lat: 52.5170365,
          lng: 13.3888599,
        },
        locationName: 'Soubré, Ivory coast',
        description: '',
        // @ts-ignore
        ownedBy: null,
        image: null,
        externalId: '123',
        inUse: false,
        __typename: 'Site',
      },
      __typename: 'Activity',
    },
    chainStepId: 'b37c1128-2a15-403b-a506-c697fc23499b',
    pointPosition: {
      x: 1,
      y: 1,
    },
    __typename: 'ChainStepActivity',
  },
  {
    id: 'b99cd1d8-9972-43e1-b446-e1bf601667fc',
    incomingLinks: [
      { chainStepActivitySourceId: '69745d43-3d91-4eb8-98ca-b9e864d76110' },
      { chainStepActivitySourceId: '72663b67-4bcc-4559-9442-52e2fa80bb0a' },
    ],
    activity: {
      id: '9d332623-c280-49b4-b928-2dfc9d90160b',
      modifiedTimestamp: new Date('2023-04-19T07:05:04.866205+00:00'),
      langs: [AvailableLanguages.ENGLISH],
      title: 'Roasting & Grinding',
      description: '',

      mediaList: [],
      companyId: '6b3sda4a7-99ab-472a-ab23-67b8a54970c4',
      ownedBy: {
        id: '6b3sda4a7-99ab-472a-ab23-67b8a54970c4',
        name: 'Qualcoco',
        logo: null,
        modifiedTimestamp: new Date('2023-04-19T07:05:04.866205+00:00'),
        createdTimestamp: new Date('2023-04-19T07:05:04.866205+00:00'),
        status: CompanyStatus.REGISTERED,
        __typename: 'Company',
      },
      component: {
        id: '568cd117-f816-4ff6-a2f6-e730c0faaf4e',
        title: 'Cocoa liquor',

        rawMaterials: [{ id: '6b37b4a7-99ab-472a-ab23-67b8a54970c2', title: 'Almonds Raw' }],

        __typename: 'Component',
      },
      cultivatedAreas: null,
      site: {
        id: 'b85b8855-23d8-4998-aa26-ba1f46a9bdfd',
        title: 'Munich, Germany',
        siteType: SiteType.GENERIC_SITE,
        locationCoordinates: {
          lat: 52.5170365,
          lng: 13.3888599,
        },
        locationName: 'Brussels, Belgium',
        description: '',
        // @ts-ignore
        ownedBy: null,
        image: null,
        externalId: '123',
        inUse: false,
        __typename: 'Site',
      },
      __typename: 'Activity',
    },
    chainStepId: 'b37c1128-2a15-403b-a506-asdfc697fcd3499b',
    pointPosition: {
      x: 2,
      y: 0,
    },
    __typename: 'ChainStepActivity',
  },
];

export const EXAMPLE_COMPONENT_CHAIN_ACTIVITIES_STEP_4: IComponentChainActivityRes[] = [
  {
    ...EXAMPLE_COMPONENT_CHAIN_ACTIVITIES_STEP_3[0],
  },
  {
    ...EXAMPLE_COMPONENT_CHAIN_ACTIVITIES_STEP_3[1],
  },
  {
    ...EXAMPLE_COMPONENT_CHAIN_ACTIVITIES_STEP_3[2],
  },
  {
    ...EXAMPLE_COMPONENT_CHAIN_ACTIVITIES_STEP_3[3],
  },
  {
    ...EXAMPLE_COMPONENT_CHAIN_ACTIVITIES_STEP_3[4],
  },
];

export const EXAMPLE_COMPONENT_SUB_CHAINS: IComponentChainSubChainRes[] = [
  {
    id: '3b0a6cbc-fbc1-4e60-bf3d-8a5f7a1e9aed',
    title: 'Cocoa beans Dried',
    childChainId: '3b0a6cbc-fbc1-4e60-bf3d-8a5f7a1e9aed',
    outputComponents: [EXAMPLE_COMPONENT],
    outputStepActivitiesIds: ['69745d43-3d91-4eb8-98ca-b9e864d76110'],
    boundingBoxPosition: {
      xMax: 2,
      xMin: 0,
      yMax: 1,
      yMin: 0,
    },
    __typename: 'SubChain',
  },
  {
    id: '83f1854a-4a7e-4b87-8735-1ba9d2cedca2',
    title: 'Cocoa beans Dried',
    childChainId: '83f1854a-4a7e-4b87-8735-1ba9d2cedca2',
    outputStepActivitiesIds: ['69745d43-3d91-4eb8-98ca-b9e864d76110'],
    outputComponents: [],
    boundingBoxPosition: {
      xMax: 2,
      xMin: 0,
      yMax: 2,
      yMin: 1,
    },
    __typename: 'SubChain',
  },
];

export const ONBOARDING_CHAIN_1: IComponentChainRes = {
  id: 'b85b8855-23d8-4998-aa26-ba1f46a9bdfd',
  inUse: true,
  title: 'New component chain',
  image: null,
  chainTasksDetails: EXAMPLE_COMPONENT_CHAIN_TASKS,
  subChains: [],
  chainStepActivities: [],
  chainSteps: [EXAMPLE_COMPONENT_CHAIN_STEPS[0]],
  ownedBy: null,
  mappingInitialised: true,
  productId: null,
  productVersionId: null,
  involvedPartnerCompanies: [],
  visibilitySettings: {
    locationVisibility: 'APPROXIMATE',
    id: 'b85b8855-23d8-4998-aa26-ba1f46a9bdfc',
    chainId: 'b85b8855-23d8-4998-aa26-ba1f46a9bdfd',
  },
  partnerRequest: null,
  __typename: 'Chain',
};

export const ONBOARDING_CHAIN_2: IComponentChainRes = {
  id: 'acb73190-4f02-4433-836a-c5ece022ce9c',
  inUse: true,
  title: 'New component chain',
  image: null,
  chainTasksDetails: EXAMPLE_COMPONENT_CHAIN_TASKS,
  subChains: [],
  chainStepActivities: EXAMPLE_COMPONENT_CHAIN_ACTIVITIES_STEP_2,
  chainSteps: [EXAMPLE_COMPONENT_CHAIN_STEPS[0]],
  mappingInitialised: true,
  productId: null,
  ownedBy: null,
  productVersionId: null,
  involvedPartnerCompanies: [],
  visibilitySettings: {
    locationVisibility: 'APPROXIMATE',
    id: 'b85b8855-23d8-4998-aa26-ba1f46a9bdfc',
    chainId: 'acb73190-4f02-4433-836a-c5ece022ce9c',
  },
  partnerRequest: null,
  __typename: 'Chain',
};

export const ONBOARDING_CHAIN_3: IComponentChainRes = {
  id: '8e804073-ca2e-43fc-8577-3c4f62f363a1',
  inUse: true,
  title: 'New component chain',
  chainTasksDetails: EXAMPLE_COMPONENT_CHAIN_TASKS,
  subChains: [],
  chainStepActivities: EXAMPLE_COMPONENT_CHAIN_ACTIVITIES_STEP_3,
  chainSteps: EXAMPLE_COMPONENT_CHAIN_STEPS.slice(0, 3),
  mappingInitialised: true,
  productId: null,
  ownedBy: null,
  productVersionId: null,
  involvedPartnerCompanies: [],
  visibilitySettings: {
    locationVisibility: 'APPROXIMATE',
    id: 'b85b8855-23d8-4998-aa26-ba1f46a9bdfc',
    chainId: '8e804073-ca2e-43fc-8577-3c4f62f363a1',
  },
  partnerRequest: null,
  __typename: 'Chain',
};

export const ONBOARDING_CHAIN_4: IComponentChainRes = {
  id: 'b515aaaf-3da3-4ecf-ac95-863014e70ba1',
  inUse: true,
  title: 'New component chain',
  image: null,
  chainTasksDetails: EXAMPLE_COMPONENT_CHAIN_TASKS,
  subChains: EXAMPLE_COMPONENT_SUB_CHAINS,
  chainStepActivities: EXAMPLE_COMPONENT_CHAIN_ACTIVITIES_STEP_4,
  chainSteps: EXAMPLE_COMPONENT_CHAIN_STEPS.slice(0, 3),
  mappingInitialised: true,
  productId: null,
  ownedBy: null,
  productVersionId: null,
  involvedPartnerCompanies: [],
  visibilitySettings: {
    locationVisibility: 'APPROXIMATE',
    id: 'b85b8855-23d8-4998-aa26-ba1f46a9bdfc',
    chainId: 'b515aaaf-3da3-4ecf-ac95-863014e70ba1',
  },
  partnerRequest: null,
  __typename: 'Chain',
};
