import { ButtonGroup, Checkbox, Grid, styled, TextField } from '@mui/material';
import { DropDown } from 'components/Forms';
import { FlexBox } from 'components/Structure';
import { InfoTooltip, ThemeButton, ThemeTypography } from 'designSystem';
import { FieldProps, useField } from 'formik';
import React, { ChangeEvent, useMemo } from 'react';
import { OriginTableSettingsColumn } from 'types/dataImport.types';
import { AvailableSizes } from 'types/enums';

interface ISettingColumItemProps<OptionKey extends string = 'free-text'>
  extends FieldProps<OriginTableSettingsColumn<OptionKey>> {
  label: string;
  formatOptions?: { key: OptionKey; label: string; sampleData?: string }[];
  required?: boolean;
  /**
   * Sample data to be displayed in the last column
   * Pass the string if the data is same for all options or
   * pass the sample data in each option
   */
  sampleData?: string;
  infoTooltip?: string;
}

const ColumnName = styled(FlexBox)(({ theme }) => ({
  width: '100%',
  borderRadius: 4,
  border: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
  borderBottom: 'none',
  background: theme.custom.themeColors.grayScale[10],
  padding: theme.spacing(1),
}));

const DataContainer = styled(FlexBox)({
  height: '100%',
  '& .MuiSelect-select': {
    padding: '7px 14px !important',
  },
  '& .MuiInputBase-root': {
    height: '35px !important',
  },
});

const SettingColumItem = <OptionKey extends string = 'free-text'>({
  required,
  label,
  formatOptions = [{ key: 'free-text' as OptionKey, label: 'Free text' }],
  sampleData,
  infoTooltip,
  field: { name, value },
}: ISettingColumItemProps<OptionKey>) => {
  const [, , { setValue }] = useField<OriginTableSettingsColumn<OptionKey>>(name);

  const selectedOptionKey = value?.format ? value.format : formatOptions?.[0].key;

  const sampleDataValue = useMemo(() => {
    const selectedOption = formatOptions?.find(option => option.key === selectedOptionKey);
    return selectedOption?.sampleData || sampleData;
  }, [sampleData, formatOptions, selectedOptionKey]);

  const handleEnableToggle = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setValue({ ...value, enabled: checked });
  };

  const onSelectionChange = (key: OptionKey) => {
    setValue({ ...value, format: key });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <FlexBox>
          {!required && <Checkbox checked={value.enabled} onChange={handleEnableToggle} />}
          <ColumnName>
            <ThemeTypography variant="BODY_MEDIUM_BOLD" autoOverflow>
              {label}
            </ThemeTypography>
            {infoTooltip && <InfoTooltip text={infoTooltip} />}
          </ColumnName>
        </FlexBox>
      </Grid>

      <Grid item xs={4}>
        <DataContainer>
          {formatOptions.length === 2 ? (
            <ButtonGroup>
              {formatOptions.map(({ key, label }) => (
                <ThemeButton
                  key={key}
                  className={selectedOptionKey === key ? 'selected' : ''}
                  onClick={() => onSelectionChange(key)}
                >
                  {label}
                </ThemeButton>
              ))}
            </ButtonGroup>
          ) : (
            <DropDown<OptionKey, { key: OptionKey; label: string }>
              variant="outlined"
              size={AvailableSizes.SMALL}
              options={formatOptions}
              placeholder="Select data type"
              renderItems={item => <>{item.label}</>}
              currentValue={selectedOptionKey}
              keyField="key"
              onChange={onSelectionChange}
              disabled={formatOptions.length === 1}
              fullWidth
            />
          )}
        </DataContainer>
      </Grid>

      <Grid item xs={4}>
        <DataContainer height="100%">
          <TextField fullWidth size="small" disabled value={sampleDataValue} />
        </DataContainer>
      </Grid>
    </Grid>
  );
};

export default SettingColumItem;
