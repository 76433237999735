import { OriginDatasetTableRowData } from 'components/DueDiligenceProcess/components/Forms/DatasetValidationForm';
import {
  CellValue,
  ColumnDefinition,
} from 'components/DataImport/DataImportTable/excelTable.types';
import { originTableSettingsSchema } from 'constants/schemas/compliance.schema';
import pick from 'lodash/pick';
import { IOriginTableSettings, TColumnKeys } from 'types/dataImport.types';
import {
  CoordinateDatasetColumnType,
  EDatasetColumn,
  TDatasetColumnType,
  EUDRDatasetCell,
  EUDRDatasetRow,
  FarmSizeDatasetColumnType,
} from 'types/dataset.types';
import { v4 as uuid } from 'uuid';

export const DatasetColumnKeys: TDatasetColumnType[] = Object.values(EDatasetColumn);

type DatasetColumnWithSampleData =
  | CoordinateDatasetColumnType
  | FarmSizeDatasetColumnType
  | `${EDatasetColumn.LATITUDE}`
  | `${EDatasetColumn.LONGITUDE}`;

export const DatasetColumnSampleData: Record<DatasetColumnWithSampleData, string> = {
  [EDatasetColumn.LATITUDE]: `40.7128 or 40°42'46" N`,
  [EDatasetColumn.LONGITUDE]: `174.0060 or 174°0'21" W`,
  [EDatasetColumn.COORDINATES_LAT_LNG]: `40.7128, 174.0060 or 40°42'46" N 174°0'21" W`,
  [EDatasetColumn.COORDINATES_LNG_LAT]: `174.0060, 40.7128 or 174°0'21" W' 40°42'46" N`,
  [EDatasetColumn.FARM_SIZE_HA]: '1.0 ha',
  [EDatasetColumn.FARM_SIZE_KM2]: '1.0 km²',
};

export const DatasetColumnName: Record<TDatasetColumnType, string> = {
  [EDatasetColumn.ORIGIN_CLUSTER_ID]: 'Origin cluster ID',
  [EDatasetColumn.FARM_ID]: 'Farm ID',
  [EDatasetColumn.FARM_NAME]: 'Farm name',
  [EDatasetColumn.FARM_OWNER_FULL_NAME]: 'Farm owner - Full name',
  [EDatasetColumn.FARM_OWNER_FIRST_NAME]: 'Farm owner - First name',
  [EDatasetColumn.FARM_OWNER_LAST_NAME]: 'Farm owner - Last name',
  [EDatasetColumn.NUMBER_OF_FARMERS]: 'Number of farmers',
  [EDatasetColumn.LATITUDE]: 'Latitude',
  [EDatasetColumn.LONGITUDE]: 'Longitude',
  [EDatasetColumn.COORDINATES_LAT_LNG]: 'Coordinates (Lat, Lng)',
  [EDatasetColumn.COORDINATES_LNG_LAT]: 'Coordinates (Lng, Lat)',
  [EDatasetColumn.FARM_SIZE_HA]: 'Farm size (Hectare)',
  [EDatasetColumn.FARM_SIZE_KM2]: 'Farm size (Square km)',
  [EDatasetColumn.ADDITIONAL_CROPS]: 'Additional crops',
  [EDatasetColumn.LAND_OWNERSHIP]: 'Land ownership',
  [EDatasetColumn.SITE_DESCRIPTION]: 'Site description',
};

export const CoordinateDatasetColumnName: Record<CoordinateDatasetColumnType, string> = pick(
  DatasetColumnName,
  [EDatasetColumn.COORDINATES_LAT_LNG, EDatasetColumn.COORDINATES_LNG_LAT]
);

export const FarmSizeDatasetColumnName: Record<FarmSizeDatasetColumnType, string> = pick(
  DatasetColumnName,
  [EDatasetColumn.FARM_SIZE_HA, EDatasetColumn.FARM_SIZE_KM2]
);

const transformRowColumnData = <Value extends CellValue>(
  cell: EUDRDatasetCell | undefined,
  columnKey: TColumnKeys,
  settings?: IOriginTableSettings
): object | ColumnDefinition<Value> => {
  if (!cell?.value) {
    if (settings?.columns[columnKey]?.enabled) {
      return { [columnKey]: { value: '' } };
    }
    return {};
  }
  return {
    [columnKey]: {
      value: cell.value as string,
      isValid: !cell.error?.length,
      errorMessage: cell.error?.[0].errorMessage,
    },
  };
};

export const transformDataForDisplay = (
  row: EUDRDatasetRow,
  settings?: IOriginTableSettings
): OriginDatasetTableRowData => {
  return {
    id: uuid(),
    // Required columns
    farmId: {
      value: row.farmId.value as string,
      isValid: !row.farmId.error?.length,
      errorMessage: row.farmId.error?.[0].errorMessage,
    },
    coordinates: {
      value: row.coordinates.value as string,
      isValid: !row.coordinates.error?.length,
      errorMessage: row.coordinates.error?.[0].errorMessage,
    },
    // Extended columns
    ...transformRowColumnData<string>(row.originClusterId, 'originClusterId', settings),
    ...transformRowColumnData<number>(row.farmSizeKm2, 'farmSize', settings),
    ...transformRowColumnData<string>(row.farmName, 'farmName', settings),
    ...transformRowColumnData<string>(row.additionalCrops, 'additionalCrops', settings),
    ...transformRowColumnData<string>(row.farmOwnerFullName, 'farmOwnerFullName', settings),
    ...transformRowColumnData<string>(row.farmOwnerFirstName, 'farmOwnerFirstName', settings),
    ...transformRowColumnData<string>(row.farmOwnerLastName, 'farmOwnerLastName', settings),
    ...transformRowColumnData<number>(row.numberOfFarmers, 'numberOfFarmers', settings),
    ...transformRowColumnData<string>(row.landOwnership, 'landOwnership', settings),
    ...transformRowColumnData<string>(row.siteDescription, 'siteDescription', settings),
  };
};

export const transformColumnNamesToTableSettingColumns: (
  names: TDatasetColumnType[]
) => IOriginTableSettings['columns'] = names => {
  return names.reduce(
    (prev, columnName) => {
      switch (columnName) {
        case EDatasetColumn.ORIGIN_CLUSTER_ID:
          return { ...prev, originClusterId: { enabled: true } };
        case EDatasetColumn.FARM_ID:
          return { ...prev, farmId: { enabled: true } };
        case EDatasetColumn.FARM_NAME:
          return { ...prev, farmName: { enabled: true } };
        case EDatasetColumn.FARM_OWNER_FULL_NAME:
          return { ...prev, farmOwnerFullName: { enabled: true } };
        case EDatasetColumn.FARM_OWNER_FIRST_NAME:
          return { ...prev, farmOwnerFirstName: { enabled: true } };
        case EDatasetColumn.FARM_OWNER_LAST_NAME:
          return { ...prev, farmOwnerLastName: { enabled: true } };
        case EDatasetColumn.NUMBER_OF_FARMERS:
          return { ...prev, numberOfFarmers: { enabled: true } };
        case EDatasetColumn.COORDINATES_LAT_LNG:
        case EDatasetColumn.COORDINATES_LNG_LAT:
          return {
            ...prev,
            coordinates: { enabled: true, format: columnName },
          };
        case EDatasetColumn.FARM_SIZE_HA:
        case EDatasetColumn.FARM_SIZE_KM2:
          return {
            ...prev,
            farmSize: { enabled: true, format: columnName as FarmSizeDatasetColumnType },
          };
        case EDatasetColumn.ADDITIONAL_CROPS:
          return { ...prev, additionalCrops: { enabled: true } };
        case EDatasetColumn.LAND_OWNERSHIP:
          return { ...prev, landOwnership: { enabled: true } };
        case EDatasetColumn.SITE_DESCRIPTION:
          return { ...prev, siteDescription: { enabled: true } };
        case EDatasetColumn.LATITUDE:
        case EDatasetColumn.LONGITUDE:
        default:
          throw new Error(`Unknown column key: ${columnName}`);
      }
    },
    {
      ...originTableSettingsSchema.default().columns,
    } as IOriginTableSettings['columns']
  );
};

export const matchColumnMappingToColumnKey = (column: TDatasetColumnType): string => {
  switch (column) {
    case EDatasetColumn.ORIGIN_CLUSTER_ID:
      return 'originClusterId';
    case EDatasetColumn.FARM_ID:
      return 'farmId';
    case EDatasetColumn.COORDINATES_LAT_LNG:
      return 'coordinates';
    case EDatasetColumn.FARM_SIZE_KM2:
      return 'farmSize';
    case EDatasetColumn.FARM_NAME:
      return 'farmName';
    case EDatasetColumn.FARM_OWNER_FIRST_NAME:
      return 'farmOwnerFirstName';
    case EDatasetColumn.FARM_OWNER_LAST_NAME:
      return 'farmOwnerLastName';
    case EDatasetColumn.NUMBER_OF_FARMERS:
      return 'numberOfFarmers';
    case EDatasetColumn.ADDITIONAL_CROPS:
      return 'additionalCrops';
    case EDatasetColumn.LAND_OWNERSHIP:
      return 'landOwnership';
    case EDatasetColumn.SITE_DESCRIPTION:
    default:
      return 'siteDescription';
  }
};

export const matchColumnKeyToColumnMapping: (
  key: string,
  settings?: IOriginTableSettings
) => TDatasetColumnType = (key, settings) => {
  switch (key) {
    case 'originClusterId':
      return EDatasetColumn.ORIGIN_CLUSTER_ID;
    case 'farmId':
      return EDatasetColumn.FARM_ID;
    case 'coordinates':
      return settings?.columns.coordinates.format || EDatasetColumn.COORDINATES_LAT_LNG;
    case 'farmSize':
      return settings?.columns.farmSize.format || EDatasetColumn.FARM_SIZE_KM2;
    case 'farmName':
      return EDatasetColumn.FARM_NAME;
    case 'farmOwnerFirstName':
      return EDatasetColumn.FARM_OWNER_FIRST_NAME;
    case 'farmOwnerLastName':
      return EDatasetColumn.FARM_OWNER_LAST_NAME;
    case 'numberOfFarmers':
      return EDatasetColumn.NUMBER_OF_FARMERS;
    case 'additionalCrops':
      return EDatasetColumn.ADDITIONAL_CROPS;
    case 'landOwnership':
      return EDatasetColumn.LAND_OWNERSHIP;
    case 'siteDescription':
    default:
      return EDatasetColumn.SITE_DESCRIPTION;
  }
};
