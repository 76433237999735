import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  Box,
  TableContainer,
} from '@mui/material';
import React, { FC } from 'react';
import { GeoCBatch, ERiskLevel } from 'types/compliance.types';
import RiskBadge from 'designSystem/DataDisplay/RiskBadge/RiskBadge';
import Moment from 'react-moment';
import capitalize from 'lodash/capitalize';

interface IBatchResultTableProps {
  batches: GeoCBatch[];
}

const Container = styled(TableContainer)(({ theme }) => ({
  border: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
  borderRadius: 6,
  background: theme.custom.themeColors.white,
}));

const StyledTable = styled(Table)(({ theme }) => ({
  '& .MuiTableCell-head': {
    padding: theme.spacing(1),
    fontWeight: 700,
    background: theme.custom.themeColors.grayScale[5],

    '&.MuiTableCell-root': {
      fontSize: 12,
    },
  },
  '& .MuiTableCell-root': {
    padding: theme.spacing(1),
    border: 'none',
    fontSize: 11,
    borderRight: `1px solid ${theme.custom.themeColors.grayScale[20]}`,

    '&:last-child': {
      borderRight: 'none',
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderTop: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
}));

const BatchResultTable: FC<IBatchResultTableProps> = ({ batches }) => {
  return (
    <Container>
      <StyledTable size="small">
        <TableHead>
          <TableRow>
            <TableCell valign="top">Origin batch</TableCell>
            <TableCell valign="top">Check date</TableCell>
            <TableCell valign="top">Quantity</TableCell>
            <TableCell valign="top">Deforestation risk</TableCell>
            {/* <TableCell valign="top">Forest degradation risk</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {batches.map(batch => (
            <>
              <StyledTableRow key={batch.id}>
                <TableCell valign="top">{batch.id}</TableCell>
                <TableCell valign="top">
                  <Moment format="LL">{batch.checkDate}</Moment>
                </TableCell>
                <TableCell valign="top">{batch.weight} kg</TableCell>
                <TableCell valign="top">
                  <Box width="fit-content">
                    <RiskBadge
                      riskLevel={ERiskLevel[batch.riskStatus]}
                      text={capitalize(ERiskLevel[batch.riskStatus]) + ' risk'}
                    />
                  </Box>
                </TableCell>
                {/* <TableCell valign="top">
                  <Box width="fit-content">
                    <RiskBadge
                      riskLevel={ERiskLevel[batch.riskStatus]}                    
                    />
                  </Box>
                </TableCell> */}
              </StyledTableRow>
            </>
          ))}
        </TableBody>
      </StyledTable>
    </Container>
  );
};

export default BatchResultTable;
