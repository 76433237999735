import { Box, styled } from '@mui/material';
import useDatasetMutation from 'components/ComponentsLibrary/hooks/useDatasetMutation';
import { useGeoDataCollection } from 'components/DueDiligenceProcess/Context/SubSections/GeoDataCollectionContext';
import DueDiligenceProcessSubSectionNavigation from 'components/DueDiligenceProcess/DueDiligenceProcessSubSectionNavigation';
import { useMessages } from 'components/hooks';
import { ErrorState, FlexBox } from 'components/Structure';
import {
  IGeoMappingFormValues,
  geoDataCustomValidation,
  geoMappingSchema,
  mappedColumnsSchema,
} from 'constants/schemas/geoUpload.schema';
import { ThemeTypography } from 'designSystem';
import DatasetItem from 'designSystem/DataDisplay/DatasetItem/DatasetItem';
import { Form, Formik, FormikProps } from 'formik';
import omit from 'lodash/omit';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';
import { EDatasetStatus } from 'types/dataset.types';
import DatasetMappingForm from '../Forms/DatasetMappingForm';

const DatasetContainer = styled('div')<{ disabled?: booleanish }>(({ disabled }) => ({
  cursor: disabled === 'true' ? 'initial' : 'pointer',
}));

const InfoContainer = styled(FlexBox)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: '6px',
  border: `1px solid ${theme.custom.themeColors.primary[40]}`,
  background: theme.custom.themeColors.primary[5],
}));

const GeoDataCollectionUploadMapping: FC = () => {
  const formRef = useRef<FormikProps<IGeoMappingFormValues>>(null);

  const { setErrorMessage } = useMessages();
  const { startDatasetPreProcessing } = useDatasetMutation();
  const { processedDatasets, setProcessedDatasets, onNextClick, onPreviousClick } =
    useGeoDataCollection();

  const [selectedDatasetIndex, setSelectedDatasetIndex] = useState<number>(0);

  useEffect(() => {
    if (!processedDatasets) {
      return;
    }
    // find the first dataset that is has the status 'MAPPED_FAILED'
    const failedDataSetIndex = processedDatasets.datasets.findIndex(
      dataset => dataset.status === EDatasetStatus.MAPPING_FAILED
    );
    if (failedDataSetIndex !== -1) {
      setSelectedDatasetIndex(failedDataSetIndex);
    }
  }, [processedDatasets]);

  const initialValues: IGeoMappingFormValues = useMemo(() => {
    if (!processedDatasets || !processedDatasets?.datasetMappings) {
      return geoMappingSchema.default();
    }

    const schema = processedDatasets.datasetMappings.map(({ datasetId, columnsData }) => {
      if (!columnsData) {
        return {
          datasetId,
          mappedColumns: [],
        };
      }
      return {
        datasetId,
        mappedColumns: columnsData.map(column => {
          return {
            ...mappedColumnsSchema.default(),
            sourceColumnName: column.columnName,
            columnDataExample: column.columnDataExample,
            ...(column.mappedColumnName ? { targetColumnName: column.mappedColumnName } : {}),
          };
        }),
      };
    });

    return schema;
  }, [processedDatasets]);

  // Each dataset should be either went though the mapping or the mapping failed then it needs to have the mapping data
  if (
    !processedDatasets?.datasets.length ||
    !processedDatasets.datasets.every(
      dataset =>
        dataset.status !== EDatasetStatus.MAPPING_FAILED ||
        (dataset.status === EDatasetStatus.MAPPING_FAILED &&
          processedDatasets.datasetMappings?.find(mapping => mapping.datasetId === dataset.id))
    )
  ) {
    return <ErrorState />;
  }

  const handleClickDataSheet = (index: number) => {
    setSelectedDatasetIndex(index);
  };

  const handleSubmit = async (values: IGeoMappingFormValues) => {
    try {
      const response = await startDatasetPreProcessing(
        values.map(({ datasetId, mappedColumns }) => ({
          datasetId,
          // Remove the excluded columns and the columnDataExample
          mappedColumns:
            mappedColumns
              .filter(({ removed }) => !removed)
              .map(mapped => omit(mapped, ['columnDataExample', 'removed'])) || [],
        }))
      );

      const processedDatasets = response.data?.preProcessDatasets;
      const datasetProcessingErrors = processedDatasets?.datasetProcessingErrors;

      // Unknown errors
      if (!processedDatasets?.datasets?.length) {
        throw new Error('No datasets were created');
      } else if (
        processedDatasets.datasets.some(({ status }) => status === EDatasetStatus.MAPPING_FAILED)
      ) {
        // Throw error from backend to the user
        if (datasetProcessingErrors?.length && datasetProcessingErrors[0].datasetErrors.length) {
          setErrorMessage(datasetProcessingErrors[0].datasetErrors[0].errorMessage);
          return;
          // Dataset mapping failed
        } else {
          throw new Error('Dataset mapping failed.');
        }
      }
      if (processedDatasets?.datasets.length) {
        setProcessedDatasets(processedDatasets);
        onNextClick();
      } else {
        throw new Error(
          'Unknown error occurred submitting the mapping and processing. No dataset was created.'
        );
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(
        'At least one error occurred while processing the dataset. If the error persists, please contact support.'
      );
    } finally {
      formRef.current?.setSubmitting(false);
    }
  };

  return (
    <Formik<IGeoMappingFormValues>
      validationSchema={geoMappingSchema}
      initialValues={initialValues}
      validate={geoDataCustomValidation}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, isValid, submitForm, errors }) => (
        <Form>
          <DueDiligenceProcessSubSectionNavigation
            allowNextStepNavigation={isValid}
            nextStepLoading={isSubmitting}
            onNextStepClick={submitForm}
            onPreviousStepClick={onPreviousClick}
          >
            <Box display="flex" gap={2} flexDirection="column">
              <InfoContainer>
                <ThemeTypography variant="BODY_MEDIUM">
                  <b>Farm id</b> and <b>Coordinates</b> are required template columns.
                </ThemeTypography>
              </InfoContainer>

              <InfoContainer>
                <ThemeTypography variant="BODY_MEDIUM">
                  If you already group your farms based on some id (e.g. community id, cooperative
                  id), you can map this column to Origin cluster id, which will save those farms in
                  clusters accordingly.
                </ThemeTypography>
              </InfoContainer>
            </Box>

            <Box display="flex" gap={2} mt={2} flexWrap="wrap">
              {processedDatasets.datasets
                .sort((a, b) => (a.title <= b.title ? -1 : 1))
                .map((dataset, index) => (
                  <DatasetContainer
                    key={dataset.id}
                    onClick={() =>
                      dataset.status === EDatasetStatus.MAPPED
                        ? undefined
                        : handleClickDataSheet(index)
                    }
                    disabled={Booleanish(dataset.status === EDatasetStatus.MAPPED)}
                  >
                    <DatasetItem
                      title={dataset.title}
                      commodity={dataset.rawMaterial.title}
                      location={dataset.originCountry}
                      owner={dataset.ownedBy.name}
                      active={index === selectedDatasetIndex}
                      status={
                        dataset.status === EDatasetStatus.MAPPED
                          ? 'valid'
                          : errors[index]
                          ? 'error'
                          : undefined
                      }
                    />
                  </DatasetContainer>
                ))}
            </Box>
          </DueDiligenceProcessSubSectionNavigation>

          <DatasetMappingForm datasetIndex={selectedDatasetIndex} />
        </Form>
      )}
    </Formik>
  );
};

export default GeoDataCollectionUploadMapping;
