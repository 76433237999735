import React, { FC } from 'react';
import { Box, styled, Tooltip } from '@mui/material';
import { ThemeTypography } from 'designSystem';
import withStyles from '@mui/styles/withStyles';
import { useDialog } from 'components/hooks';
import Icon from 'designSystem/Primitives/Icon/Icon';
import Loading from 'assets/img/loading.gif';

interface IDeforestationImageProps {
  headline: string;
  subHeadline: string;
  imageSrc: string;
  legendSrc?: string;
  displayBorder?: boolean;
  openFullScreen?: boolean;
}

const ImageContainer = styled(Box)<{ hoverAction: boolean }>(({ theme, hoverAction }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: 90,
  height: 90,
  borderRadius: 8,
  border: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
  overflow: 'hidden',

  '&:hover': {
    cursor: hoverAction ? 'pointer' : 'default',
  },
}));

const Image = styled('img')<{ displayBorder?: boolean }>(({ displayBorder }) => ({
  height: '100%',
  width: '100%',
  objectFit: 'contain',
  border: displayBorder ? '1px solid #E0E0E0' : 'none',
  animation: 'loadingAnimation 600ms',
  '@keyframes loadingAnimation': {
    '0%': {
      background: `transparent url(${Loading}) no-repeat scroll center center `,
      width: '100%',
      backgroundSize: 20,
      height: 50,
    },
    '98%': {
      background: `transparent url(${Loading}) no-repeat scroll center center`,
      width: '100%',
      backgroundSize: 20,
      height: 50,
    },
    '100%': {
      background: 'none',
    },
  },
}));

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    background: theme.palette.common.white,
    borderRadius: 4,
    paddingTop: theme.spacing(1),
    boxShadow: '0 0 8px 4px rgba(0, 0, 0, 0.1)',
    width: 90,
  },
}))(Tooltip);

const TooltipImage = styled('img')({
  width: '100%',
  height: 'auto',
});

const FullscreenButton = styled(Box)({
  position: 'absolute',
  bottom: 4,
  right: 4,
  padding: 4,
  borderRadius: 4,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
});

const DeforestationImage: FC<IDeforestationImageProps> = ({
  headline,
  subHeadline,
  imageSrc,
  legendSrc,
  displayBorder,
  openFullScreen,
}) => {
  const { openDialog } = useDialog();

  const handleOpenImageDialog = () => {
    openDialog({
      type: 'RESULT_IMAGE',
      props: {
        imageSrc: imageSrc,
        legendSrc: legendSrc,
      },
    });
  };

  return (
    <StyledTooltip
      title={legendSrc ? <TooltipImage src={legendSrc} alt="Landcover legend" /> : null}
    >
      <Box>
        <ThemeTypography variant="LABEL_INPUT">{headline}</ThemeTypography>
        <Box mb={0.5} />
        <ImageContainer onClick={handleOpenImageDialog} hoverAction={!!openFullScreen}>
          {openFullScreen && (
            <FullscreenButton>
              <Icon name="focus" size="small" color="gray-20" />
            </FullscreenButton>
          )}
          <Box ml={1} my={0.5}>
            <ThemeTypography variant="BUTTON_SMALL" color="GRAY_60">
              {subHeadline}
            </ThemeTypography>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center" height={50} padding="2px">
            <Image src={imageSrc} alt="forest change" displayBorder={displayBorder} />
          </Box>
        </ImageContainer>
      </Box>
    </StyledTooltip>
  );
};

export default DeforestationImage;
