/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CellStyleFunc,
  ColDef,
  ColDefField,
  ICellRendererParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import React, { createElement } from 'react';
import { IRawMaterial } from 'types/component.types';
import {
  Farm,
  FarmColumns,
  ITableSettings,
  TableCellStatus,
  TableCellStatusType,
} from 'types/dataImport.types';
import { Coordinates } from 'types/types';
import { v4 as uuid } from 'uuid';
import ValidatedCellRenderer from '../DataImportTable/CellRenderers/ValidatedCellRenderer';
import { IHeaderColumnProps } from '../DataImportTable/HeaderColumn';
import TooltipComponent from '../DataImportTable/TooltipComponent';
import {
  CellValue,
  ColDefWithValidator,
  ColumnDefinition,
  ValidatorFunction,
} from '../DataImportTable/excelTable.types';
import { noValidation } from './dataImport.validators';
import { FirstMileImportRowInput } from 'graphql/mutations/types/imports-mutation.types';
import { RowDataFieldDefinition } from '../DataImportTable/ExcelTable';

export const transformDataForMutation = (
  data: FarmColumns & { id?: string },
  settings: ITableSettings,
  allRawMaterials: IRawMaterial[]
): FirstMileImportRowInput | null => {
  if (!data.farmId.value || !data.name.value || !data.crop.value) {
    return null;
  }

  const rawMaterialTitle = data.crop.value || '';
  const rawMaterialId = allRawMaterials.find(
    ({ title }) => title.toLowerCase() === rawMaterialTitle.toLowerCase()
  )?.id;

  const coordinates = data.location.value?.split(',').map(Number);
  const locationCoordinates: Coordinates =
    settings.coordinatesVersion === 'latlng'
      ? { lat: coordinates?.[0] || 0, lng: coordinates?.[1] || 0 }
      : { lat: coordinates?.[1] || 0, lng: coordinates?.[0] || 0 };

  let farmSize = undefined;
  if (data.farmSize.value) {
    farmSize =
      !settings?.farmSizeUnit || settings?.farmSizeUnit === 'km2'
        ? Number(data.farmSize.value)
        : Number(data.farmSize.value) / 100;
  }

  return {
    id: data.id,
    farmExternalId: data.farmId.value,
    siteTitle: data.name.value,
    rawMaterialIds: rawMaterialId ? [rawMaterialId] : [],
    locationCoordinates,
    farmDescription: data.description?.value,
    farmSize,
  };
};

/**
 * From the old data import
 * @deprecated
 */
export const transformDataForDisplay = (
  data: Farm,
  settings: ITableSettings,
  allRawMaterials: IRawMaterial[]
): FarmColumns => {
  const rawMaterialTitle = allRawMaterials.find(({ id }) => data.rawMaterialIds?.[0] === id)?.title;

  let coordinates = '';
  if (data.locationCoordinates?.lat && data.locationCoordinates?.lng) {
    coordinates =
      settings.coordinatesVersion === 'latlng'
        ? `${data.locationCoordinates.lat}, ${data.locationCoordinates.lng}`
        : `${data.locationCoordinates.lng}, ${data.locationCoordinates.lat}`;
  }

  let farmSize;
  if (data.farmSize) {
    farmSize = settings.farmSizeUnit === 'km2' ? data.farmSize : (data.farmSize ?? 0) * 100;
  }

  return {
    id: data.id || uuid(),
    farmId: { value: data.farmExternalId },
    name: { value: data.siteTitle, isValid: true },
    crop: { value: rawMaterialTitle },
    location: { value: coordinates },
    farmSize: { value: farmSize },
    description: { value: data.farmDescription, isValid: true },
  };
};

/**
 * @deprecated
 * This function does only work for the old data import type and is replaced by @function createEmptyRowByRowData
 */
export const getEmptyRow = (
  settings?: ITableSettings
): { id: string; farmId?: { value: string; isValid: boolean } } => ({
  id: uuid(),
  ...(settings?.autoAssignIds ? { farmId: { value: uuid(), isValid: true } } : {}),
});

export const createEmptyRow = (): { id: string } => ({
  id: uuid(),
});

export const isValueEmpty = (value: CellValue | undefined): boolean => {
  return value === '' || value === undefined || value === null;
};

export const isColumnDefinition = (
  obj: string | ColumnDefinition<CellValue> | undefined
): obj is ColumnDefinition<CellValue> => {
  return typeof obj !== 'string'; // If type is string, it must be internalId column - all other columns are ColumnDefinition
};

export const isRowEmpty = (row: RowDataFieldDefinition): boolean => {
  return (Object.values(row).filter(isColumnDefinition) as ColumnDefinition<CellValue>[]).every(
    cell => {
      return isValueEmpty(cell.value);
    }
  );
};

export const hasRowIssues = (row: RowDataFieldDefinition, forSave: boolean = false): boolean => {
  return (Object.values(row).filter(isColumnDefinition) as ColumnDefinition<CellValue>[]).every(
    ({ isValid }) => (forSave ? isValid : isValid === undefined || isValid)
  );
};

export const isRowCompletelyFilled = (
  row: RowDataFieldDefinition,
  columnDefs: ColDefWithValidator<any>[]
): boolean => {
  const requiredFields = columnDefs
    .filter(({ isRequired }) => isRequired)
    .map(({ field }) => field as ColDefField<any, any>);

  const filledFields = Object.entries(row)
    .filter(([key, columnValue]) => {
      // If columnValue is string, it must be internalId column - all other columns are ColumnDefinition
      if (!isColumnDefinition(columnValue)) return true;

      // Column is not required, so it can be empty
      if (!requiredFields.includes(key)) return true;
      // Column is required, check if it is filled
      return !!columnValue.value;
    })
    .map(([key]) => key);
  return requiredFields.every(field => filledFields.includes(field));
};

export const getRowStatus = (row: RowDataFieldDefinition): TableCellStatusType => {
  const cells = Object.values(row).filter(isColumnDefinition) as ColumnDefinition<CellValue>[];
  if (
    cells.some(
      ({ isValid, errorMessage, touched }) => isValid === false || (errorMessage && !touched)
    )
  ) {
    return TableCellStatus.ERROR;
  } else if (cells.some(({ errorMessage, touched }) => errorMessage && touched)) {
    return TableCellStatus.WARN;
  } else if (cells.every(({ isValid }) => isValid === true)) {
    return TableCellStatus.VALID;
  }
  return TableCellStatus.EMPTY;
};

const valueGetter = ({ data, column }: ValueGetterParams) => data[column.getId()]?.value;

const valueSetter =
  (validateFn: ValidatorFunction) =>
  ({ data, oldValue, column, newValue, context, api }: ValueSetterParams) => {
    // Do nothing if value is unchanged
    if (isValueEmpty(oldValue) ? isValueEmpty(newValue) : oldValue === newValue) return false;

    const columnKey = column.getColId();

    data[columnKey] = {
      ...data[columnKey],
      ...validateFn(newValue, [], context),
      value: newValue,
      touched: true,
    };

    // This interrupts with the native undo/redo behavior of ag-grid so avoid this
    // Not 100% why it was used here in the first place
    // api.applyTransaction({ update: [data] });
    // This should achieve the same result
    api.refreshCells({ rowNodes: [data], force: true, suppressFlash: true });

    return true;
  };

const cellStyleFunc: CellStyleFunc = ({ column, value, data, context }) => {
  const cell = data[column.getId()];
  const isValid = isRowEmpty(data) || cell?.isValid === undefined || cell?.isValid;
  const hasErrorMessage = cell?.errorMessage;
  const touched = cell?.touched;

  if (isValid && hasErrorMessage) {
    return {
      color: touched
        ? context.theme.custom.themeColors.accent[100]
        : context.theme.custom.themeColors.error[80],
      backgroundColor: touched
        ? context.theme.custom.themeColors.accent[10]
        : context.theme.custom.themeColors.error[20],
    };
  }

  return {
    color: isValid ? 'unset' : context.theme.custom.themeColors.error[80],
    backgroundColor: isValid ? 'unset' : context.theme.custom.themeColors.error[20],
  };
};

export const createColumnDefinition: <TData = any, TValue = any>(
  field: ColDefField<TData, TValue>,
  headerComponent: React.FC<IHeaderColumnProps>,
  validateFn?: ValidatorFunction,
  cellRenderer?: React.FC<ICellRendererParams>,
  additionalSettings?: Partial<
    Omit<
      ColDef,
      | 'field'
      | 'headerComponent'
      | 'cellRenderer'
      | 'valueGetter'
      | 'valueSetter'
      | 'tooltipComponent'
      | 'tooltipField'
      | 'cellStyle'
      | 'refreshCell'
    >
  >,
  // By default all columns are required
  isRequired?: boolean
) => ColDefWithValidator<TData, TValue> = (
  field,
  headerComponent,
  validateFn = noValidation,
  cellRenderer,
  additionalSettings,
  isRequired = true
) => {
  const CustomCellRenderer: React.FC<ICellRendererParams> = params => {
    if (cellRenderer) {
      return (
        <ValidatedCellRenderer {...params}>
          {createElement(cellRenderer, params)}
        </ValidatedCellRenderer>
      );
    }
    return <ValidatedCellRenderer {...params} />;
  };

  return {
    field,
    enableCellChangeFlash: true,
    headerComponent,
    cellRenderer: CustomCellRenderer,
    valueGetter,
    valueSetter: valueSetter(validateFn),
    tooltipComponent: TooltipComponent,
    tooltipField: field,
    cellStyle: cellStyleFunc,
    validator: validateFn,
    isRequired,
    ...additionalSettings,
  };
};
