import { Loader } from 'components/Forms';
import { ErrorState } from 'components/Structure';
import React, { FC, createContext, createElement, useContext } from 'react';
import { IDueDiligenceProcess } from 'types/compliance.types';
import {
  IDueDiligenceProcessSubSectionContextDefaultValues,
  TDueDiligenceProcessSubSection,
} from 'types/dueDilligenceProcess.types';
import { IDueDiligenceProcessContextValues } from '../DueDiligenceProcessContext';

interface ISummaryContextValues extends IDueDiligenceProcessSubSectionContextDefaultValues {
  dueDiligenceProcess: IDueDiligenceProcess;
}

const SummaryContext = createContext<ISummaryContextValues>({
  selectedSubSectionIndex: 0,
  selectedSubSection: {} as TDueDiligenceProcessSubSection,
  dueDiligenceProcess: {} as IDueDiligenceProcess,
  onNextClick: () => undefined,
  onPreviousClick: () => undefined,
});

const SummaryContextProvider: FC<IDueDiligenceProcessContextValues> = ({
  selectedSubSection,
  selectedSubSectionIndex,
  dueDiligenceProcess,
}) => {
  if (!selectedSubSection || selectedSubSectionIndex === undefined) {
    return <Loader />;
  }

  if (!dueDiligenceProcess) {
    return <ErrorState />;
  }

  const handleClickNext = () => {
    // TODO: Implement
  };

  const handleClickPrevious = () => {
    // TODO: Implement
  };

  const state = {
    selectedSubSection,
    selectedSubSectionIndex,
    dueDiligenceProcess,
    onNextClick: handleClickNext,
    onPreviousClick: handleClickPrevious,
  };

  return (
    <SummaryContext.Provider value={state}>
      {createElement(selectedSubSection.content)}
    </SummaryContext.Provider>
  );
};

const useSummaryContext = () => useContext(SummaryContext);

export { SummaryContextProvider, useSummaryContext };

export default SummaryContext;
