import { Box, styled } from '@mui/material';
import { ThemeTypography } from 'designSystem';
import React, { FC } from 'react';
import theme from 'styles/theme';
import ValidationIndicator, { ValidationState } from './ValidationIndicator';

export type NavigationItemState = 'selected' | 'disabled' | 'default';

interface INavigationSubItemProps {
  sectionIndex: number;
  index: number;
  title: string;
  state?: NavigationItemState;
  validationState?: ValidationState;
}

const Index = styled('div')(({ theme }) => ({
  background: theme.custom.themeColors.grayScale[30],
  borderRadius: '50%',
  width: theme.spacing(3),
  minWidth: theme.spacing(3),
  height: theme.spacing(3),
  fontSize: 12,
  lineHeight: 11,
  boxShadow: theme.custom.shadows[1],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 700,
  marginRight: theme.spacing(1),
}));

const MENU_ITEM_COLOR: Record<NavigationItemState, string> = {
  disabled: theme.palette.grey[500],
  selected: theme.palette.secondary.main,
  default: theme.palette.text.primary,
};

const StyledMenuItem = styled('div')<{ state: NavigationItemState }>(({ state, theme }) => ({
  position: 'relative',
  flexGrow: 1,
  opacity: state === 'disabled' ? 0.5 : 1,
  marginBottom: theme.spacing(1),
  color: MENU_ITEM_COLOR[state],
}));

const NavigationSubItem: FC<INavigationSubItemProps> = ({
  validationState = 'default',
  state = 'default',
  sectionIndex,
  index,
  title,
}) => {
  return (
    <StyledMenuItem state={state} data-cy={`menu-item-${index}`}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" overflow="hidden">
          {index !== undefined && (
            <Index>
              {sectionIndex + 1}.{index + 1}
            </Index>
          )}
          <ThemeTypography variant="BODY_LARGE" autoOverflow>
            {title}
          </ThemeTypography>
        </Box>
        <ValidationIndicator state={validationState} />
      </Box>
    </StyledMenuItem>
  );
};

export default NavigationSubItem;
