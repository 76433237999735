export enum EDueDiligenceHistoryTableColumnKeys {
  TITLE = 'title',
  QUANTITY = 'quantity',
  CHECK_DATE = 'checkDate',

  ACTIONS = 'actions',
}

export const DUE_DILIGENCE_PROCESSES = [
  {
    id: EDueDiligenceHistoryTableColumnKeys.TITLE,
    // columnKey: 'title',
    // headerLabel: 'Process',
    headerName: 'Process',
    sortable: false,
    width: 400,
  },
  {
    id: EDueDiligenceHistoryTableColumnKeys.QUANTITY,
    // columnKey: 'quantity',
    // headerLabel: 'Quantity (kg)',
    headerName: 'Quantity (kg)',
    sortable: false,
  },
  {
    id: EDueDiligenceHistoryTableColumnKeys.CHECK_DATE,
    //  columnKey: 'checkDate',
    // headerLabel: 'Check date',
    headerName: 'Check date',
    sortable: false,
  },
  {
    id: EDueDiligenceHistoryTableColumnKeys.ACTIONS,
    // columnKey: 'actions',
    // headerLabel: '',
    headerName: '',
    sortable: false,
  },
];
