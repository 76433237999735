import { relayStylePagination } from '@apollo/client/utilities';
import { getHomeTitle, getOverallStatus } from 'utils';

const queryPolicies = {
  Query: {
    fields: {
      documents: relayStylePagination(),
      sharedDocuments: relayStylePagination(),
      partners: relayStylePagination(),
      getVideos: relayStylePagination(),
      getImages: relayStylePagination(),
      payments: relayStylePagination(),
      datasets: relayStylePagination(['filters']),
      sites: relayStylePagination(['filters', 'siteViewOption']),
      cultivatedAreasConnection: relayStylePagination(),
      geoDataProcesses: relayStylePagination(),
      product: (existingData, { args, toReference }) => {
        return existingData || toReference({ __typename: 'Product', id: args.productId });
      },
      getTranscodingVideos: (_, { readField }) => {
        if (!readField('getVideos')) return [];
        const { edges } = readField('getVideos');
        const videos = edges.map(video => video.node);

        return videos.filter(ref => readField('isTranscoding', ref));
      },
      overallProductStatus: (_, { readField }) => {
        const originalProducts = readField({
          fieldName: 'products',
          args: {},
        });

        const { edges = [] } = originalProducts || {};

        const products = edges.map(({ node }) => {
          return {
            status: readField('status', node),
            contentStatus: readField('contentStatus', node),
          };
        });
        const status = getOverallStatus(products);

        return {
          status,
          homeTitle: status ? getHomeTitle(status) : '',
        };
      },
    },
  },
};

export default queryPolicies;
