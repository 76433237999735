import { GeoJsonLayer } from '@deck.gl/layers/typed';
import { Box, styled } from '@mui/material';
import { BBox2d } from '@turf/helpers/dist/js/lib/geojson';
import {
  Feature,
  FeatureCollection,
  Polygon,
  bbox,
  center,
  featureCollection,
  geometry,
  polygon,
} from '@turf/turf';
import { COLOR_PALETTE } from 'constants/colors';
import { ThemeTypography } from 'designSystem';
import CustomMap, {
  ICustomMapRef,
  IMapMarker,
  IMapPopup,
  MapMarkerClickEvent,
} from 'designSystem/Map/CustomMap';
import { calculateArea } from 'designSystem/Map/utils/map.utils';
import uniqBy from 'lodash/uniqBy';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ImageVariant } from 'types/media.types';
import { IBaseFarmSite, IFarmSite } from 'types/site.types';
import { convertHexToRGBarray } from 'utils';
import { v4 as uuid } from 'uuid';
import CultivationFarmAreaInfoPopup from './CultivationFarmAreaInfoPopup';
import { DatasetError } from 'types/dataset.types';
import { ICultivatedAreas } from 'types/cultivatedArea.types';

type ICultivationFarmAreaProps = (
  | { cultivatedAreas: ICultivatedAreas[] }
  | { farms: IFarmSite[] }
) & {
  /**
   * Shows the partner that own the cultivation areas
   */
  showOwner?: boolean;
  /**
   * Warnings that are shown in the info popup
   */
  warnings?: DatasetError[];
  warningSeverity?: 'error' | 'warning';
  onEditCultivatedAreaClick?: (farmId: string) => void;
};

export interface IFeatureProperties {
  id: string;
  color: string;
  title: string;
  name: string;
  /** in square km */
  areaSize: number;
  ownedBy?: {
    id: string;
    name: string;
    logo?: ImageVariant | null;
  };
  outputTitle?: string;
  // Center of this single cultivation area
  polygonCenter: [number, number];
  // Center of the multi polygon all cultivation areas
  multiPolygonCenter?: [number, number];
  // Hide the action button in the info popup
  hideActionButton?: boolean;
  /**
   * Warnings for the plots of the cultivation area
   */
  warnings?: DatasetError[];
  warningSeverity?: 'error' | 'warning';
}

const CultivatedAreasSize = styled(Box)(({ theme }) => ({
  position: 'absolute',
  background: theme.custom.themeColors.grayScale[20],
  padding: theme.spacing(1),
  borderRadius: theme.spacing(0.5),
  zIndex: 3,
  right: 46,
  top: 10,
}));

const CultivationFarmArea: FC<ICultivationFarmAreaProps> = ({
  showOwner,
  warnings,
  warningSeverity,
  onEditCultivatedAreaClick,
  ...props
}) => {
  const customMapRef = useRef<ICustomMapRef>(null);

  const [infoPopup, setInfoPopup] = useState<IMapPopup>();
  const [mapZoom, setMapZoom] = useState<number>();

  const { cultivatedAreas, farms } = useMemo(() => {
    return { farms: [], cultivatedAreas: [], ...props };
    // @ts-ignore For some reason there is a strange rendering issue using props as dependency and typescript is not happy using fields that may not exist
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cultivatedAreas, props.farms]);

  const handleEditCultivatedArea = (id: string) => {
    setInfoPopup(undefined);
    onEditCultivatedAreaClick?.(id);
  };

  const handleFeatureClick = useCallback(
    (featureProperties: IFeatureProperties) => {
      setInfoPopup(prev => {
        if (prev && prev.id === featureProperties.id) {
          return undefined;
        }
        return {
          id: featureProperties.id,
          coordinate: featureProperties.polygonCenter,
          content: (
            <CultivationFarmAreaInfoPopup
              showOwner={showOwner}
              infoItem={featureProperties}
              onEditClick={
                !featureProperties.hideActionButton && !!onEditCultivatedAreaClick
                  ? () => handleEditCultivatedArea(featureProperties.id)
                  : undefined
              }
            />
          ),
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [infoPopup, setInfoPopup]
  );

  const handlePopupClose = useCallback(() => setInfoPopup(() => undefined), [setInfoPopup]);

  /**
   * Unique farms of the cultivation areas
   */
  const uniqueFarms = useMemo(
    () =>
      farms.length
        ? farms
        : uniqBy(
            cultivatedAreas.map(({ farm }) => farm),
            'id'
          ),
    [farms, cultivatedAreas]
  );

  /**
   * Unique farm ids of the cultivation areas
   */
  const farmIds = useMemo(() => uniqueFarms.map(({ id }) => id), [uniqueFarms]);

  /**
   * This feature collections contains all the cultivation areas of the farm as single polygons with their properties
   * To be able to show the info popup when clicking on the cultivation area on a single polygon we need to have the center of each polygon
   * To be able to show centered markers of the one cultivation area we need to have the center of the multi polygon
   */
  const polygonFeatures: FeatureCollection<Polygon, IFeatureProperties> = useMemo(() => {
    if (cultivatedAreas.length) {
      const features: Feature<Polygon, IFeatureProperties>[] = cultivatedAreas
        .map(({ farmId, farm: { title, locationName, rawMaterial, ownedBy }, coordinates }) => {
          if (coordinates?.length) {
            const multiPolygonGeometry = geometry('Polygon', coordinates);
            const warningsOfFarm = warnings?.filter(issue => issue.entityId === farmId);
            return coordinates.map(singlePolygon => {
              const polygonGeometry = geometry('Polygon', [singlePolygon]);
              return polygon<IFeatureProperties>(
                [singlePolygon],
                {
                  id: farmId,
                  // Use same color for all cultivation areas of the same farm
                  color:
                    COLOR_PALETTE[
                      farmIds.findIndex(_farmId => farmId === _farmId) % COLOR_PALETTE.length
                    ],
                  name: locationName,
                  title: title,
                  outputTitle: rawMaterial?.title,
                  ownedBy: ownedBy,
                  areaSize: calculateArea(polygonGeometry),
                  multiPolygonCenter: center(multiPolygonGeometry).geometry.coordinates as [
                    number,
                    number
                  ],
                  warnings: warningsOfFarm,
                  warningSeverity,
                  polygonCenter: center(polygonGeometry).geometry.coordinates as [number, number],
                },
                { id: uuid() }
              );
            });
          }
          return [];
        })
        .flat();

      return featureCollection(features);
    } else {
      const features: Feature<Polygon, IFeatureProperties>[] = farms
        .map(({ id, title, locationName, rawMaterial, ownedBy, cultivatedAreas }) => {
          if (cultivatedAreas?.length && cultivatedAreas[0].coordinates) {
            const multiPolygonGeometry = geometry('Polygon', cultivatedAreas[0].coordinates);
            const warningsOfFarm = warnings?.filter(issue => issue.entityId === id);
            return cultivatedAreas[0].coordinates.map(singlePolygon => {
              const polygonGeometry = geometry('Polygon', [singlePolygon]);
              return polygon<IFeatureProperties>(
                [singlePolygon],
                {
                  id,
                  // Use same color for all cultivation areas of the same farm
                  color:
                    COLOR_PALETTE[
                      farmIds.findIndex(farmId => farmId === id) % COLOR_PALETTE.length
                    ],
                  name: locationName,
                  title: title,
                  outputTitle: rawMaterial?.title,
                  ownedBy: ownedBy,
                  areaSize: calculateArea(polygonGeometry),
                  multiPolygonCenter: center(multiPolygonGeometry).geometry.coordinates as [
                    number,
                    number
                  ],
                  warnings: warningsOfFarm,
                  warningSeverity,
                  polygonCenter: center(polygonGeometry).geometry.coordinates as [number, number],
                },
                { id: uuid() }
              );
            });
          }
          return [];
        })
        .flat();

      return featureCollection(features);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farms, cultivatedAreas, farmIds]);

  const polygonLayer = useMemo(
    () =>
      new GeoJsonLayer<IFeatureProperties>({
        id: 'polygons',
        data: polygonFeatures,
        getLineWidth: 4,
        pickable: true,
        getFillColor: feature => convertHexToRGBarray(feature.properties?.color, 100),
        getLineColor: feature => convertHexToRGBarray(feature.properties?.color, 255),
        onClick: info => handleFeatureClick(info.object.properties),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [polygonFeatures]
  );

  const pointFeatures: IMapMarker[] = useMemo(() => {
    return cultivatedAreas.length
      ? cultivatedAreas
          .filter(({ centerPoint, coordinates }) => !coordinates && centerPoint)
          .map(({ centerPoint, farmId }) => ({
            id: farmId,
            coordinate: [centerPoint?.lng || 0, centerPoint?.lat || 0],
            options: {
              customIcon: 'map-marker',
              // Use same color for all cultivation areas of the same farm
              color:
                COLOR_PALETTE[
                  farmIds.findIndex(_farmId => farmId === _farmId) % COLOR_PALETTE.length
                ],
            },
          }))
      : farms
          .filter(
            ({ cultivatedAreas }) =>
              cultivatedAreas?.length &&
              !cultivatedAreas[0].coordinates &&
              cultivatedAreas[0].centerPoint
          )
          .map(({ id, cultivatedAreas }) => ({
            id,
            coordinate: [
              cultivatedAreas?.[0].centerPoint?.lng || 0,
              cultivatedAreas?.[0].centerPoint?.lat || 0,
            ],
            options: {
              customIcon: 'map-marker',
              // Use same color for all cultivation areas of the same farm
              color:
                COLOR_PALETTE[farmIds.findIndex(farmId => id === farmId) % COLOR_PALETTE.length],
            },
          }));
  }, [cultivatedAreas, farms, farmIds]);

  const handleMarkerClick = useCallback(
    (event: MapMarkerClickEvent) => {
      const farm: IBaseFarmSite | undefined = uniqueFarms.find(farm => farm.id === event.id);
      if (!farm) {
        return;
      }
      const polygon = polygonFeatures.features.find(polygon => polygon.properties.id === farm.id);
      if (polygon) {
        customMapRef.current?.customFitBounds(bbox(polygon) as BBox2d);
      }
      const warningsOfFarm = warnings?.filter(issue => issue.entityId === farm.id);
      handleFeatureClick({
        id: farm.id,
        title: farm.title,
        color: '', // Actually not needed since the color is not used in the marker
        name: farm.locationName,
        areaSize: farm.size,
        ownedBy: farm.ownedBy,
        outputTitle: farm.rawMaterial?.title,
        polygonCenter: event.coordinate,
        warnings: warningsOfFarm,
        warningSeverity,
        // Hide edit cultivation area button if there are no cultivation areas (polygons) for that farm
        hideActionButton: !polygon || !onEditCultivatedAreaClick,
      } as IFeatureProperties);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uniqueFarms]
  );

  /**
   * Markers are only shown when the zoom level is higher than 10 to identify the cultivation areas if the world is zoomed out
   */
  const markers: IMapMarker[] = useMemo(
    () =>
      mapZoom && mapZoom < 12
        ? polygonFeatures.features.reduce((prev, feature) => {
            const coordinate = feature.properties.multiPolygonCenter;
            if (!coordinate) {
              return prev;
            }
            // If marker for that cultivation area was already added, don't add it again
            if (prev.findIndex(prevMarker => prevMarker.coordinate === coordinate) !== -1) {
              return prev;
            }
            return [
              ...prev,
              {
                id: feature.properties.id,
                coordinate,
                options: {
                  customIcon: 'map-marker',
                  color: feature.properties.color,
                  focusOnClick: false,
                },
              },
            ];
          }, [] as IMapMarker[])
        : [],
    [polygonFeatures, mapZoom]
  );

  const totalAreaSize = useMemo(() => calculateArea(polygonFeatures), [polygonFeatures]);

  const numberOfPlots = useMemo(
    () => polygonFeatures.features.length + pointFeatures.length,
    [polygonFeatures, pointFeatures]
  );

  useEffect(() => {
    customMapRef.current?.centerFeatures();
    setInfoPopup(undefined); // Close the info popup when data changed
  }, [customMapRef, pointFeatures, pointFeatures]);

  return (
    <Box width="100%" height="100%" position="relative" borderRadius={6}>
      <CultivatedAreasSize>
        <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
          Total area
        </ThemeTypography>
        <ThemeTypography variant="BODY_SMALL">{totalAreaSize} km²</ThemeTypography>
        {numberOfPlots >= 1 && (
          <Box mt={1}>
            <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
              Nr of plots
            </ThemeTypography>
            <ThemeTypography variant="BODY_SMALL">{numberOfPlots}</ThemeTypography>
          </Box>
        )}
      </CultivatedAreasSize>
      <CustomMap
        customRef={customMapRef}
        markers={[...markers, ...pointFeatures]}
        layers={[polygonLayer]}
        infoPopup={infoPopup}
        mapStyle="satellite"
        style={{
          minHeight: '350px',
          height: '100%',
          width: '100%',
          borderRadius: 6,
        }}
        config={{
          enableMapStyleToggle: true,
          enableCenterButton: true,
        }}
        onZoom={event => setMapZoom(event.viewState.zoom)}
        onMapLoad={map => setMapZoom(map.getZoom())}
        onMarkerPopupClose={handlePopupClose}
        onMarkerClick={handleMarkerClick}
      />
    </Box>
  );
};

export default CultivationFarmArea;
