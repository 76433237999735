import { Box, styled } from '@mui/material';
import RiskLabel from 'assets/img/compliance/risk-label-example.svg';
import PieChart from 'assets/img/compliance/risk-pie-chart-example.svg';
import DueDiligenceProcessSubSectionNavigation from 'components/DueDiligenceProcess/DueDiligenceProcessSubSectionNavigation';
import { FlexBox } from 'components/Structure';
import DatasetSkeleton from 'designSystem/DataDisplay/DatasetSkeleton/DatasetSkeleton';
import React, { FC, createContext, createElement, useContext, useLayoutEffect } from 'react';
import { EDueDiligenceProcessStatus } from 'types/compliance.types';
import {
  EDueDiligenceProcessSubSectionKey,
  IDueDiligenceProcessSubSectionContextDefaultValues,
  TDueDiligenceProcessSubSection,
} from 'types/dueDilligenceProcess.types';
import { IDueDiligenceProcessContextValues } from '../DueDiligenceProcessContext';

type IRiskAnalysisContextValues = IDueDiligenceProcessSubSectionContextDefaultValues;

const Container = styled(Box)(({ theme }) => ({
  background: 'white',
  padding: theme.spacing(3),
  border: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
  borderRadius: 6,
  width: 'fit-content',
  marginTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const Label = styled('img')(({ theme }) => ({
  width: 450,
}));

const PieChartSkeleton = styled('img')(({ theme }) => ({
  width: 260,
}));

const RiskAnalysisContext = createContext<IRiskAnalysisContextValues>({
  selectedSubSectionIndex: 0,
  selectedSubSection: {} as TDueDiligenceProcessSubSection,
  onNextClick: () => undefined,
  onPreviousClick: () => undefined,
});

const RiskAnalysisContextProvider: FC<IDueDiligenceProcessContextValues> = ({
  selectedSubSection,
  selectedSubSectionIndex,
  dueDiligenceProcess,
  setSelectedSubSection,
  navigateToPreviousSection,
  navigateToNextSection,
}) => {
  /**
   * Set initial selected sub section based on the due diligence process
   */
  useLayoutEffect(() => {
    if (
      dueDiligenceProcess &&
      dueDiligenceProcess.status !== EDueDiligenceProcessStatus.COLLECTING_GEO_DATA
    ) {
      if (
        dueDiligenceProcess.status === EDueDiligenceProcessStatus.COMPLETED_RISK_ANALYSIS ||
        dueDiligenceProcess.status === EDueDiligenceProcessStatus.PENDING_RISK_ANALYSIS
      ) {
        setSelectedSubSection(EDueDiligenceProcessSubSectionKey.RESULTS);
      } else {
        setSelectedSubSection(EDueDiligenceProcessSubSectionKey.BATCHES);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!selectedSubSection || selectedSubSectionIndex === undefined) {
    return (
      <>
        <DueDiligenceProcessSubSectionNavigation
          title="Deforestation risk analysis"
          description="Once you have completed a deforestation risk analysis for this process, you will be able to overview detailed results here. "
          allowNextStepNavigation={false}
          onPreviousStepClick={navigateToPreviousSection}
        >
          <FlexBox gap={2}>
            <DatasetSkeleton active />
            <DatasetSkeleton />
            <DatasetSkeleton />
          </FlexBox>
        </DueDiligenceProcessSubSectionNavigation>
        <Container>
          <Label src={RiskLabel} alt="Deforestation risk label" />
          <PieChartSkeleton src={PieChart} alt="Pie chart of deforestation risks" />
        </Container>
      </>
    );
  }

  const handleClickNext = () => {
    switch (selectedSubSection.key) {
      case EDueDiligenceProcessSubSectionKey.BATCHES:
        setSelectedSubSection(EDueDiligenceProcessSubSectionKey.RESULTS);
        break;
      case EDueDiligenceProcessSubSectionKey.RESULTS:
        navigateToNextSection();
        break;
      default:
        console.error('Unknown sub section key', selectedSubSection.key);
        break;
    }
  };

  const handleClickPrevious = () => {
    switch (selectedSubSection.key) {
      case EDueDiligenceProcessSubSectionKey.BATCHES:
        navigateToPreviousSection();
        break;
      case EDueDiligenceProcessSubSectionKey.RESULTS:
        // This should not be possible, since you can not navigate back from the results
        break;
      default:
        console.error('Unknown sub section key', selectedSubSection.key);
        break;
    }
  };

  const state = {
    selectedSubSection,
    selectedSubSectionIndex,
    dueDiligenceProcess,
    onNextClick: handleClickNext,
    onPreviousClick: handleClickPrevious,
  };

  return (
    <RiskAnalysisContext.Provider value={state}>
      {createElement(selectedSubSection.content)}
    </RiskAnalysisContext.Provider>
  );
};
const useRiskAnalysis = () => useContext(RiskAnalysisContext);

export { RiskAnalysisContextProvider, useRiskAnalysis };

export default RiskAnalysisContext;
