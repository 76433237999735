import { useQuery } from '@apollo/client';
import { GET_DATASET, GET_DATASET_TABLE_REPRESENTATION } from 'graphql/queries/dataset.queries';
import { GET_BASE_PARTNER_COMPANY } from 'graphql/queries/partner.queries';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { EDatasetStatus, IDataset } from 'types/dataset.types';
import { IBasePartnerCompany } from 'types/partner.types';

const useDatasetOverview = () => {
  // In the partner library the partnerId is set
  const { id: partnerId, datasetId } = useParams<{ id?: string; datasetId?: string }>();

  const { data, loading, error, refetch } = useQuery<{ dataset: IDataset }>(GET_DATASET, {
    variables: { id: datasetId },
    skip: !datasetId,
  });

  /**
   * Query the table representation of the dataset only if needed
   * If should write the query for the table representation in the cache
   * Note: This improves the performance of the application
   */
  const { data: tableRepresentationData, loading: tableRepresentationLoading } = useQuery<{
    dataset: IDataset;
  }>(GET_DATASET_TABLE_REPRESENTATION, {
    variables: { id: datasetId },
    skip:
      !datasetId ||
      !data?.dataset ||
      (data.dataset.status !== EDatasetStatus.VALIDATED &&
        data.dataset.status !== EDatasetStatus.VALIDATION_FAILED),
  });

  /**
   * Merge the two queries together
   */
  const dataset = useMemo(() => {
    if (data && tableRepresentationData) {
      return { ...data.dataset, ...tableRepresentationData.dataset };
    }
    return data?.dataset;
  }, [data, tableRepresentationData]);

  const { data: partnerData, loading: partnerLoading } = useQuery<{
    partner: IBasePartnerCompany;
  }>(GET_BASE_PARTNER_COMPANY, {
    variables: {
      id: partnerId,
    },
    skip: !!datasetId || !partnerId,
  });

  const isDatasetCreation = !datasetId;

  return {
    dataset,
    partner: partnerData?.partner,
    loading: partnerLoading || loading || tableRepresentationLoading,
    error,
    isDatasetCreation,
    isPartnerLibrary: !!partnerId,
    partnerId,
    refetch,
  };
};

export default useDatasetOverview;
