import { useQuery } from '@apollo/client';
import { Box, Grid, styled } from '@mui/material';
import { useDueDiligenceProcess } from 'components/DueDiligenceProcess/Context/DueDiligenceProcessContext';
import DueDiligenceProcessSubSectionNavigation from 'components/DueDiligenceProcess/DueDiligenceProcessSubSectionNavigation';
import { Loader } from 'components/Forms';
import { useFeatureFlags } from 'components/hooks';
import { FeatureFlag } from 'components/hooks/useFeatureFlags';
import { ErrorState, PageSubTitle } from 'components/Structure';
import { ThemeTypography } from 'designSystem';
import DatasetItem from 'designSystem/DataDisplay/DatasetItem/DatasetItem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { GET_GEOC_PARCELS } from 'graphql/queries/dueDiligence.queries';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  EDueDiligenceProcessStatus,
  ERiskLevel,
  GeoCBatch,
  GeocParcelConnection,
} from 'types/compliance.types';
import { RouteParamsWithId } from 'types/router.types';
import BatchResults from '../BatchResults';
import BatchSelection from '../BatchSelection';
import RiskAccordion from '../RiskAccordion';
import HorizontalScrollContainer from 'designSystem/Layout/HorizontalScrollContainer/HorizontalScrollContainer';
import RiskAnalysisAccordion from '../RiskAnalysisAccordion';
import BatchResultTable from '../BatchResultTable';

const DataSheetContainer = styled('div')(({ theme }) => ({
  cursor: 'pointer',
}));

const HintContainer = styled('div')(({ theme }) => ({
  background: theme.custom.themeColors.primary[5],
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1),
  height: 'fit-content',
  border: `1px solid ${theme.custom.themeColors.primary[40]}`,
}));

const SuccessContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  background: theme.custom.themeColors.success[20],
  borderRadius: '50px',
  padding: theme.spacing(1),
  width: 'fit-content',
}));

const RiskAnalysisResults: FC = () => {
  const { id: paramId } = useParams<RouteParamsWithId>();
  const [selectedDatasetId, setSelectedDatasetId] = useState<string>();
  const [selectedBatch, setSelectedBatch] = useState<GeoCBatch>();
  const { isFeatureEnabled } = useFeatureFlags();
  const disabledSectionAfterStep3 = isFeatureEnabled(FeatureFlag.DISABLE_EUDR_GUIDE_AFTER_STEP3);
  const { dueDiligenceProcess } = useDueDiligenceProcess();
  const navigate = useNavigate();

  const {
    data: parcelsData,
    loading,
    error,
  } = useQuery<{ parcels: GeocParcelConnection }>(GET_GEOC_PARCELS, {
    variables: { filters: { batchId: selectedBatch?.id, geoDataProcessId: paramId } }, // Pass all batch
    skip: !paramId || !selectedBatch?.id,
  });

  const parcels = useMemo(() => parcelsData?.parcels?.edges || [], [parcelsData]);

  useEffect(() => {
    if (!!dueDiligenceProcess?.datasets.length) {
      setSelectedDatasetId(dueDiligenceProcess.datasets[0].id);
    }
  }, [dueDiligenceProcess]);

  useEffect(() => {
    if (
      !dueDiligenceProcess ||
      dueDiligenceProcess.status === EDueDiligenceProcessStatus.PENDING_RISK_ANALYSIS
    ) {
      return;
    }
    const selectedDataset = dueDiligenceProcess.datasets.find(({ id }) => id === selectedDatasetId);
    if (selectedDataset && selectedDataset.geocBatches?.length) {
      setSelectedBatch(selectedDataset.geocBatches[0]);
    }
  }, [dueDiligenceProcess, selectedDatasetId]);

  if (!dueDiligenceProcess) {
    return <ErrorState />;
  }

  const handleClickDataSheet = (id: string) => {
    setSelectedDatasetId(id);
  };

  const handleBatchChange = (batch: GeoCBatch) => {
    setSelectedBatch(batch);
  };

  const handleNavigateToFarm = (id: string) => {
    navigate(`/sites/external/${id}`);
  };

  // Analysis in progress
  if (dueDiligenceProcess.status === EDueDiligenceProcessStatus.PENDING_RISK_ANALYSIS) {
    return (
      <DueDiligenceProcessSubSectionNavigation
        title="Deforestation risk analysis in progress"
        description="Your geo-data is now going through a detailed remote sensing deforestation risk analysis, checking satellite imagery over time and evaluating the changes in vegetation for the specified regions and plots submitted in your geo-data. "
        titleLoading
        disableNavigation
      >
        <HintContainer>
          <ThemeTypography variant="BODY_MEDIUM">
            The analysis can take up to 24 hours. We will notify you in the application and by email
            when the analysis is completed.
          </ThemeTypography>
        </HintContainer>

        <Box mt={2} mb={1}>
          <PageSubTitle title="Included geo-data" />
        </Box>

        <HorizontalScrollContainer>
          <Box display="flex" gap={2}>
            {dueDiligenceProcess.datasets.map((dataset, index) => (
              <DatasetItem
                key={index}
                title={dataset.title}
                location={dataset.originCountry}
                owner={dataset.ownedBy.name}
                commodity={dataset.rawMaterial.title}
                checked
                disabled
              />
            ))}
          </Box>
        </HorizontalScrollContainer>
      </DueDiligenceProcessSubSectionNavigation>
    );
  }

  return (
    <>
      <DueDiligenceProcessSubSectionNavigation
        disabledPreviousNavigation
        allowNextStepNavigation={!disabledSectionAfterStep3}
      >
        <Box display="flex" flexDirection="column" gap={2} mb={2}>
          <SuccessContainer>
            <Icon name="impact" color="green" />
            <ThemeTypography variant="BODY_LARGE_BOLD" color="GREEN">
              Your analysis have been saved and results can also be viewed in the compliance
              overview and partner overview.
            </ThemeTypography>
          </SuccessContainer>
          <ThemeTypography variant="BODY_MEDIUM">
            You can overview the results here for each origin geo-dataset and each plot. If you have
            a medium or high risk for any of the origin geo-datasets, you might need to perform some
            risk mitigation procedures in the next steps.
          </ThemeTypography>
        </Box>
        <RiskAnalysisAccordion />
        <Box display="flex" gap={2} mt={2} flexWrap="wrap">
          {dueDiligenceProcess.datasets.map((dataSheet, index) => (
            <DataSheetContainer key={index} onClick={() => handleClickDataSheet(dataSheet.id)}>
              <DatasetItem
                title={dataSheet.title}
                location={dataSheet.originCountry}
                active={dataSheet?.id === selectedDatasetId}
                owner={dataSheet.ownedBy.name}
                commodity={dataSheet.rawMaterial.title}
                riskLevel={ERiskLevel[dataSheet.riskStatus]}
              />
            </DataSheetContainer>
          ))}
        </Box>
      </DueDiligenceProcessSubSectionNavigation>

      <Box mt={2}>
        <BatchResultTable
          batches={
            dueDiligenceProcess.datasets.find(({ id }) => id === selectedDatasetId)?.geocBatches ||
            []
          }
        />
      </Box>
      <Box my={3}>
        {selectedBatch && (
          <BatchSelection
            batches={
              dueDiligenceProcess.datasets.find(({ id }) => id === selectedDatasetId)
                ?.geocBatches || []
            }
            selectedBatch={selectedBatch}
            onSelectBatch={handleBatchChange}
          />
        )}
      </Box>

      <Grid container spacing={2}>
        {!parcelsData ? (
          <Box position="relative" width="100%" mt={6}>
            {error && <ErrorState />}
            {loading && <Loader />}
          </Box>
        ) : (
          <>
            <Grid item xs={6}>
              <PageSubTitle title="Batch risk results" />
              <Box mt={0.5} />
              <BatchResults
                highRisks={parcelsData.parcels?.highRisks}
                mediumRisks={parcelsData.parcels?.mediumRisks}
                lowRisks={parcelsData.parcels?.lowRisks}
              />
            </Grid>
            <Grid item xs={6}>
              {/* Todo: add plot map */}
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <PageSubTitle title="Plot results" />
                <Box mx={1}>
                  <Icon name="farm" size="large" />
                </Box>
                <ThemeTypography variant="BODY_SMALL" color="ALMOST_BLACK">
                  {parcelsData.parcels?.count} plots
                </ThemeTypography>
              </Box>
              <Box mt={0.5} />
              {parcelsData.parcels?.count > 0 &&
                parcels.map(({ node }) => (
                  <Box key={node.id} mb={1}>
                    <RiskAccordion
                      parcel={node}
                      onOpenFarmClick={() => handleNavigateToFarm(node.cultivatedAreas.farm.id)}
                    />
                  </Box>
                ))}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default RiskAnalysisResults;
