import { ReactourStep } from 'reactour';
import { ImageVariant, WidgetType } from './media.types';

export enum CustomerPlan {
  'INTERNAL' = 'INTERNAL',
  'PAYING' = 'PAYING',
  'FREEMIUM' = 'FREEMIUM',
  'TRIAL' = 'TRIAL',
  'INACTIVE' = 'INACTIVE',
}

export enum CompanyStatus {
  INVITED = 'INVITED',
  NOT_INVITED = 'NOT_INVITED',
  REGISTERED = 'REGISTERED',
}

export enum BasicLogAction {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
}

export enum CompanyFeature {
  FEEDBACK = 'FEEDBACK',
  CERTIFICATES = 'CERTIFICATES',
  WHITE_LABELING = 'WHITE_LABELING',
  VERIFIED_PAYMENTS = 'VERIFIED_PAYMENTS',
  COMPLIANCE = 'COMPLIANCE',
  PAYMENTS = 'PAYMENTS',
}

export type CustomerPlanType = `${CustomerPlan}`;

export type CompanyStatusType = `${CompanyStatus}`;

export type BasicLogActionType = `${BasicLogAction}`;

export type CompanyFeatureType = `${CompanyFeature}`;

export interface CompanyLog {
  id: string;
  comment: string;
  userEmail: string;
  user: User;
  company: ICompany;
  timestamp: Date;
  action: BasicLogActionType;
}

export interface CompanyPlan {
  name: string;
  expired: boolean;
  upgradeRequested: boolean;
  expirationDate?: string | null;
  daysLeftInTrial: number;
  customerPlanId: string;
  planType: CustomerPlanType;
  features: CompanyFeatureType[];
}

/**
 * This base type needs to match exactly the fields of the @IBasePartnerCompany
 */
export interface IBaseCompany {
  id: string;
  name: string;
  logo?: ImageVariant;
  modifiedTimestamp: Date;
  createdTimestamp: Date;
  status: CompanyStatusType;
  externalId?: string;
}

export interface ICompany extends IBaseCompany {
  sitesCount: number;
  activitiesCount: number;
  description: string;
  website: string;
  onlineShop?: string;
  address?: Address;
  planDetails?: CompanyPlan;
  design?: CompanyDesign;
  integrationSettings?: IntegrationSettings;
  dataValid: boolean;
  apiKey: string;
  gaTrackingId: string;
  social: SocialSettings;
}

export interface IntegrationSettings {
  showClaims: boolean;
  widgetType: WidgetType;
}

export interface Address {
  street: string;
  streetNumber: string;
  zipCode: string;
  city: string;
  country: string;
}
export interface CompanyDesign {
  typography: CompanyTypography;
  palette: CompanyPalette;
}

export interface CompanyTypography {
  header: string;
  body: string;
}

export interface CompanyPalette {
  primary: string;
  secondary: string;
}

export interface SocialSettings {
  twitter: string | null;
  facebook: string | null;
  instagram: string | null;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  role: string;
  invitedTimestamp: string | null;
  isRegistered: boolean;
  onboarding: {
    sawWelcomeSlides: boolean;
    sawEditorTour: boolean;
    sawComponentChainTour: boolean;
    sawSupplyChainTour: boolean;
    sawDataImportTour: boolean;
  };
  latestTermsAccepted: boolean;
  notifications: {
    weeklyRoundup: boolean;
  };
  isAdmin: boolean;
}

export interface OnboardingTour {
  tourSteps: ReactourStep[];
  amplitudeEvent: string;
  onCompleted: () => null | void;
  firstStepButtonText: string;
}
