/* eslint-disable @typescript-eslint/no-explicit-any */
import { styled, useTheme } from '@mui/material/styles';
import {
  CellContextMenuEvent,
  CellPosition,
  CellValueChangedEvent,
  ColDef,
  CsvExportParams,
  GridApi,
  GridReadyEvent,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { usePasteHandler } from 'components/hooks';
import useCopyHandler from 'components/hooks/useCopyHandler';
import useCutHandler from 'components/hooks/useCutHandler';
import { FlexBox } from 'components/Structure';
import { ThemeButton, ThemeTypography } from 'designSystem';
import React, {
  ForwardedRef,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { getOperatingSystem } from 'utils/browser.utils';
import copyTextToClipboard from 'utils/clipboard.utils';
import { v4 as uuid } from 'uuid';
import {
  DATA_IMPORT_TABLE_ROW_HEIGHT,
  DEFAULT_COL_DEF_EDIT,
  DEFAULT_COL_DEF_VIEW,
} from '../constants/dataImport.constants';
import {
  createEmptyRow,
  getEmptyRow,
  isColumnDefinition,
  isRowEmpty,
  isValueEmpty,
} from '../utils/dataImport.utils';
import DELETE_COLUMN from './ColumDefinitions/DeleteColumn';
import VALIDATION_COLUMN from './ColumDefinitions/ValidationColumn';
import {
  CellValue,
  ColDefWithValidator,
  ColumnDefinition,
  IExcelTableContext,
  SYSTEM_COLUMN_KEYS,
} from './excelTable.types';

export interface IGridApiHandle<TRowData extends RowDataFieldDefinition = any> {
  getApi: () => GridApi<TRowData> | null;
  runValidations: () => boolean;
  getColumnKeys: (params?: { removeFirstAndLastColumn?: boolean }) => string[];
  getDataAsCsv: (params?: CsvExportParams & { removeFirstAndLastColumn?: boolean }) => string;
}

export type RowDataFieldDefinition = {
  id: string;
  [key: string]: ColumnDefinition<CellValue> | undefined | string;
};
export interface IExcelTableProps<TRowData extends RowDataFieldDefinition, ContextValues> {
  gridRef?: ForwardedRef<IGridApiHandle<TRowData>>;
  rowData: TRowData[];
  columnDefs: ColDefWithValidator<TRowData>[];
  additionalContextValues?: ContextValues;
  height?: number | string;
  mode: 'view' | 'edit' | 'add-edit' | 'validation';
  /**
   * In those columns validation will be triggered on every change for the whole column
   * @default Validate on all changes
   */
  columnValidationOnEveryChange?: string[];
  onGridApiReady?: (api: GridApi<TRowData>) => void;
}

const Container = styled('div')<{ editMode: boolean }>(({ theme, editMode }) => ({
  '--ag-grid-size': '5px !important',
  '--ag-border-color': `${theme.custom.themeColors.grayScale[20]} !important`,
  '--ag-cell-horizontal-border': `1px solid ${theme.custom.themeColors.grayScale[20]} !important`,
  '--ag-row-border-color': `${theme.custom.themeColors.grayScale[20]} !important`,
  '--ag-header-background-color': `${theme.custom.themeColors.grayScale[10]} !important`,
  '--ag-header-column-separator-display': 'block !important',
  '--ag-header-column-separator-color': `${theme.custom.themeColors.grayScale[20]} !important`,
  '--ag-header-height': `${DATA_IMPORT_TABLE_ROW_HEIGHT}px !important`,
  '--ag-row-height': `${DATA_IMPORT_TABLE_ROW_HEIGHT}px !important`,
  '--ag-font-size': '12px !important',
  '--ag-cell-horizontal-padding': '0 !important',
  '--ag-borders-critical': '1px solid',
  '--ag-data-color': `${
    editMode ? theme.custom.themeColors.black : theme.custom.themeColors.grayScale[60]
  } !important`,
  '--ag-header-foreground-color': `${
    editMode ? theme.custom.themeColors.black : theme.custom.themeColors.grayScale[60]
  } !important`,
  '--ag-value-change-value-highlight-background-color': `${theme.custom.themeColors.primary[5]} !important`,

  '& .ag-center-cols-container': {
    marginRight: '0px !important',
  },

  '& .ag-root-wrapper': {
    borderRadius: 6,
    fontSize: 12,
  },

  '& .ag-pinned-right-cols-container': {
    marginRight: '0px !important',
  },

  '& .ag-pinned-left-cols-container .ag-cell, & .ag-pinned-right-cols-container .ag-cell': {
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  },

  '& .ag-cell > div:not(.ag-cell-edit-wrapper):not(.MuiFormControl-root), & .ag-header-cell-comp-wrapper':
    {
      padding: '0 8px',
    },
}));

const ExcelTable: <RowData extends RowDataFieldDefinition, ContextValues = unknown>(
  props: IExcelTableProps<RowData, ContextValues>
) => ReactElement = ({
  gridRef,
  rowData,
  columnDefs,
  additionalContextValues,
  height = 500,
  mode = 'view',
  columnValidationOnEveryChange,
  onGridApiReady,
}) => {
  const gridApiRef = useRef<GridApi | null>(null);
  const inputRowsRef = useRef<HTMLInputElement | null>(null);

  const colDefs = useMemo<ColDefWithValidator<any>[]>(() => columnDefs, [columnDefs]);

  const colDefsWithSystemColumns = useMemo<ColDefWithValidator<any>[]>(
    () =>
      mode === 'edit' || mode === 'add-edit'
        ? [VALIDATION_COLUMN, ...colDefs, DELETE_COLUMN]
        : mode === 'validation'
        ? [VALIDATION_COLUMN, ...colDefs]
        : colDefs,
    [colDefs, mode]
  );
  const defaultColDef = useMemo<ColDef>(
    () => (mode === 'edit' || mode === 'add-edit' ? DEFAULT_COL_DEF_EDIT : DEFAULT_COL_DEF_VIEW),
    [mode]
  );

  const theme = useTheme();

  // + Additional context values
  const context = useMemo<IExcelTableContext & any>(
    () => ({
      theme,
      mode,
      columnDefs,
      isAppleDevice: getOperatingSystem().includes('Mac'),
      ...additionalContextValues,
    }),
    [theme, additionalContextValues, mode, columnDefs]
  );

  const autoAssignIds = 'settings' in context ? context.settings?.autoAssignIds : false;

  useImperativeHandle(gridRef, () => ({
    getApi: () => gridApiRef.current,
    runValidations: blurCellsAndRunValidations,
    getDataAsCsv,
    getColumnKeys,
  }));

  const getColumnKeys = useCallback(
    (params?: { removeFirstAndLastColumn?: boolean }) => {
      const columnKeys =
        gridApiRef.current?.getAllDisplayedColumns().map(col => col.getColId()) || [];
      if (params?.removeFirstAndLastColumn) {
        columnKeys.shift();
        columnKeys.pop();
      }
      return columnKeys;
    },
    [gridApiRef]
  );

  const getDataAsCsv = useCallback(
    (
      params?: CsvExportParams & { removeFirstAndLastColumn?: boolean; removeEmptyRows?: boolean }
    ) => {
      const { removeFirstAndLastColumn } = params || {};
      const csvData = gridApiRef.current?.getDataAsCsv({
        columnSeparator: ';',
        suppressQuotes: true,
        ...params,
      });
      if (csvData && removeFirstAndLastColumn) {
        const csvRows = csvData.split('\r\n');
        if (csvRows.length > 0) {
          return csvRows
            .map(row => {
              const cells = row.split(';');
              if (cells.length && cells.some(cell => !!cell)) {
                cells.shift();
                cells.pop();
                return cells.join(';');
              }
              return undefined;
            })
            .filter(Boolean)
            .join('\r\n');
        }
        return '';
      }
      return csvData || '';
    },
    [gridApiRef]
  );

  const runValidations = useCallback(() => {
    let isDataValid = true;
    const updates: unknown[] = [];

    const gridData: RowDataFieldDefinition[] = [];
    gridApiRef.current?.forEachNode(node => gridData.push(node.data)); // Make it generic

    gridData.forEach(updatedRow => {
      let updated = false;

      if (isRowEmpty(updatedRow)) return;

      // Not very generic to have this here
      // @ts-ignore
      if (autoAssignIds && !updatedRow.farmId?.value) {
        updatedRow.farmId = { value: uuid(), isValid: true };
        updated = true;
      }

      colDefs.forEach(col => {
        const columnKey = col.field as string; // Key of the cell;
        if (columnKey && col.validator) {
          const allColumnValues: string[] = gridData
            .map(row => {
              const cell = row[columnKey];
              if (!cell || !isColumnDefinition(cell)) {
                return cell;
              }
              return cell.value?.toString();
            })
            .filter(Boolean) as string[];
          const cell = updatedRow[columnKey];
          if (!isColumnDefinition(cell)) {
            return;
          }
          const value = cell?.value;
          const validationResult = col.validator(value?.toString() || '', allColumnValues, context);
          if (validationResult.isValid === false) {
            isDataValid = false;
          }
          if (
            validationResult.isValid !== cell?.isValid ||
            validationResult.validationMessage !== cell?.validationMessage
          ) {
            // Validation result changed
            // @ts-ignore
            updatedRow[columnKey] = {
              ...cell,
              ...validationResult,
            };
            updated = true;
          }
        }
      });

      if (updated) {
        updates.push(updatedRow);
      }
    });

    if (updates.length > 0) {
      const result = gridApiRef.current?.applyTransaction({ update: updates });
      gridApiRef.current?.refreshCells({ columns: SYSTEM_COLUMN_KEYS, force: true });
      gridApiRef.current?.redrawRows({ rowNodes: result?.update });
    }

    return isDataValid;
  }, [autoAssignIds, gridApiRef, colDefs, context]);

  const blurCellsAndRunValidations = useCallback(() => {
    const gridApi = gridApiRef.current;

    const focusedCell = gridApi?.getFocusedCell();
    if (focusedCell) {
      gridApi?.clearFocusedCell();
    }

    return runValidations();
  }, [gridApiRef, runValidations]);

  useEffect(() => {
    runValidations();
  }, [additionalContextValues, runValidations]);

  const getRowValues = (
    row: string[],
    columns: ColDefWithValidator[],
    context: IExcelTableContext
  ) =>
    row
      .map((value, colOffset) => {
        const col = columns[colOffset];
        return col?.validator
          ? [col.field, { value, ...col.validator(value, [], context) }]
          : [undefined, undefined];
      })
      .filter(([key]) => key)
      .reduce((acc, [key, value]) => ({ ...acc, [key as any]: value }), {});

  const getCellValue = (cellPosition: CellPosition) => {
    const row = gridApiRef.current?.getDisplayedRowAtIndex(cellPosition.rowIndex);
    const colId = cellPosition.column.getColId();

    const cell = row?.data[colId];
    if (cell && typeof cell === 'object') {
      return cell.value;
    }

    return cell;
  };

  useCopyHandler(() => {
    const focusedCell = gridApiRef.current?.getFocusedCell();
    if (focusedCell) {
      const value = getCellValue(focusedCell);

      if (value) {
        copyTextToClipboard(value);
      }
    }
  });

  useCutHandler(() => {
    const focusedCell = gridApiRef.current?.getFocusedCell();
    if (focusedCell) {
      const row = gridApiRef.current?.getDisplayedRowAtIndex(focusedCell.rowIndex);
      const colId = focusedCell.column.getColId();
      const cell = row?.data[colId];

      if (cell && typeof cell === 'object' && cell.value) {
        copyTextToClipboard(cell.value);
      }

      // Workaround to clear cell value
      handlePaste('');
    }
  });

  const handlePaste = (data: string) => {
    if (mode !== 'edit' && mode !== 'add-edit') {
      return;
    }

    const gridApi = gridApiRef.current;

    // Grid API not ready ywt or cell editing in progress
    if (!gridApi || gridApi.getEditingCells().length > 0) return;

    const focusedCell = gridApi.getFocusedCell();
    if (!focusedCell) return; // No cell focused

    const columns = gridApi.getAllDisplayedColumns().map(col => col.getColId());

    const rows = data.split('\n').map(row => row.split('\t').map(cell => cell.trim()));

    const addFromIndex = gridApi.getDisplayedRowCount();
    const colIndex = columns.indexOf(focusedCell.column.getColId());

    const orderedColDefs = columns
      .slice(colIndex)
      .map(col => colDefs.find(def => def.field === col))
      .filter((def): def is ColDefWithValidator => def !== undefined);

    const updates = rows.slice(0, addFromIndex).map((row, idx) => {
      const currentData = gridApi.getDisplayedRowAtIndex(focusedCell.rowIndex + idx)?.data;
      return {
        ...getEmptyRow('settings' in context ? context.settings : undefined),
        ...currentData,
        ...getRowValues(row, orderedColDefs, context),
      };
    });

    const rowsToAdd = rows.slice(addFromIndex).map(row => ({
      ...getEmptyRow('settings' in context ? context.settings : undefined),
      ...getRowValues(row, orderedColDefs, context),
    }));

    // Add empty row if last row is not empty
    if (rowsToAdd.length > 0)
      rowsToAdd.push(getEmptyRow('settings' in context ? context.settings : undefined));

    gridApi.applyTransaction({ update: updates, add: rowsToAdd });
    gridApi.refreshCells({ columns: SYSTEM_COLUMN_KEYS, force: true });
  };

  usePasteHandler(handlePaste);

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      gridApiRef.current = params.api;
      onGridApiReady?.(params.api);
    },
    [gridApiRef, onGridApiReady]
  );

  const onCellValueChanged = useCallback(
    (event: CellValueChangedEvent) => {
      const { rowIndex, column, newValue, node } = event;
      if (autoAssignIds && !isRowEmpty(node.data) && !node.data.farmId) {
        node.setDataValue('farmId', uuid());
      }

      if (rowIndex !== null && column) {
        const lastRowIndex = event.api.getLastDisplayedRowIndex();
        const lastRow = event.api.getDisplayedRowAtIndex(lastRowIndex);

        // Add new row if last row is not empty
        if (mode === 'add-edit' && lastRow && !isRowEmpty(lastRow.data)) {
          event.api.applyTransaction({
            add: [getEmptyRow('settings' in context ? context.settings : undefined)],
          });
        }

        // Refresh system columns
        event.api.refreshCells({ columns: SYSTEM_COLUMN_KEYS, force: true });
        // // Jump to the next cell if current cell is not empty
        if (!isValueEmpty(newValue) && event.source !== 'redo') {
          const columns = event.api.getAllDisplayedColumns().map(col => col.getColId());
          const orderedColKeys = columns
            .map(col => colDefs.find(def => def.field === col))
            .filter((def): def is ColDefWithValidator => def !== undefined)
            .map(({ field }) => field);
          const cellKeyIndex = orderedColKeys?.findIndex(id => id === column.getColId());
          if (cellKeyIndex > -1) {
            const goToNextRow = cellKeyIndex + 1 >= orderedColKeys.length;
            const key = orderedColKeys?.[goToNextRow ? 0 : cellKeyIndex + 1];
            event.api.clearFocusedCell();
            event.api.setFocusedCell(goToNextRow ? rowIndex + 1 : rowIndex, key || column, null);
          }
        }

        if (
          !columnValidationOnEveryChange?.length ||
          columnValidationOnEveryChange?.includes(column.getColId())
        ) {
          runValidations();
        }
      }
    },
    [autoAssignIds, context, colDefs, columnValidationOnEveryChange, mode, runValidations]
  );

  const onCellContextMenuClick = useCallback((event: CellContextMenuEvent) => {
    if (!!event.rowIndex && !!event.colDef.field) {
      event.api.setFocusedCell(event.rowIndex, event.colDef.field, null);
    }
  }, []);

  const handleAddMoreRows = useCallback(() => {
    const gridApi = gridApiRef.current;
    if (!gridApi) return;

    const rowsToAdd = parseInt(inputRowsRef.current?.value || '0', 10);
    if (rowsToAdd <= 0) return;

    gridApi.applyTransaction({
      add: Array.from({ length: rowsToAdd }, () => createEmptyRow()),
    });
  }, [gridApiRef]);

  return (
    <Container
      className="ag-theme-alpine"
      style={{ height }}
      editMode={mode === 'edit' || mode === 'add-edit'}
    >
      <AgGridReact
        // rowSelection="multiple" // Can be used to delete data in bulk
        undoRedoCellEditingLimit={20}
        rowData={rowData}
        resetRowDataOnUpdate
        columnDefs={colDefsWithSystemColumns}
        defaultColDef={defaultColDef}
        getRowId={params => params.data.id}
        context={context}
        undoRedoCellEditing
        suppressScrollOnNewData
        suppressDragLeaveHidesColumns
        tooltipShowDelay={0}
        tooltipInteraction
        tooltipMouseTrack
        suppressRowHoverHighlight={mode === 'view' || mode === 'validation'}
        suppressCellFocus={mode === 'view' || mode === 'validation'}
        reactiveCustomComponents
        onCellValueChanged={onCellValueChanged}
        onCellContextMenu={onCellContextMenuClick}
        onGridReady={onGridReady}
      />
      {mode === 'add-edit' && (
        <FlexBox gap={2} my={2}>
          <ThemeButton size="small" color="WHITE" onClick={handleAddMoreRows}>
            Add
          </ThemeButton>
          <input ref={inputRowsRef} type="number" defaultValue={100} />
          <ThemeTypography variant="BODY_MEDIUM">more rows at the bottom</ThemeTypography>
        </FlexBox>
      )}
    </Container>
  );
};

export default ExcelTable;
