import { gql } from '@apollo/client';
import {
  GENERIC_SITE_FRAGMENT,
  FARM_SITE_FRAGMENT,
  MINE_SITE_FRAGMENT,
  FOREST_SITE_FRAGMENT,
  COLLECTION_SITE_FRAGMENT,
  ORIGIN_SITE_FRAGMENT,
  PLANT_SITE_FRAGMENT,
  PROCESSING_SITE_FRAGMENT,
  FACTORY_SITE_FRAGMENT,
  MANUFACTURING_SITE_FRAGMENT,
  WAREHOUSE_SITE_FRAGMENT,
  HANDLING_SITE_FRAGMENT,
  GROCERY_STORE_SITE_FRAGMENT,
  DISTRIBUTION_SITE_FRAGMENT,
  SITE_CLUSTER_FRAGMENT,
  BASE_FARM_SITE_FRAGMENT,
} from 'graphql/fragments/site.fragments';

export const CREATE_SITE = gql`
  mutation CreateSite($input: CreateSiteInput!, $lang: Language) {
    createSite(input: $input, lang: $lang) {
      site {
        ... on GenericSite {
          ...genericSiteValues
        }
        ... on Farm {
          ...baseFarmSiteValues
        }
        ... on Mine {
          ...mineValues
        }
        ... on Forest {
          ...forestValues
        }
        ... on CollectionSite {
          ...collectionSiteValues
        }
        ... on OriginSite {
          ...originSiteValues
        }
        ... on Plant {
          ...plantValues
        }
        ... on ProcessingSite {
          ...processingSiteValues
        }
        ... on Factory {
          ...factoryValues
        }
        ... on ManufacturingSite {
          ...manufacturingSiteValues
        }
        ... on Warehouse {
          ...warehouseValues
        }
        ... on HandlingSite {
          ...handlingSiteValues
        }
        ... on GroceryStore {
          ...groceryStoreValues
        }
        ... on DistributionSite {
          ...distributionSiteValues
        }
        ... on SiteCluster {
          ...siteClusterValues
        }
      }
    }
  }
  ${GENERIC_SITE_FRAGMENT}
  ${BASE_FARM_SITE_FRAGMENT}
  ${MINE_SITE_FRAGMENT}
  ${FOREST_SITE_FRAGMENT}
  ${COLLECTION_SITE_FRAGMENT}
  ${ORIGIN_SITE_FRAGMENT}
  ${PLANT_SITE_FRAGMENT}
  ${PROCESSING_SITE_FRAGMENT}
  ${FACTORY_SITE_FRAGMENT}
  ${MANUFACTURING_SITE_FRAGMENT}
  ${WAREHOUSE_SITE_FRAGMENT}
  ${HANDLING_SITE_FRAGMENT}
  ${GROCERY_STORE_SITE_FRAGMENT}
  ${DISTRIBUTION_SITE_FRAGMENT}
  ${SITE_CLUSTER_FRAGMENT}
`;

/**
 * Contains the full farm site values since the cultivation area needs to be updated
 */
export const UPDATE_SITE = gql`
  mutation UpdateSite($id: UUID!, $input: UpdateSiteInput!, $lang: Language) {
    updateSite(id: $id, input: $input, lang: $lang) {
      site {
        ... on GenericSite {
          ...genericSiteValues
        }
        ... on Farm {
          ...farmSiteValues
        }
        ... on Mine {
          ...mineValues
        }
        ... on Forest {
          ...forestValues
        }
        ... on CollectionSite {
          ...collectionSiteValues
        }
        ... on OriginSite {
          ...originSiteValues
        }
        ... on Plant {
          ...plantValues
        }
        ... on ProcessingSite {
          ...processingSiteValues
        }
        ... on Factory {
          ...factoryValues
        }
        ... on ManufacturingSite {
          ...manufacturingSiteValues
        }
        ... on Warehouse {
          ...warehouseValues
        }
        ... on HandlingSite {
          ...handlingSiteValues
        }
        ... on GroceryStore {
          ...groceryStoreValues
        }
        ... on DistributionSite {
          ...distributionSiteValues
        }
        ... on SiteCluster {
          ...siteClusterValues
        }
      }
    }
  }
  ${GENERIC_SITE_FRAGMENT}
  ${FARM_SITE_FRAGMENT}
  ${MINE_SITE_FRAGMENT}
  ${FOREST_SITE_FRAGMENT}
  ${COLLECTION_SITE_FRAGMENT}
  ${ORIGIN_SITE_FRAGMENT}
  ${PLANT_SITE_FRAGMENT}
  ${PROCESSING_SITE_FRAGMENT}
  ${FACTORY_SITE_FRAGMENT}
  ${MANUFACTURING_SITE_FRAGMENT}
  ${WAREHOUSE_SITE_FRAGMENT}
  ${HANDLING_SITE_FRAGMENT}
  ${GROCERY_STORE_SITE_FRAGMENT}
  ${DISTRIBUTION_SITE_FRAGMENT}
  ${SITE_CLUSTER_FRAGMENT}
`;

export const DELETE_SITE = gql`
  mutation DeleteSite($id: UUID!, $deleteSitesFromCluster: Boolean) {
    deleteSite(id: $id, deleteSitesFromCluster: $deleteSitesFromCluster) {
      id
      siteClusterSiteIds
    }
  }
`;
