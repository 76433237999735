import { Box, Dialog, DialogProps, DialogTitle, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@styled-icons/evaicons-solid/Close';
import PageSubTitle from 'components/Structure/PageSubTitle';
import Icon, { IconNameType } from 'designSystem/Primitives/Icon/Icon';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import React, { FC, ForwardRefExoticComponent, FunctionComponent, SVGProps } from 'react';

const DialogTitleStyled = styled(DialogTitle)(() => ({
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
}));

const CloseButtonWrapper = styled(Box)(({ theme }) => ({
  color: theme.custom.colors.actionButtonHover,
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  zIndex: 100,
}));

export const StyledDialog = styled(Dialog)<
  {
    hasBorderTop?: boolean;
    background?: string;
  } & DialogProps
>(({ theme, hasBorderTop, background, maxWidth }) => ({
  '& .MuiDialog-paper': {
    maxWidth: (!isNaN(maxWidth as unknown as number) && maxWidth) as unknown as string,
    borderTop: hasBorderTop ? `10px solid ${theme.custom.colors.blueFrost}` : 'none',
    background: background || undefined,
  },
}));

type IDialogDefaultProps = {
  children: React.ReactElement | React.ReactElement[];
  onClose?: () => void;
  title: string;
  itemTitle?: string;
  background?: string;

  /** @default false */
  hasBorderTop?: boolean;
  preventCloseOnBackground?: boolean;
} & (
  | {
      /**
       * @deprecated to be removed in future
       */
      icon?:
        | FunctionComponent<
            SVGProps<SVGSVGElement> & {
              size?: number | null | undefined;
            }
          >
        | ForwardRefExoticComponent<
            Omit<{ size?: number | string | undefined }, 'ref'> & React.RefAttributes<SVGSVGElement>
          >;
    }
  | { iconName?: IconNameType }
);

const StyledItemTitle = styled(ThemeTypography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  textTransform: 'uppercase',
  letterSpacing: '0.1em',
}));

const DialogDefault: FC<IDialogDefaultProps & Partial<DialogProps>> = ({
  children,
  onClose,
  title,
  itemTitle,
  hasBorderTop = false,
  preventCloseOnBackground = false,
  ...props
}) => {
  // Only one of the props can be set
  const IconComponent = 'icon' in props ? props.icon : undefined;
  const iconName = 'iconName' in props ? props.iconName : undefined;

  const checkBeforeClose = (e: unknown, reason?: 'backdropClick' | 'escapeKeyDown') => {
    const allowClose = !preventCloseOnBackground || reason !== 'backdropClick';

    if (allowClose) {
      onClose?.();
    }
  };

  return (
    <StyledDialog open onClose={checkBeforeClose} hasBorderTop={hasBorderTop} {...props}>
      <CloseButtonWrapper>
        <IconButton data-cy="close-dialog-button" size="small" disableRipple onClick={onClose}>
          <Close size={24} />
        </IconButton>
      </CloseButtonWrapper>

      <DialogTitleStyled>
        {IconComponent && (
          <Box mr={1} justifyContent="center" display="flex">
            <IconComponent size={24} />
          </Box>
        )}
        {iconName && <Icon name={iconName} size="large" mr={1} />}
        <PageSubTitle title={title} />

        {itemTitle && (
          <StyledItemTitle autoOverflow variant="TITLE_EXTRA_SMALL" color="GRAY_40">
            {itemTitle}
          </StyledItemTitle>
        )}
      </DialogTitleStyled>
      {children}
    </StyledDialog>
  );
};

export default DialogDefault;
