import { AvailableLanguagesType } from 'types/enums';

/**
 * Typemap of the values
 * @example
 * type AvailableLanguagesType = 'en' | 'de' | 'fr' | 'nl' | 'es'
 * @prop title - The title of the language
 * @prop label - The label of the language
 * @prop flag - The flag of the language
 */
export const LANGUAGES: Record<
  AvailableLanguagesType,
  { title: string; label: string; flag: string }
> = {
  en: {
    title: 'English',
    label: 'EN',
    flag: '/flags/gb.svg',
  },
  de: {
    title: 'German',
    label: 'DE',
    flag: '/flags/de.svg',
  },
  fr: {
    title: 'French',
    label: 'FR',
    flag: '/flags/fr.svg',
  },
  nl: {
    title: 'Dutch',
    label: 'NL',
    flag: '/flags/nl.svg',
  },
  es: {
    title: 'Spanish',
    label: 'ES',
    flag: '/flags/es.svg',
  },
  tr: {
    title: 'Turkish',
    label: 'TR',
    flag: '/flags/tr.svg',
  },
};
