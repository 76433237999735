import {
  ClaimIcon,
  ClaimsIcon,
  CompliancePlaceholderIcon,
  DocumentPlaceholderIcon,
  HomeIcon,
  ImagesIcon,
  ImpactClaimPlaceholderIcon,
  PartnerIcon,
  PartnerPlaceholderIcon,
  PartnersIcon,
  ProductPlaceholderIcon,
  SettingsIcon,
} from 'assets/img/menu';
import DocumentTitle from 'components/DocumentLibrary/DocumentTitle';
import DrawerComponentItemIcon from 'components/Drawer/utils/DrawerComponentItemIcon';
import DrawerComponentItemTitle from 'components/Drawer/utils/DrawerComponentItemTitle';
import { ClaimTitle } from 'components/ImpactClaims/ClaimShared';
import PartnerName from 'components/Partners/PartnerName/PartnerName';
import { ProductTitle } from 'components/Product';
import Icon, { IconNameType } from 'designSystem/Primitives/Icon/Icon';
import React, { CSSProperties, FC } from 'react';
import theme from 'styles/theme';
import SiteTitle from './utils/SiteTitle';
import { CustomerPlan, CustomerPlanType } from 'types/company.types';

export type DrawerSubMenuItem = {
  label: string;
  to: (params: { [key: string]: string }) => string;
  activeRoutes?: string[];
  dataTestId: string;
  visible?: boolean;
} & (
  | {
      /**
       * Use the iconName instead
       * @deprecated
       */
      Icon: FC;
      /**
       * Remove this prop when we have a better solution for defining svg colors
       * @deprecated
       */
      activeClassStyle?: CSSProperties | { [key: string]: CSSProperties };
    }
  | { iconName: IconNameType }
);

export type DrawerMenuItem = {
  label: string;
  to: string;
  submenu?: DrawerSubMenuItem[];
  submenuPaths?: string[];
  /**
   * If the main page has multiple sub routes
   */
  paths?: string[];
  params?: string[];
  SubmenuTitle?: FC;
  SubmenuIcon?: FC;
  showWarning?: boolean;
  exact?: boolean;
  dividerAbove?: boolean;
  sectionLabelAbove?: string;
  visible: boolean;
  dataTestId: string;
} & (
  | {
      /**
       * Use the iconName instead
       * @deprecated
       */
      Icon: FC;
    }
  | { iconName: IconNameType }
);

export const getDrawerMenu = ({
  isCompanyDataValid,
  plan,
  isComponentChainMapping,
  isSharedDocument,
  isInternalDocument,
  isInternalSite,
  isExternalSite,
  isUnassignedSite,
  isSiteCluster,
  isCompliancePageEnabled,
  isPaymentsPageEnabled,
  isEUDRComplianceGuideEnabled,
}: {
  isCompanyDataValid: boolean;
  plan: CustomerPlanType;
  // TODO: Workaround to only show the correct submenu item in the drawer. Refactor that
  isComponentChainMapping: boolean;
  isInternalDocument: boolean;
  isSharedDocument: boolean;
  isInternalSite: boolean;
  isExternalSite: boolean;
  isSiteCluster: boolean;
  isUnassignedSite: boolean;
  isCompliancePageEnabled: boolean;
  isPaymentsPageEnabled: boolean;
  isEUDRComplianceGuideEnabled: boolean;
}): DrawerMenuItem[] => {
  const isFreemium = plan === CustomerPlan.FREEMIUM;

  return [
    {
      label: 'Home',
      Icon: HomeIcon,
      exact: true,
      to: '/',
      visible: true,
      dataTestId: 'menu-home',
    },
    {
      label: 'Compliance',
      iconName: 'compliance',
      to: '/compliance/eudr', // TODO: change later to /compliance for other compliance pages
      params: ['id'],
      SubmenuTitle: () => <>EUDR</>, // TODO: change later for other compliance pages
      SubmenuIcon: CompliancePlaceholderIcon,
      visible: !isFreemium && isCompliancePageEnabled,
      submenuPaths: [
        '/compliance/eudr',
        '/compliance/eudr/guide',
        '/compliance/eudr/:id/guide',
        '/compliance/eudr/due-diligence-history',
      ],
      submenu: [
        {
          label: 'Compliance overview',
          to: () => `/compliance/eudr`,
          iconName: 'due-diligence-badge',
          activeRoutes: ['/compliance/eudr'],
          visible: true,
          dataTestId: 'submenu-compliance-overview',
        },
        {
          label: 'Geo-data analysis guide',
          to: ({ id }) => (id ? `/compliance/eudr/${id}/guide` : `/compliance/eudr/guide`),
          iconName: 'guide',
          activeRoutes: ['/compliance/eudr/guide', '/compliance/eudr/:id/guide'],
          visible: isEUDRComplianceGuideEnabled,
          dataTestId: 'submenu-compliance-guide',
        },
        {
          label: 'Due dilligence history',
          to: () => `/compliance/eudr/due-diligence-history`,
          iconName: 'archive',
          activeRoutes: ['/compliance/eudr/due-diligence-history'],
          visible: true,
          dataTestId: 'submenu-compliance-due-diligence-history',
        },
      ],
      dataTestId: 'menu-compliance',
    },
    {
      label: 'Origin data import',
      iconName: 'import',
      to: '/imports',
      exact: false,
      visible: !isFreemium,
      dataTestId: 'menu-origin-data-import',
    },
    // Management section
    {
      label: 'Partners',
      Icon: PartnersIcon,
      to: '/partners',
      sectionLabelAbove: 'Management',
      submenuPaths: [
        '/partners/:id',
        '/partners/:id/sites',
        '/partners/:id/components',
        '/partners/:id/components/:componentId',
        '/partners/:id/component-chains',
        '/partners/:id/activities',
        '/partners/:id/documents',
        '/partners/:id/documents/:documentId',
        '/partners/:id/requests',
        '/partners/:id/requests/:requestId',
        '/partners/:id/imports',
        '/partners/:id/imports/farms',
        '/partners/:id/imports/farms/:importId',
        '/partners/:id/imports/datasets',
        '/partners/:id/imports/datasets/:datasetId',
      ],
      params: ['id', 'componentId', 'importId', 'datasetId'],
      exact: true,
      visible: true,
      SubmenuTitle: PartnerName,
      SubmenuIcon: PartnerPlaceholderIcon,
      submenu: [
        {
          label: 'Partner overview',
          to: ({ id }) => `/partners/${id}`,
          Icon: PartnerIcon,
          activeClassStyle: {}, // Empty object to override default svg color
          visible: true,
          dataTestId: 'submenu-partner-overview',
        },
        {
          label: 'Partner sites',
          to: ({ id }) => `/partners/${id}/sites`,
          Icon: PartnerIcon,
          activeRoutes: ['/partners/:id/sites'],
          dataTestId: 'submenu-partner-sites',
          visible: true,
        },
        {
          label: 'Partner components',
          to: ({ id }) => `/partners/${id}/components`,
          Icon: PartnerIcon,
          activeRoutes: ['/partners/:id/components/:componentId', '/partners/:id/component-chains'],
          dataTestId: 'submenu-partner-components',
          visible: true,
        },
        {
          label: 'Partner activities',
          to: ({ id }) => `/partners/${id}/activities`,
          iconName: 'activities',
          visible: true,
          dataTestId: 'submenu-partner-activities',
        },
        {
          label: 'Partner documents',
          to: ({ id }) => `/partners/${id}/documents`,
          iconName: 'documents',
          activeRoutes: ['/partners/:id/documents/:documentId'],
          activeClassStyle: { '& path': { fill: theme.palette.secondary.main } },
          visible: !isFreemium,
          dataTestId: 'submenu-partner-documents',
        },
        {
          label: 'Partner requests',
          to: ({ id }) => `/partners/${id}/requests`,
          iconName: 'document-request-pending',
          visible: !isFreemium,
          activeRoutes: ['/partners/:id/requests/:requestId'],
          activeClassStyle: { '& path': { fill: theme.palette.secondary.main } },
          dataTestId: 'submenu-partner-requests',
        },
        {
          label: 'Origin data import',
          iconName: 'import',
          to: ({ id }) => `/partners/${id}/imports`,
          activeRoutes: [
            '/partners/:id/imports',
            '/partners/:id/imports/farms',
            '/partners/:id/imports/farms/:importId',
            '/partners/:id/imports/datasets',
            '/partners/:id/imports/datasets/:datasetId',
          ],
          visible: !isFreemium, // Only accessible for clients
          dataTestId: 'submenu-origin-data-import',
        },
      ],
      dataTestId: 'menu-partners',
    },
    {
      label: 'Supply chains',
      iconName: 'supply-chain',
      to: '/supply-chains',
      params: ['productId', 'chainId'],
      submenuPaths: [
        '/supply-chains/:productId/:chainId',
        '/supply-chains/:productId/:chainId/editor',
      ],
      SubmenuTitle: ProductTitle,
      SubmenuIcon: ProductPlaceholderIcon,
      submenu: [
        {
          label: 'Supply chain overview',
          to: ({ productId, chainId }) => `/supply-chains/${productId}/${chainId}`,
          iconName: 'supply-chain',
          activeRoutes: ['/supply-chains/:productId/:chainId'],
          visible: true,
          dataTestId: 'submenu-supply-chain-overview',
        },
        {
          label: 'Supply chain mapping',
          to: ({ productId, chainId }) => `/supply-chains/${productId}/${chainId}/editor`,
          iconName: 'supply-chain-edit',
          activeRoutes: ['/supply-chains/:productId/:chainId/editor'],
          visible: true,
          dataTestId: 'submenu-supply-chain-mapping',
        },
      ],
      visible: !isFreemium,
      dataTestId: 'menu-supply-chains',
    },
    {
      label: 'Components',
      iconName: 'components',
      to: '/components',
      params: ['id'],
      paths: ['/components', '/component-chains'],
      submenuPaths: ['/components/:id', '/component-chains/:id/editor'],
      SubmenuTitle: DrawerComponentItemTitle,
      SubmenuIcon: DrawerComponentItemIcon,
      submenu: [
        {
          label: 'Component overview',
          to: ({ id }) => `/components/${id}`,
          iconName: 'component',
          visible: !isComponentChainMapping,
          dataTestId: 'submenu-component-overview',
        },
        {
          label: 'Component chain mapping',
          to: ({ id }) => `/component-chains/${id}/editor`,
          iconName: 'component-chain',
          visible: isComponentChainMapping,
          dataTestId: 'submenu-component-chain-mapping',
        },
      ],
      visible: true,
      dataTestId: 'menu-components',
    },
    {
      label: 'Sites',
      iconName: 'locations',
      to: isFreemium ? '/sites/internal' : '/sites/external',
      submenuPaths: [
        '/sites/internal/:id',
        '/sites/external/:id',
        '/sites/unassigned/:id',
        '/sites/internal/:id/sites',
        '/sites/external/:id/sites',
        '/sites/unassigned/:id/sites',
      ],
      paths: ['/sites/internal', '/sites/external', '/sites/unassigned'],
      SubmenuTitle: SiteTitle,
      params: ['id'],
      exact: true,
      SubmenuIcon: () => <Icon name="site-placeholder" color="white" />,
      submenu: [
        {
          label: 'Site overview',
          to: ({ id }) => `/sites/internal/${id}`,
          iconName: 'site-placeholder',
          visible: isInternalSite,
          dataTestId: 'submenu-site-overview-internal',
        },
        {
          label: 'Site overview',
          to: ({ id }) => `/sites/external/${id}`,
          iconName: 'site-placeholder',
          visible: isExternalSite,
          dataTestId: 'submenu-site-overview-external',
        },
        {
          label: 'Site overview',
          to: ({ id }) => `/sites/unassigned/${id}`,
          iconName: 'site-placeholder',
          visible: isUnassignedSite,
          dataTestId: 'submenu-site-overview-unassigned',
        },
        {
          label: 'Cluster sites',
          to: ({ id }) => `/sites/internal/${id}/sites`,
          iconName: 'site-placeholder',
          visible: isInternalSite && isSiteCluster,
          dataTestId: 'submenu-cluster-sites-internal',
        },
        {
          label: 'Cluster sites',
          to: ({ id }) => `/sites/external/${id}/sites`,
          iconName: 'site-placeholder',
          visible: isExternalSite && isSiteCluster,
          dataTestId: 'submenu-cluster-sites-external',
        },
        {
          label: 'Cluster sites',
          to: ({ id }) => `/sites/unassigned/${id}/sites`,
          iconName: 'site-placeholder',
          visible: isUnassignedSite && isSiteCluster,
          dataTestId: 'submenu-cluster-sites-unassigned',
        },
      ],
      visible: true,
      dataTestId: 'menu-sites',
    },
    {
      label: 'Activities',
      iconName: 'activities',
      to: isFreemium ? '/activities/internal' : '/activities/external',
      paths: ['/activities/internal', '/activities/external', '/activities/unassigned'],
      visible: true,
      dataTestId: 'menu-activities',
    },
    {
      label: 'Requests',
      iconName: 'document-request-pending',
      to: '/requests',
      visible: isFreemium,
      dataTestId: 'menu-requests',
    },
    {
      label: 'Documents',
      iconName: 'documents',
      exact: false,
      to: '/documents/internal',
      paths: [
        '/documents/internal',
        '/documents/internal/:documentId',
        '/documents/shared',
        '/documents/shared/:documentId',
        '/documents/requested',
        '/documents/requested/:documentId',
      ],
      submenuPaths: [
        '/documents/internal/:documentId',
        '/documents/shared/:documentId',
        '/documents/requested/:documentId',
      ],
      SubmenuTitle: DocumentTitle,
      SubmenuIcon: DocumentPlaceholderIcon,
      visible: true,
      params: ['documentId'],
      submenu: [
        {
          label: 'Document overview',
          to: ({ documentId }) => `/documents/internal/${documentId}`,
          iconName: 'document',
          visible: isInternalDocument,
          dataTestId: 'submenu-document-overview-internal',
        },
        {
          label: 'Document overview',
          to: ({ documentId }) => `/documents/shared/${documentId}`,
          iconName: 'document',
          visible: isSharedDocument,
          dataTestId: 'submenu-document-overview-shared',
        },
        {
          label: 'Document overview',
          to: ({ documentId }) => `/documents/requested/${documentId}`,
          iconName: 'document',
          visible: !isInternalDocument && !isSharedDocument,
          dataTestId: 'submenu-document-overview-requested',
        },
      ],
      dataTestId: 'menu-documents',
    },
    {
      label: 'Payments',
      iconName: 'scale',
      to: '/payments',
      visible: !isFreemium && isPaymentsPageEnabled,
      dataTestId: 'menu-payments',
    },
    {
      label: 'Origin data import',
      iconName: 'import',
      to: '/imports',
      exact: false,
      visible: isFreemium,
      dataTestId: 'menu-origin-data-import',
    },
    // Communication section
    {
      label: 'Product pages',
      iconName: 'products',
      to: '/products',
      submenuPaths: [
        '/products/:id',
        '/products/:id/editor',
        '/products/:id/editor',
        '/products/:id/analytics',
        '/products/:id/analytics/behavior',
        '/products/:id/analytics/embedded',
        '/products/:id/feedback',
      ],
      sectionLabelAbove: !isFreemium ? 'Communication' : undefined,
      SubmenuTitle: ProductTitle,
      SubmenuIcon: ProductPlaceholderIcon,
      params: ['id'],
      exact: true,
      visible: !isFreemium,
      submenu: [
        {
          label: 'Product page overview',
          to: ({ id }) => `/products/${id}`,
          iconName: 'product',
          visible: true,
          activeRoutes: [
            '/products/:id',
            '/products/:id/analytics',
            '/products/:id/analytics/behavior',
            '/products/:id/analytics/embedded',
            '/products/:id/feedback',
          ],
          dataTestId: 'submenu-product-page-overview',
        },
        {
          label: 'Product page editor',
          to: ({ id }) => `/products/${id}/editor`,
          iconName: 'product-edit',
          visible: true,
          dataTestId: 'submenu-product-page-editor',
        },
      ],
      dataTestId: 'menu-product-pages',
    },
    {
      label: 'Impact Claims',
      Icon: ClaimsIcon,
      to: '/impact-claims',
      submenuPaths: ['/impact-claims/:id'],
      params: ['id'],
      exact: true,
      SubmenuTitle: ClaimTitle,
      SubmenuIcon: ImpactClaimPlaceholderIcon,
      visible: !isFreemium,
      submenu: [
        {
          label: 'Claim overview',
          to: ({ id }) => `/impact-claims/${id}`,
          Icon: ClaimIcon,
          visible: true,
          dataTestId: 'submenu-claim-overview',
        },
      ],
      dataTestId: 'menu-impact-claims',
    },
    {
      label: 'Media',
      Icon: ImagesIcon,
      exact: true,
      to: '/media-library',
      visible: true,
      dataTestId: 'menu-media',
    },
    // Settings and help section
    {
      label: 'Settings',
      Icon: SettingsIcon,
      to: '/settings',
      showWarning: !isCompanyDataValid,
      dividerAbove: true,
      visible: true,
      dataTestId: 'menu-settings',
    },
  ];
};
