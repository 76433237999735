/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-const */
import { useQuery } from '@apollo/client';
import { Loader } from 'components/Forms';
import { ErrorState } from 'components/Structure';
import { Percentage } from 'designSystem/Navigation/Stepper/Stepper';
import { GET_GEO_DATA_PROCESS } from 'graphql/queries/dueDiligence.queries';
import React, {
  FC,
  createContext,
  createElement,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { EDueDiligenceProcessStatus, IDueDiligenceProcess } from 'types/compliance.types';
import {
  EDueDiligenceProcessSectionKey,
  TDueDiligenceProcessSection,
  TDueDiligenceProcessSectionKey,
  TDueDiligenceProcessSubSection,
  TDueDiligenceProcessSubSectionKey,
} from 'types/dueDilligenceProcess.types';
import { DUE_DILIGENCE_PROCESS_SECTIONS } from '../dueDiligenceProcess.data';
import IDueDiligenceProcessHeader from '../DueDiligenceProcessHeader';
import DueDiligenceProcessReducerReducer from './DueDiligenceProcessReducer';

export interface IDueDiligenceProcessContextValues {
  sections: (TDueDiligenceProcessSection & {
    completion: Percentage;
    count: number;
  })[];

  selectedSection?: TDueDiligenceProcessSection & {
    completion: Percentage;
    count: number;
  };
  selectedSubSection?: TDueDiligenceProcessSubSection;
  selectedSectionIndex: number;
  selectedSubSectionIndex?: number;
  dueDiligenceProcess?: IDueDiligenceProcess;

  setSelectedSection: (sectionId: TDueDiligenceProcessSectionKey) => void;
  setSelectedSubSection: (sectionId: TDueDiligenceProcessSubSectionKey) => void;
  updateSectionCompletion: (
    sectionId: TDueDiligenceProcessSectionKey,
    completion?: Percentage
  ) => void;

  navigateToNextSection: () => void;
  navigateToPreviousSection: () => void;
}

const DueDiligenceProcessContext = createContext<IDueDiligenceProcessContextValues>({
  sections: [],
  selectedSectionIndex: 0,
  selectedSubSectionIndex: undefined,
  /**
   * The states can not be undefined, so we set them as empty objects
   * The children that are using this context will not be rendered until the data is loaded. We show a loading or error state instead.
   */
  selectedSubSection: undefined,
  dueDiligenceProcess: {} as IDueDiligenceProcess,
  setSelectedSection: () => undefined,
  setSelectedSubSection: () => undefined,
  navigateToNextSection: () => undefined,
  navigateToPreviousSection: () => undefined,
  updateSectionCompletion: () => undefined,
});

const DueDiligenceProcessProvider: FC = () => {
  const { id } = useParams();
  const { data, loading, error } = useQuery<{ geoDataProcess: IDueDiligenceProcess }>(
    GET_GEO_DATA_PROCESS,
    {
      variables: { id },
      skip: !id,
    }
  );

  const initialSections = DUE_DILIGENCE_PROCESS_SECTIONS.sections.map(section => ({
    ...section,
    completion: 0 as Percentage,
    count: section.subSections.length,
  }));

  const [initialized, setInitialized] = useState(false);
  const [stateValues, dispatch] = useReducer(DueDiligenceProcessReducerReducer, {
    sections: initialSections,
  });

  const {
    sections,
    selectedSectionIndex,
    selectedSection,
    selectedSubSection,
    selectedSubSectionIndex,
  } = useMemo(() => stateValues, [stateValues]);

  /** This event also changes the selected subsection to the first one */
  const setSelectedSection = (key: TDueDiligenceProcessSectionKey) =>
    dispatch({ type: 'SET_SELECTED_SECTION', key });
  const setSelectedSubSection = (key: TDueDiligenceProcessSubSectionKey) =>
    dispatch({ type: 'SET_SELECTED_SUB_SECTION', key });
  const updateSectionCompletion = (key: TDueDiligenceProcessSectionKey, completion?: Percentage) =>
    dispatch({ type: 'SET_SECTION_COMPLETION', key, completion });

  /**
   * Run only initially to set the selected section based on the due diligence process status
   * Will not be updated again
   */
  useEffect(() => {
    if (initialized) {
      return;
    }
    if (data?.geoDataProcess) {
      switch (data.geoDataProcess.status) {
        case EDueDiligenceProcessStatus.COLLECTING_GEO_DATA:
          setSelectedSection(EDueDiligenceProcessSectionKey.GEO_DATA_COLLECTION);
          break;
        case EDueDiligenceProcessStatus.SELECTING_RISK_ANALYSIS:
          setSelectedSection(EDueDiligenceProcessSectionKey.RISK_ANALYSIS);
          updateSectionCompletion(EDueDiligenceProcessSectionKey.GEO_DATA_COLLECTION, 100);
          break;
        case EDueDiligenceProcessStatus.PENDING_RISK_ANALYSIS:
        case EDueDiligenceProcessStatus.COMPLETED_RISK_ANALYSIS:
          setSelectedSection(EDueDiligenceProcessSectionKey.RISK_ANALYSIS);
          updateSectionCompletion(EDueDiligenceProcessSectionKey.GEO_DATA_COLLECTION, 100);
          updateSectionCompletion(EDueDiligenceProcessSectionKey.RISK_ANALYSIS, 100);
          break;
        default:
          setSelectedSection(EDueDiligenceProcessSectionKey.GEO_DATA_COLLECTION);
          break;
      }
    } else {
      setSelectedSection(EDueDiligenceProcessSectionKey.GEO_DATA_COLLECTION);
    }
    setInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.geoDataProcess, initialized]);

  if (loading || !selectedSection || selectedSectionIndex === undefined) {
    return <Loader />;
  }
  if (error) {
    return <ErrorState />;
  }

  /**
   * This will navigate to the previous subsection that is enabled based on the current section & subsection
   * It will not navigate to the previous section if there is none available
   */
  const navigateToPreviousSection = () => {
    if (selectedSectionIndex === undefined || selectedSectionIndex - 1 < 0) {
      // eslint-disable-next-line no-console
      console.error('Can not navigate to previous section, already at the first section');
      return;
    }
    setSelectedSection(stateValues.sections[selectedSectionIndex - 1].key);
  };

  /**
   * This will navigate to the next subsection that is enabled based on the current section & subsection
   * If the current subsection is the last one, it will navigate to the next section
   */
  const navigateToNextSection = () => {
    if (
      selectedSectionIndex === undefined ||
      selectedSectionIndex + 1 >= stateValues.sections.length
    ) {
      // eslint-disable-next-line no-console
      console.error('Can not navigate to next section, already at the last section');
      return;
    }
    // If there are no more subsections, navigate to the next section
    setSelectedSection(stateValues.sections[selectedSectionIndex + 1].key);
  };

  const state = {
    // Set the values manually to avoid the typescript error of the state values being undefined
    sections,
    selectedSectionIndex,
    selectedSection,
    selectedSubSection,
    selectedSubSectionIndex,
    dueDiligenceProcess: data?.geoDataProcess,
    navigateToPreviousSection,
    navigateToNextSection,
    setSelectedSection,
    setSelectedSubSection,
    updateSectionCompletion,
  };

  return (
    <DueDiligenceProcessContext.Provider value={state}>
      <IDueDiligenceProcessHeader
        title={DUE_DILIGENCE_PROCESS_SECTIONS.title}
        imageUrl={DUE_DILIGENCE_PROCESS_SECTIONS.image}
        selectedSectionKey={selectedSection.key}
        selectedSubSectionKey={selectedSubSection?.key}
        sections={sections}
        onMenuSectionClick={setSelectedSection}
      >
        {/* Pass all state values directly to the sub context so it does not need to use them via the hook */}
        {createElement(selectedSection.contextProvider, state)}
      </IDueDiligenceProcessHeader>
    </DueDiligenceProcessContext.Provider>
  );
};

const useDueDiligenceProcess = () => useContext(DueDiligenceProcessContext);

export { DueDiligenceProcessProvider, useDueDiligenceProcess };

export default DueDiligenceProcessContext;
