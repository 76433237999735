import { FetchResult, useMutation } from '@apollo/client';
import { IGeoUploadFormValue } from 'constants/schemas/geoUpload.schema';
import { DELETE_DOCUMENTS } from 'graphql/mutations';
import {
  CREATE_DATASET,
  DELETE_DATASET,
  EXPORT_DATASET,
  PRE_PROCESS_DATASETS,
  PROCESS_DATASETS,
  UPDATE_DATASET,
} from 'graphql/mutations/dataset.mutations';
import { SUBMIT_EUDR_RISK_ANALYSIS } from 'graphql/mutations/dueDiligenceProcess.mutations';
import {
  ICreateDatasetInput,
  IExportDatasetInput,
  IPreProcessDatasetInput,
  IProcessDatasetInput,
  IUpdateDatasetInput,
} from 'graphql/mutations/types/dataset-mutation.types';
import { ISubmitEUDRRiskAnalysisInput } from 'graphql/mutations/types/dueDiligenceProcess-mutation.types';
import { GET_DATASETS } from 'graphql/queries/dataset.queries';
import { useMatch, useParams } from 'react-router-dom';
import { IDataset, IPreProcessDatasetPayload, IProcessDatasetsPayload } from 'types/dataset.types';
import { RouteParamsWithId } from 'types/router.types';
import { GraphQlConnection } from 'types/types';

const useDatasetMutation = () => {
  // Can be statmentId (compliance section) or partnerId (partner library/partner ui)
  const { id } = useParams<RouteParamsWithId>();
  const isCompliance = !!useMatch('/compliance/*');
  const isPartnerLibrary = !!useMatch('/partners/*');
  const [deleteDocument] = useMutation(DELETE_DOCUMENTS);

  const [_createDataset] = useMutation<
    { createDataset: { dataset: IDataset } },
    { input: ICreateDatasetInput }
  >(CREATE_DATASET, {
    // update: (cache, { data }) => {
    //   if (data?.createDataset.dataset) {
    //     const newDataset = data.createDataset.dataset;
    //     if (id && isCompliance) {
    //       const statementCacheKey = cache.identify({
    //         __typename: 'EUDRStatement',
    //         id: id,
    //       });
    //       const statement = cache.readFragment<IDueDiligenceProcess>({
    //         id: statementCacheKey,
    //         fragment: EUDR_STATEMENT,
    //         fragmentName: 'eudrStatementValues',
    //       });
    //       cache.writeQuery({
    //         query: GET_STATEMENT,
    //         data: {
    //           statement: {
    //             ...statement,
    //             eudrDatasets: statement?.datasets.length
    //               ? [...statement.datasets, newDataset]
    //               : [newDataset],
    //           },
    //         },
    //       });
    //     }
    //   }
    // },
  });

  const [_updateDataset] = useMutation<
    { updateDataset: { dataset: IDataset } },
    { id: string; input: IUpdateDatasetInput }
  >(UPDATE_DATASET, {
    // update: (cache, { data }) => {
    //   if (data?.updateDataset.dataset) {
    //     const datasetCacheKey = cache.identify({
    //       __typename: 'Dataset',
    //       id: data.updateDataset.dataset.id,
    //     });
    //     const existingDataset = cache.readFragment<IDataset>({
    //       id: datasetCacheKey,
    //       fragment: DATASET_FRAGMENT,
    //       fragmentName: 'datasetValues',
    //     });
    //     cache.writeFragment({
    //       id: datasetCacheKey,
    //       fragment: DATASET_FRAGMENT,
    //       fragmentName: 'datasetValues',
    //       data: {
    //         ...existingDataset,
    //       },
    //     });
    //   }
    // },
  });

  const [_deleteDataset] = useMutation<{ deleteDataset: { id: string } }, { id: string }>(
    DELETE_DATASET,
    {
      update: (cache, { data }) => {
        if (data?.deleteDataset.id) {
          if (id && isCompliance) {
            // const statementCacheKey = cache.identify({
            //   __typename: 'EUDRStatement',
            //   id: id,
            // });
            // const statement = cache.readFragment<IDueDiligenceProcess>({
            //   id: statementCacheKey,
            //   fragment: EUDR_STATEMENT,
            //   fragmentName: 'eudrStatementValues',
            // });
            // cache.writeQuery({
            //   query: GET_STATEMENT,
            //   data: {
            //     statement: {
            //       ...statement,
            //       eudrDatasets: statement?.datasets.filter(
            //         dataset => dataset.id !== data.deleteDataset.id
            //       ),
            //     },
            //   },
            // });
          } else {
            const datasets = cache.readQuery<{ datasets: GraphQlConnection<IDataset> }>({
              query: GET_DATASETS,
              variables: { ...(isPartnerLibrary && { filters: { ownedById: id } }) },
            })?.datasets;

            cache.writeQuery({
              query: GET_DATASETS,
              variables: { ...(isPartnerLibrary && { filters: { ownedById: id } }) },
              data: {
                datasets: {
                  ...datasets,
                  edges: datasets?.edges.filter(edge => edge.node.id !== data.deleteDataset.id),
                },
              },
            });
          }
        }
      },
    }
  );

  const [_processDatasets] = useMutation<
    { processDatasets: IProcessDatasetsPayload },
    { inputs: IProcessDatasetInput[] }
  >(PROCESS_DATASETS);

  const [_preProcessDatasets] = useMutation<
    { preProcessDatasets: IPreProcessDatasetPayload },
    { inputs: IPreProcessDatasetInput[] }
  >(PRE_PROCESS_DATASETS);

  const [_submitEUDRRiskAnalysis] = useMutation<unknown, { input: ISubmitEUDRRiskAnalysisInput }>(
    SUBMIT_EUDR_RISK_ANALYSIS,
    { refetchQueries: ['GetGeoDataProcess'] }
  );

  const [_exportDataset] = useMutation<
    { exportDataset: { fileUrl: string } },
    { id: string; input: IExportDatasetInput }
  >(EXPORT_DATASET);

  const startDatasetPreProcessing = (inputs: IPreProcessDatasetInput[]) =>
    _preProcessDatasets({
      variables: {
        inputs,
      },
      refetchQueries: ['GetDataset', 'GetDatasetTableRepresentation', 'GetDatasetSites'],
    });

  const startDatasetProcessing = (inputs: IProcessDatasetInput[]) =>
    _processDatasets({
      variables: {
        inputs,
      },
      refetchQueries: [
        'GetGeoDataProcess',
        'GetDataset',
        'GetDatasetTableRepresentation',
        'GetDatasetSites',
      ],
    });

  const createDataset = async (
    value: Pick<IGeoUploadFormValue, 'title' | 'rawMaterialId' | 'countryCode' | 'ownedBy'> & {
      files: { id: string }[];
    },
    skipCacheUpdate = false,
    geoDataProcessId?: string
  ) =>
    await _createDataset({
      variables: {
        input: {
          title: value.title,
          originCountry: value.countryCode,
          rawMaterialId: value.rawMaterialId,
          ownedById: value.ownedBy.id,
          documentIds: value.files.map(({ id }) => id),
          geoDataProcessIds: geoDataProcessId ? [geoDataProcessId] : undefined,
        },
      },
      ...(skipCacheUpdate ? { fetchPolicy: 'no-cache' } : {}),
    });

  const createDatasets = async (
    values: (Pick<IGeoUploadFormValue, 'title' | 'rawMaterialId' | 'countryCode' | 'ownedBy'> & {
      files: { id: string }[];
    })[],
    eudrStatementId?: string
  ): Promise<
    FetchResult<{
      createDataset: {
        dataset: IDataset;
      };
    }>[]
  > => {
    if (!values.length) {
      return Promise.resolve([]);
    }
    try {
      return await Promise.all(
        values.map(
          async value =>
            await _createDataset({
              variables: {
                input: {
                  title: value.title,
                  originCountry: value.countryCode,
                  rawMaterialId: value.rawMaterialId,
                  ownedById: value.ownedBy.id,
                  documentIds: value.files.map(({ id }) => id),
                  geoDataProcessIds: eudrStatementId ? [eudrStatementId] : undefined,
                },
              },
            })
        )
      );
    } catch (error) {
      console.error('Something went wrong creating datasets', error);
      return [];
    }
  };

  const updateDataset = async (
    value: Partial<
      Pick<IGeoUploadFormValue, 'title' | 'rawMaterialId' | 'countryCode' | 'ownedBy'>
    > & {
      datasetId: string; // datasetid is required
      files?: { id: string }[];
    },
    skipCacheUpdate = false
  ) =>
    await _updateDataset({
      variables: {
        id: value.datasetId,
        input: {
          title: value.title,
          originCountry: value.countryCode,
          rawMaterialId: value.rawMaterialId,
          ownedById: value.ownedBy?.id,
          documentIds: value.files?.map(({ id }) => id),
        },
      },
      ...(skipCacheUpdate ? { fetchPolicy: 'no-cache' } : {}),
    });

  const updateDatasets = async (
    values: (Partial<
      Pick<IGeoUploadFormValue, 'title' | 'rawMaterialId' | 'countryCode' | 'ownedBy'>
    > & {
      datasetId: string; // datasetid is required
      files?: { id: string }[];
    })[]
  ): Promise<
    FetchResult<{
      updateDataset: {
        dataset: IDataset;
      };
    }>[]
  > => {
    if (!values.length) {
      return Promise.resolve([]);
    }
    try {
      return await Promise.all(values.map(async value => await updateDataset(value)));
    } catch (error) {
      console.error('Something went wrong creating datasets', error);
      return [];
    }
  };

  const deleteDataset = async (dataset: { id: string; documents?: { id: string }[] }) => {
    try {
      // TODO: move the document deletion to the backend
      if (dataset.documents?.length) {
        deleteDocument({ variables: { ids: dataset.documents.map(({ id }) => id) } });
      }
      _deleteDataset({ variables: { id: dataset.id } });
    } catch (error) {
      console.error('Something went wrong deleting datasets', error);
      return [];
    }
  };

  const submitEudrRiskAnalysis = async (datasetBatches: ISubmitEUDRRiskAnalysisInput) =>
    _submitEUDRRiskAnalysis({
      variables: {
        input: datasetBatches,
      },
    });

  const exportDataset = async ({
    datasetId,
    exportType,
    exportFormat,
  }: {
    datasetId: string;
    exportType: IExportDatasetInput['exportType'];
    exportFormat: IExportDatasetInput['exportFormat'];
  }) => {
    return await _exportDataset({
      variables: {
        id: datasetId,
        input: {
          datasetId,
          exportType,
          exportFormat,
        },
      },
    });
  };

  return {
    updateDataset,
    createDatasets,
    createDataset,
    deleteDataset,
    startDatasetProcessing,
    startDatasetPreProcessing,
    updateDatasets,
    submitEudrRiskAnalysis,
    exportDataset,
  };
};

export default useDatasetMutation;
