import { relayStylePagination } from '@apollo/client/utilities';

const datasetPolicies = {
  Dataset: {
    fields: {
      sites: relayStylePagination(['filters', 'siteViewOption']),
    },
  },
};

export default datasetPolicies;
