/* eslint-disable @typescript-eslint/no-explicit-any */
import { Percentage } from 'designSystem/Navigation/Stepper/Stepper';
import { Reducer } from 'react';
import { IDueDiligenceProcessContextValues } from './DueDiligenceProcessContext';
import {
  TDueDiligenceProcessSection,
  TDueDiligenceProcessSectionKey,
  TDueDiligenceProcessSubSectionKey,
} from 'types/dueDilligenceProcess.types';

type DueDiligenceProcessReducerActions =
  | { type: 'SET_SELECTED_SECTION'; key: TDueDiligenceProcessSectionKey }
  | { type: 'SET_SELECTED_SUB_SECTION'; key: TDueDiligenceProcessSubSectionKey }
  | {
      type: 'SET_SECTION_COMPLETION';
      key: TDueDiligenceProcessSectionKey;
      completion?: Percentage;
    };

type EudrDueDiligenceProcessReducerState = {
  selectedSection?: TDueDiligenceProcessSection & {
    completion: Percentage;
    count: number;
  };
} & Pick<IDueDiligenceProcessContextValues, 'sections'> &
  Partial<
    Pick<
      IDueDiligenceProcessContextValues,
      'selectedSectionIndex' | 'selectedSubSection' | 'selectedSubSectionIndex'
    >
  >;

const DueDiligenceProcessReducerReducer: Reducer<
  EudrDueDiligenceProcessReducerState,
  DueDiligenceProcessReducerActions
> = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTED_SECTION': {
      if (action.key === undefined) {
        return {
          ...state,
          selectedSection: undefined,
          selectedSectionIndex: undefined,
          selectedSubSectionIndex: undefined,
        };
      }
      const selectedSectionIndex = state.sections.findIndex(({ key: id }) => id === action.key);
      if (selectedSectionIndex === -1) {
        // eslint-disable-next-line no-console
        console.error(`Section with id ${action.key} not found`);
        return state;
      }
      // If nothing changed, return the same state
      if (state.selectedSectionIndex === selectedSectionIndex) {
        return state;
      }
      return {
        ...state,
        selectedSection: state.sections[selectedSectionIndex],
        selectedSectionIndex,
        // The subsection should be selection based on the state in the sub context
        selectedSubSection: undefined,
        selectedSubSectionIndex: undefined,
      };
    }
    case 'SET_SELECTED_SUB_SECTION': {
      if (action.key === undefined || !state.selectedSection) {
        return {
          ...state,
          selectedSection: undefined,
          selectedSectionIndex: undefined,
          selectedSubSectionIndex: undefined,
        };
      }
      // Find first index that is not in the disabledSubSections
      const selectedSubSectionIndex = state.selectedSection.subSections.findIndex(
        ({ key }) => key === action.key
      );
      if (!state.selectedSection || selectedSubSectionIndex === -1) {
        // eslint-disable-next-line no-console
        console.error(`Sub section with id ${action.key} not found`);
        return state;
      }

      return {
        ...state,
        sections: state.sections.map(section => {
          if (section.key === state.selectedSection?.key && section.completion !== 100) {
            return {
              ...section,
              completion: ((selectedSubSectionIndex / section.subSections.length) *
                100) as Percentage,
            };
          }
          return section;
        }),
        selectedSubSection: state.selectedSection.subSections[selectedSubSectionIndex],
        selectedSubSectionIndex,
      };
    }
    case 'SET_SECTION_COMPLETION': {
      return {
        ...state,
        sections: state.sections.map(section => {
          if (action.key === section.key) {
            // Updating manually by event section key
            if (action.completion !== undefined) {
              return {
                ...section,
                completion: action.completion,
              };
            }

            // Updating automatically current selection by current subsection index
            if (
              state.selectedSection?.key === action.key &&
              state.selectedSubSectionIndex !== undefined &&
              section.completion !== 100
            ) {
              return {
                ...section,
                completion: ((state.selectedSubSectionIndex /
                  state.selectedSection.subSections.length) *
                  100) as Percentage,
              };
            }
          }
          return section;
        }),
      };
    }
    default:
      return state;
  }
};

export default DueDiligenceProcessReducerReducer;
