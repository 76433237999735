import { FlexBox } from 'components/Structure';
import { ThemeTypography } from 'designSystem';
import React, { FC, useMemo } from 'react';
import { ERiskLevel, IDueDiligenceProcess } from 'types/compliance.types';
import Moment from 'react-moment';
import RiskBadge from 'designSystem/DataDisplay/RiskBadge/RiskBadge';
import { EDatasetErrorEntity } from 'types/dataset.types';
import { Badge } from 'components/DataImport/DataImport.styles';
import { Box, styled } from '@mui/material';

interface IDueDiligenceProcessTitleProps {
  dueDiligenceProcess: IDueDiligenceProcess;
  processRiskLevel?: ERiskLevel;
}

const OriginContainer = styled(Box)(({ theme }) => ({
  borderRadius: 4,
  backgroundColor: theme.custom.themeColors.grayScale[20],
  padding: '4px 6px',
}));

const DueDiligenceProcessTitle: FC<IDueDiligenceProcessTitleProps> = ({
  dueDiligenceProcess,
  processRiskLevel,
}) => {
  const { datasets } = dueDiligenceProcess;

  const numberOfPlotWarnings = useMemo(
    () =>
      datasets.reduce((acc, dataset) => {
        acc += dataset.issues.filter(issue => issue.entityType === EDatasetErrorEntity.SITE).length;
        return acc;
      }, 0),
    [datasets]
  );

  return (
    <Box display="flex" flexDirection="column" alignItems="start" gap={0.5} width={'100%'}>
      <FlexBox gap={1}>
        <FlexBox>
          <ThemeTypography variant="BODY_MEDIUM_BOLD">
            ({datasets.length} dataset{datasets.length === 1 ? '' : 's'})
          </ThemeTypography>
          <ThemeTypography variant="BODY_MEDIUM">
            &nbsp; - Modified &nbsp;
            <Moment format="YYYY-MM-DD" date={dueDiligenceProcess.modifiedTimestamp} />
          </ThemeTypography>
        </FlexBox>

        {/* check if at least one dataset has a risk status */}
        {processRiskLevel && (
          <RiskBadge
            riskLevel={processRiskLevel}
            text={
              (processRiskLevel === ERiskLevel.HIGH
                ? 'High '
                : processRiskLevel === ERiskLevel.MEDIUM
                ? 'Medium'
                : 'Low') + ' risk'
            }
          />
        )}

        {/* count all datasets with site issues */}
        {numberOfPlotWarnings > 0 && (
          <Badge severity="warning">
            {numberOfPlotWarnings} plot warning{numberOfPlotWarnings === 1 ? '' : 's'}
          </Badge>
        )}
      </FlexBox>
      <FlexBox gap={1} width="100%" overflow="hidden">
        {!!dueDiligenceProcess.datasets.length &&
          dueDiligenceProcess.datasets.slice(0, 3).map(dataset => (
            <OriginContainer key={dataset.id}>
              <ThemeTypography variant="BODY_SMALLER_BOLD" autoOverflow>
                {dataset.title}
              </ThemeTypography>
            </OriginContainer>
          ))}
        {dueDiligenceProcess.datasets.length > 3 && (
          <OriginContainer>
            <ThemeTypography variant="BODY_SMALLER_BOLD" autoOverflow>
              +{dueDiligenceProcess.datasets.length - 3} more
            </ThemeTypography>
          </OriginContainer>
        )}
      </FlexBox>
    </Box>
  );
};
export default DueDiligenceProcessTitle;
