import { isNumber } from '@turf/turf';
import { ITableSettings, ValidationResult } from 'types/dataImport.types';
import { IExcelTableContext, ValidatorFunction } from '../DataImportTable/excelTable.types';
import { VALIDATION_MESSAGE_EMPTY } from '../constants/dataImport.constants';
import { isValueEmpty } from './dataImport.utils';
import { IRawMaterial } from 'types/component.types';
import { CoordinateDatasetColumnType, EDatasetColumn } from 'types/dataset.types';
import { CoordinateDatasetColumnName } from 'utils/dataset.utils';
import { isInteger } from 'formik';

const LNG_COORDINATE_FORMAT_REGEX = [
  /^[-+]?([1-9]?[0-9]|1[0-7][0-9]|180)(\.\d+)?°?$/, // DD: Decimal Degrees with optional degree symbol
  /^[-+]?([1-9]?[0-9]|1[0-7][0-9]|180)°\s*([0-5]?[0-9])'\s*([0-5]?[0-9](\\.\\d+)?)"?\s*[EW]?$/, // DMS: Degrees, Minutes, Seconds with optional N/S/E/W
  /^[-+]?([1-9]?[0-9]|1[0-7][0-9]|180)(\.\d+)?°?\s*\d{1,2}("|\s*")?\s*[EW]?$/, // DDS: Decimal Degrees with up to 9 decimal places and optional N/S/E/W
];

const LAT_COORDINATE_FORMAT_REGEX = [
  /^[-+]?([1-8]?[0-9]|90)(\.\d+)?°?$/, // DD: Decimal Degrees with optional degree symbol
  /^[-+]?([1-8]?[0-9]|90)°\s*([0-5]?[0-9])'\s*([0-5]?[0-9](\\.\\d+)?)"?\s*[NS]?$/, // DMS: Degrees, Minutes, Seconds with optional N/S/E/W
  /^[-+]?([1-8]?[0-9]|90)(\.\d+)?°?\s*\d{1,2}("|\s*")?\s*[NS]?$/, // DDS: Decimal Degrees with up to 9 decimal places and optional N/S/E/W
];

export const areCoordinatesValid = (
  coordinates: string,
  format: CoordinateDatasetColumnType = EDatasetColumn.COORDINATES_LAT_LNG
): boolean => {
  // Possible supported separators: comma, space
  const splitted =
    coordinates.includes(' ') && !coordinates.includes(',')
      ? coordinates.trim().split(' ')
      : coordinates.trim().replaceAll(' ', '').split(',');

  if (splitted.length !== 2) {
    return false;
  }

  const [lat, lng] = format === EDatasetColumn.COORDINATES_LAT_LNG ? splitted : splitted.reverse();
  const lngMatch = LNG_COORDINATE_FORMAT_REGEX.some(regex => lng.match(regex));
  const latMatch = LAT_COORDINATE_FORMAT_REGEX.some(regex => lat.match(regex));

  if (!latMatch || !lngMatch) {
    return false;
  }

  return true;
};

export const noValidation: ValidatorFunction = () => ({
  isValid: true,
  validationMessage: undefined,
});

export const validateUniqueness: ValidatorFunction = (value, allColumnValues) => {
  if (isValueEmpty(value)) {
    return {
      isValid: false,
      validationMessage: VALIDATION_MESSAGE_EMPTY,
    };
  }

  if (allColumnValues.filter(columnValue => columnValue === value).length > 1) {
    return {
      isValid: false,
      validationMessage:
        'This value is not unique. Please verify that each row has a unique value.',
    };
  }

  return {
    isValid: true,
    validationMessage: undefined,
  };
};

export const validateNotEmpty: ValidatorFunction = value => {
  const valid = !isValueEmpty(value);
  return {
    isValid: valid,
    validationMessage: valid ? undefined : VALIDATION_MESSAGE_EMPTY,
  };
};

export const validateCoordinates: ValidatorFunction = (value, allColumnValues, context) => {
  if (isValueEmpty(value)) {
    return {
      isValid: false,
      validationMessage: VALIDATION_MESSAGE_EMPTY,
    };
  }
  let format: CoordinateDatasetColumnType = EDatasetColumn.COORDINATES_LAT_LNG;
  if (context?.settings?.columns?.coordinates.format) {
    format = context?.settings?.columns.coordinates.format;
  } else {
    // @deprecated only used by the old data import
    format =
      context?.settings?.coordinatesVersion === 'lnglat'
        ? EDatasetColumn.COORDINATES_LNG_LAT
        : EDatasetColumn.COORDINATES_LAT_LNG;
  }

  const valid = areCoordinatesValid(value, format);

  return {
    isValid: valid,
    validationMessage: valid
      ? undefined
      : `Location needs to be in format of ${CoordinateDatasetColumnName[format]}. Two numbers separated by a comma or space while the latitude needs to be in range of -90 to +90 and the longitude in range of -180 to +180.`,
  };
};

export const createPositiveFloatValidator: (
  errorMessage: string,
  required?: boolean
) => ValidatorFunction =
  (errorMessage, required = false) =>
  value => {
    if (required && isValueEmpty(value))
      return {
        isValid: false,
        validationMessage: VALIDATION_MESSAGE_EMPTY,
      };

    if (!required && isValueEmpty(value)) {
      return {
        isValid: true,
        validationMessage: undefined,
        value: undefined,
      };
    }

    if (value === undefined)
      return {
        isValid: undefined,
      };

    const valid = value !== undefined && isNumber(value) && parseFloat(value) >= 0;
    return {
      value: valid ? parseFloat(value) : value,
      isValid: valid,
      validationMessage: valid ? undefined : errorMessage,
    };
  };

export const createPositiveIntegerValidator: (
  errorMessage: string,
  required?: boolean
) => ValidatorFunction =
  (errorMessage, required = false) =>
  value => {
    if (required && isValueEmpty(value))
      return {
        isValid: false,
        validationMessage: VALIDATION_MESSAGE_EMPTY,
      };

    if (!required && isValueEmpty(value)) {
      return {
        isValid: true,
        validationMessage: undefined,
        value: undefined,
      };
    }

    if (value === undefined)
      return {
        isValid: undefined,
      };

    const valid = value !== undefined && isInteger(value) && parseInt(value) >= 0;
    return {
      value: valid ? parseInt(value) : value,
      isValid: valid,
      validationMessage: valid ? undefined : errorMessage,
    };
  };

export const validateNotEmptyAndCrop = (
  value: string,
  allColumnValues: string[],
  context?: IExcelTableContext & { settings: ITableSettings; rawMaterials: IRawMaterial[] }
): ValidationResult => {
  if (isValueEmpty(value)) {
    return {
      isValid: false,
      validationMessage: VALIDATION_MESSAGE_EMPTY,
    };
  }
  return validateCrop(value, allColumnValues, context);
};

export const validateCrop = (
  value: string,
  allColumnValues: string[],
  context?: IExcelTableContext & {
    settings: ITableSettings;
    rawMaterials: IRawMaterial[];
    selectMultipleRawMaterials?: boolean;
  }
): ValidationResult => {
  if (isValueEmpty(value)) {
    return {
      isValid: true,
    };
  }

  if (!context?.rawMaterials?.length) {
    return {
      isValid: false,
      validationMessage: 'No raw materials available. Please wait for the list to load.',
    };
  }

  const materialTitles = context?.rawMaterials.map(material => material.title.toLowerCase());

  const isValid = value
    .split(',')
    .every(inputValue => materialTitles.includes(inputValue.trim().toLowerCase()));

  if (!isValid) {
    return {
      isValid: false,
      validationMessage: `${
        context?.selectMultipleRawMaterials
          ? 'One or multiple raw material types are'
          : 'This raw material type is'
      } not yet part of our list. Select an existing type from list, or request a new one.`,
    };
  }

  return {
    isValid: true,
    validationMessage: undefined,
  };
};
