import React, { FC, MouseEvent } from 'react';
import { Box, IconButton, styled, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { CardContainer, ThemeTypography } from 'designSystem';
import Image from 'designSystem/DataDisplay/Image/Image';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { SiteLabelKeys } from 'utils/site.utils';
import { ISite } from 'types/site.types';
import { DatasetError } from 'types/dataset.types';
import WarningTooltip from './WarningTooltip';
import { FlexBox } from 'components/Structure';
import useMeasure from 'react-use/lib/useMeasure';

interface ISiteItemProps {
  site: ISite;
  warnings: DatasetError[];
  severity?: 'warning' | 'error';
  hideSeverity?: boolean;
  onClick?: (site: ISite) => void;
  onEdit?: (site: ISite) => void;
  onEditCultivatedArea?: (site: ISite) => void;
  onDelete?: (site: ISite) => void;
}

const DatasetItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '100%',

  '&:not(:last-child)': {
    marginBottom: theme.spacing(1),
  },
}));

const NumberCircle = styled(Box)<{ severity?: 'error' | 'warning' }>(({ theme, severity }) => ({
  width: 16,
  height: 16,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background:
    severity === 'error' ? theme.custom.themeColors.error[60] : theme.custom.themeColors.accent[60],
  flexShrink: 0,
}));

const WarningTextContainer = styled(Box)<{ cursor?: string; severity?: 'error' | 'warning' }>(
  ({ theme, cursor, severity }) => ({
    borderRadius: 4,
    padding: '4px 4px',
    background:
      severity === 'error'
        ? theme.custom.themeColors.error[20]
        : theme.custom.themeColors.accent[20],
    cursor,
  })
);

const TitleContainer = styled(FlexBox)(({ theme }) => ({
  wordBreak: 'break-all',
}));

const SiteItem: FC<ISiteItemProps> = ({
  site,
  warnings,
  severity,
  hideSeverity,
  onEdit,
  onClick,
  onEditCultivatedArea,
  onDelete,
}) => {
  const theme = useTheme();
  const isMediumScreenAndSmaller = useMediaQuery(theme.breakpoints.down('lg'));
  const [containerRef, { width: containerWidth }] = useMeasure<HTMLDivElement>();
  const warningTextMaxWidth = containerWidth - (isMediumScreenAndSmaller ? 550 : 700);

  const hasWarnings = warnings.length > 0;

  const handleEditCultivationArea = (event: MouseEvent) => {
    event.stopPropagation();
    onEditCultivatedArea?.(site);
  };
  const handleEdit = (event: MouseEvent) => {
    event.stopPropagation();
    onEdit?.(site);
  };
  const handleDelete = (event: MouseEvent) => {
    event.stopPropagation();
    onDelete?.(site);
  };

  return (
    <DatasetItem ref={containerRef} key={site.id} display="flex" alignItems="center" gap={1}>
      {!hideSeverity && (
        <Icon
          name={hasWarnings ? 'error-filled' : 'check-filled'}
          size="large"
          color={hasWarnings ? (severity === 'error' ? 'red' : 'yellow') : 'green'}
        />
      )}
      <CardContainer padding={1.5} blueHover={!!onClick} onClick={() => onClick?.(site)}>
        <FlexBox maxWidth="100%" justifyContent="space-between">
          {/*  80px for the action button container & 24px padding */}
          <FlexBox width="calc(100% - 80px - 24px)">
            {!!site.image ? (
              <Image width={44} height={44} image={site.image} />
            ) : (
              <Box display="flex" bgcolor="white" borderRadius="6px" padding="4px">
                <Icon name="site-placeholder" size="xx-large" color="blue-ice" />
              </Box>
            )}
            {/* 12px margin left & 44px image */}
            <Box width="calc(100% - 56px)" ml={1.5}>
              <Box maxWidth="100%" display="flex" alignItems="center" gap={0.5}>
                <TitleContainer gap={1} maxWidth="100%">
                  <ThemeTypography variant="ITEM_TITLE" autoOverflow>
                    {site.title}
                  </ThemeTypography>
                  {site.externalId && (
                    <>
                      <ThemeTypography variant="BODY_MEDIUM" color="GRAY_100">
                        |
                      </ThemeTypography>
                      <ThemeTypography autoOverflow variant="BODY_MEDIUM" color="GRAY_100">
                        {site.externalId}
                      </ThemeTypography>
                    </>
                  )}
                </TitleContainer>

                <Box ml={0.5} />
                {hasWarnings && (
                  <>
                    <NumberCircle severity={severity}>
                      <ThemeTypography variant="BODY_SMALL_BOLD" color="GRAY_100">
                        {warnings.length}
                      </ThemeTypography>
                    </NumberCircle>
                    <WarningTooltip warnings={warnings} severity={severity}>
                      <WarningTextContainer cursor="default" severity={severity}>
                        <ThemeTypography
                          variant="BODY_SMALL"
                          color="GRAY_100"
                          autoOverflow
                          maxWidth={warningTextMaxWidth}
                          hideTooltip
                        >
                          {warnings[0].errorMessage}
                        </ThemeTypography>
                      </WarningTextContainer>
                    </WarningTooltip>
                    {warnings.length > 1 && (
                      <WarningTooltip warnings={warnings} severity={severity}>
                        <WarningTextContainer cursor="default" severity={severity}>
                          <ThemeTypography variant="BODY_SMALL" color="GRAY_100">
                            ...
                          </ThemeTypography>
                        </WarningTextContainer>
                      </WarningTooltip>
                    )}
                  </>
                )}
              </Box>
              <Box maxWidth="100%" display="flex" alignItems="center">
                <Icon name="tag-filled" size="small" color="gray-60" />
                <Box ml={0.5} />
                <ThemeTypography variant="LABEL_INPUT">
                  {SiteLabelKeys[site.siteType]}
                </ThemeTypography>
                <Box ml={1} />
                <Icon name="location" size="small" color="gray-60" />
                <Box ml={0.5} />
                <ThemeTypography autoOverflow variant="LABEL_INPUT">
                  {site.locationName}
                </ThemeTypography>
              </Box>
            </Box>
          </FlexBox>

          <Box display="flex" alignItems="center" gap={0.5} mr={1}>
            {!!onEditCultivatedArea && (
              <Tooltip title="Edit cultivation area">
                <IconButton onClick={handleEditCultivationArea}>
                  <Icon name="polygon-mapping" size="medium" />
                </IconButton>
              </Tooltip>
            )}
            {!!onEdit && (
              <Tooltip title="Edit site">
                <IconButton onClick={handleEdit}>
                  <Icon name="edit" size="medium" />
                </IconButton>
              </Tooltip>
            )}
            {!!onDelete && (
              <Tooltip title="Delete site">
                <IconButton onClick={handleDelete}>
                  <Icon name="delete" size="medium" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </FlexBox>
      </CardContainer>
    </DatasetItem>
  );
};

export default SiteItem;
