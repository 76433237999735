import { IRawMaterial } from './component.types';
import { IDataset } from './dataset.types';
import { PartnerRequest } from './partner.types';
import { ICultivatedAreas } from './cultivatedArea.types';

export enum EDueDiligenceProcessStatus {
  CREATED = 'CREATED',
  EVALUATING_AFFECTEDNESS = 'EVALUATING_AFFECTEDNESS',
  COLLECTING_GEO_DATA = 'COLLECTING_GEO_DATA',
  SELECTING_RISK_ANALYSIS = 'SELECTING_RISK_ANALYSIS',
  PENDING_RISK_ANALYSIS = 'PENDING_RISK_ANALYSIS',
  COMPLETED_RISK_ANALYSIS = 'COMPLETED_RISK_ANALYSIS',
  SELECTING_ASSESSMENTS = 'SELECTING_ASSESSMENTS',
  PENDING_ASSESSMENTS = 'PENDING_ASSESSMENTS',
  COMPLETED_ASSESSMENTS = 'COMPLETED_ASSESSMENTS',
  RISK_MITIGATION = 'RISK_MITIGATION',
  COMPLETED = 'COMPLETED',
  SUBMITTED_EU = 'SUBMITTED_EU',
  ARCHIVED = 'ARCHIVED',
}

export type TDueDiligenceProcessStatus = `${EDueDiligenceProcessStatus}`;

export enum EEudrResponsibilityType {
  OPERATOR = 'OPERATOR',
  TRADER = 'TRADER',
  TRADER_OPERATOR = 'TRADER_OPERATOR',
  PARTNER = 'PARTNER',
}

export enum EEudrAffectednessType {
  NOT_AFFECTED = 'NOT_AFFECTED',
  PARTIALLY_AFFECTED = 'PARTIALLY_AFFECTED',
  INDIRECTLY_AFFECTED = 'INDIRECTLY_AFFECTED',
  FULLY_AFFECTED = 'FULLY_AFFECTED',
}

export enum ERiskLevel {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export type TRiskLevel = `${ERiskLevel}`;

export interface IDueDiligenceProcess {
  id: string;
  title: string;
  createdTimestamp: Date;
  modifiedTimestamp: Date;
  riskStatus: TRiskLevel;
  status: TDueDiligenceProcessStatus;
  datasets: IDataset[];

  // Mocks or old (not implemented by backend)
  involvedPartnerCompanies: { id: string }[];
  companyResponsibilityType: EEudrResponsibilityType;
  companyAffectednessType: EEudrAffectednessType;
  year: number;
  aggregatedQuestionnaires?: {
    aggregatedResponseSummary: { answered: number; notAnswered: number; total: number };
    aggregatedRiskSummary: {
      lowRiskPercentage: number;
      mediumRiskPercentage: number;
      highRiskPercentage: number;
    };
    requests: PartnerRequest[];
  };
}

export enum ChangeDetection {
  LOW = 'Low risk (No/Negligible risk)',
  MEDIUM = 'Medium risk',
  HIGH = 'High risk',
}

export enum EComplianceType {
  EUDR = 'eudr',
}

export interface GeoCParcel {
  geocParcelId: string;
  checkDate: string;
  rawMaterialId: string;
  cultivatedAreas: ICultivatedAreas;
  resultTimestamp: string;
  deforestationRisk: TRiskLevel;
  baselineDate: string;
  landcoverPngBaselineDate: string;
  forestCoverage: number;
  plantationCoverage: number;
  shrubsCoverage: number;
  noTreesCoverage: number;
  rgbPngBaselineDate: string;
  rgbPngProductionEnddate: string;
  forestChangePng: string;
  deforestationIndex: number;
  deforestationAreaHa: number;
  degradationRisk: TRiskLevel;
  degradationAreaHa: number;
  degradationIndex: number;
  countryRisk: TRiskLevel;
  geocBatches: GeoCBatch[];
  rawMaterial: IRawMaterial;
  id: string;
  createdTimestamp: Date;
}

export interface GeoCBatch {
  id: string;
  createdTimestamp: Date;
  externalBatchId: string;
  geocBatchId: string;
  datasetId: string;
  weight: number;
  checkDate: string;
  riskStatus: TRiskLevel;
  geoDataProcessId: string;
}

export interface GeocParcelConnection {
  edges: { node: GeoCParcel }[];
  highRisks: number;
  mediumRisks: number;
  lowRisks: number;
  count: number;
}

export interface QuestionnaireResponsesOption {
  id: string;
  text: string;
  weight: number;
  riskLevel: ERiskLevel;
  answerPercentage: number;
}

export interface QuestionnaireResponse {
  text: string;
  options: QuestionnaireResponsesOption[];
}
export interface QuestionnaireResponseSummary {
  averageResponseTime: number;
  riskMode: ERiskLevel;
}
