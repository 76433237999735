import { CsvExportParams } from 'ag-grid-community';
import useRawMaterials from 'components/ComponentsLibrary/hooks/useRawMaterials';
import CropCellEditor from 'components/DataImport/DataImportTable/CellEditors/CropCellEditor';
import CropCellRenderer from 'components/DataImport/DataImportTable/CellRenderers/CropCellRenderer';
import ExcelTable, {
  IExcelTableProps,
  IGridApiHandle,
} from 'components/DataImport/DataImportTable/ExcelTable';
import HeaderColumn from 'components/DataImport/DataImportTable/HeaderColumn';
import {
  ColDefWithValidator,
  ColumnDefinition,
} from 'components/DataImport/DataImportTable/excelTable.types';
import { createColumnDefinition } from 'components/DataImport/utils/dataImport.utils';
import {
  createPositiveFloatValidator,
  createPositiveIntegerValidator,
  validateCoordinates,
  validateCrop,
  validateNotEmpty,
} from 'components/DataImport/utils/dataImport.validators';
import { INITIAL_TABLE_ROWS } from 'constants/pagination';
import { IDatasetMappedColumn } from 'graphql/mutations/types/dataset-mutation.types';
import React, {
  FC,
  ForwardedRef,
  memo,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { IRawMaterial } from 'types/component.types';
import { IOriginTableSettings, TColumnKeys } from 'types/dataImport.types';
import { EDatasetColumn, DatasetTableRepresentation } from 'types/dataset.types';
import {
  DatasetColumnName,
  DatasetColumnSampleData,
  matchColumnKeyToColumnMapping,
  transformDataForDisplay,
} from 'utils/dataset.utils';
import { v4 as uuid } from 'uuid';
export interface IDatasetTableHandle {
  getDataAsCsv?: (
    params?: CsvExportParams & { removeFirstAndLastColumn?: boolean; removeEmptyRows?: boolean }
  ) => string;
  runValidations?: () => boolean;
  getColumnsMapping: () => IDatasetMappedColumn[];
}

interface IDatasetValidationFormProps {
  isEUDR?: boolean;
  datasetTableRef?: ForwardedRef<IDatasetTableHandle>;
  tableRepresentation?: DatasetTableRepresentation;
  mode: IExcelTableProps<OriginDatasetTableRowData, unknown>['mode'];
  tableSettings?: IOriginTableSettings;
  height?: number | string;
}

export type OriginDatasetTableRowData = {
  id: string;
  farmId: ColumnDefinition<string>;
  originClusterId?: ColumnDefinition<string>;
  farmName?: ColumnDefinition<string>;
  coordinates: ColumnDefinition<string>;
  farmSize?: ColumnDefinition<number>;
  numberOfFarmers?: ColumnDefinition<number>;
  additionalCrops?: ColumnDefinition<string>;
  landOwnership?: ColumnDefinition<string>;
  farmOwnerFullName?: ColumnDefinition<string>;
  farmOwnerFirstName?: ColumnDefinition<string>;
  farmOwnerLastName?: ColumnDefinition<string>;
  siteDescription?: ColumnDefinition<string>;
};

interface OriginDatasetTableContext {
  settings?: IOriginTableSettings;
  rawMaterials: IRawMaterial[];
  selectMultipleRawMaterials: boolean;
}

const DatasetValidationForm: FC<IDatasetValidationFormProps> = ({
  datasetTableRef,
  mode,
  isEUDR,
  tableSettings,
  tableRepresentation,
  height = 500,
}) => {
  const gridRef = useRef<IGridApiHandle<OriginDatasetTableRowData>>(null);

  const { rawMaterials } = useRawMaterials({ eudrRelevant: isEUDR });

  // const [rowData, setRowData] = useState<OriginDatasetTableRowData[]>([]);

  const checkIfColumnIsEnabledInSettings = (columnKey: TColumnKeys) =>
    tableSettings?.columns &&
    columnKey in tableSettings.columns &&
    tableSettings.columns[columnKey as TColumnKeys]?.enabled;

  const createRequiredRowDataColumns = (): OriginDatasetTableRowData => ({
    id: uuid(),
    farmId: { value: '' },
    coordinates: { value: '' },
  });

  /**
   * Column field keys @type OriginDatasetTableRowData should match the keys in the table settings @type TColumnKeys
   */
  const columns: ColDefWithValidator<OriginDatasetTableRowData>[] = useMemo(() => {
    const allColumnDefinitions: ColDefWithValidator<OriginDatasetTableRowData>[] = [
      createColumnDefinition(
        'farmId',
        () => <HeaderColumn title={DatasetColumnName[EDatasetColumn.FARM_ID]} />,
        validateNotEmpty
      ),
      createColumnDefinition(
        'originClusterId',
        () => <HeaderColumn title={DatasetColumnName[EDatasetColumn.ORIGIN_CLUSTER_ID]} />,
        undefined,
        undefined,
        undefined,
        false
      ),
      createColumnDefinition(
        'farmName',
        () => <HeaderColumn title={DatasetColumnName[EDatasetColumn.FARM_NAME]} />,
        undefined,
        undefined,
        undefined,
        false
      ),
      createColumnDefinition(
        'coordinates',
        () => (
          <HeaderColumn
            title={
              tableSettings?.columns?.coordinates?.format
                ? DatasetColumnName[tableSettings.columns.coordinates.format]
                : 'Coordinates'
            }
          />
        ),
        validateCoordinates
      ),
      createColumnDefinition(
        'farmSize',
        () => (
          <HeaderColumn
            title={
              tableSettings?.columns?.farmSize?.format
                ? DatasetColumnName[tableSettings.columns.farmSize.format]
                : 'Farm size'
            }
          />
        ),
        createPositiveFloatValidator(
          `Farm size needs to be a positive number, in e.g. ${
            tableSettings?.columns?.farmSize?.format
              ? DatasetColumnSampleData[tableSettings.columns.farmSize.format]
              : '1.0 km²'
          }.`
        ),
        undefined,
        undefined,
        false
      ),
      createColumnDefinition(
        'numberOfFarmers',
        () => <HeaderColumn title={DatasetColumnName[EDatasetColumn.NUMBER_OF_FARMERS]} />,
        createPositiveIntegerValidator(`Needs to be a positive integer number, eg 2`),
        undefined,
        undefined,
        false
      ),
      createColumnDefinition(
        'additionalCrops',
        () => <HeaderColumn title={DatasetColumnName[EDatasetColumn.ADDITIONAL_CROPS]} />,
        validateCrop,
        CropCellRenderer,
        {
          cellEditor: memo(CropCellEditor),
          // onCellClicked: startEditingCell, // Workaround to fix opening the cell editor on double click
        },
        false
      ),
      createColumnDefinition(
        'landOwnership',
        () => <HeaderColumn title={DatasetColumnName[EDatasetColumn.LAND_OWNERSHIP]} />,
        undefined,
        undefined,
        undefined,
        false
      ),
      createColumnDefinition(
        'farmOwnerFullName',
        () => <HeaderColumn title={DatasetColumnName[EDatasetColumn.FARM_OWNER_FULL_NAME]} />,
        undefined,
        undefined,
        undefined,
        false
      ),
      createColumnDefinition(
        'farmOwnerFirstName',
        () => <HeaderColumn title={DatasetColumnName[EDatasetColumn.FARM_OWNER_FIRST_NAME]} />,
        undefined,
        undefined,
        undefined,
        false
      ),
      createColumnDefinition(
        'farmOwnerLastName',
        () => <HeaderColumn title={DatasetColumnName[EDatasetColumn.FARM_OWNER_LAST_NAME]} />,
        undefined,
        undefined,
        undefined,
        false
      ),
      createColumnDefinition(
        'siteDescription',
        () => <HeaderColumn title={DatasetColumnName[EDatasetColumn.SITE_DESCRIPTION]} />,
        undefined,
        undefined,
        undefined,
        false
      ),
    ];

    return allColumnDefinitions.filter(
      ({ field }) => field && checkIfColumnIsEnabledInSettings(field as TColumnKeys)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRepresentation?.columnsNames, tableSettings]);

  const rowData = useMemo(() => {
    if (!tableRepresentation) {
      return [...Array(INITIAL_TABLE_ROWS).keys()].map(() => createRequiredRowDataColumns());
    } else {
      return [...tableRepresentation.rows.map(row => transformDataForDisplay(row, tableSettings))];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRepresentation]); // The row data is not dependent on tableSettings otherwise we would override changes made in the table data

  const getColumnsMapping: () => IDatasetMappedColumn[] = useCallback(() => {
    const keys =
      gridRef.current?.getColumnKeys({
        removeFirstAndLastColumn: true,
      }) || [];
    return keys.map(key => ({
      sourceColumnName: key,
      targetColumnName: matchColumnKeyToColumnMapping(key, tableSettings),
    }));
  }, [gridRef, tableSettings]);

  useImperativeHandle(
    datasetTableRef,
    () => ({
      getDataAsCsv: gridRef.current?.getDataAsCsv,
      runValidations: gridRef.current?.runValidations,
      getColumnsMapping,
    }),
    [gridRef, getColumnsMapping]
  );

  return (
    <ExcelTable<OriginDatasetTableRowData, OriginDatasetTableContext>
      mode={mode}
      height={height}
      gridRef={gridRef}
      additionalContextValues={{
        settings: tableSettings,
        rawMaterials,
        selectMultipleRawMaterials: true,
      }}
      rowData={rowData}
      columnDefs={columns}
    />
  );
};

export default DatasetValidationForm;
