import { useMutation, useQuery } from '@apollo/client';
import { useMessages } from 'components/hooks';
import { CREATE_RAW_MATERIAL } from 'graphql/mutations/component.mutations';
import { GET_RAW_MATERIALS } from 'graphql/queries/component.queries';
import { useMemo } from 'react';
import { IRawMaterial, IRawMaterialResponse } from 'types/component.types';
import { removeGraphConnections } from 'utils/graphql.utils';

interface IUseRawMaterialsProps {
  eudrRelevant?: boolean;
}

const useRawMaterials = ({ eudrRelevant }: IUseRawMaterialsProps = {}) => {
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { data, loading, error } = useQuery<IRawMaterialResponse>(GET_RAW_MATERIALS, {
    variables: {
      filters: {
        eudrRelevant,
        latestStatus: 'APPROVED',
      },
    },
  });

  const rawMaterials = useMemo(
    () => (data?.rawMaterials ? removeGraphConnections(data.rawMaterials) : []),
    [data]
  );

  const [createRawMaterial] = useMutation<{ createRawMaterial: IRawMaterial }>(
    CREATE_RAW_MATERIAL,
    {
      onCompleted: ({ createRawMaterial: { id } }) => {
        setSuccessMessage(
          'You have added a new raw material! It will take some time util your raw material will be approved by us and can be used.'
        );
      },
      onError: e => {
        setErrorMessage('Something went wrong creating your raw material');
      },
    }
  );

  return {
    error,
    loading,
    rawMaterials,
    createRawMaterial,
  };
};

export default useRawMaterials;
