import { Box, styled } from '@mui/material';
import DatasetVerification from 'components/DataImport/Views/DatasetVerification';
import { useDueDiligenceProcess } from 'components/DueDiligenceProcess/Context/DueDiligenceProcessContext';
import { useGeoDataCollection } from 'components/DueDiligenceProcess/Context/SubSections/GeoDataCollectionContext';
import DueDiligenceProcessSubSectionNavigation from 'components/DueDiligenceProcess/DueDiligenceProcessSubSectionNavigation';
import { Loader } from 'components/Forms';
import { useDialog } from 'components/hooks';
import { appQueryParams } from 'constants/appQueryParams';
import { InfoTooltip, ThemeButton } from 'designSystem';
import DatasetItem from 'designSystem/DataDisplay/DatasetItem/DatasetItem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import useDueDiligenceProcessMutations from 'hooks/useDueDiligenceProcessMutations';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';
import { EDueDiligenceProcessStatus } from 'types/compliance.types';
import { DatasetView, IDataset } from 'types/dataset.types';
import { EDueDiligenceProcessSubSectionKey } from 'types/dueDilligenceProcess.types';
import { createEnumParam, useQueryParam } from 'use-query-params';

const DatasetContainer = styled('div')<{ disabled?: booleanish }>(({ theme, disabled }) => ({
  cursor: disabled === 'true' ? 'initial' : 'pointer',
}));

const Container = styled(Box)<{ isEditMode: booleanish }>(({ theme, isEditMode }) => ({
  ...(isEditMode === 'true' && {
    '&:after': {
      content: '""',
      position: 'absolute',
      border: `1px dashed ${theme.custom.themeColors.grayScale[40]}`,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: 6,
      zIndex: 1,
      pointerEvents: 'none',
    },
  }),
}));

const GeoDataCollectionConfirmation: FC = () => {
  const { setSelectedSubSection } = useDueDiligenceProcess();
  const { dueDiligenceProcess, onPreviousClick, onNextClick } = useGeoDataCollection();
  const { updateDueDiligenceProcess } = useDueDiligenceProcessMutations();

  const { openDialog } = useDialog();
  const [, setViewOption] = useQueryParam(
    appQueryParams.viewOption,
    createEnumParam<DatasetView>([DatasetView.LIST, DatasetView.MAP])
  );

  const [selectedDataset, setSelectedDataset] = useState<IDataset>(); // TODO: CHange to id
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (dueDiligenceProcess?.datasets.length) {
      setSelectedDataset(dueDiligenceProcess.datasets[0]);
    }
  }, [dueDiligenceProcess]);

  const sortedDatasets = useMemo(
    () =>
      dueDiligenceProcess?.datasets
        ? [...dueDiligenceProcess.datasets].sort((a, b) => (a.title <= b.title ? -1 : 1))
        : [],
    [dueDiligenceProcess]
  );

  if (!dueDiligenceProcess) {
    return <Loader />;
  }

  const handleNextStepClick = async () => {
    if (isEditMode) {
      setIsEditMode(false);
    } else if (dueDiligenceProcess.status === EDueDiligenceProcessStatus.COLLECTING_GEO_DATA) {
      await updateDueDiligenceProcess(dueDiligenceProcess.id, {
        status: EDueDiligenceProcessStatus.SELECTING_RISK_ANALYSIS,
      });
      onNextClick();
    } else {
      onNextClick();
    }
  };

  const handleClickHandleEditMode = () => {
    if (!isEditMode) {
      setViewOption(DatasetView.LIST);
    }
    setIsEditMode(!isEditMode);
  };

  const handleClickRestart = () => {
    setSelectedSubSection(EDueDiligenceProcessSubSectionKey.METHODS);
  };

  const handleDeleteDatasetFromStatement = (datasetId: string) => {
    openDialog({
      type: 'ALERT',
      props: {
        title: 'Remove geo-dataset',
        text: 'Are you sure you want to remove this dataset? This will remove the geo-data for this due diligence. It will however still available in the platform connected to your partner.',
        cancelText: 'Cancel',
        submitText: 'Confirm',
        onCancel: () => undefined,
        onSubmit: () => {
          updateDueDiligenceProcess(dueDiligenceProcess.id, {
            datasetIds: dueDiligenceProcess.datasets
              .map(dataset => dataset.id)
              .filter(id => id !== datasetId),
          });
        },
      },
    });
  };

  return (
    <>
      <DueDiligenceProcessSubSectionNavigation
        allowNextStepNavigation
        disabledPreviousNavigation
        customNextStepButton={
          dueDiligenceProcess.status === EDueDiligenceProcessStatus.COLLECTING_GEO_DATA
            ? { text: !isEditMode ? 'Confirm' : 'Exit edit mode' }
            : undefined
        }
        titleAdornment={
          dueDiligenceProcess.status === EDueDiligenceProcessStatus.COLLECTING_GEO_DATA &&
          !isEditMode ? (
            <Box ml={2} display="flex" alignItems="center" gap={1}>
              <ThemeButton
                startIcon={<Icon name="edit" size="x-small" />}
                color="BLUE_ICE"
                onClick={handleClickHandleEditMode}
              >
                Edit geo-data
              </ThemeButton>
              <ThemeButton
                startIcon={<Icon name="plus" size="x-small" />}
                color="BLUE_ICE"
                onClick={handleClickRestart}
              >
                Add geo-data
              </ThemeButton>
              <InfoTooltip text="Adding more geo-data will take you back to step 1.1. Once you have completed adding more geo-data, all previously added geo-data will also still be available here in the overview. " />
            </Box>
          ) : undefined
        }
        onNextStepClick={handleNextStepClick}
        onPreviousStepClick={onPreviousClick}
      >
        <Container isEditMode={Booleanish(isEditMode)}>
          <Box display="flex" gap={2} flexWrap="wrap">
            {sortedDatasets.map(dataset => (
              <DatasetContainer key={dataset.id} onClick={() => setSelectedDataset(dataset)}>
                <DatasetItem
                  title={dataset.title}
                  commodity={dataset.rawMaterial.title}
                  location={dataset.originCountry}
                  owner={dataset.ownedBy.name}
                  active={dataset.id === selectedDataset?.id}
                  status="valid"
                  onClickDelete={
                    isEditMode && dueDiligenceProcess.datasets.length > 1
                      ? () => handleDeleteDatasetFromStatement(dataset.id)
                      : undefined
                  }
                />
              </DatasetContainer>
            ))}
          </Box>
        </Container>
      </DueDiligenceProcessSubSectionNavigation>

      <Box mt={2} minHeight={50} position="relative">
        {selectedDataset && (
          <DatasetVerification
            dataset={selectedDataset}
            initialViewOption={DatasetView.MAP}
            isEUDR
            isEditDisabled={!isEditMode}
          />
        )}
      </Box>
    </>
  );
};

export default GeoDataCollectionConfirmation;
