import { Box, Grid, styled } from '@mui/material';
import { Plus } from '@styled-icons/bootstrap/Plus';
import { Edit } from '@styled-icons/boxicons-solid';
import emailSend from 'assets/img/icons/email-send.svg';
import unknownDocument from 'assets/img/icons/unknown-document.svg';
import defaultPartnerImage from 'assets/img/partners/default-partner-image.png';
import ComponentItem from 'components/ComponentsLibrary/ComponentItem';
import PaginatedMap from 'components/CultivatedFarmArea/PaginatedMap';
import SiteItem from 'components/DataImport/SiteItem';
import { Loader } from 'components/Forms';
import ActivityItem from 'components/Partners/ActivitiesTable/ActivityItem';
import { usePartnerMutations } from 'components/Partners/hooks';
import PartnerDocumentOverview from 'components/Partners/PartnerDocuments/PartnerDocumentOverview';
import PartnerInviteCard from 'components/Partners/PartnerInviteCard/PartnerInviteCard';
import PartnerRequestOverview from 'components/Partners/PartnerRequests/PartnerRequestOverview';
import { ErrorState, PageContainer, PageSubTitle, PageTitle } from 'components/Structure';
import FlexBox from 'components/Structure/FlexBox';
import OverviewHeader from 'components/Structure/OverviewHeader/OverviewHeader';
import { OverviewHeaderContainer } from 'components/Structure/OverviewHeader/OverviewHeader.styles';
import { PREVIEW_LATEST_ITEMS } from 'constants/pagination';
import { EmptyStateCard, ImpactClaimsCollection, ThemeButton } from 'designSystem';
import LogSummary from 'designSystem/DataDisplay/LogSummary/LogSummary';
import Icon from 'designSystem/Primitives/Icon/Icon';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import usePartnerOverview from 'hooks/usePartnerOverview';
import React, { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CompanyStatus } from 'types/company.types';
import { AvailableSizes, BackgroundTypes, ItemTypes } from 'types/enums';
import { getCompanyStatusColor, getReadableCompanyStatus } from 'utils/partner.utils';

export const RequestIcon = styled('img')(() => ({
  width: 18,
}));

export const SeeAllLink = styled(Link)(({ theme }) => ({
  color: theme.custom.themeColors.primary[100],
  textDecoration: 'none',
  fontFamily: 'Lato',
  fontSize: '11px',
  fontWeight: 700,
  lineHeight: '16px',
  padding: '4px 8px',
  '&:hover': {
    background: '#F4F5FA',
    color: '#5D6D98',
  },
}));

const PartnerOverview: FC = () => {
  const {
    partner,
    hasAnyData,
    components,
    activities,
    sites,
    requests,
    cultivatedAreas,
    impactClaims,
    documents,
    latestLog,
    loading,
    loadingCultivatedAreas,
    error,
    isFreemium,
    isAllowedToSendRequests,
    isPartnerInvitationsEnabled,
    showInvitePartner,
    showInvitePartnerNextStep,
    showReinvitePartnerNextStep,
    hasNextPageCultivatedAreas,
    handleSkipInviteStep,
    handleAddActivity,
    handleEditActivity,
    handleEditCultivatedArea,
    handleResendInvite,
    handleEditPartner,
    handleAddSite,
    handleEditSite,
    handleAddComponent,
    handleEditComponent,
    handleDeleteComponent,
    handleDeleteSite,
    handleFetchMoreCultivatedAreas,
  } = usePartnerOverview();
  const { handleRemoveActivity } = usePartnerMutations();
  const navigate = useNavigate();

  if (loading) return <Loader />;
  if (error || !partner) return <ErrorState action={undefined} />;

  return (
    <PageContainer>
      <PageTitle
        autoBackNavigation
        title="Partner overview"
        goBackLabel="All Partners"
        goBackUrl="/partners"
      />
      <Box mb={3} />

      <Grid container spacing={3}>
        <Grid item xs={12} lg={12} xl={8}>
          <OverviewHeader image={partner.logo} imageUrl={defaultPartnerImage}>
            <Box display="flex" justifyContent="space-between" flex={1} height="100%">
              <Box
                display="flex"
                flexDirection="column"
                flex={1}
                justifyContent="center"
                alignItems="flex-start"
              >
                <FlexBox>
                  <ThemeTypography variant="TITLE_MEDIUM" autoOverflow maxWidth={550}>
                    {partner.name}
                  </ThemeTypography>

                  {partner.externalId && (
                    <FlexBox ml={2}>
                      <ThemeTypography variant="TITLE_EXTRA_SMALL" color="GRAY_40">
                        |
                      </ThemeTypography>
                      <Box mr={1} />
                      <ThemeTypography
                        variant="TITLE_EXTRA_SMALL"
                        autoOverflow
                        maxWidth={200}
                        color="GRAY_40"
                      >
                        {partner.externalId}
                      </ThemeTypography>
                    </FlexBox>
                  )}
                </FlexBox>

                <FlexBox mb={1}>
                  <FlexBox mr={1}>
                    <Icon name="tag-filled" color="gray-80" size="small" mr={1} />
                    <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
                      Supplier
                    </ThemeTypography>
                  </FlexBox>

                  {isPartnerInvitationsEnabled && (
                    <ThemeTypography
                      variant="ITEM_SUBTITLE"
                      color={getCompanyStatusColor(partner.status)}
                    >
                      {getReadableCompanyStatus(partner.status)}
                    </ThemeTypography>
                  )}
                </FlexBox>

                <Box display="flex">
                  <ThemeButton
                    startIcon={<Edit size={14} />}
                    size="large"
                    color="YELLOW"
                    loading={false}
                    onClick={() => handleEditPartner()}
                  >
                    Edit partner
                  </ThemeButton>

                  {showInvitePartner && (
                    <Box ml={1}>
                      <ThemeButton
                        startIcon={
                          <RequestIcon src={unknownDocument} alt="Document with question mark" />
                        }
                        size="large"
                        color="BLUE_ICE"
                        loading={false}
                        onClick={() => handleEditPartner(true)}
                      >
                        Invite partner
                      </ThemeButton>
                    </Box>
                  )}

                  {showReinvitePartnerNextStep && (
                    <Box ml={1}>
                      <ThemeButton
                        startIcon={<RequestIcon src={emailSend} alt="Envelop with arrow" />}
                        data-cy="resend-invite-btn"
                        size="large"
                        color="BLUE_ICE"
                        loading={false}
                        onClick={handleResendInvite}
                      >
                        Re-send invite
                      </ThemeButton>
                    </Box>
                  )}

                  {!isFreemium && (
                    <Box ml={2}>
                      <ImpactClaimsCollection
                        searchQuery={partner.name}
                        impactClaims={impactClaims}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </OverviewHeader>
        </Grid>

        {/* History of partner logs */}
        <Grid item xs={12} lg={6} xl={4}>
          <PageSubTitle title="History" mb={2} display={{ xl: 'none' }} />
          <OverviewHeaderContainer height={{ xl: '100%' }}>
            <LogSummary latestLog={latestLog} createdTimestamp={partner.createdTimestamp} />
          </OverviewHeaderContainer>
        </Grid>

        {hasAnyData && showInvitePartnerNextStep ? (
          <Grid item xs={12} lg={6} xl={12}>
            <PageSubTitle title="Next Steps" mb={2} />
            <PartnerInviteCard partner={partner} setSkipInviteStepCard={handleSkipInviteStep} />
          </Grid>
        ) : (
          <>
            {/* Latest partner sites  */}
            {!!sites.length && (
              <Grid item xs={12} lg={6}>
                <Box position="relative">
                  <PageSubTitle
                    title="Sites"
                    visibleItems={sites.length}
                    totalItems={partner.sitesCount}
                    mb={1}
                  >
                    <FlexBox gap={1}>
                      {partner.sitesCount && partner.sitesCount > PREVIEW_LATEST_ITEMS && (
                        <SeeAllLink to={`sites`}>See all sites</SeeAllLink>
                      )}
                      <ThemeButton
                        onClick={handleAddSite}
                        color="WHITE"
                        size="small"
                        data-cy="add-activity-button"
                        startIcon={<Plus size={16} />}
                      >
                        Add new
                      </ThemeButton>
                    </FlexBox>
                  </PageSubTitle>
                  {sites.map(site => (
                    <SiteItem
                      key={site.id}
                      site={site}
                      warnings={[]}
                      hideSeverity
                      onEditCultivatedArea={site => handleEditCultivatedArea(site.id)}
                      onEdit={handleEditSite}
                      onDelete={() => handleDeleteSite(site.id)}
                      onClick={() => navigate(`/sites/external/${site.id}`)}
                    />
                  ))}
                </Box>
              </Grid>
            )}

            {/* Latest partner components  */}
            {!!components.length && (
              <Grid item xs={12} lg={6}>
                <Box position="relative">
                  <PageSubTitle
                    title="Components"
                    visibleItems={components.length}
                    totalItems={partner.componentsCount}
                    mb={1}
                  >
                    <FlexBox gap={1}>
                      {partner.componentsCount &&
                        partner.componentsCount > PREVIEW_LATEST_ITEMS && (
                          <SeeAllLink to={`components`}>See all components</SeeAllLink>
                        )}
                      <ThemeButton
                        onClick={handleAddComponent}
                        color="WHITE"
                        size="small"
                        startIcon={<Plus size={16} />}
                      >
                        Add new
                      </ThemeButton>
                    </FlexBox>
                  </PageSubTitle>

                  {components.map(component => (
                    <ComponentItem
                      key={component.id}
                      componentItem={component}
                      onEdit={handleEditComponent}
                      onDelete={handleDeleteComponent}
                      onClick={() => navigate(`/components/${component.id}`)}
                    />
                  ))}
                </Box>
              </Grid>
            )}

            {/* Latest partner activities */}
            {!!activities.length && (
              <Grid item xs={12} lg={6}>
                <Box position="relative">
                  <PageSubTitle
                    title="Activities"
                    visibleItems={activities.length}
                    totalItems={partner.activitiesCount}
                    mb={1}
                  >
                    <FlexBox gap={1}>
                      {partner.activitiesCount &&
                        partner.activitiesCount > PREVIEW_LATEST_ITEMS && (
                          <SeeAllLink to={`activities`}>See all activities</SeeAllLink>
                        )}
                      <ThemeButton
                        onClick={handleAddActivity}
                        color="WHITE"
                        size="small"
                        startIcon={<Plus size={16} />}
                      >
                        Add new
                      </ThemeButton>
                    </FlexBox>
                  </PageSubTitle>
                  {activities.map(activity => (
                    <ActivityItem
                      key={activity.id}
                      activity={activity}
                      onEdit={() => handleEditActivity(activity)}
                      onDelete={() =>
                        handleRemoveActivity({ activityId: activity.id, alertFirst: true })
                      }
                      onClick={handleEditActivity}
                      // TODO: Enable route to overview as soon as we have it
                      // onClick={() => navigate(`/activities/external/${activity.id}`)}
                    />
                  ))}
                </Box>
              </Grid>
            )}

            {/* Latest partner cultivated areas  */}
            {!!partner?.cultivatedAreaCount && (
              <Grid item xs={12} lg={6}>
                <PaginatedMap
                  cultivatedAreas={cultivatedAreas}
                  loading={loadingCultivatedAreas}
                  hasNextPage={hasNextPageCultivatedAreas}
                  itemCount={partner.cultivatedAreaCount}
                  onFetchMore={handleFetchMoreCultivatedAreas}
                  onEditCultivatedArea={handleEditCultivatedArea}
                />
              </Grid>
            )}

            {/* Latest partner requests or   */}
            {isAllowedToSendRequests && (
              <Grid item xs={12} lg={6}>
                <PartnerRequestOverview
                  requests={requests}
                  totalRequests={partner.requestsCount}
                  partner={partner}
                />
              </Grid>
            )}

            {!!documents.length && (
              <Grid item xs={12} lg={6}>
                <PartnerDocumentOverview
                  documents={documents}
                  totalDocuments={partner.sharedDocumentsCount}
                  partner={partner}
                />
              </Grid>
            )}

            {!activities.length && (
              <Grid item xs={12} lg={6}>
                <EmptyStateCard
                  variant={ItemTypes.LOCATION}
                  onCreateItemClick={handleAddActivity}
                  buttonText="Add activity"
                  title={
                    partner.status === CompanyStatus.INVITED ||
                    partner.status === CompanyStatus.REGISTERED
                      ? 'Give your partner a head start and add the partner activities you know of'
                      : 'Add the activities which belong to this partner, to reuse in your product communication'
                  }
                  size={AvailableSizes.LARGE}
                  background={BackgroundTypes.WORLD}
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
    </PageContainer>
  );
};

export default PartnerOverview;
