import { Box, DialogActions, DialogContent } from '@mui/material';
import { FlexBox } from 'components/Structure';
import { DialogDefault, ThemeButton, ThemeTypography } from 'designSystem';
import React, { FC, useState } from 'react';
import { IDefaultDialogProps } from 'types/dialog.types';
import { IClusterSite } from 'types/site.types';
import useSiteMutations from './hooks/useSiteMutations';

interface IDeleteSiteClusterDialogProps extends IDefaultDialogProps {
  siteCluster: IClusterSite;
}

const DeleteSiteClusterDialog: FC<IDeleteSiteClusterDialogProps> = ({
  open,
  siteCluster,
  onClose,
}) => {
  const { deleteSite } = useSiteMutations();

  const [loadingDeleteAllSites, setDeleteAllSitesLoading] = useState(false);
  const [loadingDeleteOnlyCluster, setDeleteOnlyClusterLoading] = useState(false);

  const handleDeleteAllSites = async () => {
    setDeleteAllSitesLoading(true);
    await deleteSite({ variables: { id: siteCluster.id, deleteSitesFromCluster: true } });
    onClose?.();
  };

  const handleDeleteOnlyCluster = async () => {
    setDeleteOnlyClusterLoading(true);
    await deleteSite({ variables: { id: siteCluster.id, deleteSitesFromCluster: false } });
    onClose?.();
  };

  return (
    <DialogDefault
      open={open}
      title="Delete site cluster"
      itemTitle={siteCluster.title}
      fullWidth
      maxWidth="md"
      onClose={onClose}
    >
      <DialogContent>
        <Box mb={1}>
          <ThemeTypography variant="BODY_LARGE">
            You are about to delete the site cluster. You can choose if you want to delete all sites
            of the cluster or keep them in your account. All data will be lost and you will not be
            able to recover this item.
          </ThemeTypography>
        </Box>
      </DialogContent>
      <DialogActions>
        <FlexBox width="100%" justifyContent="space-between">
          <ThemeButton
            color="BLUE_ICE"
            size="large"
            data-cy="alert-dialog-cancel"
            onClick={onClose}
          >
            Cancel
          </ThemeButton>
          <FlexBox gap={1}>
            <ThemeButton
              loading={loadingDeleteAllSites}
              onClick={handleDeleteAllSites}
              color="YELLOW"
              size="large"
              autoFocus
            >
              Delete cluster and all sites
            </ThemeButton>
            <ThemeButton
              loading={loadingDeleteOnlyCluster}
              onClick={handleDeleteOnlyCluster}
              color="YELLOW"
              size="large"
              autoFocus
            >
              Delete cluster and keep sites
            </ThemeButton>
          </FlexBox>
        </FlexBox>
      </DialogActions>
    </DialogDefault>
  );
};

export default DeleteSiteClusterDialog;
