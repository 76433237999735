import { Box, Grid, Link } from '@mui/material';
import { Plus } from '@styled-icons/bootstrap/Plus';
import ActivityItem from 'components/Partners/ActivitiesTable/ActivityItem';
import { usePartnerMutations } from 'components/Partners/hooks';
import { ProductSummarySkeleton } from 'components/Product/ProductTable';
import { PageSubTitle } from 'components/Structure';
import { useConfig } from 'components/hooks';
import { CardContainer, ThemeButton } from 'designSystem';
import useActivities from 'hooks/useActivities';
import React, { FC, Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const ActivityOverview: FC = () => {
  const { handleRemoveActivity } = usePartnerMutations();
  const { handleCreateActivity, activities, loading, handleEditActivity } = useActivities();
  const { homeProductsMaxLength } = useConfig();

  return (
    <>
      {loading ? (
        <Box flex={1} data-cy="home-activity-overview">
          {[...Array(homeProductsMaxLength).keys()].map(key => (
            <CardContainer key={key} padding={1.5} mt={1}>
              <ProductSummarySkeleton loading />
            </CardContainer>
          ))}
        </Box>
      ) : (
        !!activities.length && (
          <Grid item xs={12} lg={6}>
            <Box flex={1} data-cy="home-activity-overview">
              <PageSubTitle title="Activities" mb={2}>
                <Box display="flex" alignItems="center" gap={2.5}>
                  <Link
                    component={RouterLink}
                    to="/activities/internal"
                    color="secondary"
                    className="small"
                    data-cy="link-see-all-activities"
                  >
                    See all activities
                  </Link>
                  <ThemeButton
                    onClick={handleCreateActivity}
                    startIcon={<Plus size={20} />}
                    size="small"
                    color="WHITE"
                    data-cy="button-overview-create-new"
                  >
                    Add new
                  </ThemeButton>
                </Box>
              </PageSubTitle>
              {loading && !activities?.length ? (
                <Fragment>
                  {[...Array(homeProductsMaxLength).keys()].map(key => (
                    <CardContainer key={key} padding={1.5} mt={1}>
                      <ProductSummarySkeleton loading />
                    </CardContainer>
                  ))}
                </Fragment>
              ) : (
                <Box data-cy="home-activity-container">
                  {activities.slice(0, homeProductsMaxLength).map(activity => (
                    <ActivityItem
                      key={activity.id}
                      activity={activity}
                      onClick={() => handleEditActivity(activity)}
                      onEdit={() => handleEditActivity(activity)}
                      onDelete={() =>
                        handleRemoveActivity({ activityId: activity.id, alertFirst: true })
                      }
                    />
                  ))}
                </Box>
              )}
            </Box>
          </Grid>
        )
      )}
    </>
  );
};

export default ActivityOverview;
