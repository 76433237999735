import { Box, styled, AccordionDetails, AccordionSummary } from '@mui/material';
import { FlexBox } from 'components/Structure';
import { ThemeTypography } from 'designSystem';
import React, { FC } from 'react';
import { ERiskLevel } from 'types/compliance.types';
import { Accordion } from 'designSystem/DataDisplay/Accordion/Accordion';
import RiskBadge from 'designSystem/DataDisplay/RiskBadge/RiskBadge';
import Icon from 'designSystem/Primitives/Icon/Icon';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  '&.MuiAccordion-root': {
    margin: 0,
    backgroundColor: theme.custom.themeColors.grayScale[5],
  },

  '& .MuiAccordionDetails-root': {
    paddingTop: 0,
    background: 'transparent',
  },
}));

const RiskAnalysisAccordion: FC = () => {
  return (
    <StyledAccordion>
      <AccordionSummary>
        <FlexBox gap={1}>
          <Icon name="info-filled" color="gray-30" />
          <ThemeTypography color="ALMOST_BLACK" variant="BODY_MEDIUM_BOLD">
            Risk result categories
          </ThemeTypography>
        </FlexBox>
      </AccordionSummary>
      <AccordionDetails>
        <FlexBox gap={1} flexWrap="wrap">
          <Box bgcolor="white" padding={1} borderRadius="6px">
            <Box width="fit-content" mb={1}>
              <RiskBadge riskLevel={ERiskLevel.LOW} text="Low risk" />
            </Box>
            <ThemeTypography variant="BODY_SMALLER_BOLD" color="ALMOST_BLACK">
              No or only a negligible deforestation risk was detected.
            </ThemeTypography>
          </Box>
          <Box bgcolor="white" padding={1} borderRadius="6px">
            <Box width="fit-content" mb={1}>
              <RiskBadge riskLevel={ERiskLevel.MEDIUM} text="Medium risk" />
            </Box>
            <ThemeTypography variant="BODY_SMALLER_BOLD" color="ALMOST_BLACK">
              A moderate deforestation risk was detected. Action required.
            </ThemeTypography>
          </Box>
          <Box bgcolor="white" padding={1} borderRadius="6px">
            <Box width="fit-content" mb={1}>
              <RiskBadge riskLevel={ERiskLevel.HIGH} text="High risk" />
            </Box>
            <ThemeTypography variant="BODY_SMALLER_BOLD" color="ALMOST_BLACK">
              A high deforestation risk was detected. Action required.
            </ThemeTypography>
          </Box>
        </FlexBox>
        <ThemeTypography variant="BODY_SMALL"></ThemeTypography>
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default RiskAnalysisAccordion;
