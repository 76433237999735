import { Box, ButtonGroup } from '@mui/material';
import { Plus } from '@styled-icons/bootstrap';
import { Loader, SearchToolbar } from 'components/Forms';
import PartnerSkeleton from 'components/Partners/Skeleton/PartnerSkeleton';
import { SitesTableColumnKeys } from 'components/Sites/SitesTable/sitesHeaderColumns';
import SitesTable from 'components/Sites/SitesTable/SitesTable';
import {
  ActionContainer,
  EmptySearchResults,
  ErrorState,
  InfiniteScrollWrapper,
  PageContainer,
  PageTitle,
} from 'components/Structure';
import { ACTIVITIES_TABLE_ROWS_PER_PAGE } from 'constants/pagination';
import { CardIllustration, EmptyStateCard, ThemeButton } from 'designSystem';
import usePartnerSites from 'hooks/usePartnerSites';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { AvailableSizes, BackgroundTypes, IllustrationTypes, ItemTypes } from 'types/enums';
import { ISite, SiteViewOption } from 'types/site.types';

const PartnerSites: FC = () => {
  const navigate = useNavigate();
  const {
    sites,
    partner,
    error,
    loading,
    searchTerm,
    siteViewOption,
    hasNextPage,
    refetch,
    handleSiteViewOptionChange,
    handleSearchTermChange,
    handleCreateSite,
    handleEditSite,
    handleEditSiteCluster,
    handleDeleteSite,
    handleDeleteSiteCluster,
    handleManageClusterSites,
    handleEditSiteCultivatedArea,
    handleCreateSiteCluster,
  } = usePartnerSites();

  if (loading && sites === undefined) return <Loader />;
  if (error) return <ErrorState />;

  const handleSiteClick = ({ id }: ISite) => navigate(`/sites/external/${id}`);

  if (!sites.length && !searchTerm && !loading) {
    return (
      <PageContainer>
        <PageTitle
          title="Partner sites"
          goBackLabel="All Partners"
          goBackUrl="/partners"
          titleExtension={partner?.name}
        />
        <Box mt={4}>
          <EmptyStateCard
            data-testid="sites-getting-started-card"
            data-testid-button="sites-getting-started-card-button"
            title="Add the partner sites you know of to give your collaborator a head start"
            variant={ItemTypes.LOCATION}
            buttonText="Add new sites"
            background={BackgroundTypes.WORLD}
            size={AvailableSizes.LARGE}
            renderIllustration={
              <CardIllustration
                image={IllustrationTypes.LOCATIONS}
                width={300}
                right={25}
                top={0}
              />
            }
            onCreateItemClick={handleCreateSite}
          />
        </Box>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <PageTitle
        title="Partner sites"
        goBackLabel="All Partners"
        goBackUrl="/partners"
        titleExtension={partner?.name}
      />
      <Box mt={2} position="relative">
        <ActionContainer>
          <ButtonGroup size="small">
            <ThemeButton
              className={siteViewOption === SiteViewOption.CLUSTER ? 'selected' : ''}
              onClick={() => handleSiteViewOptionChange(SiteViewOption.CLUSTER)}
            >
              Cluster view
            </ThemeButton>
            <ThemeButton
              className={siteViewOption === SiteViewOption.LIST ? 'selected' : ''}
              onClick={() => handleSiteViewOptionChange(SiteViewOption.LIST)}
            >
              List view
            </ThemeButton>
          </ButtonGroup>
          <Box mr={1} />
          <SearchToolbar
            setSearchTerm={handleSearchTermChange}
            searchTerm={searchTerm || ''}
            className="search-input"
            data-cy="sites-search-input"
            placeholder="Search sites"
            actions={
              <Box ml={1} display="flex" gap={1}>
                <ThemeButton
                  size="medium"
                  color="WHITE"
                  onClick={handleCreateSiteCluster}
                  startIcon={<Plus size={20} />}
                  data-cy="create-cluster-btn"
                >
                  Add origin cluster
                </ThemeButton>
                <ThemeButton
                  size="medium"
                  color="YELLOW"
                  onClick={() => handleCreateSite()}
                  startIcon={<Plus size={20} />}
                  data-cy="create-site-btn"
                >
                  Add site
                </ThemeButton>
              </Box>
            }
          />
        </ActionContainer>

        {sites.length > 0 && !loading && (
          <InfiniteScrollWrapper hasMore={hasNextPage} next={refetch}>
            <SitesTable
              columns={[
                SitesTableColumnKeys.TITLE,
                SitesTableColumnKeys.MODIFIED_TIMESTAMP,
                SitesTableColumnKeys.ACTIONS,
              ]}
              rowsPerPage={ACTIVITIES_TABLE_ROWS_PER_PAGE}
              sites={sites}
              siteClusterBaseLink="/sites/external"
              onSiteClick={handleSiteClick}
              onSiteEdit={handleEditSite}
              onSiteClusterEdit={handleEditSiteCluster}
              onSiteDelete={handleDeleteSite}
              onSiteClusterDelete={handleDeleteSiteCluster}
              onManageClusterSites={handleManageClusterSites}
              onEditCultivatedArea={handleEditSiteCultivatedArea}
            />
          </InfiniteScrollWrapper>
        )}
      </Box>

      {loading && <Loader />}

      {!sites.length && !loading && (
        <EmptySearchResults
          message="No search results found"
          action={{
            label: 'Create new site',
            onClick: handleCreateSite,
          }}
          skeletonComponent={<PartnerSkeleton title={searchTerm || ''} />}
        />
      )}
    </PageContainer>
  );
};

export default PartnerSites;
