import { Box, TableCell, Tooltip, styled } from '@mui/material';
import { Trash } from '@styled-icons/bootstrap';
import { PencilSquare } from '@styled-icons/bootstrap/PencilSquare';
import { PartnerPlaceholderImage } from 'assets/img';
import {
  Date,
  IconContainer,
  Location,
  StyledCell,
  StyledIconButton,
  TableRow,
} from 'components/Partners/PartnersTable/PartnersTableBody/PartnersTableBody.styles';
import { usePartnerMutations } from 'components/Partners/hooks';
import { FlexBox } from 'components/Structure';
import { StyledTableBody } from 'components/TableBase';
import { ThemeTypography } from 'designSystem';
import Image from 'designSystem/DataDisplay/Image/Image';
import Icon from 'designSystem/Primitives/Icon/Icon';
import React, { FC, MouseEvent } from 'react';
import Moment from 'react-moment';
import { IActivity } from 'types/activity.types';
import { Booleanish } from 'types/booleanish.types';
import { HeaderColumn } from 'types/types';
import { ActivitiesTableColumnKeys } from './activitiesHeaderColumns';

interface Props {
  activities: IActivity[];
  rowsPerPage: number;
  currentPage: number;
  isTransparent?: boolean;
  headerColumns: HeaderColumn[];
  onSelect: (activity: IActivity) => void;
  onEdit: (activity: IActivity) => void;
}

const Tag = styled(FlexBox)(({ theme }) => ({
  background: theme.custom.colors.lightBorderColor,
  height: 21,
  padding: theme.spacing(1),
}));

const StyledImage = styled('div')<{ backgroundImage: string }>(({ backgroundImage }) => ({
  width: '42px',
  height: '42px',
  flexShrink: 0,
  backgroundImage: `url(${backgroundImage})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  borderRadius: 4,
}));

const ActivityTableBody: FC<Props> = ({
  activities,
  onSelect,
  onEdit,
  rowsPerPage,
  currentPage,
  isTransparent = false,
  headerColumns,
}) => {
  const { handleRemoveActivity } = usePartnerMutations();

  return (
    <StyledTableBody data-cy="activities-table-body">
      {activities
        .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
        .map((activity, index) => {
          const { id, title, mediaList } = activity;

          const labelId = `partner-table-${id}`;
          const firstMediaWithImage = mediaList.find(
            media => media.imageId || media.imageVariantId
          );

          return (
            <TableRow
              data-cy="activity-table-row"
              data-cy-id={id}
              hover
              id={labelId}
              is-transparent={Booleanish(isTransparent)}
              key={id}
              onClick={(event: MouseEvent) => {
                event.stopPropagation();
                onSelect(activity);
              }}
            >
              {headerColumns.map(({ id: columnId, align }) => {
                switch (columnId) {
                  case ActivitiesTableColumnKeys.TITLE: {
                    return (
                      <StyledCell key={`${id}-${columnId}-title`} scope="row" align={align}>
                        <FlexBox maxWidth="100%" justifyContent="space-between">
                          {/*  48px for the action button container & 24px padding */}
                          <FlexBox width="calc(100% - 48px - 24px)">
                            {firstMediaWithImage?.url ? (
                              <Image
                                alt={`Logo of ${title}`}
                                image={firstMediaWithImage}
                                height={44}
                                width={44}
                              />
                            ) : (
                              <Box display="flex" bgcolor="white" borderRadius="6px" padding="4px">
                                <Icon
                                  name="activity-placeholder"
                                  color="blue-ice"
                                  size="xx-large"
                                />
                              </Box>
                            )}
                            {/* 8px py padding & 44px image & margin 16px */}
                            <Box
                              width="calc(100% - 68px)"
                              ml={1.5}
                              display="flex"
                              flexDirection="column"
                            >
                              <FlexBox maxWidth="100%">
                                <ThemeTypography variant="ITEM_TITLE" autoOverflow>
                                  {activity.title}
                                </ThemeTypography>
                                {activity.component && (
                                  <>
                                    <Icon name="arrow-right" size="large" mx={1} />
                                    <Tag>
                                      <ThemeTypography variant="LABEL_INPUT" autoOverflow>
                                        {activity.component?.title}
                                      </ThemeTypography>
                                    </Tag>
                                  </>
                                )}
                              </FlexBox>
                              <Location autoOverflow variant="BODY_MEDIUM">
                                {activity.site.locationName}
                              </Location>
                            </Box>
                          </FlexBox>
                        </FlexBox>
                      </StyledCell>
                    );
                  }

                  case ActivitiesTableColumnKeys.PARTNER: {
                    return (
                      <StyledCell key={`${id}-${index}-partner`} scope="row" align={align}>
                        <Box display="flex" alignItems="center">
                          <StyledImage
                            backgroundImage={activity.ownedBy?.logo?.url || PartnerPlaceholderImage}
                          />
                          <Box ml={2}>
                            <ThemeTypography variant="ITEM_SUBTITLE">
                              {activity.ownedBy?.name || 'Unknown'}
                            </ThemeTypography>
                          </Box>
                        </Box>
                      </StyledCell>
                    );
                  }
                  case ActivitiesTableColumnKeys.MODIFIED_TIMESTAMP: {
                    return (
                      <StyledCell key={`${id}-${index}-created-at`} scope="row" align={align}>
                        <Date variant="subtitle2">
                          <Moment format="LL">{activity.modifiedTimestamp}</Moment>
                        </Date>
                      </StyledCell>
                    );
                  }
                  case ActivitiesTableColumnKeys.ACTIONS: {
                    return (
                      <TableCell
                        key={`${id}-${columnId}-actions`}
                        width={100}
                        scope="row"
                        align={align}
                      >
                        <IconContainer>
                          {[
                            {
                              icon: <PencilSquare size="15" />,
                              tooltip: 'Edit partner location',
                              onClick: onEdit,
                              dataCy: 'edit-activity-btn',
                            },
                            {
                              icon: <Trash size="15" />,
                              tooltip: 'Remove partner location',
                              onClick: () =>
                                handleRemoveActivity({
                                  activityId: activity.id,
                                  alertFirst: true,
                                }),
                              dataCy: 'delete-activity-btn',
                            },
                          ].map((action, index) => {
                            return (
                              <Tooltip key={index} title={action.tooltip}>
                                <StyledIconButton
                                  data-cy={action.dataCy}
                                  onClick={event => {
                                    event.stopPropagation();
                                    action.onClick(activity);
                                  }}
                                >
                                  {action.icon}
                                </StyledIconButton>
                              </Tooltip>
                            );
                          })}
                        </IconContainer>
                      </TableCell>
                    );
                  }
                  default:
                    return <></>;
                }
              })}
            </TableRow>
          );
        })}
    </StyledTableBody>
  );
};

export default ActivityTableBody;
