import { Box } from '@mui/material';
import { ErrorState, PageContainer, PageTitle } from 'components/Structure';
import { useDialog, usePageTitle } from 'components/hooks';
import React, { FC } from 'react';
import useDueDiligenceProcesses from 'components/DueDiligenceProcess/hooks/useDueDiligenceProcesses';
import { Loader } from 'components/Forms';
import useDueDiligenceProcessMutations from 'hooks/useDueDiligenceProcessMutations';
import { useNavigate } from 'react-router-dom';
import DueDiligenceHistoryTable from 'components/DueDiligenceProcess/components/DueDiligenceHistoryTable/DueDiligenceHistoryTable';
import { ThemeButton } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';

const EudrDueDiligenceHistory: FC = () => {
  usePageTitle('Due diligence history');
  const navigate = useNavigate();
  const { openDialog } = useDialog();

  const { dueDiligenceProcesses, loading, error, hasNextPage, handlePageEndReached } =
    useDueDiligenceProcesses();
  const { deleteDueDiligenceProcess } = useDueDiligenceProcessMutations();

  const handleCreateNewProcess = () => navigate('/compliance/eudr/guide');

  const handleNavigateToProcessGuide = (dueDiligenceProcessId: string) => {
    navigate(`/compliance/eudr/${dueDiligenceProcessId}/guide`);
  };

  const handleDeleteProcess = (dueDiligenceProcessId: string) => {
    openDialog({
      type: 'ALERT',
      props: {
        title: 'Remove due diligence process',
        text: 'Are you sure you want to remove this due diligence process? ',
        cancelText: 'Cancel',
        submitText: 'Confirm',
        onCancel: () => undefined,
        onSubmit: () => {
          deleteDueDiligenceProcess(dueDiligenceProcessId);
        },
      },
    });
  };

  if (loading) return <Loader />;
  if (error || !dueDiligenceProcesses) return <ErrorState />;
  return (
    <PageContainer>
      <PageTitle
        title="Due diligence history"
        titleExtension="EUDR"
        goBackLabel="Regulation overview"
        goBackUrl="/compliance"
      />

      <Box overflow="hidden" px={2}>
        <Box mt={3}>
          <ThemeButton startIcon={<Icon name="guide" />} onClick={handleCreateNewProcess}>
            Start new process
          </ThemeButton>
        </Box>
        <DueDiligenceHistoryTable
          dueDiligenceProcesses={dueDiligenceProcesses}
          hasNextPage={hasNextPage}
          onEndReached={handlePageEndReached}
          onNavigateToProcessGuide={handleNavigateToProcessGuide}
          onDeleteProcess={handleDeleteProcess}
        />
      </Box>
    </PageContainer>
  );
};

export default EudrDueDiligenceHistory;
