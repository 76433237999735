import { Box, CircularProgress, styled } from '@mui/material';
import { FlexBox, PageSubTitle } from 'components/Structure';
import { InfoTooltip, ThemeTypography } from 'designSystem';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { AvailableSizes } from 'types/enums';
import CultivationFarmArea from './CultivationFarmArea';
import { ICultivatedAreas } from 'types/cultivatedArea.types';
import { CULTIVATION_AREAS_PER_PAGE } from 'constants/pagination';
import { DatasetError } from 'types/dataset.types';
import { IFarmSite } from 'types/site.types';
import { Booleanish, booleanish } from 'types/booleanish.types';

type IPaginatedMapProps = ({ cultivatedAreas: ICultivatedAreas[] } | { farms: IFarmSite[] }) & {
  hasNextPage: boolean;
  itemCount: number;
  /**
   * Shows the partner that own the cultivation areas
   */
  showOwner?: boolean;
  /**
   * Warnings that are shown in the info popup
   */
  warnings?: DatasetError[];
  warningSeverity?: 'error' | 'warning';
  loading?: boolean;
  itemsPerPage?: number;
  title?: string;
  hideTitle?: boolean;
  tooltipText?: string;
  onFetchMore: () => void;
  onEditCultivatedArea?: (farmId: string) => void;
};

const LinkButton = styled('div')<{ loading?: booleanish; disabled?: booleanish }>(
  ({ theme, loading, disabled }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    fontSize: 12,
    color:
      loading === 'true' || disabled === 'true'
        ? theme.palette.text.disabled
        : theme.custom.themeColors.black,
    cursor: loading === 'true' || disabled === 'true' ? 'cursor' : 'pointer',
    textDecoration: 'underline',
    pointerEvents: loading === 'true' || disabled === 'true' ? 'none' : 'auto',
  })
);

const PaginatedMap: FC<IPaginatedMapProps> = ({
  hasNextPage,
  loading,
  itemsPerPage = CULTIVATION_AREAS_PER_PAGE,
  itemCount,
  warnings,
  warningSeverity,
  showOwner,
  title,
  hideTitle,
  tooltipText,
  onFetchMore,
  onEditCultivatedArea,
  ...rest
}) => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [lastPage, setLastPage] = useState<number | null>(null);

  const { cultivatedAreas, farms } = useMemo(() => {
    return { farms: [], cultivatedAreas: [], ...rest };
    // @ts-ignore For some reason there is a strange rendering issue using props as dependency and typescript is not happy using fields that may not exist
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.cultivatedAreas, rest.farms]);

  const currentCultivatedAreas: ICultivatedAreas[] = useMemo(
    () => cultivatedAreas.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cultivatedAreas, currentPage]
  );

  const currentFarms: IFarmSite[] = useMemo(
    () => farms.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [farms, currentPage]
  );

  const hasNextPageAvailable = useMemo(
    () => hasNextPage || currentPage < Math.ceil(itemCount / itemsPerPage) - 1,
    [itemCount, itemsPerPage, hasNextPage, currentPage]
  );

  useEffect(() => {
    setLastPage(prev => (hasNextPage ? currentPage : prev));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPage, currentPage]);

  const handleLoadNextCultivatedAreas = async () => {
    if (
      ('cultivatedAreas' in rest &&
        cultivatedAreas.length <= (currentPage + 1) * CULTIVATION_AREAS_PER_PAGE) ||
      ('farms' in rest && farms.length <= (currentPage + 1) * CULTIVATION_AREAS_PER_PAGE)
    ) {
      await onFetchMore();
    }

    setCurrentPage(currentPage => currentPage + 1);
  };

  const handleLoadPreviousCultivatedAreas = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage => currentPage - 1);
    }
  };

  const startCultivatedAreaIndex = useMemo(
    () => currentPage * itemsPerPage + 1,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage]
  );

  const endCultivatedAreaIndex = useMemo(
    () => (currentCultivatedAreas.length || currentFarms.length) + currentPage * itemsPerPage,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentCultivatedAreas, currentFarms, currentPage]
  );

  return (
    <Box flex={1} data-cy="cultivated-area" minHeight={400} height="100%">
      <PageSubTitle
        title={hideTitle ? '' : title || 'Origin cultivation areas'}
        spaceBetween={false}
        mb={1}
        sx={{ textWrap: 'nowrap' }}
      >
        <FlexBox width="100%" alignItems="center" gap={0.5}>
          {!hideTitle && (
            <FlexBox ml={1}>
              <ThemeTypography variant="BODY_MEDIUM" color="GRAY_100">
                ({startCultivatedAreaIndex}-{endCultivatedAreaIndex}{' '}
                {itemCount ? `of ${itemCount}` : undefined})
              </ThemeTypography>
              <InfoTooltip
                text={
                  tooltipText ||
                  'Overview all origin cultivation areas for this partner. This could be locations of sites that this partner own or locations that they source from.'
                }
                size={AvailableSizes.SMALL}
              />
            </FlexBox>
          )}

          <FlexBox gap={1}>
            {hideTitle && (
              <FlexBox gap={0.5}>
                <ThemeTypography variant="BODY_MEDIUM" color="GRAY_100">
                  Showing {cultivatedAreas.length ? 'cultivated areas' : 'farms'}{' '}
                  {startCultivatedAreaIndex}-{endCultivatedAreaIndex}{' '}
                  {itemCount ? `of ${itemCount}` : undefined}
                </ThemeTypography>
                <InfoTooltip
                  text={
                    tooltipText ||
                    'Overview all origin cultivation areas for this partner. This could be locations of sites that this partner own or locations that they source from.'
                  }
                  size={AvailableSizes.SMALL}
                />
              </FlexBox>
            )}
            {!!lastPage && currentPage > 0 && (
              <LinkButton
                onClick={loading ? undefined : handleLoadPreviousCultivatedAreas}
                disabled={Booleanish(loading)}
              >
                Load previous
              </LinkButton>
            )}
            {(loading || (itemCount && itemCount > itemsPerPage && hasNextPageAvailable)) && (
              <LinkButton
                loading={Booleanish(loading)}
                onClick={loading ? undefined : handleLoadNextCultivatedAreas}
              >
                Load next
                {loading && <CircularProgress color="primary" size={10} />}
              </LinkButton>
            )}
          </FlexBox>
        </FlexBox>
      </PageSubTitle>

      <CultivationFarmArea
        cultivatedAreas={currentCultivatedAreas}
        farms={currentFarms}
        warningSeverity={warningSeverity}
        warnings={warnings}
        showOwner={showOwner}
        onEditCultivatedAreaClick={onEditCultivatedArea}
      />
    </Box>
  );
};

export default PaginatedMap;
