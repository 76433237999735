import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import { addressSchema } from 'constants/schemas';
import { FormControlBar } from 'components/WelcomeSlides';
import { useMutation, useQuery } from '@apollo/client';
import { Fade, styled, Box } from '@mui/material';
import { GET_COMPANY_DETAILS } from 'graphql/queries';
import { useLogEvent, useMessages } from 'components/hooks';
import { EDIT_COMPANY } from 'graphql/mutations';
import { ErrorState } from 'components/Structure';
import { Loader } from 'components/Forms';
import mapSection from 'assets/img/welcomeSlides/map-section-berlin.png';
import { PageContainer, TextContainer, ImageContainer, Headline, SkipButton } from './styles';
import { useWelcomeSlides } from 'components/WelcomeSlides/hooks';
import AddressFields from 'components/Settings/AddressFields';

interface AddressFormValues {
  street: string;
  streetNumber: string;
  zipCode: string;
  city: string;
  country: string;
}

interface IAddressProps {
  active: boolean;
}

const StyledTextContainer = styled(TextContainer)(({ theme }) => ({
  width: '55%',
}));

const StyledImageContainer = styled(ImageContainer)(({ theme }) => ({
  width: '45%',
}));

const Address: FC<IAddressProps> = ({ active }) => {
  const { nextSlide } = useWelcomeSlides();
  const { logEvent } = useLogEvent();
  const {
    data: { company } = {},
    error,
    loading,
  } = useQuery(GET_COMPANY_DETAILS, {
    fetchPolicy: 'cache-only',
  });

  const { setSuccessMessage, setErrorMessage } = useMessages();
  const [editCompany] = useMutation(EDIT_COMPANY, {
    onCompleted: () => {
      setSuccessMessage('Success! Company details were updated.');
      logEvent('WELCOME_SLIDES_UPDATE_ADDRESS');
    },
    onError: () => {
      setErrorMessage('Something went wrong updating your companies details.');
    },
  });

  const handleSubmit = (input: { address: AddressFormValues }) => {
    return editCompany({
      variables: {
        input: {
          name: company?.name,
          website: company?.website,
          ...input,
        },
      },
    });
  };

  if (error) return <ErrorState error={error} />;
  if (!company && loading) return <Loader />;

  return (
    <Formik
      initialValues={{
        ...(company?.address || addressSchema.default()),
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={addressSchema}
      onSubmit={values => handleSubmit({ address: { ...values } })}
    >
      {({ isSubmitting, submitForm }) => (
        <>
          <PageContainer>
            <StyledTextContainer>
              <Headline variant="TITLE_LARGE">Company address</Headline>
              <Box mt={3}>
                <Form>
                  <AddressFields fullWidth />
                </Form>
              </Box>
            </StyledTextContainer>
            <Fade in={active} timeout={500}>
              <StyledImageContainer>
                <SkipButton onClick={nextSlide}>Add this later</SkipButton>
                <img alt="Map section of Berlin" src={mapSection} />
              </StyledImageContainer>
            </Fade>
          </PageContainer>
          <FormControlBar onSubmit={submitForm} isSubmitting={isSubmitting} />
        </>
      )}
    </Formik>
  );
};

export default Address;
