export const PARTNER_REQUEST_ROWS_PER_PAGE = 5;

export const PARTNER_REQUEST_RECEIVED_ROWS_PER_PAGE = 10;

export const COMPONENTS_TABLE_ROWS_PER_PAGE = 6;

export const SUPPLY_CHAIN_TABLE_ROWS_PER_PAGE = 5;

export const ACTIVITIES_TABLE_ROWS_PER_PAGE = 10;

export const PREVIEW_LATEST_ITEMS = 3;

export const SITES_TABLE_ROWS_PER_PAGE = 15;

export const CULTIVATION_AREAS_PER_PAGE = 100;

export const INITIAL_TABLE_ROWS = 100;

export const DUE_DILIGENCE_PROCESSES_PER_PAGE = 15;
