import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import PartnerAutocompleteWrapper from 'components/ChainMapping/Forms/PartnerAutocomplete';
import RawMaterialSelection from 'components/ComponentsLibrary/Forms/RawMaterialSelection';
import { TextInputField } from 'components/Forms';
import CountrySelectField from 'components/Forms/CountrySelectField';
import { FlexBox } from 'components/Structure';
import { IDatasetFormValue } from 'constants/schemas/geoUpload.schema';
import { ThemeButton, ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { Form, FormikProps } from 'formik';
import isEqual from 'lodash/isEqual';
import React, { FC, useEffect } from 'react';

interface IDatasetFormProps extends FormikProps<IDatasetFormValue> {
  disableOwnerSelection?: boolean;
  isTableView?: boolean;
  showOnlyEudrRelevantRawMaterials?: boolean;
  hideSubmitButton?: boolean;
  submitButtonText?: string;
  onTableSettingsClick?: () => void;
  onFormChange?: () => void;
}

const SelectContainer = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
  background: theme.custom.themeColors.white,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'flex-end',
  width: '100%',
}));

const DropdownContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  gap: theme.spacing(2),
  width: '100%',
  '& > div': {
    flex: 3,
  },
}));

const HintContainer = styled('div')(({ theme }) => ({
  background: theme.custom.themeColors.primary[5],
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  height: 'fit-content',
  border: `1px solid ${theme.custom.themeColors.primary[40]}`,
}));

const DatasetForm: FC<IDatasetFormProps> = ({
  values,
  initialValues,
  isValid,
  isSubmitting,
  isTableView,
  disableOwnerSelection,
  showOnlyEudrRelevantRawMaterials,
  hideSubmitButton,
  submitButtonText,
  onTableSettingsClick,
  onFormChange,
}) => {
  useEffect(() => {
    if (!isEqual(values, initialValues)) {
      onFormChange?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, initialValues]);

  return (
    <Form>
      <Box display="flex" flexDirection="column" gap={2}>
        <SelectContainer>
          <DropdownContainer>
            <TextInputField
              name="title"
              isRequired
              helperText="Add a name to your geo-dataset that helps you and your colleagues remember to what origin this dataset refers to. Using specific region names could be helpful."
              label="Title"
              size="small"
              placeholder='E.g. "Origin, geo-data Ghana"'
            />
            <RawMaterialSelection
              isRequired
              eudrRelevant={showOnlyEudrRelevantRawMaterials}
              multiple={false}
              name="rawMaterialId"
              size="small"
              helperText="Select the commodity that is sourced from this origin."
            />
            <CountrySelectField
              isRequired
              name="countryCode"
              label="Country of origin"
              helperText="Select the country where this origin geo-data is located. You can only have one country per origin geo-dataset."
            />
            <PartnerAutocompleteWrapper
              isRequired
              name="ownedBy"
              label="Owner"
              disabled={disableOwnerSelection}
              helperText="Select an owner of this origin geo-dataset. This would optimally be a supplier that is involved in the supply chain connected to this origin. You can also select your own company."
            />
          </DropdownContainer>

          <FlexBox justifyContent="flex-end" gap={2}>
            {isTableView && (
              <ThemeButton
                variant="contained"
                color="BLUE_ICE"
                onClick={onTableSettingsClick}
                startIcon={<Icon name="settings-filled" size="small" />}
                data-tut="onboarding_tour_data_import_settings"
              >
                Table settings
              </ThemeButton>
            )}

            {!hideSubmitButton && (
              <ThemeButton
                type="submit"
                disabled={!isValid || (!isTableView && isEqual(values, initialValues))}
                loading={isSubmitting}
              >
                {submitButtonText ? submitButtonText : isTableView ? 'Save & verify' : 'Save'}
              </ThemeButton>
            )}
          </FlexBox>
        </SelectContainer>

        {isTableView && (
          <HintContainer>
            <ThemeTypography variant="BODY_MEDIUM" color="BLUE_80">
              If you plan to use this geo-data for EUDR compliance, it is recommended to include the
              size of each farm plot. For single location plots that are missing a size attribute,
              you can add the size here. Otherwise, when this geo-data is used for an EUDR
              deforestation analysis, the plot size of each plot with a single coordinate will
              during the analysis default to 4 ha, according to regulation requirements.
            </ThemeTypography>
          </HintContainer>
        )}
      </Box>
    </Form>
  );
};

export default DatasetForm;
