import { useMutation } from '@apollo/client';
import { Box, styled } from '@mui/material';
import { FileEarmarkText, Trash } from '@styled-icons/bootstrap';
import { useDocumentUploadActions } from 'components/DocumentLibrary/hooks';
import { Dropzone } from 'components/FileUpload';
import { IDropzoneProps } from 'components/FileUpload/Dropzone';
import { ActionButton, ErrorMessage, FileNameText } from 'components/FileUpload/styles';
import { FlexBox } from 'components/Structure';
import { useUploadState } from 'components/hooks';
import { ThemeTypography } from 'designSystem';
import { FieldProps, useField } from 'formik';
import { DELETE_DOCUMENTS } from 'graphql/mutations';
import React, { FC, useEffect, useMemo } from 'react';
import { DropEvent, FileRejection } from 'react-dropzone';
import { booleanish } from 'types/booleanish.types';
import { FileConfiguration } from 'types/dataset.types';
import SeparatorSelector from '../SeparatorSelector';
import UploadItem, { UploadItemContainer } from './UploadItem';

interface IUploadFieldProps {
  multiple?: boolean;
  supportedFileTypes?: IDropzoneProps['supportedFileTypes'];
  fileConfiguration: FileConfiguration;
  onFileConfigurationChange: (fileConfiguration: FileConfiguration) => void;
}

interface IUploadFile {
  id: string;
  title: string;
  inUploadContext: boolean;
}

const Background = styled(Box)<{ error?: booleanish }>(({ theme, error }) => ({
  background:
    error === 'true' ? theme.custom.themeColors.error[20] : theme.custom.themeColors.primary[5],
  borderRadius: 4,
  padding: theme.spacing(1),
}));

/**
 * Need to access the UploadContext so wrap the UploadField in the UploadProvider
 */
const UploadField: FC<IUploadFieldProps & FieldProps<IUploadFile[]>> = ({
  supportedFileTypes,
  multiple,
  fileConfiguration,
  field: { name, value },
  onFileConfigurationChange,
}) => {
  const [, { touched, error }, { setValue, setError, setTouched }] = useField<
    IUploadFile[] | undefined
  >(name);
  const { fileIDs, fileMap } = useUploadState();
  const { onDrop, maxSize } = useDocumentUploadActions();

  const [_deleteDocument] = useMutation<{ deleteDocument: { ids: string } }, { ids: string[] }>(
    DELETE_DOCUMENTS
  );

  // check if there are any files with the .csv extension
  const hasCsvFiles = value.some(({ title }) => title.endsWith('.csv'));

  useEffect(() => {
    if (fileIDs.length) {
      const datasetRecordIds = fileIDs
        .filter(id => fileMap[id].contextObject.formikName === name && fileMap[id].recordId)
        .map(id => ({
          id: fileMap[id].recordId,
          title: fileMap[id].data.name,
          inUploadContext: true,
        }));
      setValue(
        [
          ...value.filter(
            ({ id }) => datasetRecordIds.findIndex(record => id === record.id) === -1
          ),
          ...datasetRecordIds,
        ],
        false
      );
      let index: number;
      if ((index = fileIDs.findIndex(id => fileMap[id].error)) !== -1) {
        setError(fileMap[fileIDs[index]].error);
      } else {
        setError(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, fileIDs, fileMap]);

  const contextFileIds = useMemo(
    () =>
      value
        .filter(({ inUploadContext }) => inUploadContext)
        .map(file => fileIDs.find(id => file.id === fileMap[id].recordId))
        .filter(id => id !== undefined) as string[],
    [value, fileIDs, fileMap]
  );

  const nonContextFiles = useMemo(
    () => value.filter(({ inUploadContext }) => !inUploadContext),
    [value]
  );

  useEffect(() => {
    if (contextFileIds.length) {
      setTouched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextFileIds]);

  const hasFilesAttached = value.length > 0;
  const fileUploadErrors = contextFileIds.filter(id => !!fileMap[id].error);
  // Not sure why the formik error is not correctly typed as string[]
  const fileParsingErrors =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    touched && hasFilesAttached && error ? (Array.isArray(error) ? error : [error]) : [];
  const fileParsingErrorCount = fileParsingErrors.filter(Boolean).length;

  const handleDrop = (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
    // Add formikName to the file object so we can track which field the file belongs to
    onDrop(acceptedFiles, fileRejections, { formikName: name, category: 'GEOGRAPHICAL_FEATURES' });
  };

  const handleDeleteDocument = (documentId: string) => {
    setTouched(true);
    setValue(value?.filter(file => file.id !== documentId) || [], true); // delete the file from the formik state
    _deleteDocument?.({ variables: { ids: [documentId] } });
  };

  return (
    <Box>
      {!hasFilesAttached && touched && (
        <Background error="true" mb={2}>
          <ThemeTypography variant="BODY_MEDIUM_BOLD" color="RED">
            Upload at least one file
          </ThemeTypography>
        </Background>
      )}
      {fileParsingErrorCount > 0 && (
        <Background error="true" mb={2}>
          <ThemeTypography variant="BODY_MEDIUM_BOLD" color="RED">
            Note and address the errors here in order to include all your geo-data for your due
            diligence.
          </ThemeTypography>
        </Background>
      )}

      {/* Hide the dropzone if its single file upload and there is already an uploaded file */}
      {(multiple || (!multiple && !hasFilesAttached)) && (
        <Dropzone
          supportedFileTypes={supportedFileTypes}
          maxFileMb={maxSize}
          multiple={multiple}
          onDrop={handleDrop}
        />
      )}

      {hasFilesAttached && (
        <Box mb={2}>
          <SeparatorSelector
            fileConfiguration={fileConfiguration}
            displayCsvSeparator={hasCsvFiles}
            onFileConfigurationChange={onFileConfigurationChange}
          />
        </Box>
      )}

      <FlexBox mb={2} gap={2}>
        {hasFilesAttached && (
          <Background>
            <ThemeTypography variant="BODY_MEDIUM_BOLD">
              {value.length} {value.length > 1 ? 'files' : 'file'}
            </ThemeTypography>
          </Background>
        )}
        {!!fileUploadErrors.length && (
          <Background error="true">
            <ThemeTypography variant="BODY_MEDIUM_BOLD" color="RED">
              {fileUploadErrors.length} upload errors
            </ThemeTypography>
          </Background>
        )}
        {fileParsingErrorCount > 0 && (
          <Background error="true">
            <ThemeTypography variant="BODY_MEDIUM_BOLD" color="RED">
              {fileParsingErrorCount} parsing errors
            </ThemeTypography>
          </Background>
        )}
      </FlexBox>

      <Box maxHeight={1000} overflow="auto">
        {contextFileIds?.map((id, index) => {
          return (
            <UploadItem
              key={id}
              id={id}
              processingError={fileParsingErrors[index]}
              onFileDelete={handleDeleteDocument}
            />
          );
        })}

        {nonContextFiles.map(({ id, title }, index) => (
          <UploadItemContainer key={id}>
            <Box display="flex" alignItems="center" width="90%">
              <FileEarmarkText size={28} />
              <Box display="flex" flexGrow={1} flexDirection="column" width="70%" gap={0.5}>
                <Box>
                  <FileNameText>{title}</FileNameText>
                </Box>
                {fileParsingErrors[index - (contextFileIds?.length || 0)] && (
                  <ErrorMessage error>
                    {fileParsingErrors[index - (contextFileIds?.length || 0)]}
                  </ErrorMessage>
                )}
              </Box>
            </Box>
            <ActionButton onClick={() => handleDeleteDocument(id)}>
              <Trash size={14} />
            </ActionButton>
          </UploadItemContainer>
        ))}
      </Box>
    </Box>
  );
};

export default UploadField;
