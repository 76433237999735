import { Box } from '@mui/material';
import { DueDiligenceProcessProvider } from 'components/DueDiligenceProcess/Context/DueDiligenceProcessContext';
import { PageContainer, PageTitle } from 'components/Structure';
import { usePageTitle } from 'components/hooks';
import React, { FC } from 'react';

const EudrDueDiligenceProcess: FC = () => {
  usePageTitle('Due diligence process');

  return (
    <PageContainer>
      <PageTitle
        title="Geo-data analysis guide"
        titleExtension="EUDR"
        goBackLabel="Compliance overview"
        goBackUrl="/compliance/eudr"
      />

      <Box mb={3} />
      {/* The provider maintains the content and its children */}
      <DueDiligenceProcessProvider />
    </PageContainer>
  );
};

export default EudrDueDiligenceProcess;
