import React, { FC } from 'react';
import { DialogContent, styled, Dialog, Box } from '@mui/material';
import { IDefaultDialogProps } from 'types/dialog.types';
import Icon from 'designSystem/Primitives/Icon/Icon';

interface IResultImageDialogProps extends IDefaultDialogProps {
  imageSrc: string;
  legendSrc?: string;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
}));

const StyledContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: 500,
  height: 500,
  backgroundColor: theme.custom.themeColors.white,
  borderRadius: 8,
  margin: theme.spacing(1.2),
  padding: theme.spacing(2),
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  width: 230,
  height: 'auto',
  maxHeight: 450,
}));

const Image = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
}));

const LegendImage = styled('img')(({ theme }) => ({
  width: 100,
  height: 'auto',
  borderRadius: 8,
  padding: theme.spacing(1),
  boxShadow: theme.custom.shadows[1],
}));

const CloseContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  top: 0,
  right: 0,
  borderRadius: '50%',
  padding: theme.spacing(1),
  cursor: 'pointer',
}));

const ResultImageDialog: FC<IResultImageDialogProps> = ({ onClose, open, imageSrc, legendSrc }) => {
  return (
    <StyledDialog onClose={onClose} open={!!open}>
      <CloseContainer onClick={onClose}>
        <Icon name="cross" size="small" color="gray-40" />
      </CloseContainer>
      <StyledContent>
        <Box
          position="absolute"
          top={32}
          right={32}
          display="flex"
          width="100%"
          justifyContent="flex-end"
        >
          {legendSrc && <LegendImage src={legendSrc} />}
        </Box>
        <ImageContainer>
          <Image src={imageSrc} />
        </ImageContainer>
      </StyledContent>
    </StyledDialog>
  );
};

export default ResultImageDialog;
