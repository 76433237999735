import { Box } from '@mui/material';
import useDatasetMutation from 'components/ComponentsLibrary/hooks/useDatasetMutation';
import { useGeoDataCollection } from 'components/DueDiligenceProcess/Context/SubSections/GeoDataCollectionContext';
import DueDiligenceProcessSubSectionNavigation from 'components/DueDiligenceProcess/DueDiligenceProcessSubSectionNavigation';
import {
  CSV_SEPARATOR_OPTIONS,
  DECIMAL_SEPARATOR_OPTIONS,
} from 'components/DueDiligenceProcess/utils/eudrCompliance.utils';
import { useMessages, useUploadState } from 'components/hooks';
import {
  IGeoUploadFormValue,
  IGeoUploadFormValues,
  geoUploadDatasetSchema,
  geoUploadSchema,
} from 'constants/schemas/geoUpload.schema';
import { Form, Formik, FormikProps } from 'formik';
import { IProcessDatasetInput } from 'graphql/mutations/types/dataset-mutation.types';
import React, { FC, useMemo, useRef, useState } from 'react';
import { EDatasetErrorEntity, EDatasetStatus } from 'types/dataset.types';
import FormikTabs from '../FormikTabs';
import DatasetUploadForm from '../Forms/DatasetUploadForm';

const GeoDataCollectionUpload: FC = () => {
  const formRef = useRef<FormikProps<IGeoUploadFormValues>>(null);
  const { setErrorMessage } = useMessages();
  const { handleCleanCreatedRecords, cleanFiles, undoFileUploads, removeFile } = useUploadState();
  const { processedDatasets, onNextClick, setProcessedDatasets, onPreviousClick } =
    useGeoDataCollection();
  const { createDatasets, deleteDataset, startDatasetPreProcessing, updateDatasets } =
    useDatasetMutation();

  const [selectedDatasetIndex, setSelectedDatasetIndex] = useState<number>(0);

  // Ideally we set the initial values based on the statement, but ths is currently not since we would need to download the files again and put them back into the upload context
  // This is a huge issue since since now we need to make sure to delete datasets if something goes wrong otherwise we have orphaned datasets on the statement that you can not delete
  const initialValues: IGeoUploadFormValues = useMemo(
    () =>
      processedDatasets?.datasets?.length
        ? processedDatasets.datasets.map(dataset => ({
            ...geoUploadDatasetSchema.default(),
            datasetId: dataset.id,
            title: dataset.title,
            rawMaterialId: dataset.rawMaterial.id,
            countryCode: dataset.originCountry,
            ownedBy: dataset.ownedBy,
            allowMultipleFiles: false,
            fileConfiguration: {
              csvSeparator: undefined,
              decimalSeparator: undefined,
            },
            files: dataset.documents.map(document => ({
              id: document.id,
              title: document.title,
              inUploadContext: false,
            })),
          }))
        : geoUploadSchema.default(),
    [processedDatasets]
  );

  /**
   * If no datasets are processed yet, get rid of files in the uploadContext
   */
  const handlePreviousClick = () => {
    undoFileUploads();
    handleCleanCreatedRecords();
    cleanFiles();
    onPreviousClick();
  };

  const handleSubmit = async (values: IGeoUploadFormValues) => {
    try {
      if (values.length === 0) {
        throw new Error('The form values are not defined');
      }

      // Create helper array to keep track of the created datasets and their index in the form
      const datasetsToCreate: { value: IGeoUploadFormValue; originalValueIndex: number }[] = values
        .map((value, index) => ({
          value,
          originalValueIndex: index,
        }))
        .filter(({ value: { datasetId } }) => !datasetId);
      // Create the datasets that were not created and update existing datasets
      const datasetCreationResults = await createDatasets(
        datasetsToCreate.map(({ value }) => value) // The datasets were not created yet
      );
      if (datasetCreationResults.length) {
        // Update form with the created dataset ids
        // We need to be careful to update the correct form values in the correct order since they can be dataset already existing between
        formRef.current?.setValues(
          values.map((value, index) => {
            const creationIndex = datasetsToCreate.findIndex(
              ({ originalValueIndex }) => originalValueIndex === index
            );
            if (creationIndex !== -1) {
              const datasetId =
                datasetCreationResults[creationIndex].data?.createDataset?.dataset?.id;
              if (datasetId) {
                return {
                  ...value,
                  datasetId,
                };
              }
            }
            return value;
          }),
          false
        );
      }

      const datasetUpdateResults = await updateDatasets(
        values.filter(({ datasetId }) => !!datasetId) as (IGeoUploadFormValue & {
          datasetId: string;
        })[] // The value were datasetId are set were already created
      );

      // Build unique array with all existing dataset ids
      const datasetIds = [
        ...datasetCreationResults.map(result => result.data?.createDataset.dataset.id),
        ...datasetUpdateResults.map(result => result.data?.updateDataset.dataset.id),
      ].filter(datasetId => !!datasetId) as string[];

      if (datasetIds.length === 0) {
        throw new Error('An issue occurred while creating the datasets');
      }
      const inputs: IProcessDatasetInput[] = datasetIds.map(id => {
        const fileConfig = values[0]?.fileConfiguration;
        return {
          datasetId: id,
          decimalSeperator:
            (fileConfig?.decimalSeparator &&
              DECIMAL_SEPARATOR_OPTIONS[fileConfig.decimalSeparator].value) ||
            undefined,
          csvSeperator:
            (fileConfig?.csvSeparator && CSV_SEPARATOR_OPTIONS[fileConfig.csvSeparator].value) ||
            undefined,
        };
      });
      const response = await startDatasetPreProcessing(inputs);

      if (!response.data?.preProcessDatasets?.datasets?.length) {
        throw new Error('No datasets were created');
      }

      const processedDatasets = response.data?.preProcessDatasets;
      const datasetProcessingErrors = processedDatasets?.datasetProcessingErrors;

      if (response.errors?.length) {
        throw new Error('Unidentified errors occurred in the backend');
        // If some files could not be processed, we can not continue
      } else if (
        processedDatasets.datasets.some(({ status }) => status === EDatasetStatus.PARSING_FAILED)
      ) {
        // If there are any parsing errors in the dataset we try to show them on the correct file in the upload form
        if (
          datasetProcessingErrors?.length &&
          datasetProcessingErrors.some(({ datasetErrors }) =>
            datasetErrors.some(({ entityType }) => entityType === EDatasetErrorEntity.DOCUMENT)
          )
        ) {
          // We need to place the error at the correct array index of the form
          // 1. Find the index of the datasetId in the form values matching the processingError
          // 2. Find the index of the file in the files array matching the processingError
          const parsingErrors = formRef.current?.values.map(({ datasetId, files }) => {
            const errors = datasetProcessingErrors.find(
              ({ datasetId: errorDatasetId }) => errorDatasetId === datasetId
            )?.datasetErrors;
            if (errors?.some(({ entityType }) => entityType === EDatasetErrorEntity.DOCUMENT)) {
              return {
                datasetId,
                files: files.map(({ id }) => {
                  const documentError = errors.find(
                    ({ entityType, entityId }) =>
                      entityType === EDatasetErrorEntity.DOCUMENT && entityId === id
                  );
                  return documentError ? documentError.errorMessage : undefined;
                }),
              };
            }
            return undefined;
          });
          // @ts-ignore
          uploadFormRef.current?.setErrors(parsingErrors);
        } else {
          throw new Error(
            'The dataset errors occurred while parsing the dataset could not be matched to the files in the form'
          );
        }

        // If there are mapping errors we show the mapping form or if there are no errors the dataset got created and we show the it in the dataset overview
      } else if (
        (processedDatasets.datasets.some(
          ({ status }) => status === EDatasetStatus.MAPPING_FAILED
        ) &&
          processedDatasets.datasetMappings?.length) ||
        processedDatasets?.datasets
      ) {
        setProcessedDatasets(processedDatasets);
        onNextClick();
      } else {
        throw new Error(
          'Unknown error occurred submitting the mapping and processing. No dataset was created.'
        );
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(
        'At least one error occurred while processing the dataset. If the error persists, please contact support.'
      );
    } finally {
      formRef.current?.setSubmitting(false);
    }
  };

  const handleDeleteDatasetAndFiles = (dataset: {
    id: string | undefined;
    documents: {
      id: string;
    }[];
  }) => {
    // Remove all files from the upload context
    dataset.documents.forEach(document => {
      removeFile(document.id, true);
    });
    if (dataset.id) {
      deleteDataset(dataset as { id: string; documents: { id: string }[] });
    }
  };

  return (
    <Formik<IGeoUploadFormValues>
      innerRef={formRef}
      enableReinitialize
      validationSchema={geoUploadSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ isValid, errors, isSubmitting, submitForm }) => (
        <Form>
          <DueDiligenceProcessSubSectionNavigation
            allowNextStepNavigation={isValid && !errors.length}
            nextStepLoading={isSubmitting}
            customNextStepButton={{ text: 'Upload & convert' }}
            onNextStepClick={submitForm}
            onPreviousStepClick={handlePreviousClick}
          >
            <FormikTabs
              selectedIndex={selectedDatasetIndex}
              onChange={setSelectedDatasetIndex}
              onDeleteDataset={handleDeleteDatasetAndFiles}
            />

            <Box mb={4}>
              <DatasetUploadForm isEUDR datasetIndex={selectedDatasetIndex} />
            </Box>
          </DueDiligenceProcessSubSectionNavigation>
        </Form>
      )}
    </Formik>
  );
};

export default GeoDataCollectionUpload;
