import { Box, BoxProps } from '@mui/material';
import Skeleton from 'designSystem/DataDisplay/Skeleton/Skeleton';
import documentsBackground from 'assets/img/cards/documents-background.png';
import detailedDocumentsBackground from 'assets/img/cards/certifications-background.png';
import rectangleBackground from 'assets/img/cards/rectangle-background.png';
import sexagonBackground from 'assets/img/cards/sexagon-background.png';
import worldBackground from 'assets/img/cards/world-background.png';
import ProductSummarySkeleton from 'components/Product/ProductTable/ProductSummarySkeleton';
import React, { Fragment, ReactNode, useMemo } from 'react';
import { BackgroundTypes, AvailableSizes } from 'types/enums';
import theme from 'styles/theme';
import MapLayers from 'assets/img/cards/map-layers-empty.png';
import { styled } from '@mui/material/styles';

type BorderStyle = 'default' | 'selected' | 'none';

interface ContainerProps extends Omit<BoxProps, 'background'> {
  background?: BackgroundTypes;
  hasShadow?: boolean;
  borderStyle?: BorderStyle;
  blueHover?: boolean;
  disabled?: boolean;
}

const BORDER: Record<BorderStyle, string> = {
  default: `1px solid ${theme.custom.themeColors.grayScale[10]}`,
  selected: `2px solid ${theme.custom.themeColors.primary[100]}`,
  none: 'unset',
};

const Container = styled(Box)<ContainerProps>(
  ({ background, hasShadow, borderStyle = 'none', blueHover, disabled }) => {
    const placeBackgroundImageRight =
      background === BackgroundTypes.WORLD ||
      background === BackgroundTypes.DETAILED_DOCUMENTS ||
      background === BackgroundTypes.MAP_LAYERS_RIGHT;
    const shrinkBackgroundImage = background === BackgroundTypes.DOCUMENTS;

    return {
      position: 'relative',
      maxWidth: '100%',
      backgroundColor: '#fff',
      backgroundImage: background ? `url(${getImageUrl(background)})` : undefined,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: placeBackgroundImageRight ? 'right bottom' : 'left bottom',
      backgroundSize: shrinkBackgroundImage ? 'auto 70%' : 'auto 100%',
      opacity: disabled ? 0.6 : 1,
      borderRadius: 6,
      flex: '1',
      boxShadow: hasShadow
        ? background
          ? '0px 4px 26px rgba(0, 0, 0, 0.12), inset 0 0 40px 25px #fff'
          : '0px 4px 26px rgba(0, 0, 0, 0.12)'
        : 'unset',
      overflow: 'hidden',
      border: BORDER[borderStyle],
      cursor: blueHover ? 'pointer' : 'inherit',

      '&:hover': {
        backgroundColor: blueHover ? theme.custom.themeColors.primary[5] : '#fff',
      },
    };
  }
);

interface TitleProps {
  size?: AvailableSizes;
}

const CardTitle = styled('p')<TitleProps>(({ theme, size }) => ({
  fontSize: size === 'SMALL' ? '20px' : size === 'MEDIUM' ? '27px' : '32px',
  margin: 0,
  fontWeight: 700,
  maxWidth: 650,
  paddingBottom: size === 'LARGE' ? theme.spacing(6) : theme.spacing(2),
  lineHeight: '1.2em',
}));

const IllustrationContainer = styled(Box)(() => ({
  '@media screen and (max-width: 1000px)': {
    display: 'none',
  },
}));

interface Props {
  title?: string | ReactNode;
  padding?: number;
  size?: AvailableSizes;
  background?: BackgroundTypes;
  renderIllustration?: ReactNode;
  loading?: boolean;
  /** @default true */
  hasShadow?: boolean;
  /** @default false */
  hasBorder?: boolean;
  /** @default false */
  blueHover?: boolean;
  /** @default false */
  selected?: boolean;
  /** @default false with less opacity */
  disabled?: boolean;
}

const CardContainer: React.FC<Props & Omit<BoxProps, 'title'>> = ({
  title,
  size,
  background,
  renderIllustration,
  children,
  padding = 3,
  loading,
  hasShadow = true,
  hasBorder = false,
  blueHover = false,
  selected = false,
  ...rest
}) => {
  const borderStyle = useMemo(() => {
    if (selected) {
      return 'selected';
    }
    if (hasBorder) {
      return 'default';
    }
    return 'none';
  }, [selected, hasBorder]);

  return (
    <Container
      padding={padding}
      display="flex"
      background={background}
      hasShadow={hasShadow}
      blueHover={blueHover}
      borderStyle={borderStyle}
      {...rest}
    >
      {loading ? (
        <Box display="flex" flexDirection="column" flex={1} width="100%">
          {size === 'LARGE' && (
            <CardTitle size={size}>
              <Skeleton width={300} variant="text" />
            </CardTitle>
          )}
          {size === 'LARGE' || size === 'MEDIUM' ? (
            <Container padding={1} maxWidth={500} mb={2}>
              <ProductSummarySkeleton loading />
            </Container>
          ) : (
            <Fragment>
              <Skeleton variant="text" width={200} />
              <Skeleton variant="text" width="100%" />
            </Fragment>
          )}
          {(size === 'LARGE' || size === 'MEDIUM') && (
            <Box mt="auto" display="flex">
              <Skeleton variant="rectangular" width={150} height={30} />
              <Skeleton variant="rectangular" width={150} height={30} />
            </Box>
          )}
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" flex={1} maxWidth="100%">
          {title && <CardTitle size={size}>{title}</CardTitle>}
          {children}
        </Box>
      )}
      <IllustrationContainer>{renderIllustration}</IllustrationContainer>
    </Container>
  );
};

export default CardContainer;

function getImageUrl(imageType: BackgroundTypes) {
  if (!imageType) return null;

  return {
    WORLD: worldBackground,
    SEXAGON: sexagonBackground,
    RECTANGLE: rectangleBackground,
    DOCUMENTS: documentsBackground,
    DETAILED_DOCUMENTS: detailedDocumentsBackground,
    MAP_LAYERS: MapLayers,
    MAP_LAYERS_RIGHT: MapLayers,
  }[imageType];
}
