import { useMutation, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Loader } from 'components/Forms';
import { useCompanyFeatureFlags, useMessages } from 'components/hooks';
import Container from 'components/Structure/PageContainer';
import DesignForm from 'components/Settings/DesignForm';
import { ErrorState, FeatureOverlay } from 'components/Structure';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import { UPDATE_COMPANY_CONFIGS } from 'graphql/mutations';
import { GET_COMPANY_DESIGN } from 'graphql/queries';
import React, { Fragment } from 'react';
import SettingsHeader from '../components/Settings/SettingsHeader';
import { CompanyFeature } from 'types/company.types';

const OverflowContainer = styled(Container)(() => ({
  position: 'relative',
  overflow: 'visible',
}));

const DesignSettings = () => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { data: { company: { design } } = {}, error, loading } = useQuery(GET_COMPANY_DESIGN);
  const { isCompanyFeatureEnabled } = useCompanyFeatureFlags();
  const designFeatureEnabled = isCompanyFeatureEnabled(CompanyFeature.WHITE_LABELING);

  const { setSuccessMessage, setErrorMessage } = useMessages();
  const [updateCompanyConfigs] = useMutation(UPDATE_COMPANY_CONFIGS, {
    onCompleted: () => {
      setSuccessMessage('Success! Company details were updated.');
    },
    onError: () => {
      setErrorMessage('Something went wrong updating your companies details.');
    },
  });

  const handleSubmit = values => {
    return updateCompanyConfigs({
      variables: {
        input: {
          design: values,
        },
      },
    });
  };

  if (error) return <ErrorState error={error} />;
  if (!design && loading) return <Loader />;

  return (
    <Fragment>
      <OverflowContainer>
        {!designFeatureEnabled && <FeatureOverlay />}
        <SettingsHeader
          title="Branding"
          subtitle="Add your corporate identity to style your product stories."
        />
        <DesignForm onSubmit={handleSubmit} design={design} fullWidth={smallScreen}>
          {({ submitForm, isSubmitting }) => (
            <Grid container item justifyContent="flex-end" alignItems="center">
              <ThemeButton
                size="large"
                color="YELLOW"
                loading={isSubmitting}
                type="submit"
                onClick={submitForm}
              >
                Save
              </ThemeButton>
            </Grid>
          )}
        </DesignForm>
      </OverflowContainer>
    </Fragment>
  );
};

export default DesignSettings;
